import axios from 'axios';
import url from './url';

export const dataUploadAPI = {
  addQuestions(data) {
    return axios.post(`${url}api/add-questions`, data);
  },
  addQuestionHelper(data) {
    return axios.post(`${url}api/add-question-helper`, data);
  },
  addTasks(data) {
    return axios.post(`${url}api/add-tasks`, data);
  },
};
