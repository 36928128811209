import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DiagnosticWelcome from './diagnosticWelcome';
import DiagnosticTool from './diagnosticTool/diagnosticController';
import DiagnosticReport from './diagnosticReport/diagnosticReport';
import { topicsAPI } from '../../API/topics';
import Loading from '../../designSystem/components/interactiveElements/loading';

import './diagnosticDemo.scss';

export default function Diagnostic(props) {
  const { match } = props;
  const [step, setStep] = React.useState(parseInt(match.params.step, 10));
  const [topic, setTopic] = React.useState(null);

  const getTopics = React.useCallback(async topic_id => {
    topicsAPI.GET.getTopicByID(topic_id)
      .then(response => {
        setTopic(response.data.topic);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  React.useEffect(() => {
    setStep(parseInt(match.params.step, 10));
    getTopics(match.params.topic_id);
  }, [match.params.step, match.params.topic_id]);

  if (!topic)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <>
      {step === 1 && <DiagnosticWelcome topic={topic} />}
      {step === 2 && <DiagnosticTool {...props} />}
      {step === 3 && <DiagnosticReport {...props} />}
    </>
  );
}

Diagnostic.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      topic_id: PropTypes.string.isRequired,
      step: PropTypes.string.isRequired,
    }),
  }),
};
