import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@material-ui/core';
import ToggleButtons from '../designSystem/components/buttons/toggle-buttons';
import TextField from '../designSystem/components/textFields/textField';
import './questions.scss';

QuestionSelection.propTypes = {
  question: PropTypes.any,
  handleAnswer: PropTypes.func.isRequired,
};

QuestionSelection.defaultProps = {
  question: {},
};

export default function QuestionSelection({ question, handleAnswer, forAdd }) {
  const { user, getIdTokenClaims } = useAuth0();

  React.useEffect(() => {
    async function InitiateTesting() {
      const context = await getIdTokenClaims();
      const userRoles = context[process.env.REACT_APP_AUTH_ROLES_NAMESPACE];
      if (userRoles && userRoles.includes('testing')) {
        console.log(question);
      }
    }
    if (user) {
      InitiateTesting();
    }
  }, [user, getIdTokenClaims, question]);

  return (
    <>
      {question.id && <QuestionModel question={question} handleAnswer={handleAnswer} />}
      {forAdd && <QuestionModel question={question} handleAnswer={handleAnswer} />}
    </>
  );
}

function QuestionModel({ question, handleAnswer }) {
  const handleTextField = event => {
    handleAnswer(event, event.target.value);
  };

  const handleToggleButtons = (event, item, index) => {
    handleAnswer(event, item.value);
  };

  return (
    <Grid item xs={12} className="model_one_wrapper">
      <h3 className="question_body_label">{question.title}</h3>
      <h4 className="question_body_description">{question.description}</h4>
      {question.image_url !== null && (
        <img
          alt="model three"
          src={`https://geniusforest.blob.core.windows.net/questions/${question.image_url}`}
          className="m_top_2 question_image"
        />
      )}
      {question.question_options !== null ? (
        <Grid
          className="question_body_buttons m_bottom_2"
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <ToggleButtons
                list={question.question_options}
                height={'100%'}
                width={'100%'}
                value={''}
                handleSelection={(event, index, item) =>
                  handleToggleButtons(event, index, item.value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className="question_text_field_wrapper">
          <TextField
            id="question"
            label="Type your Answer"
            value={question.validation_control.selected_answer}
            helperText={''}
            error={false}
            handleChanges={handleTextField}
            width={window.innerWidth < 600 ? '100%' : 300}
          />
        </div>
      )}
    </Grid>
  );
}

QuestionModel.propTypes = {
  question: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    validation_control: PropTypes.shape({
      selected_answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  handleAnswer: PropTypes.func.isRequired,
};
