import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import '../accountSettings.scss';
import { useHistory } from 'react-router-dom';

export default function StudentGrid(props) {
  const { user, current_subject } = props.learner;
  const history = useHistory();

  const getUserName = () => {
    if (user.name.includes('@')) return user.nickname;
    return user.name;
  };

  const handleStudentPage = () => {
    history.push('/account/settings/' + user.user_id);
    history.go(0);
  };

  return (
    <Grid item className="manageStudents_object_div">
      <Divider width={'100%'} />
      <div className="manageStudents_data_div">
        <img src={user.picture} className="profile_img" alt="profile" width={71} height={71} />
        <span className="student_name">{getUserName()}</span>
        <span className="student_email">{user.email} </span>
        <span className="student_grade">Grade {current_subject}</span>
        <span className="student_password">●●●●●●●●●●●</span>
        <button className="pencil_icon_button">
          <img
            src="/assets/icons/pencil_icon.png"
            width={19}
            height={19}
            className="pencil_icon"
            alt="pencil_icon"
            onClick={handleStudentPage}
          />
        </button>
      </div>
    </Grid>
  );
}
