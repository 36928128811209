import React from 'react';
import Button from '../../../../designSystem/components/buttons/button';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import '../myStudents.scss';

export default function MyStudentEmpty(props) {
  const history = useHistory();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { subscription } = props;

  const getActiveLearners = (learners) => {
    let result = [];
    for (let i = 0; i < learners.length; i++) {
      if (learners[i].status === true) {
        result.push(learners[i])
      }
    }
    return result;
  }

  const getStudentsAvailable = () => {
    const activeLearners = getActiveLearners(subscription.learners)
    const totalStudents = getActiveLearners(subscription.empty_learners).length + activeLearners.length;
    const onboardStudents = activeLearners.length;
    return totalStudents - onboardStudents;
  }

  return (
    <div className="student_page_wrapper">
      <h1>My Students</h1>
      <div className="info_box">
        <div className="right_side">
          <span className="membership_count">
            You have ({subscription && getStudentsAvailable()}) memberships available
          </span>
          <div className="buttons_div">
            <Button
              width={'105%'}
              fontSize={smScreen ? 14 : 16}
              height={'100%'}
              label="Connect an existing student account"
              buttonStyle="default"
              onClick={() => history.push('/my-students/add-existing')}
              addClass="button"
            />
          </div>
          <div className="buttons_div2">
            <Button
              label="Create a new student account"
              width={'105%'}
              height={'100%'}
              fontSize={smScreen ? 14 : 16}
              addClass="button"
              onClick={() => history.push('/my-students/add-new')}
              buttonStyle="default"
            />
          </div>
        </div>
        <img
          src="/assets/images/accountbox_img.png"
          width={'80%'}
          height={'80%'}
          className="info_box_img"
        ></img>
      </div>
    </div>
  );
}
