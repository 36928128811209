import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Grid } from '@material-ui/core';
import ProgressCircle from '../../../../designSystem/components/interactiveElements/progressCircle';
import Loading from '../../../../designSystem/components/interactiveElements/loading';

function PracticeActivity(props) {
  const { practiceActivities } = props;

  const calculateScore = practice => {
    return Math.round(Math.abs((practice.wrong_attempts / practice.attempts) * 100 - 100));
  };

  if (!practiceActivities)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <Grid item xs={12} className="practice_activity">
      <Grid container>
        <Grid item xs={12} className="title">
          <h2 className="title">Practice Activities</h2>
        </Grid>
        <Grid item xs={12}>
          <hr className="custom_hr" />
        </Grid>
        <Grid item xs={12} className="activity_wrapper">
          {practiceActivities.map((practice, index) => (
            <Grid
              key={practice.skill_name}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item className="activity_item">
                <div className="name">{practice.topic_name}</div>
                <div className="skill_name">{practice.skill_name}</div>
                <div className="date">
                  Practiced{' '}
                  <Moment fromNow ago>
                    {new Date(practice.created_at)}
                  </Moment>{' '}
                  ago at <Moment date={new Date(practice.created_at)} format="LT" />
                </div>
              </Grid>
              <Grid item xs={12} sm className="progress_wrapper">
                <ProgressCircle progress_value={calculateScore(practice)} />
              </Grid>
              {index !== practiceActivities.length - 1 && (
                <Grid item xs={12}>
                  <hr className="custom_hr" />
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

PracticeActivity.propTypes = {
  practiceActivities: PropTypes.arrayOf(
    PropTypes.shape({
      topic_name: PropTypes.string,
      skill_name: PropTypes.string,
      created_at: PropTypes.string,
      length: PropTypes.number,
    }),
  ).isRequired,
};

export default PracticeActivity;
