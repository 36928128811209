import React from 'react';
// import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DifficultLevel from '../../../../designSystem/components/interactiveElements/difficultLevel';

function SkillInformation(props) {
  const { skills, selected_skill } = props;

  const current_skill = skills[selected_skill];

  const tasks_plan = current_skill.tasks.slice(0, 3);
  const skill_wrong_attempts = current_skill.validation_control.wrong_attempts;
  const skill_attempts = current_skill.validation_control.attempts_counter;

  const learner_progress = Math.abs((skill_wrong_attempts / skill_attempts) * 100 - 100);

  const calculateLevel = skill => {
    const difficult_level = Math.abs((skill_wrong_attempts / skill_attempts) * 100 - 100);
    if (difficult_level < 50) return 'Fundamental';
    if (difficult_level > 50 && difficult_level < 70) return 'Intermediate';
    if (difficult_level > 70 && difficult_level < 90) return 'Advanced';
    if (difficult_level > 90 && difficult_level <= 100) return 'Master';
  };

  const calculateDifficultLevel = () => {
    if (learner_progress <= 20) return 1;
    if (learner_progress > 20 && learner_progress <= 40) return 2;
    if (learner_progress > 40 && learner_progress <= 60) return 3;
    if (learner_progress > 60 && learner_progress <= 80) return 4;
    if (learner_progress > 80) return 5;
  };

  return (
    <Grid item className="skill_information">
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item className="skill_name">
          {current_skill.skill_name}
        </Grid>
        <Grid item className="skill_level_title">
          Current level
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className="skill_level_wrapper-mobile"
        spacing={2}
      >
        <Grid item className="skill_level_title">
          Current level
        </Grid>
        <Grid item className="skill_level">
          {calculateLevel(current_skill)}
        </Grid>
      </Grid>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item className="skill_level">
          {calculateLevel(current_skill)}
        </Grid>
      </Grid>
      <div>
        <hr className="custom_hr" />
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12} className="practice_plan_wrapper">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className="practice_plan_top"
            >
              <Grid item>
                <Grid container>
                  <Grid item xs={12} className="skill_name">
                    Practice Plan
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={12} className="skill_level_title">
                    Difficulty
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                {tasks_plan.map((item, index) => (
                  <Grid item xs={12} key={item.name} className="unit_name">
                    <div className="list_icon">
                      <img
                        src="/assets/icons/list_white_icon.svg"
                        alt="Active Icon"
                        aria-hidden={true}
                      />
                    </div>
                    {item.name}
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                {tasks_plan.map((item, index) => (
                  <Grid item xs={12} key={`question ${index}`} className="unit_difficult">
                    <DifficultLevel difficult_level={calculateDifficultLevel()} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SkillInformation.propTypes = {};

export default SkillInformation;
