import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@material-ui/core';
import { questionsAPI } from '../../../API/questions';
import Loading from '../../../designSystem/components/interactiveElements/loading';
import QuestionForm from './components/questionForm';
import './question.scss';
import { useHistory } from 'react-router-dom';
import DefaultAlert from '../../../designSystem/components/alerts/defaultAlert';

function Question(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { question_id } = props.match.params;
  const history = useHistory();

  const [question, setQuestion] = React.useState();
  const [initial_level, setInitialLevel] = React.useState();
  const [level_assigned, setLevelAssigned] = React.useState(false);

  const addInitialFilter = name => {
    const session_filter = JSON.parse(sessionStorage.getItem('questions_filter'));
    if (name === 'course_id' && !session_filter) return '';
    if (session_filter) return session_filter[name] ? session_filter[name] : undefined;
  };

  const [filters, setFilters] = React.useState();
  const [filter, setFilter] = useState({
    course_id: addInitialFilter('course_id'),
    subject_id: addInitialFilter('subject_id'),
    topic_id: addInitialFilter('topic_id'),
    skill_id: addInitialFilter('skill_id'),
    task_id: addInitialFilter('task_id'),
  });

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert(
      {
        open: true,
        severity,
        title,
        body,
      },
      setTimeout(() => {
        setDefaultAlert({
          open: false,
          severity,
          title,
          body,
        });
      }, 5000),
    );
  };

  const getQuestion = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    questionsAPI.GET.getQuestionById(question_id, token)
      .then(response => {
        setQuestion(response.data);
        setInitialLevel(response.data.difficult_level)
      })
      .catch(error => {
        console.log(error);
      });
  });

  const getFilters = async () => {
    const token = await getAccessTokenSilently();
    await questionsAPI.GET.getFilteredQuestions(token)
        .then(response => { 
            setFilters(response.data.filter_options);
        })
        .catch(error => {
            console.log(error);
        })
  }

  const isLevelAssigned = (new_data) => {
    return new_data.difficult_level !== initial_level;
  }

  const updateQuestion = async editData => {
    const token = await getAccessTokenSilently();
    const is_level_assigned = isLevelAssigned(editData);
    await questionsAPI.PUT.editQuestion(question_id, is_level_assigned, editData, token)
      .then(response => {
        handleAlert('success', 'The Question was successfully updated');
        //TODO: The API should return the question
        // setQuestion(response.data);
        getQuestion();
      })
      .catch(error => {
        const { title, body } = error.response.data;
        handleAlert('error', title, body);
      });
  };

  React.useEffect(() => {
    const fetchQuestionsData = async () => {
      await getQuestion();
    };
    fetchQuestionsData();
  }, []);


  useEffect(() => {
    getFilters();
  },[])

  const handleFilter = (name, value) => {
    switch (name) {
      case 'course_id':
        setFilter(oldObject => ({
          ...oldObject,
          [name]: value,
          subject_id: '',
          topic_id: undefined,
          skill_id: undefined,
          task_id: undefined,
        }));
        filter.subject_id = '';
        break;
      case 'subject_id':
        setFilter(oldObject => ({
          ...oldObject,
          [name]: value,
          topic_id: '',
          skill_id: undefined,
          task_id: undefined,
        }));
        filter.topic_id = '';
        break;
      case 'topic_id':
        setFilter(oldObject => ({
          ...oldObject,
          [name]: value,
          skill_id: '',
          task_id: undefined,
        }));
        filter.skill_id = '';
        break;
      case 'skill_id':
          setFilter(oldObject => ({
            ...oldObject,
            [name]: value,
            task_id: '',
          }));
      default:
        setFilter(oldObject => ({
          ...oldObject,
          [name]: value,
        }));
        break;
    }
  };

  if (!question || !filters)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <>
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      <h1 className="m_top_5">Question #{question.id}</h1>
      <div
        id="backButton"
        className="interactive_element"
        onClick={() => history.push(`/admin-portal/questions/`)}
      >
        <div className="button_container">
          <img src="/assets/icons/back_circle.svg" alt="back circle" />
          <img src="/assets/icons/back_arrow.svg" className="arrow" alt="back arrow" />
        </div>
        <p>Back</p>
      </div>
      <QuestionForm 
        question={question} 
        update={updateQuestion}
        filters={filters}
        selectedFilter={filter} 
        handleFilter={handleFilter} 
      />
    </>
  );
}

Question.propTypes = {};

export default Question;
