import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';
import Button from '../../../designSystem/components/buttons/button';
import ButtonIconRound from '../../../designSystem/components/buttons/round-icon-button';
import QuestionPreview from '../../../designSystem/components/questionPreview/questionPreview';

function CourseBox({ topics, handleDiagnose }) {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [expand, setExpand] = React.useState(false);
  const [selectedBox, setSelectedBox] = React.useState(null);
  const [selectedSkill, setSelectedSkill] = React.useState(null);

  const handleExpand = index => {
    setSelectedSkill(null);
    !expand ? setExpand(true) : setExpand(false);
    if (index !== selectedBox) setExpand(true);
    setSelectedBox(index);
  };

  const handlePreview = skill => {
    if (smScreen) return false;
    setSelectedSkill(skill);
  };

  React.useEffect(() => {
    if (smScreen) {
      setSelectedSkill(null);
    }
  }, [smScreen]);

  return (
    <div className="course_box_wrapper page_wrapper">
      {topics.map((topic, index) => (
        <Grid container className="course_box" key={topic.name}>
          <Grid item xs={12} sm={10} md={9}>
            <h2 className="title">{topic.name}</h2>
            <div className="description">{topic.description}</div>
          </Grid>
          <Grid item xs className="mobile_progress">
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Box className="practice_button">
                <Button
                  label="Diagnose"
                  addId="practice_button"
                  onClick={() => handleDiagnose(topic)}
                  width={96}
                  height={44}
                  buttonStyle="secondary"
                  type="button"
                />
              </Box>
              <span className="m_left_15 expand_icon">
                {expand && index === selectedBox ? (
                  <ButtonIconRound arrow_direction="down" handleClick={() => handleExpand(index)} />
                ) : (
                  <ButtonIconRound arrow_direction="up" handleClick={() => handleExpand(index)} />
                )}
              </span>
            </Grid>
          </Grid>
          <Grid
            container
            className={
              expand && index === selectedBox ? 'expansion_section visible' : 'expansion_section'
            }
          >
            <Grid item xs={12}>
              <hr className="custom_hr" />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs>
                  <Grid container>
                    {topic.skills.map((skill, index) => (
                      <Grid item xs={12} key={skill.name}>
                        <div className="links_wrapper">
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            <button
                              role="button"
                              className={`skills_button ${smScreen && 'no_interaction'}`}
                              onClick={() => handlePreview(skill)}
                              tabIndex={smScreen ? -1 : 0}
                            >
                              <span className="list_dot">&#8226;</span>
                              <span className="links">{skill.name}</span>
                            </button>
                          </Grid>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  {selectedSkill && selectedBox === index && (
                    <QuestionPreview skill={selectedSkill} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="m_top_2 mobile_box_controls">
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Box></Box>
              <Box className="practice_button expansion_mobile">
                <Button
                  label="Diagnose"
                  addId="practice_button"
                  onClick={() => handleDiagnose(topic)}
                  width={96}
                  height={44}
                  buttonStyle="secondary"
                  type="button"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}

export default withRouter(CourseBox);
