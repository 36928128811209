import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, useTheme, useMediaQuery, Box } from '@material-ui/core';
import Button from './../../../designSystem/components/buttons/button';

const Section_2 = () => {
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up('lg'));
  let history = useHistory();

  return (
    <Grid container className="section_2_wrapper landing_page_wrapper">
      <div className="section_two_image_container">
        <img
          src="/assets/images/section_two_background_image_one.png"
          alt="section_two_background_image_one"
          className="section_two_image_one"
        />
        <img
          src="/assets/images/section_two_background_image_two.png"
          alt="section_two_background_image_two"
          className="section_two_image_two"
        />
      </div>
      <Grid item xs={12} lg={4} className="benefit_text_wrapper">
        <h2>How Genius Forest Benefits You</h2>
        <p className="sub_text_one">
          Our 3 step process ensures learning plans are personalized to your unique skill level.
        </p>
        <Button
          label="Try live demo"
          width={171}
          height={57}
          buttonStyle="default"
          onClick={() =>
            history.push(`/diagnostic-demo/${Math.floor(Math.random() * (5 - 1 + 1) + 1)}/1`)
          }
          addClass="demo_button try_demo"
        />
      </Grid>
      <Grid item xs={12} md className="benefit_cards_wrapper">
        <Grid
          container
          direction="row"
          justifyContent={lgScreen ? 'space-between' : 'center'}
          alignItems="center"
          className="benefits_grid"
        >
          <Grid item id="card_1" className="benefit_card" tabIndex={0}>
            <h2>1</h2>
            <div className="benefit_card_text">
              <h3>Tailor</h3>
              <p>Access your skills to create a learning path tailored to fit your goals.</p>
            </div>
          </Grid>
          <Grid item id="card_2" className="benefit_card" tabIndex={0}>
            <h2 style={{ color: '#F99F20' }}>2</h2>
            <div className="benefit_card_text">
              <h3>Identify</h3>
              <p>Identify your strong points and focus on skills you need to improve.</p>
            </div>
          </Grid>
          <Grid item id="card_3" className="benefit_card" tabIndex={0}>
            <h2 style={{ color: '#F05023' }}>3</h2>
            <div className="benefit_card_text">
              <h3>Progress</h3>
              <p>Monitor your learning history so you can see your progress overtime.</p>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="section_two_mobile">
        <Button
          label="Try live demo"
          width={150}
          height={57}
          buttonStyle="default"
          onClick={() =>
            history.push(`/diagnostic-demo/${Math.floor(Math.random() * (5 - 1 + 1) + 1)}/1`)
          }
          addClass="demo_button_mobile try_demo"
        />
        {/* <p className="sub_text_two_mobile">Try the live demo of the diagnostic tool by yourself, or with your child!</p> */}
      </Grid>
    </Grid>
  );
};

export default Section_2;
