import React from 'react';
import TextField from '../../../../designSystem/components/textFields/textField';
import Button from '../../../../designSystem/components/buttons/button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { authAPI } from "../../../../API/auth";
import AcceptDialog from "./acceptDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={150} ref={ref} {...props} />;
});

export default function ChangePassword(props) {
  // either with async/await
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('601'));
  const { user, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const { open, handleClose, handleFormSubmit, user_id, learner } = props;
  const useFormMethods = useForm();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setError
  } = useFormMethods;

  const [form, setForm] = React.useState([])
  const [acceptOpen, setAcceptOpen] = React.useState(false);

  const handleAcceptClose = () => {
    document.getElementById('password_dialog').style.display = 'block';
    setAcceptOpen(false);
  }

  const validateCurrentPassword = async (password) => {
    let result = true;
    const userEmail = learner ? learner.email : user.email;
    await authAPI.POST.checkUserPassword(userEmail, password).then((response) => {
      console.log(response);
      return result = true;
    }).catch((error) => {
      console.log(error);
      setError('current_password', { message: 'Invalid current password.' });
      return result = false
    })
    return result;
  }

  const handleButtonClick = async formData => {
    const validation = await validateCurrentPassword(formData.current_password);
    if (!validation) return false;
    const data = {
      password: formData.password,
      user_id: user_id,
    };
    if (learner && learner.user_id === user.sub) {
      document.getElementById('password_dialog').style.display = 'none';
      setAcceptOpen(true);
      setForm(data);
      return;
    } else if (!learner) {
      document.getElementById('password_dialog').style.display = 'none';
      setAcceptOpen(true);
      setForm(data);
      return;
    }
    handleFormSubmit(data);
    if (data.user_id !== user.sub) return history.push('/account/settings');
    return history.push(`/account/settings/${data.user_id}`)
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      disableScrollLock
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
          position: 'unset',
          margin: 'unset',
        },
      }}
      id="password_dialog"
    >
      <DialogContent className='changePwDialog'>
        <AcceptDialog open={acceptOpen} handleClose={handleAcceptClose} form={form} handleFormSubmit={handleFormSubmit} />
        <div className="changePw">
          <img src="/assets/images/add_existing_trees.png" className="tree_image" />
          <button
            role="button"
            className="interactive_element close_button"
            onClick={handleClose}
          >
            <img
              src="/assets/icons/close_icon.png"
              width={smScreen ? 11 : 15}
              height={smScreen ? 11 : 15}
            ></img>
          </button>
          <span className="changePwText">Enter new password</span>
          <div className='newStudentInfo'>
            <form onSubmit={handleSubmit(handleButtonClick)}>
              <TextField
                id="currentPassword"
                placeholder="Current password"
                name='current_password'
                type='password'
                width={smScreen ? 250 : 350}
                requiredField
                helperText={errors.current_password ? errors.current_password.message : ''}
                error={!!errors.current_password}
                autocomplete={false}
                register={register({
                  required: 'Current password is required',
                })}
              />
              <TextField
                id="studentPassword"
                placeholder="New password"
                name='password'
                type='password'
                width={smScreen ? 250 : 350}
                requiredField
                helperText={errors.password ? errors.password.message : ''}
                error={!!errors.password}
                autocomplete={false}
                register={register({
                  required: 'Password is required',
                  minLength: { value: 8, message: 'Min length 8' },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i,
                    message: 'At least one Special character (!@#$%^&*), Lowercase, uppercase, and numbers ',
                  }
                })}
              />
              <TextField
                name='password_repeat'
                placeholder="Confirm password"
                type='password'
                width={smScreen ? 250 : 350}
                requiredField
                helperText={errors.password_repeat ? errors.password_repeat.message : ''}
                error={!!errors.password_repeat}
                autocomplete={false}
                register={register({
                  required: 'Must confirm password',
                  validate: value => {
                    if (watch('password') != value) {
                      return "Your passwords do no match";
                    }
                  }
                })}
              />
              <div className="changePwButton">
                <Button
                  buttonStyle="primary"
                  label="Change password"
                  height={50}
                  marginTop={20}
                  addClass='changePwButtonText'
                  width={smScreen ? 250 : 350}
                  type="submit"
                  onClick={handleSubmit}
                //   disable={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
} 