import React from 'react';
import '../pricing.scss';
import { useHistory } from 'react-router-dom';
import Button from '../../../designSystem/components/buttons/button';
import { useTheme, useMediaQuery } from '@material-ui/core';

export default function Card(props) {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let history = useHistory();
  return (
    <div className="section_two_card">
      <div>
        <h2 className="trial_name">{props.cardContent.title}</h2>
        <div className="pricing_div">
          <p className="dollar_sign">$</p>
          <span className="price">{props.cardContent.price}</span>
          <p className="per_duration">
            /{props.cardContent.duration} <br /> per student
          </p>
        </div>
        <ul>
          <li className="list_items">{props.cardContent.cardList[0]}</li>
          <li className="list_items">{props.cardContent.cardList[1]}</li>
          <li className="list_items">{props.cardContent.cardList[2]}</li>
          <li className="list_items">{props.cardContent.cardList[3]}</li>
        </ul>
        <div className="free_trial_button">
          <Button
            label="Start free trial"
            width={smScreen ? 150 : 171}
            height={smScreen ? 50 : 57}
            fontSize={16}
            buttonStyle="primary"
            onClick={() => history.push('/pre-signup')}
          />
        </div>
      </div>
    </div>
  );
}
