import React from 'react';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import TextField from '../textFields/textField';
import MultilineTextField from '../textFields/multilineTextField';
import Button from '../buttons/button';
import SecondaryToggleButton from '../buttons/secondaryToggleButton';
import './contactUsForm.scss';

function ContactUsForm(props) {
  const {
    formState: { errors },
    register,
    setValue,
    getValues,
    watch,
  } = props;

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const rolesList = [
    {
      label: 'Parent',
      value: 'parent',
    },
    {
      label: 'Student',
      value: 'student',
    },
    {
      label: 'Teacher',
      value: 'teacher',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ];

  const handleSetRole = (event, newValue) => {
    setValue('role', newValue);
  };

  React.useState(() => {
    watch('role');
  }, []);

  return (
    <div className="contact-us-form">
      <h3 className="card-title">Your role</h3>
      <Grid container>
        <Grid item xs={12} className="toggle-buttons">
          <SecondaryToggleButton
            list={rolesList}
            value={getValues('role')}
            handleSelection={handleSetRole}
            height={56}
            width={140}
          />
        </Grid>
      </Grid>
      <Grid container spacing={smScreen ? 0 : 2}>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            name="first_name"
            label="First Name"
            helperText={errors.first_name ? errors.first_name.message : ''}
            error={!!errors.first_name}
            width="100%"
            requiredField
            register={register({
              required: 'First Name is required',
              minLength: { value: 2, message: 'Min length 2' },
              maxLength: { value: 30, message: 'Max length 30' },
              pattern: {
                value: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/i,
                message: 'Can only contain letters and numbers.',
              },
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            name="last_name"
            label="Last Name"
            width="100%"
            requiredField
            helperText={errors.last_name ? errors.last_name.message : ''}
            error={!!errors.last_name}
            register={register({
              required: 'Last Name is required',
              minLength: { value: 2, message: 'Min length 2' },
              maxLength: { value: 30, message: 'Max length 30' },
              pattern: {
                value: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/i,
                message: 'Can only contain letters and numbers.',
              },
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            name="email"
            label="Email Address"
            width="100%"
            requiredField
            helperText={errors.email ? errors.email.message : ''}
            error={!!errors.email}
            register={register({
              required: 'Email is required',
              maxLength: { value: 100, message: 'Max length 100' },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid Email Format',
              },
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            name="phone"
            label="Phone Number"
            helperText={errors.phone ? errors.phone.message : ''}
            error={!!errors.phone}
            width="100%"
            register={register({
              maxLength: { value: 20, message: 'Max length 20' },
              pattern: {
                value: /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/i,
                message: 'Invalid Phone Format',
              },
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <MultilineTextField
            type="search"
            name="message"
            label="Message"
            requiredField
            helperText={errors.message ? errors.message.message : ''}
            error={!!errors.message}
            width="100%"
            height="210px"
            multiline
            style={{ textAlign: 'right' }}
            register={register({
              required: 'Message is required',
              minLength: { value: 1, message: 'Min length 1' },
              maxLength: { value: 500, message: 'Max length 500' },
              pattern: {
                value: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/i,
                message: 'Can only contain letters and numbers.',
              },
            })}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          label="Submit"
          onClick={() => {}}
          width={xsScreen ? '100%' : 144}
          height={57}
          buttonStyle="primary"
          type="submit"
        />
      </Grid>
    </div>
  );
}

export default ContactUsForm;
