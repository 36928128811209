import React from 'react';
import { Grid } from '@material-ui/core';
import './notFound.scss';

export default function NothingToShow(props) {
  const { title, message } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className="nothingToShow-wrapper"
    >
      <Grid item xs={12}>
        <div className="top-image-wrapper">
          <img
            src="/assets/images/early-access-image.png"
            alt="early access"
            className="top-image"
          />
          <img
            src="/assets/images/section_one_bg_img_two.png"
            alt="early access"
            className="back-image-two"
          />
          <img
            src="/assets/images/section_one_bg_image_one.png"
            alt="early access"
            className="back-image-one"
          />
          <img
            src="/assets/images/section_one_bg_image_seven.png"
            alt="early access"
            className="back-image-seven"
          />
          <img
            src="/assets/images/section_one_bg_image_five.png"
            alt="early access"
            className="back-image-five"
          />
          <img
            src="/assets/images/section_one_bg_image_six.png"
            alt="early access"
            className="back-image-six"
          />
        </div>
      </Grid>
      <Grid item xs={12} className="text">
        {title}
      </Grid>
      <Grid item xs={12} className="sub-text">
        {message}
      </Grid>
    </Grid>
  )
}

