import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import Button from '../../../designSystem/components/buttons/button';
import ButtonIconRound from '../../../designSystem/components/buttons/round-icon-button';
import LockedSubscription from '../../../designSystem/components/dialogs/lockedSubscription';

function ReinforcementBox({ skill, history, subscription }) {
  const [expand, setExpand] = React.useState(false);
  const [openLock, setOpenLock] = React.useState(false);

  const handleExpand = () => {
    !expand ? setExpand(true) : setExpand(false);
  };

  const handleResume = () => {
    if (subscription.status === 'paused') {
      setOpenLock(true);
    } else {
      history.push(`/practice/${skill.topic_id}/${skill.skill_id}`);
    }
  };

  const learner_progress = (skill.skill_learner_points / skill.skill_points) * 100;

  const calculateLevel = () => {
    if (learner_progress <= 20) return 'Fundamental';
    if (learner_progress > 20 && learner_progress <= 40) return 'Intermediate';
    if (learner_progress > 40 && learner_progress <= 60) return 'Advanced';
    if (learner_progress > 60 && learner_progress <= 80) return 'Advanced';
    if (learner_progress > 80 && learner_progress <= 100) return 'Master';
  };

  return (
    <Grid container className="reinforcement_box">
      <LockedSubscription
        open={openLock}
        handleClose={() => setOpenLock(false)}
        handleConfirm={() => history.push('/resume-subscription')}
      />
      <Grid item xs={12} sm={6} md={6}>
        <div className="skill_name">{skill.skill_name}</div>
        <div className="grade_info_box">
          <span className="grade_number">{skill.subject_id}</span>
          <span className="grade_type">{calculateLevel()}</span>
        </div>
      </Grid>
      <Grid item xs>
        <Grid container direction="row" justify="flex-end" alignItems="center" className="button_expand_container">
          <Box>
            <Button
              label="Resume"
              addId={skill.skill_name}
              onClick={handleResume}
              width={96}
              height={44}
              buttonStyle="default"
              type="button"
            />
          </Box>
          <span className="m_left_15 expand_icon">
            {expand ? (
              <ButtonIconRound arrow_direction="down" handleClick={handleExpand} />
            ) : (
              <ButtonIconRound arrow_direction="up" handleClick={handleExpand} />
            )}
          </span>
        </Grid>
      </Grid>
      {expand && (
        <Grid item xs={12} className="skill_description">
          {skill.skill_description}
        </Grid>
      )}
    </Grid>
  );
}

ReinforcementBox.propTypes = {
  subscription: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.any)]).isRequired,
  skill: PropTypes.shape({
    skill_name: PropTypes.string,
    skill_points: PropTypes.string,
    skill_learner_points: PropTypes.number,
    skill_total_time: PropTypes.number,
    skill_total_questions: PropTypes.number,
    skill_attempts: PropTypes.number,
    skill_wrong_attempts: PropTypes.number,
    tasks: PropTypes.arrayOf(PropTypes.any),
    subject_id: PropTypes.number,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(ReinforcementBox);
