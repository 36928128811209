import React from 'react';
import { Grid } from '@material-ui/core';

export default function OntarioCurriculum() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className="ontario_curriculum_wrapper page_wrapper"
    >
      <Grid item>
        <h4 className="title">Ontario Math Curriculum</h4>
        <h4 className="body">
          Genius Forest content aligns with Ontario’s Math Curriculum standards from Grade 6-8. Our
          system assesses students’ unique skill level and tailors questions to effectively support
          individual learning and development.
        </h4>
      </Grid>
      <Grid item className="image_wrapper">
        <img
          src="/assets/icons/ontario-icon.svg"
          alt="Ontario Icon"
          aria-hidden={true}
          className="desktop_icon"
        />
        <img
          src="/assets/icons/ontario-icon-mobile.svg"
          alt="Ontario Icon"
          aria-hidden={true}
          className="mobile_icon"
        />
      </Grid>
    </Grid>
  );
}
