import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../../../designSystem/components/interactiveElements/loading';

function SkillGraph({ active_index, data, handleClick, average }) {
  const calculateBarSize = skill => {
    const result = Math.abs((skill.skill_wrong_attempts / skill.skill_attempts) * 100 - 100);
    return `${result}%`;
  };

  const positionAverageLine = () => {
    const maxValue = data[0].skill_points;
    return (average / maxValue) * 100;
  };

  const calculateLevel = skill => {
    const difficult_level = Math.abs(
      (skill.skill_wrong_attempts / skill.skill_attempts) * 100 - 100,
    );
    if (difficult_level <= 25) return 1;
    if (difficult_level > 25 && difficult_level <= 50) return 2;
    if (difficult_level > 50 && difficult_level <= 75) return 3;
    if (difficult_level > 75) return 4;
  };

  if (data.length === 0) return <Loading />;

  return (
    <div className="performance_chart_wrapper">
      <img
        src="/assets/icons/average-chart-line.svg"
        alt="average"
        className="average_line"
        style={{ left: `${positionAverageLine()}%` }}
      />
      <img src="/assets/icons/graph_arrow.svg" className="bottom_arrow" alt="graph arrow" />
      <div className="average_label" style={{ left: `${positionAverageLine() - 8}%` }}>
        Average <br /> Performance
      </div>
      <div
        className={
          calculateLevel(data[active_index]) === 1
            ? 'performance_fundamental active_level'
            : 'performance_fundamental'
        }
      >
        Fundamental
      </div>
      <div
        className={
          calculateLevel(data[active_index]) === 2
            ? 'performance_intermediate active_level'
            : 'performance_intermediate'
        }
      >
        Intermediate
      </div>
      <div
        className={
          calculateLevel(data[active_index]) === 3
            ? 'performance_advanced active_level'
            : 'performance_advanced'
        }
      >
        Advanced
      </div>
      <div
        className={
          calculateLevel(data[active_index]) === 4
            ? 'performance_master active_level'
            : 'performance_master'
        }
      >
        Master
        <img className="master_level" src="/assets/icons/master_level.svg" alt="master coin" />
      </div>
      {data.map((item, index) => (
        <div
          onClick={() => handleClick(index)}
          className={active_index === index ? 'bar_chart active_bar_chart' : 'bar_chart'}
          style={{ width: calculateBarSize(item) }}
          key={item.skill_name}
        >
          <span className="bar_label">{item.skill_name}</span>
        </div>
      ))}
    </div>
  );
}

SkillGraph.propTypes = {
  handleClick: PropTypes.func,
  active_index: PropTypes.number,
  average: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  ).isRequired,
};

export default SkillGraph;
