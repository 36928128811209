import React from 'react';
import MainBox from './components/mainBox';
import { useParams, Redirect } from 'react-router-dom';
import ParentBox from './components/parentBox';
import { welcomeEmailAPI } from '../../API/welcome';
import { useAuth0 } from '@auth0/auth0-react';
import './askParent.scss';

export default function AskParent({ subscription }) {
  let { tab } = useParams();
  const { user, getAccessTokenSilently } = useAuth0();
  const [selectedTab, setSelectedTab] = React.useState(tab);

  const handleWelcomeStudentEmail = async () => {
    const token = await getAccessTokenSilently();

    const data = {
      email: user.email,
    };

    await welcomeEmailAPI.POST.welcomeEmailStudent(token, data)
      .then(response => {
        console.log(response);
        sessionStorage.setItem('welcomeEmailSent', true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (selectedTab !== tab) {
      return setSelectedTab(tab);
    }
    async function fetchData() {
      if (
        sessionStorage.getItem('welcomeEmailSent') === null ||
        sessionStorage.getItem('welcomeEmailSent') === 'false'
      ) {
        await handleWelcomeStudentEmail();
      }
    }

    fetchData();
  }, [selectedTab, tab]);

  let user_type = false;

  if (user) {
    user_type = user['https://www.geniusforest.ca/user_type'];
  }

  if (subscription && user_type === 'learner') return <Redirect to="/my-practice" />;

  return (
    <div className="student_registration_wrapper">{!selectedTab ? <MainBox /> : <ParentBox />}</div>
  );
}
