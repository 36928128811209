import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import DefaultTab from '../../../../designSystem/components/tabs/defaultTab';
import Loading from '../../../../designSystem/components/interactiveElements/loading';
import SkillBox from '../../../../designSystem/components/cards/skillBox';
import NothingToShow from "../../../../designSystem/components/messages/notFound/nothingToShow";

function SkillsInformation(props) {
  const { skills, history } = props;
  const [selected_tab, setSelectedTab] = React.useState(0);

  const handleSelectedTab = (event, value) => {
    setSelectedTab(value);
  };

  const info_options = [
    {
      label: 'Trouble Skills',
      value: 0,
    },
    {
      label: 'Improved Skills',
      value: 1,
    },
    {
      label: 'Mastered Skills',
      value: 2,
    },
  ];

  const calculateLevel = skill => {
    const { skill_points, skill_learner_points } = skill;
    const difficult_level = (skill_learner_points / skill_points) * 100;
    if (difficult_level <= 25) return 1; // 'Fundamental';
    if (difficult_level > 25 && difficult_level <= 50) return 2; //'Intermediate';
    if (difficult_level > 50 && difficult_level <= 75) return 3; //'Advanced';
    if (difficult_level > 75 && difficult_level <= 100) return 4; //'Master';
  };

  const calculateScore = skill => {
    const { skill_wrong_attempts, skill_attempts } = skill;
    return Math.round(Math.abs((skill_wrong_attempts / skill_attempts) * 100 - 100));
  };

  const renderTroubleSkills = () => {
    const result = [];
    for (let i = 0; i < skills.length; i++) {
      if (calculateScore(skills[i]) < 70 && calculateLevel(skills[i]) !== 4) {
        result.push(skills[i]);
      }
    }
    return result;
  };

  const renderImprovedSkills = () => {
    const result = [];
    for (let i = 0; i < skills.length; i++) {
      if (calculateScore(skills[i]) >= 70 && calculateLevel(skills[i]) !== 4) {
        result.push(skills[i]);
      }
    }
    return result;
  };

  const renderMasterSkills = () => {
    const result = [];
    for (let i = 0; i < skills.length; i++) {
      if (calculateLevel(skills[i]) === 4) {
        result.push(skills[i]);
      }
    }
    return result;
  };

  if (!skills) {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} className="skills_tab_wrapper">
        <Grid container direction="row" justify="center" alignItems="center">
          <DefaultTab
            list={info_options}
            selected_tab={selected_tab}
            handleSelectedTab={handleSelectedTab}
            width={300}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="skill_boxes_wrapper">
        <Grid container spacing={3}>
          {selected_tab === 0 && (
            <>
              {renderTroubleSkills().length > 0 ? (
                <>
                  {renderTroubleSkills().map(skill => (
                    <Grid item xs={12} md={6} key={skill.skill_name}>
                      <SkillBox
                        skill={skill}
                        showButton
                        handlePracticeButton={(topic_id, skill_id) =>
                          history.push(`/practice/${topic_id}/${skill_id}`)
                        }
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item xs={12}>
                  <NothingToShow title="No results" message="Great Job! There are no trouble skills. Keep practicing." />
                </Grid>
              )}
            </>
          )}
          {selected_tab === 1 && (
            <>
              {renderImprovedSkills().length > 0 ? (
                <>
                  {renderImprovedSkills().map(skill => (
                    <Grid item xs={12} md={6} key={skill.skill_name}>
                      <SkillBox
                        skill={skill}
                        showButton
                        handlePracticeButton={(topic_id, skill_id) =>
                          history.push(`/practice/${topic_id}/${skill_id}`)
                        }
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item xs={12} >
                  <NothingToShow title="No results" message="Looks like there are no improved skills yet. Keep practicing." />
                </Grid>
              )}
            </>
          )}
          {selected_tab === 2 && (
            <>
              {renderMasterSkills().length > 0 ? (
                <>
                  {renderMasterSkills().map(skill => (
                    <Grid item xs={12} md={6} key={skill.skill_name}>
                      <SkillBox
                        skill={skill}
                        showButton
                        handlePracticeButton={(topic_id, skill_id) =>
                          history.push(`/practice/${topic_id}/${skill_id}`)
                        }
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item xs={12} >
                  <NothingToShow title="No results" message="Looks like there are no mastered skills yet. Keep practicing." />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

SkillsInformation.propTypes = {
  skills: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.any)]).isRequired,
};

export default withRouter(SkillsInformation);
