import axios from 'axios';
import url from './url';

export const newsletterEmailAPI = {
  POST: {
    addNewsletterEmail(data) {
      console.log(data);
      return axios.post(`${url}api/addNewsLetterEmail`, data);
    },
  },
};
