import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Loading from '../interactiveElements/loading';
import './buttons.scss';

ButtonComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disable: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  addClass: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  addId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  buttonStyle: PropTypes.string.isRequired,
  type: PropTypes.string,
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  active: PropTypes.bool,
  darkMode: PropTypes.bool,
  capitalize: PropTypes.string,
};

ButtonComponent.defaultProps = {
  height: 35,
  disable: false,
  icon: false,
  fontSize: 16,
  addClass: '',
  addId: '',
  type: 'button',
  loading: false,
  active: false,
  darkMode: false,
  tabIndex: '0',
  capitalize: 'capitalize',
};

export default function ButtonComponent({
  label,
  width,
  disable,
  onClick,
  icon,
  height,
  variant,
  fontSize,
  addClass,
  addId,
  buttonStyle,
  type,
  loading,
  active,
  darkMode,
  bgColor,
  tabIndex,
  capitalize,
}) {
  const defaultButton = makeStyles(theme => ({
    root: {
      background: bgColor || theme.palette.default.main,
      color: theme.palette.default.buttonOutlined,
      height: height,
      fontSize: fontSize,
      fontWeight: 600,
      lineHeight: '16px',
      width: width,
      padding: '0px 5px',
      borderWidth: 1,
      borderRadius: 4,
      border: `1px solid ${theme.palette.default.border}`,
      fontFamily: theme.fontFamily.default,
      textTransform: capitalize,

      '&:hover': {
        backgroundColor: theme.palette.default.hover,
        border: `1px solid ${theme.palette.default.hover}`,
        color: theme.palette.default.main,
      },
      '&:focus': {
        color: theme.palette.default.main,
        boxShadow: `0 0 0 4px ${darkMode ? '#19252a' : 'white'},0 0 0 5px ${
          theme.palette.default.focus
        }`,
        backgroundColor: theme.palette.default.focus,
        border: `1px solid ${theme.palette.default.focus}`,
      },
      '&.MuiButton-outlined.Mui-disabled': {
        background: theme.palette.default.disabled,
        color: theme.palette.default.disabledText,
        border: `1px solid ${theme.palette.default.disabledBorder}`,
      },
    },
  }));

  const primaryButton = makeStyles(theme => ({
    root: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.text,
      border: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
      '&:focus': {
        boxShadow: `0 0 0 4px ${darkMode ? '#19252a' : 'white'},0 0 0 5px ${
          theme.palette.primary.focus
        }`,
        backgroundColor: theme.palette.primary.focus,
      },
      '&.MuiButton-outlined.Mui-disabled': {
        background: theme.palette.primary.disabled,
        color: theme.palette.primary.disabledText,
        border: 'none',
      },
    },
  }));

  const secondaryButton = makeStyles(theme => ({
    root: {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.text,
      border: 'none',
      '&:hover': {
        backgroundColor: theme.palette.secondary.hover,
        color: theme.palette.secondary.text,
        border: 'none',
      },
      '&:focus': {
        boxShadow: `0 0 0 4px ${darkMode ? '#19252a' : 'white'},0 0 0 5px ${
          theme.palette.secondary.main
        }`,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.text,
        border: `1px solid ${theme.palette.secondary.focus}`,
      },
      '&.MuiButton-outlined.Mui-disabled': {
        background: theme.palette.secondary.disabled,
        color: theme.palette.secondary.disabledText,
        border: 'none',
      },
    },
  }));

  const lightButton = makeStyles(theme => ({
    root: {
      background: active ? '#fffff' : `${theme.palette.lightButton.main}`,
      color: active ? theme.palette.lightButton.focusText : theme.palette.lightButton.text,
      border: theme.palette.lightButton.main,
      borderRadius: '14px',
      boxShadow: active
        ? `0 0 0 4px ${darkMode ? '#19252a' : 'white'},0 0 0 5px ${
            theme.palette.lightButton.main
          } !important`
        : 'none !important',

      '&:hover': {
        background: `${theme.palette.lightButton.focus} !important`,
        boxShadow: `0px 0px 20px rgb(0 0 0 / 8%) !important`,
        color: theme.palette.lightButton.focusText,
        border: 'none',
      },
      '&:focus': {
        background: `${theme.palette.lightButton.focus} !important`,
        boxShadow: `0px 0px 20px rgb(0 0 0 / 8%)`,
        color: theme.palette.lightButton.focusText,
        border: 'none !important',
      },
      '&.MuiButton-outlined.Mui-disabled': {
        background: theme.palette.lightButton.disabled,
        color: theme.palette.lightButton.disabledText,
        border: 'none',
      },
    },
  }));

  const dangerButton = makeStyles(theme => ({
    root: {
      background: '#f05122',
      color: active ? theme.palette.lightButton.focusText : theme.palette.lightButton.text,
      borderRadius: '4px',
      border: 'none',
      '&:hover': {
        backgroundColor: '#d9481d',
      },
      '&:focus': {
        boxShadow: `0 0 0 4px ${darkMode ? '#19252a' : 'white'},0 0 0 5px ${'#d9481d'}`,
        backgroundColor: '#d9481d',
      },
      '&.MuiButton-outlined.Mui-disabled': {
        background: theme.palette.primary.disabled,
        color: theme.palette.primary.disabledText,
        border: 'none',
      },
    },
  }));

  const denyButton = makeStyles(theme => ({
    root: {
      background: '#EF4741',
      color: active ? theme.palette.lightButton.focusText : theme.palette.lightButton.text,
      borderRadius: '4px',
      border: 'none',
      '&:hover': {
        backgroundColor: '#d9481d',
      },
      '&:focus': {
        boxShadow: `0 0 0 4px ${darkMode ? '#19252a' : 'white'},0 0 0 5px ${'#d9481d'}`,
        backgroundColor: '#d9481d',
      },
      '&.MuiButton-outlined.Mui-disabled': {
        background: theme.palette.primary.disabled,
        color: theme.palette.primary.disabledText,
        border: 'none',
      },
    },
  }));

  const classes = defaultButton();

  const selectedStyle =
    buttonStyle === 'default'
      ? defaultButton()
      : buttonStyle === 'primary'
      ? primaryButton()
      : buttonStyle === 'light'
      ? lightButton()
      : buttonStyle === 'danger'
      ? dangerButton()
      : buttonStyle === 'deny'
      ? denyButton()
      : secondaryButton();

  return (
    <Button
      variant="outlined"
      id={addId !== '' ? addId : ''}
      className={
        addClass !== ''
          ? `${addClass} ${classes.root} ${selectedStyle.root}`
          : `${classes.root}  ${selectedStyle.root}`
      }
      size="large"
      type={type}
      disabled={disable || loading}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {loading && (
        <div className="loading_button ">
          <Loading size={35} />
        </div>
      )}
      {icon && <img alt="button icon" src={icon} className="custom_button_icon" />}
      {label}
    </Button>
  );
}
/*
  < ---- Example usage ----- >
   <Button
    label="Primary"
    addId="primary_disabled"
    onClick={() => {}}
    width={91}
    height={42}
    buttonStyle="primary"
    disable
    type="submit"
  /> 
  */
