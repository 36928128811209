import React from 'react';
import { Grid } from '@material-ui/core';
import DefaultCarousel from '../../../designSystem/components/carousel/defaultCarousel';

export default function CarouselSection(props) {
  const default_carousel = [
    {
      title: 'Voice Ove 1',
      body: 'You can now use the voice over option to listen to questions',
      imageUrl: '/assets/images/carousel_box_image.svg',
      learn_more: '#',
    },
    {
      title: 'Voice Over 2',
      body: 'You can now use the voice over option to listen to questions',
      imageUrl: '/assets/images/carousel_box_image.svg',
      learn_more: '#',
    },
    {
      title: 'Voice Over 3',
      body: 'You can now use the voice over option to listen to questions',
      imageUrl: '/assets/images/carousel_box_image.svg',
      learn_more: '#',
    },
    {
      title: 'Voice Over 4',
      body: 'You can now use the voice over option to listen to questions',
      imageUrl: '/assets/images/carousel_box_image.svg',
      learn_more: '#',
    },
    {
      title: 'Voice Over 5',
      body: 'You can now use the voice over option to listen to questions',
      imageUrl: '/assets/images/carousel_box_image.svg',
      learn_more: '#',
    },
    {
      title: 'Voice Over 6',
      body: 'You can now use the voice over option to listen to questions',
      imageUrl: '/assets/images/carousel_box_image.svg',
      learn_more: '#',
    },
  ];
  return (
    <Grid container className="designSystem_section-wrapper">
      <Grid item xs={12} className="designSystem-sections">
        <h2 className="title">Carousel</h2>
        <Grid container>
          <Grid item xs={12}>
            <h2 className="subTitle">Default Carousel</h2>
          </Grid>
          <Grid item xs={12} className="designSystem-elements">
            <DefaultCarousel list={default_carousel} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
