import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

PrimaryTab.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSelectedTab: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '64px',
    '& .MuiButtonBase-root': {
      color: '#505D62',
      fontFamily: theme.fontFamily.default,
      fontWeight: 600,
      fontSize: '23px',
      lineHeight: '28px',
      marginRight: '0%',
      marginTop: '10px',
      marginBottom: '10px',
      border: `2px  solid transparent`,
      textTransform: 'capitalize',
      borderRadius: '20px',
      height: '85px',
      '&:hover': {
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
        color: '#19252A !important',
        opacity: 1,
      },
      padding: '0px 20px',
    },
    '& .MuiTabs-flexContainer': {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    '& .MuiTabs-centered': {
      padding: '30px 0px !important',
    },
    '& .Mui-selected': {
      color: '#19252A',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    },
    '& .Mui-focusVisible': {
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
      outlineOffset: '10px',
      zIndex: '1',
    },
    '& .MuiTabs-indicator': {
      height: '0px !important',
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
    },

    // '& .MuiTabs-fixed': {
    //   width: '220px',
    // },

    // '& .MuiTab-wrapper': {
    //   alignItems: 'baseline;',
    // },

    '@media only screen and (max-width: 1070px)': {
      '& .MuiButtonBase-root': {
        fontSize: '22px',
        lineHeight: '28px',
        marginRight: '2%',
        '& .MuiTabs-flexContainer': {
          height: '40px',
        },
      },
    },
    '@media only screen and (max-width: 950px)': {
      '& .MuiButtonBase-root': {
        fontSize: '18px',
        lineHeight: '22px',
        marginRight: '0%',
        marginTop: '20px',
        marginBottom: '20px',
        '& .MuiTabs-flexContainer': {
          height: '40px',
        },
      },
      '& .MuiTabs-flexContainer': {
        paddingLeft: '15px',
      },
    },
    '@media only screen and (max-width: 600px)': {
      '& .MuiButtonBase-root': {
        fontSize: '14px',
        padding: ' 5px 8px',
        height: '30px',
      },
    },
  },
}));

export default function PrimaryTab({ list, handleSelectedTab }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const tabs = document.getElementsByClassName('MuiButtonBase-root');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleSelectedTab(newValue);
  };

  const removeSelectedTab = event => {
    for (var i = 0; i < tabs.length; i++) {
      const selectedTab = tabs[i].classList.contains('Mui-selected');
      if (selectedTab) {
        tabs[i].classList.remove('Mui-selected');
        setSelectedTab(i);
      }
    }
  };
  const addSelectedTab = () => {
    tabs[selectedTab].classList.add('Mui-selected');
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.root}
        centered={window.innerWidth > 1014}
        variant={window.innerWidth < 1014 ? 'scrollable' : 'standard'}
      >
        {list.map((item, index) => {
          return (
            <Tab
              onMouseLeave={addSelectedTab}
              onMouseOver={removeSelectedTab}
              key={index}
              label={item.label}
              icon={
                <img
                  src={item.icon}
                  width="43px"
                  height="50px"
                  className="tab_icons"
                  alt={item.label}
                />
              }
            />
          );
        })}
      </Tabs>
    </>
  );
}

/* 
Example usage: 
   const list = [
    {
      label: 'Engaging',
      icon: '/assets/images/home_tab_engaging.png',
    },
    {
      label: 'Interactive',
      icon: '/assets/images/home_tab_interactive.png',
    },
    {
      label: 'Reporting',
      icon: '/assets/images/home_tab_reporting.png',
    },
    {
      label: 'Diagnostic',
      icon: '/assets/images/home_tab_diagnostic.png',
    },
    {
      label: 'Curriculum',
      icon: '/assets/images/home_tab_curriculum.png',
    },
  ];
<Tab list={list} handleSelectedTab={handleSelectedTab} />
*/
