import React from 'react';
import Moment from 'react-moment';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Grid } from '@material-ui/core';
import { assets } from '../../../../utils/config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BillingDialog(props) {
  const { data, subscription, handleClose } = props;

  console.log(data);

  const formatAmount = () => {
    const amount = data.paymentRequests[0].totalCompletedAmountMoney.amount.toString();
    var len = amount.length;
    return `$${amount.substring(0, len - 2) + '.' + amount.substring(len - 2)}`;
  };

  const getCardNumber = () => {
    return data.customer.cards[0].last4;
  };

  const getCardBrand = () => {
    const brand = data.customer.cards[0].cardBrand;
    if (brand === 'VISA') return 'visa';
    return 'master';
  };

  const handleDownload = () => {
    const printContent = document.getElementById('receipt').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location = window.location.origin;
  };

  if (!data) return <></>;

  return (
    <Dialog
      open={!data ? false : true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="Receipt"
      className="billing_dialog_wrapper"
      fullWidth={true}
      maxWidth={'sm'}
      id="receipt"
    >
      <DialogTitle className="dialog_header">
        <img
          src={`${assets.url}/logo.svg`}
          type="button"
          alt="Genius Forest Logo"
          className="billing_logo"
        />
        <div className="dialog-title">Receipt</div>
        <button className="close_dialog" onClick={handleClose} type="button">
          <span className="sr_only">Close Dialog</span>
          <CloseIcon style={{ width: '24px' }} />
        </button>
      </DialogTitle>
      <DialogContent className="dialog_body">
        <div className="invoice_wrapper">Invoice #{data.invoiceNumber}</div>
        <iframe src={data.publicUrl} className="receipt-frame" allowtransparency="true"></iframe>
      </DialogContent>
    </Dialog>
  );
}
