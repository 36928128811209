import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';

QuestionOutlineBoxMobile.propTypes = {
  question_number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  current_question: PropTypes.number.isRequired,
};

export default function QuestionOutlineBoxMobile(props) {
  const {
    practice_controller: { current_skill },
    question_number,
    skill,
  } = props;

  return (
    <Grid item className="skill_outline_wrapper skill_outline_wrapper_mobile">
      <Box
        className={
          current_skill === question_number - 1
            ? 'skill_outline_box box_expanded'
            : 'skill_outline_box'
        }
        tabIndex={current_skill === question_number - 1 ? 0 : -1}
      >
        <span className="sr_only">Skill Outline</span>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Box className="box_title">
            {current_skill === question_number - 1 ? (
              <img
                src="/assets/icons/check_round_icon.svg"
                alt="Active Icon"
                aria-hidden={true}
                className="active_indicator"
              />
            ) : (
              <div className="test_outline_number_indicator">{question_number}</div>
            )}
            {skill.skill_name}
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}
