import React from 'react';
import '../accountSettings.scss';
import SettingsBox from './settingsBox';
import StudentGrid from './studentGrid';
import { Grid } from '@material-ui/core';
import Loading from '../../../../designSystem/components/interactiveElements/loading';
import { useAuth0 } from '@auth0/auth0-react';
import DefaultAlert from '../../../../designSystem/components/alerts/defaultAlert';

export default function AccountSettingsParent(props) {
  const { subscription, fileSelectedHandler, handleFormSubmit, isLoading } = props;
  const { user } = useAuth0();

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert(
      {
        open: true,
        severity,
        title,
        body,
      },
      setTimeout(() => {
        setDefaultAlert({
          open: false,
          severity,
          title,
          body,
        });
      }, 5000),
    );
  };

  if (!subscription) return <LoadingPage />;

  return (
    <div className="accountSettings_wrapper">
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      <SettingsBox
        user={user}
        subscription={subscription}
        handleAlert={handleAlert}
        fileSelectedHandler={fileSelectedHandler}
        handleFormSubmit={handleFormSubmit}
        isLoading={isLoading}
      />
      {subscription.learners.length > 0 ? (
        <div className="manageStudents_box">
          <span className="manage_students">Manage Students</span>
          <div className="manageStudents_text_div">
            <span className="student_span">Student</span>
            <span className="name_span">Name</span>
            <span className="email_span">Email</span>
            <span className="grade_level_span">Current grade level</span>
            <span className="password_span">Password</span>
          </div>
          <Grid container className="manageStudents_grid">
            {subscription.learners?.map(learner => (
              <StudentGrid learner={learner} />
            ))}
          </Grid>
        </div>
      ) : (
        <div className="m_top_6"></div>
      )}
    </div>
  );
}
const LoadingPage = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Loading />
    </Grid>
  );
};
