import React from 'react';
import SectionOne from './components/sectionOne';
import SectionTwo from './components/sectionTwo';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import QuestionPage from './components/questionPage';
import Loading from '../../designSystem/components/interactiveElements/loading';
import './FAQ.scss';

function FAQ({ history }) {
  let { category } = useParams();

  const [selectedCategory, setSelectedCategory] = React.useState(category);
  const [search, setSearch] = React.useState('');
  const [data, setData] = React.useState([
    {
      label: 'Account',
      questions: [
        {
          label: 'Updating personal information',
          answer:
            "To update your personal information, click on your profile icon. In the drop-down menu, click on account settings. Here, you'll be able to edit your name, email address, password, and notification settings.",
        },
        {
          label: 'Changing your account password',
          answer:
            'Your account password can be modified under the Account Settings page. Account Settings page can be accessed by clicking on your profile button at the top right corner of the nav bar. Please make sure to click the Save Changes button to save any changes.',
        },
      ],
    },
    {
      label: 'Subscription',
      questions: [
        {
          label: 'Managing your subscription',
          answer:
            "To manage your existing subscriptions, click on your profile icon. In the drop-down menu, click on membership & billing. Here, you'll be able to view, cancel, and add new memberships.",
        },
        {
          label: 'Cancelling your subscription',
          answer:
            'Under membership & billing, click on Cancel premium to cancel any active subscriptions. Cancelling means you will lose all access to your premium benefits, and not be charged on the next renewal period.',
        },
        {
          label: 'Updating payment methods',
          answer:
            'Payment methods can be easily updated at any time under the Membership & Billing page. Membership & Billing page can be accessed by clicking on your profile button at the top right corner of the nav bar. On this page, scroll to the bottom of the page where you can see the Billing section. Click the Edit button and enter the updated payment information. Please be sure to click the Save button to save the changes.',
        },
      ],
    },
    {
      label: 'PlansAndPricing',
      questions: [
        {
          label: 'Pricing details',
          answer:
            'Pricing details such as monthly or yearly charged amount and next billing date can be found under the Membership & Billing page. Membership & Billing page can be accessed through clicking on your profile button at the top right corner of the nav bar. On this page, you will be able to find the pricing details at the bottom of the page inside the billing section.',
        },
        {
          label: 'Adding additional students',
          answer:
            'Under membership & billing, click on Add membership. From here, you can select the number of students you would like to add, as well as the type of subscription (monthly/yearly).',
        },
      ],
    },
    {
      label: 'Curriculum',
      questions: [
        {
          label: 'Genius Forest in the classroom',
          answer:
            'While Genius Forest does not replace curriculum entirely, it can be used to supplement and strengthen materials and concepts taught in the classroom. To find out how you can incorporate our platform into your teaching, visit our Contact page, or you send us an email at contact@geniusforest.com ',
        },
        {
          label: 'How many grade levels does Genius Forest cover?',
          answer: 'Genius Forest currently provides resources for those students in grade 6 to 8.',
        },
        {
          label: 'How many subjects does Genius Forest cover for each course?',
          answer:
            'Genius Forest covers a wide variety of subjects for each course. Genius Forest provides resources for subjects related to Numbers, Algebra, Data, Spatial Science, and Financial Literacy. There are countless resources and questions for each subject that can be used to improve your children`s academic potential.',
        },
      ],
    },
    {
      label: 'Platform',
      questions: [
        {
          label: 'How does Genius Forest work?',
          answer:
            'Genius Forest`s process ensures each student recieves a learning plan personalized to their unique skill level. Each question is tailored to fit each student`s academic objectives. Once students spend some time on Genius Forest, the platform will identifty strong points and areas of improvements. Genius Forest also monitors learning history and performance overtime, taking the smart and efficient approach to teaching children.',
        },
      ],
    },
  ]);

  const handleSearch = event => {
    event.preventDefault();
    const { value } = event.target;
    setSearch(value);
  };

  const [selectedQuestion, setSelectedQuestion] = React.useState(null);

  const handleSelectQuestion = value => {
    if (value === 'all') return setSearch('');
    setSelectedQuestion(value);
    setSearch(value);
  };

  React.useEffect(() => {
    if (!category) setSearch('');
    if (selectedCategory !== category) {
      if (!selectedQuestion) setSearch('');
      return setSelectedCategory(category);
    }
    const unlisten = history.listen(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    return () => {
      unlisten();
    };
  }, [selectedCategory, category]);

  if (!data)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <Grid direction="column" className="page_wrapper FAQ_wrapper">
      <SectionOne handleSearch={handleSearch} search={search} />
      {!selectedCategory ? (
        <SectionTwo data={data} search={search} handleSelectQuestion={handleSelectQuestion} />
      ) : (
        <QuestionPage data={data} search={search} handleSelectQuestion={handleSelectQuestion} />
      )}
    </Grid>
  );
}

export default FAQ;
