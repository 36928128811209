import React from 'react';
import { Grid } from '@material-ui/core';
import ButtonIconRound from '../../../designSystem/components/buttons/round-icon-button';

export default function Accordion(props) {
  const [expand, setExpand] = React.useState(false);

  const handleExpand = () => {
    !expand ? setExpand(true) : setExpand(false);
  };

  return (
    <Grid container style={{ flexWrap: 'nowrap' }} className="faq_box">
      <Grid container justifyContent="space-between" alignItems="center" className="inner_faq">
        <h3>{props.question}</h3>
        <span className="m_left_15 expand_icon">
          {expand ? (
            <ButtonIconRound arrow_direction="down" handleClick={handleExpand} />
          ) : (
            <ButtonIconRound arrow_direction="up" handleClick={handleExpand} />
          )}
        </span>
      </Grid>
      <Grid container className={expand ? 'expansion_section visible' : 'expansion_section'}>
        <Grid item xs={12}>
          {props.answer}
        </Grid>
      </Grid>
    </Grid>
  );
}
