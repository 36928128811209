import React from 'react';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import ToggleButtons from '../../../designSystem/components/buttons/toggle-buttons';

export default function SelectGrade(props) {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { subjects, handleSelectSubject } = props;
  const [subjectsList, setSubjectsList] = React.useState([]);
  const [selectedSubjectIndex, setSelectedSubjectIndex] = React.useState(0);

  const formateDropdown = (item, index) => {
    return {
      label: item.name,
      value: index,
      disabled: false,
    };
  };

  const addSubjectsToList = React.useCallback(subjects => {
    const dropdownList = [];
    for (let i = 0; i < subjects.length; i++) {
      dropdownList.push(formateDropdown(subjects[i], i));
    }
    setSubjectsList(dropdownList);
  }, []);

  const handleSubject = (event, item, index) => {
    setSelectedSubjectIndex(index);
    handleSelectSubject(subjects[index]);
  };

  React.useEffect(() => {
    if (subjects) addSubjectsToList(subjects);
  }, [subjects, addSubjectsToList]);

  return (
    <div className="page_wrapper">
      <Grid container className=" curriculum_select_grade_wrapper">
        <Grid item xs={12}>
          <h5 className="grade_title">Select your grade level below</h5>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={mdScreen ? 1 : 2}
          >
            <ToggleButtons
              list={subjectsList}
              value={selectedSubjectIndex}
              handleSelection={handleSubject}
              height={57}
              width={xsScreen ? 85 : mdScreen ? 120 : 190}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
