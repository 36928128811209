import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import './buttons.scss';

BasicButtonGroup.propTypes = {
  buttonsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      Value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ariaLabel: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BasicButtonGroup.defaultProps = {
  width: '183px',
  height: '50px',
  fontSize: '16px',
};

export default function BasicButtonGroup({
  buttonsList,
  ariaLabel,
  selectedValue,
  handleClick,
  width,
  height,
  fontSize,
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiButtonBase-root': {
        textTransform: 'capitalize',
        fontFamily: theme.fontFamily.default,
        fontWeight: 600,
        fontSize: fontSize,
        lineHeight: '22px',
        color: '#000000',
        width: width,
        height: height,
        background: '#F8F8F8',
        border: 'none',
        borderRadius: '7px',
      },
    },
    selected: {
      background: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.secondary.text} !important`,
    },
  }));

  const classes = useStyles();

  const handleSelection = value => {
    handleClick(value);
  };

  return (
    <div className={classes.root}>
      <ButtonGroup aria-label={ariaLabel}>
        {buttonsList.map((item, index) => {
          return (
            <Button
              key={item.value}
              aria-label={selectedValue === item.value ? `selected ${item.label}` : `${item.label}`}
              className={selectedValue === item.value && classes.selected}
              onClick={() => handleSelection(item.value)}
            >
              {item.label}
            </Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
}

/*
  < ---- Example usage ----- >
  <ButtonGroup
    ariaLabel="Sign up Options"
    buttonsList={buttonsList}
    handleClick={(value) => setUserType(value)}
    width={183}
    height={50}
    fontSize={16}
  />
*/
