import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from './../../../designSystem/components/buttons/button';

const SectionThree = () => {
  let history = useHistory();

  const buttonHandler = action => {
    if (action === 'enter') {
      document.getElementById('button_cover_3').classList.add('active');
      document.getElementById('button_3').classList.add('active');
    } else {
      document.getElementById('button_cover_3').classList.remove('active');
      document.getElementById('button_3').classList.remove('active');
    }
  };

  return (
    <div className="section_three_wrapper">
      <img
        src="/assets/images/section_seven_background.png"
        alt="section_three_background"
        className="section_three_background"
      />
      <div className="section_three_content_container">
        <h2>Discover how Genius Forest</h2>
        <h2>can support your learning</h2>
        <div className="buttons_wrapper">
          <div
            className="sign_up_button_wrapper"
            onMouseEnter={() => {
              buttonHandler('enter');
            }}
            onMouseLeave={() => {
              buttonHandler('leave');
            }}
            onClick={() => history.push('/pre-signup')}
            onFocus={() => {
              buttonHandler('enter');
            }}
            onBlur={() => {
              buttonHandler('leave');
            }}
          >
            <div className="sign_up_button_cover" id="button_cover_3"></div>
            <button className="sign_up_button" id="button_3">
              Sign up for free
            </button>
          </div>
          <Button
            label="How it works?"
            width={149}
            height={57}
            buttonStyle="default"
            onClick={() => history.push('/product')}
            bgColor="#EFF8F5"
            fontSize={16}
            addClass="try_demo"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
