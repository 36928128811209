import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { Grid } from '@material-ui/core';
import ProgressCircle from '../../../designSystem/components/interactiveElements/progressCircle';
import { learnersAPI } from '../../../API/learners';

function SkillInformation({ skills, active_index, learner }) {
  const { getAccessTokenSilently } = useAuth0();

  const [beaten_students, setBeatenStudents] = React.useState(0);

  const skill_name = skills[active_index] && skills[active_index].skill_name;
  const subject_name = skills[active_index] && skills[active_index].subject_name;

  const calculateLevel = skill => {
    const learner_points = skill.skill_learner_points ? skill.skill_learner_points : 0;
    const difficult_level = (learner_points / skill.skill_points) * 100;
    if (difficult_level <= 25) return 'Fundamental';
    if (difficult_level > 25 && difficult_level <= 50) return 'Intermediate';
    if (difficult_level > 50 && difficult_level <= 75) return 'Advanced';
    if (difficult_level > 75 && difficult_level <= 100) return 'Master';
  };

  const getSkillProgress = () => {
    let learner_skill_points = 0;
    let learner_skill_progress = 0;
    if (skills[active_index]) {
      learner_skill_points = skills[active_index].skill_learner_points
        ? skills[active_index].skill_learner_points
        : 0;

      learner_skill_progress = (learner_skill_points / skills[active_index].skill_points) * 100;
    }
    return learner_skill_progress;
  };

  const getBeatenValue = React.useCallback(
    async skill => {
      const token = await getAccessTokenSilently();
      const { skill_id, skill_learner_points } = skill;
      if (skill_id) {
        learnersAPI.GET.getBeatenStudents(skill_id, learner.id, skill_learner_points, token)
          .then(response => {
            setBeatenStudents(response.data.result);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    [setBeatenStudents, getAccessTokenSilently, learner.id],
  );

  React.useEffect(() => {
    getBeatenValue(skills[active_index]);
  }, [skills, active_index, getBeatenValue]);

  return (
    <Grid container className="skill_information_wrapper">
      <Grid item xs={12}>
        <h3 className="skill_name">{skill_name}</h3>
      </Grid>
      <Grid item xs={12} className="beaten_text">
        <h4 className="beaten_text">
          You’ve outperformed <span className="beaten_percentage">{Math.round(beaten_students)}% </span>students
        </h4>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
          <Grid item className="current_level_box">
            <div className="level_title">Current Level</div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={2}
              className="skill_body"
            >
              <Grid item className="subject_name">
                {subject_name}
              </Grid>
              <Grid item className="level_name">
                {skills[active_index] && calculateLevel(skills[active_index])}
              </Grid>
              <Grid item>
                <ProgressCircle
                  progress_value={getSkillProgress()}
                  background="#54666D"
                  text="#6FF2C2"
                />
              </Grid>
            </Grid>
          </Grid>
          {/* 
          TODO: Not part of the first MVP
          <Grid item className="estimate_date_box">
            <div className="level_title">Estimated Master Date</div>
            <div className="level_date">Mar 28 2022</div>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}

SkillInformation.propTypes = {
  skills: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        skill_name: PropTypes.string,
        subject_name: PropTypes.string,
        skill_learner_points: PropTypes.number,
        skill_points: PropTypes.number,
        skill_total_questions: PropTypes.number,
        skill_attempts: PropTypes.number,
        skill_wrong_attempts: PropTypes.number,
        tasks: PropTypes.arrayOf(PropTypes.any),
        subject_id: PropTypes.number,
      }),
    ),
  ]).isRequired,
  active_index: PropTypes.number.isRequired,
};

export default SkillInformation;
