import React from 'react';
import McuTextFields from './components/TextFieldsSection';
import McuButtons from './components/buttonsSection';
import McuDropdowns from './components/dropdowns';
import McuTab from './components/tabsSection';
import McuCarousel from './components/carouselSection';
import McuInteractiveComponents from './components/interactiveElementsSection';
import './designSystem.scss';

export default function MasterControlUI(props) {
  return (
    <>
      <div className="masterControl_wrapper">
        <McuTextFields />
        <McuButtons />
        <McuDropdowns />
        <McuInteractiveComponents />
        <McuTab />
        <McuCarousel />
      </div>
    </>
  );
}
