import React from 'react';
import PropTypes from 'prop-types';
import QuestionSelection from '../../../../questionModels/questionSelection';
import Button from '../../../../designSystem/components/buttons/button';

function QuestionPreview({ question, forAdd }) {
  question.validation_control = { selected_answer: '' };
  return (
    <div className="preview_wrapper">
      <h4 className="preview_title">Preview</h4>
      <QuestionSelection question={question} handleAnswer={(event, value) => {}} forAdd={forAdd} />
      <Button
        label="Check"
        addId="check_button"
        onClick={() => {}}
        width={77}
        height={44}
        buttonStyle="default"
      /> 
    </div>
  );
}

QuestionPreview.propTypes = {
  question: PropTypes.objectOf(PropTypes.any),
};

export default QuestionPreview;
