import React from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../designSystem/components/interactiveElements/loading';
import NothingToShow from "../designSystem/components/messages/notFound/nothingToShow";

const PrivateRoute = ({
  component: Component,
  path,
  subscription,
  handleHideLayout,
  updateLayout,
  handleChangeLayout,
  history,
  ...rest
}) => {
  const { isLoading, user, getIdTokenClaims } = useAuth0();

  React.useEffect(() => {
    async function getUserRoles() {
      const context = await getIdTokenClaims();
      const userRoles = context[process.env.REACT_APP_AUTH_ROLES_NAMESPACE];
      if (userRoles.includes('teacher')) {
        return history.push('/admin-portal/questions');
      }
    }
    if (user) getUserRoles();
  }, [user, getIdTokenClaims, history]);

  if (isLoading) return <LoadingPage />;
  if (!user) return <NotRegistered />;
  if (!user.email_verified) return <Redirect to="/email-verification" />;

  const userType = user['https://www.geniusforest.ca/user_type'];
  if (!subscription && path.includes('/my-students')) return <LoadingPage />;
  if (!subscription && userType === 'parent' && path.includes('/ask-parent'))
    return <Redirect to="/my-students" />;

  return (
    <div className="pages_wrapper">
      <Route
        path={path}
        {...rest}
        render={props => (
          <Component
            handleHideLayout={handleHideLayout}
            handleUpdateData={() => updateLayout()}
            subscription={subscription}
            handleChangeLayout={handleChangeLayout}
            {...props}
          />
        )}
      />
    </div>
  );
};

export default withRouter(PrivateRoute);

const NotRegistered = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Box>
        <NothingToShow title="Restricted access" message="Please login, to enjoy all the benefits Genius Forest has to offer." />
      </Box>
    </Grid>
  );
};

const LoadingPage = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Loading />
    </Grid>
  );
};
