import React from 'react';
import Moment from 'react-moment';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Loading from '../../../../designSystem/components/interactiveElements/loading';

export default function BillingTable(props) {
  const { data, handleButton } = props;

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const header = [
    {
      name: 'created_at',
      label: 'Date paid',
    },
    {
      name: 'invoice_number',
      label: 'Reference #',
    },
    {
      name: 'payment_method',
      label: 'Payment method',
    },
    {
      name: 'amount',
      label: 'Amount',
    },
    {
      name: 'receipt',
      label: 'Receipt',
    },
  ];

  const formatAmount = item => {
    const amount = item.paymentRequests[0].totalCompletedAmountMoney.amount.toString();
    var len = amount.length;
    return `$${amount.substring(0, len - 2) + '.' + amount.substring(len - 2)}`;
  };

  return (
    <>
      <table className="billing-table">
        <thead>
          <tr className="header">
            {header.map(item => (
              <th key={item.name} id={item.name} className="header-items">
                {item.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data[0] && (
            <>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="body-items">
                    {<Moment date={item.updatedAt} format="DD/MM/YYYY" />}
                  </td>
                  {!xsScreen && (
                    <>
                      <td className="body-items">{item.invoiceNumber}</td>
                      <td className="body-items">{item.customer.cards[0].cardBrand}</td>
                    </>
                  )}
                  <td className="body-items view-wrapper">{formatAmount(item)}</td>
                  <td className="body-items view-wrapper">
                    <button
                      type="button"
                      onClick={() => handleButton(item)}
                      className="view-button"
                      aria-label={`row number, ${index + 1}, Date paid, ${(
                        <Moment date={item.created_at} format="DD/MM/YYYY" />
                      )}, Reference number, ${item.invoiceNumber}, Payment method, ${item.customer.cards[0].cardBrand
                        }, Amount, ${formatAmount(item)}, view details.`}
                    >
                      {xsScreen ? 'View details' : 'View'}
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {!data && (
        <div className="text_center m_top_6">
          <Loading />
        </div>
      )}
      {data && data.body && <div className="text_center m_top_6 no-invoices">{data.body}</div>}
    </>
  );
}
