import React from 'react';
import ReactGoogleAnalytics from 'react-ga';
import { Grid } from '@material-ui/core';
import SecondaryTabs from '../../designSystem/components/tabs/secondaryTab';
import LearnerInfoCoin from '../../designSystem/components/interactiveElements/learnerInfoCoin';
import SubjectSelection from './components/subjectSelection';
import Analysis from './components/analysis/analysis';
import Summary from './components/summary/summary';
import { useAuth0 } from '@auth0/auth0-react';
import { practiceAPI } from '../../API/practice';
import { topicsAPI } from '../../API/topics';
import { skillsAPI } from '../../API/skills';
import DefaultDropdown from '../../designSystem/components/dropdowns/dropdown';
import './myProgress.scss';

function MyProgress(props) {
  const { match, history, subscription } = props;
  const { tab } = match.params;
  const { user, getAccessTokenSilently } = useAuth0();

  const getLearner = () => {
    if (!subscription) return false;
    let result;
    subscription.learners.map(learner => {
      if (learner.user_id === user.sub) return (result = learner);
    });
    return result;
  };

  const learner = getLearner();
  const [selectedTab, setSelectedTab] = React.useState(tab ? parseInt(tab, 10) : 0);

  const settingsTabs = [
    { label: 'Summary', value: 0 },
    { label: 'Analysis', value: 1 },
  ];

  const handleTab = (event, value) => {
    setSelectedTab(value);
    history.push(`/my-progress/${value}`);
  };

  const [selectedSubject, setSelectedSubject] = React.useState(false);
  const [mobileTopics, setMobileTopics] = React.useState();
  const [mobileTopicsList, setMobileTopicsList] = React.useState();
  const [selectedTopicIndex, setSelectedTopicIndex] = React.useState(0);
  const [selectedTopic, setSelectedTopic] = React.useState(null);
  const [topics, setTopics] = React.useState(false);
  //practice sumamry and acitivities
  const [practiceSummary, setPracticeSummary] = React.useState(false);
  const [practiceActivities, setPracticeActivities] = React.useState(false);

  // API CALLS
  const getTopicPracticeActivities = React.useCallback(
    async topic => {
      const token = await getAccessTokenSilently();
      await practiceAPI.GET.getPracticeActivities(topic.id, learner.id, token)
        .then(response => {
          const { practice_activities } = response.data;
          if (practice_activities.length > 0) {
            setPracticeActivities(false);
            setPracticeActivities(practice_activities);
          } else {
            setPracticeActivities(false);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    [getAccessTokenSilently, learner.id],
  );

  const getTopicPracticeSummary = React.useCallback(
    async topic => {
      const token = await getAccessTokenSilently();
      await topicsAPI.GET.getTopicPracticeSummary(topic.id, learner.id, token)
        .then(response => {
          const { practice_summary } = response.data;
          setPracticeSummary(practice_summary);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [getAccessTokenSilently, learner.id],
  );
  // END OF API CALLS

  const formateDropdown = (item, index) => {
    return {
      label: item.name,
      value: index,
      disabled: false,
    };
  };

  const addTopicsToList = React.useCallback(topics => {
    const dropdownList = [];
    const analysisDropdown = [];
    for (let i = 0; i < topics.length; i++) {
      dropdownList.push(formateDropdown(topics[i], i));
      analysisDropdown.push(formateDropdown(topics[i], i + 1));
    }
    setMobileTopicsList(dropdownList);
    analysisDropdown.unshift({
      label: 'Overview',
      value: 0,
      disabled: false,
    });
    setMobileTopicsListAnalysis(analysisDropdown);
  }, []);

  const handleSelectSubject = React.useCallback(subject => {
    setSelectedSubject(subject);
  }, []);

  const handleTopic = (topic, index) => {
    //TODO: MUST IMPLEMENT OVERVIEW FUNCTION
    // if(topic === 'all'){
    //   setSelectedTopic({
    //     name: 'Overview'
    //   })
    // }
    // else{
    setSelectedTopicIndex(index);
    setSelectedTopic(topics[index]);
    getTopicPracticeActivities(topics[index]);
    getTopicPracticeSummary(topics[index]);
    // }
  };

  React.useEffect(() => {
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
  }, []);

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@ANALYSIS STATES AND FUNCTIONS@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  //for analysis
  const [mobileTopicsListAnalysis, setMobileTopicsListAnalysis] = React.useState();
  const [mobileTopicsListAnalysisIndex, setMobileTopicsListAnalysisIndex] = React.useState(0);
  const [skills, setSkills] = React.useState(false);

  const getPracticeSkills = React.useCallback(
    async topic_id => {
      const token = await getAccessTokenSilently();

      await practiceAPI.GET.getPracticeSkills(topic_id, learner.id, token)
        .then(response => {
          const { skills } = response.data;
          setSkills(skills);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [getAccessTokenSilently, learner.id],
  );

  const getAllSkills = React.useCallback(
    async selectedSubject => {
      const token = await getAccessTokenSilently();

      await skillsAPI.GET.getSkillsBySubjectID(token, selectedSubject.id, learner.id)
        .then(response => {
          const { skills } = response.data;
          setSkills(skills);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [getAccessTokenSilently, learner.id],
  );

  //for topic selection in analysis
  const [analysisTopics, setAnalysisTopics] = React.useState([]);
  const [selectedAnalysisTopic, setSelectedAnalysisTopic] = React.useState(0);

  const handleSelectTopic = React.useCallback(
    topic => {
      if (topic === 0) {
        getAllSkills(selectedSubject);
        setMobileTopicsListAnalysisIndex(topic);
        setSelectedAnalysisTopic(topic);
      } else {
        getPracticeSkills(topic.id);
      }
    },
    [getAllSkills, getPracticeSkills, selectedSubject],
  );

  const handleAnalysisTopic = (item, index) => {
    if (item === 0) {
      handleSelectTopic(item);
      return;
    }
    setSelectedAnalysisTopic(index);
    handleSelectTopic(analysisTopics[index - 1]);
    setMobileTopicsListAnalysisIndex(index);
  };

  const getTopics = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    topicsAPI.GET.getLearnerTopicsBySubjectID(token, selectedSubject.id, learner.id)
      .then(response => {
        setAnalysisTopics(response.data);
        handleAnalysisTopic(0, 0);
      })
      .catch(error => {
        console.log(error);
      });
  }, [selectedSubject, getAccessTokenSilently, learner.id, handleSelectTopic]);

  // if (!mobileTopics)
  //   return (
  //     <Grid
  //       container
  //       direction="column"
  //       justify="center"
  //       alignItems="center"
  //       className="pages_wrapper"
  //     >
  //       <Loading />
  //     </Grid>
  //   );

  return (
    <>
      <div className="myProgress_mobile">
        <div className="icon_mobile">
          <LearnerInfoCoin {...props} learner={learner} />
        </div>
        <div className="subject_dropdown">
          <p className="subject_dropdown_label">Grade</p>
          <div className="subject_dropdown_mobile">
            <SubjectSelection
              {...props}
              handleSelectSubject={handleSelectSubject}
              status="mobile"
              width={80}
              learner={learner}
            />
          </div>
          <div className="topic_dropdown_mobile">
            {mobileTopics && selectedTab === 0 && (
              <DefaultDropdown
                list={mobileTopicsList}
                selectedItem={selectedTopicIndex}
                handleChange={value => handleTopic(value.target.value, value.target.value)}
                width={160}
              />
            )}
            {mobileTopics && selectedTab === 1 && (
              <DefaultDropdown
                list={mobileTopicsListAnalysis}
                selectedItem={mobileTopicsListAnalysisIndex}
                handleChange={value => handleAnalysisTopic(value.target.value, value.target.value)}
                width={160}
              />
            )}
          </div>
        </div>
      </div>
      <Grid item xs={12} className="myProgress_tab">
        <SecondaryTabs
          list={settingsTabs}
          selectedTab={selectedTab}
          handleSelectedTab={handleTab}
        />
      </Grid>
      <Grid item xs={12} className="myProgress_wrapper page_wrapper">
        <Grid container>
          <Grid item xs={12} className="icon_desktop">
            <LearnerInfoCoin {...props} learner={learner} />
          </Grid>
          <SubjectSelection
            {...props}
            handleSelectSubject={handleSelectSubject}
            status="desktop"
            width={160}
            learner={learner}
          />
          {selectedTab === 0 && (
            <Summary
              {...props}
              selectedSubject={selectedSubject}
              setMobileTopics={setMobileTopics}
              addTopicsToList={addTopicsToList}
              handleTopic={handleTopic}
              selectedTopicIndex={selectedTopicIndex}
              setSelectedTopicIndex={setSelectedTopicIndex}
              selectedTopic={selectedTopic}
              setSelectedTopic={setSelectedTopic}
              setTopics={setTopics}
              topics={topics}
              practiceActivities={practiceActivities}
              setPracticeActivities={setPracticeActivities}
              getTopicPracticeActivities={getTopicPracticeActivities}
              getTopicPracticeSummary={getTopicPracticeSummary}
              practiceSummary={practiceSummary}
              learner={learner}
            />
          )}
          {selectedTab === 1 && (
            <Analysis
              {...props}
              selectedSubject={selectedSubject}
              skills={skills}
              getAllSkills={getAllSkills}
              handleSelectTopic={handleSelectTopic}
              getTopics={getTopics}
              handleAnalysisTopic={handleAnalysisTopic}
              analysisTopics={analysisTopics}
              setAnalysisTopics={setAnalysisTopics}
              selectedAnalysisTopic={selectedAnalysisTopic}
              setSelectedAnalysisTopic={setSelectedAnalysisTopic}
              learner={learner}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default MyProgress;
