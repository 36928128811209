import React from 'react';
import SectionOne from './components/sectionOne';
import SectionTwo from './components/sectionTwo';
export default function pricing() {
  return (
    <div className="pricing_wrapper">
      <SectionOne />
      <SectionTwo />
    </div>
  );
}
