import axios from 'axios';
import url from './url';

export const coursesAPI = {
    GET: {
        getCourseByID(token, course_id){
            return axios.get(`${url}api/getCoursesByID/${course_id}`,{
                headers: {authorization: `Bearer ${token}`},
            });
        }
    }
}