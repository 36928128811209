import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import NextQuestionArrow from '../../../assets/lottie/question_animated_arrow.json';
import NextQuestionArrowBlack from '../../../assets/lottie/question_animated_arrow-black.json';
import './buttons.scss';

AnimatedNextButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  mobile_break_point: PropTypes.number.isRequired,
  addClass: PropTypes.string.isRequired,
  show_animation: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default function AnimatedNextButton({ handleClick, mobile_break_point, addClass, show_animation, type }) {
  // To implement the animated arrow
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: type === 'white' ? NextQuestionArrow : NextQuestionArrowBlack,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const IOSMobile = window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i) ? true : false;
  const custom_width = IOSMobile ? 43 : 46;
  const image_url = type === 'white' ? '/assets/icons/next_question_arrow.svg' : '/assets/icons/next_question_arrow-black.svg';
  return (
    <button 
      onClick={handleClick} 
      aria-label="next" 
      type="button" 
      className={`next_question_button ${addClass}`}
    >
      {show_animation ? (
        <Lottie options={lottieOptions} height={window.innerWidth <= mobile_break_point ? 33 : 80} width={window.innerWidth <= mobile_break_point ? custom_width : 123} />
      ) : (
        <>
          <img className="icon_desktop" alt="go to the next question button" src={image_url} />
          <img className="icon_mobile" alt="go to next question button" src={image_url} />
        </>
      )}
    </button>
  );
}
