import React from 'react';
import PropTypes from 'prop-types';

QuestionResultMessage.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
};

export default function QuestionResultMessage({ message, error }) {
  return (
    <div className="result_message_wrapper">
      <div className="position_relative">
        <div className="box">
          <span className={error === true ? 'error' : 'success'}>{message}</span>
        </div>
        <img className="result_image" alt="result icon" src="/assets/icons/question_result_icon.svg" />
      </div>
    </div>
  );
}
