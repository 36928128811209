import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import DifficultLevel from '../../../../designSystem/components/interactiveElements/difficultLevel';
import ProgressCircle from '../../../../designSystem/components/interactiveElements/progressCircle';

QuestionOutlineBoxMobile.propTypes = {
  question_number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  current_question: PropTypes.number.isRequired,
};

export default function QuestionOutlineBoxMobile(props) {
  const {
    practice_controller: { current_skill },
    question_number,
    skill,
  } = props;

  const tasks = skill.tasks.slice(0, 3);
  const learner_points = skill.skill_learner_points ? skill.skill_learner_points : 0;
  const learner_progress = (learner_points / skill.skill_points) * 100;

  const calculateDifficultLevel = learner_progress => {
    if (learner_progress <= 20) return 1;
    if (learner_progress > 20 && learner_progress <= 40) return 2;
    if (learner_progress > 40 && learner_progress <= 60) return 3;
    if (learner_progress > 60 && learner_progress <= 80) return 4;
    if (learner_progress > 80) return 5;
  };
  return (
    <Grid item className="skill_outline_wrapper skill_outline_wrapper_mobile">
      <Box
        className={
          current_skill === question_number - 1
            ? 'skill_outline_box box_expanded'
            : 'skill_outline_box'
        }
        tabIndex={current_skill === question_number - 1 ? 0 : -1}
      >
        <span className="sr_only">Skill Outline</span>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Box className="box_title">
            <div className="active_indicator">
              {current_skill === question_number - 1 && (
                <img
                  src="/assets/icons/active-green-icon.png"
                  alt="Active Icon"
                  aria-hidden={true}
                />
              )}
            </div>
            {skill.skill_name}
          </Box>
        </Grid>
        <div className="unit_info_wrapper">
          <div className="grade_info_box">
            <span className="grade_number">6</span>
            <span className="grade_type">Fundamental</span>
          </div>
          <ProgressCircle progress_value={learner_progress} size={40} />
        </div>
        {/* {current_skill === question_number - 1 && (
          <Grid item xs={12} className="practice_plan_wrapper">
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                <Grid container>
                  <Grid item xs={12} className="title">
                    Practice Plan ({tasks.length})
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={12} className="title">
                    Difficulty
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                {tasks.map((item, index) => (
                  <Grid item xs={12} key={item.name} className="unit_name">
                    <div className="list_icon">
                      <img
                        src="/assets/icons/list_black_icon.svg"
                        alt="Active Icon"
                        aria-hidden={true}
                      />
                    </div>
                    {item.name}
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                {tasks.map((item, index) => (
                  <Grid item xs={12} key={`question ${index}`} className="unit_difficult">
                    <DifficultLevel difficult_level={calculateDifficultLevel(learner_progress)} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )} */}
      </Box>
    </Grid>
  );
}
