import React from 'react'

const Section_6 = () => {
  return (
    <div className="section_6_wrapper">
        <h2 style={{marginTop: '0px'}}>Trusted and Recognized By</h2>
        <div className="recognized_images_wrapper">
            <img src="/assets/images/recognized_1.png" alt="recognized_1" className="partner_image"/>
            <img src="/assets/images/recognized_2.png" alt="recognized_2" className="partner_image"/>
            <img src="/assets/images/recognized_3.png" alt="recognized_3" className="partner_image"/>
            <img src="/assets/images/recognized_4.png" alt="recognized_4" className="partner_image"/>
            <img src="/assets/images/recognized_5.png" alt="recognized_5" className="partner_image"/>
        </div>
    </div>
  )
}

export default Section_6