import React from 'react';
import TextField from '../../../../designSystem/components/textFields/textField';
import Button from '../../../../designSystem/components/buttons/button';
import { useTheme, useMediaQuery, FormControlLabel, Checkbox } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { settingsAPI } from '../../../../API/settings';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@material-ui/core';
import Loading from '../../../../designSystem/components/interactiveElements/loading';
import ChangePassword from './changePassword';
import AcceptDialog from './acceptDialog';
import '../accountSettings.scss';

export default function SettingsBox(props) {
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.down('601'));
  const { user } = useAuth0();
  const { subscription, fileSelectedHandler, handleFormSubmit, isLoading } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [disable, setDisable] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState([])
  const [acceptOpen, setAcceptOpen] = React.useState(false);

  const getUserName = () => {
    if (user.name.includes('@')) return user.nickname;
    return user.name;
  };
  const checkSocial = () => {
    return user.sub.includes('oauth2')
  }
  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }
  const handleAcceptClose = () => {
    setAcceptOpen(false);
  }

  const useFormMethods = useForm({
    defaultValues: {
      name: getUserName(user),
      email: user.email,
    },
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useFormMethods;

  const checkValue = async value => {
    const data = {
      email: value,
    };
    if (data.email.toLowerCase() == user.email.toLowerCase()) {
      const res = {
        status: 200,
      };
      return res;
    }
    const token = await getAccessTokenSilently();
    const response = await settingsAPI.POST.validateSettingsEmail(data, token);
    return response;
  };

  const handleButtonClick = async formData => {
    const data = {
      form: formData,
      user_id: user.sub,
      user_type: subscription.user_type,
      old_email: user.email,
      old_name: user.nickname,
    };

    if (formData.email && formData.email !== user.email) {
      setAcceptOpen(true);
      setForm(data);
      return;
    }
    handleFormSubmit(data);
  };

  if (!subscription) return <LoadingPage />;

  return (
    <div className="accountSettings_box">
      <AcceptDialog open={acceptOpen} handleClose={handleAcceptClose} form={form} handleFormSubmit={handleFormSubmit} />
      <ChangePassword open={open} handleClose={handleClose} handleFormSubmit={handleFormSubmit} user_id={user.sub} />
      <div className="accountSettings_text">Account Settings</div>
      <div className="accountSettings_profile">Profile</div>
      <div className="accountSettings_password">Password</div>
      <div className="accountSettings_notifications">Email Notifications</div>
      <img src={user.picture} className="profile_img" alt="profile" width={136} height={136} />
      <label>
        <img className="interactive_element add_photo" src="/assets/images/add_photo.png" />
        <input
          type="file"
          id="image_upload"
          name="dataUpload"
          accept=".png,.jpg,.jpeg"
          onChange={event =>
            fileSelectedHandler(event, {
              user_id: user.sub,
              user_img: user.picture,
            })
          }
          className="file-upload"
        />
      </label>
      <form onSubmit={handleSubmit(handleButtonClick)}>
        <div className="accountSettings_info">
          <span className="info_text">Name</span>
          <TextField
            id="profile_name"
            placeholder="Name"
            type="text"
            width={smScreen ? 310 : 514}
            requiredField
            name="name"
            helperText={errors.name ? errors.name.message : ''}
            error={!!errors.name}
            register={register({
              required: ' Name is required',
              minLength: { value: 2, message: 'Min length 2' },
              maxLength: { value: 30, message: 'Max length 30' },
              pattern: {
                value: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/i,
                message: 'Can only contain letters and numbers.',
              },
            })}
          />
          <span className="info_text">Email address</span>
          <TextField
            id="profile_email"
            placeholder="email@example.com"
            type="text"
            disabled={checkSocial()}
            requiredField
            helperText={errors.email ? errors.email.message : ''}
            error={!!errors.email}
            name="email"
            register={register({
              required: 'Email is required',
              maxLength: { value: 100, message: 'Max length 100' },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid Email Format',
              },
              validate: {
                value: async value => {
                  const res = await checkValue(value);
                  if (res.status !== 200) {
                    return res.data.message;
                  } else {
                    return true;
                  }
                },
              },
            })}
            width={smScreen ? 310 : 514}
            marginBottom={smScreen ? 8 : 30}
          />
          <span className="info_text">Password</span>
          <span className='pass_placeholder'>●●●●●●●●●●●</span>
          {!checkSocial() && (
            <button className="change_button" type="button" disabled={checkSocial()} onClick={handleOpen}>
              <span className="change_password">Reset</span>
            </button>
          )}
        </div>
        <div className="accountSettings_checkInfo">
          <span className="send_me_notifications">Send me email notifications for:</span>
          <div className="checkInfo_data">
            <FormControlLabel
              control={
                <Controller
                  name="weekly_report"
                  control={control}
                  defaultValue={subscription.weekly_report}
                  render={props => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      className="checkbox"
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
            />
            <span className="checkInfo_text">
              Weekly reports <br />{' '}
              <span className="checkInfo_subtext">
                Receive a summary of what you students have been working on
              </span>
            </span>
          </div>
          <div className="checkInfo_data">
            <FormControlLabel
              control={
                <Controller
                  name="newsletter"
                  control={control}
                  defaultValue={subscription.newsletter}
                  render={props => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      className="checkbox"
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
            />
            <span className="checkInfo_text">
              Genius Forest Newsletter <br />{' '}
              <span className="checkInfo_subtext">News, announcements, and product updates</span>
            </span>
          </div>
          <div className="checkInfo_data">
            <FormControlLabel
              control={
                <Controller
                  name="account_activity"
                  control={control}
                  defaultValue={subscription.account_activity}
                  render={props => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      className="checkbox"
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
            />
            <span className="checkInfo_text">
              Account activity <br />{' '}
              <span className="checkInfo_subtext">
                Receive notifications about general student activity
              </span>
            </span>
          </div>
        </div>
        <div className="save_changes_button">
          {isLoading && (
            <Loading />
          )}
          <Button
            label="Save Changes"
            width={smScreen ? 310 : 142}
            height={44}
            fontSize={16}
            type="submit"
            addClass="button_text"
            onClick={() => { }}
            disable={isLoading}
          />
        </div>
      </form>
    </div>
  );
}
const LoadingPage = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Loading />
    </Grid>
  );
};