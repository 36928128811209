import axios from 'axios';
import url from './url';

export const welcomeEmailAPI = {
  POST: {
    welcomeEmailStudent(token, data) {
      return axios.post(`${url}api/welcome-email-student`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
};
