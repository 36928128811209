import React from 'react';
import ReactGoogleAnalytics from 'react-ga';
import { Redirect, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../../designSystem/components/buttons/button';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { authAPI } from '../../API/auth';
import DefaultAlert from '../../designSystem/components/alerts/defaultAlert';
import './emailVerification.scss';

function EmailVerification(props) {
  const { user, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('750'));

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert(
      {
        open: true,
        severity,
        title,
        body,
      },
      setTimeout(() => {
        setDefaultAlert({
          open: false,
          severity,
          title,
          body,
        });
      }, 5000),
    );
  };

  const handleResendEmail = async () => {
    const token = await getAccessTokenSilently();
    const data = {
      user_id: user.sub,
    };
    await authAPI.POST.resendEmailVerification(token, data)
      .then(response => {
        console.log(response);
        handleAlert('success', 'Email verification successfully sent!');
      })
      .catch(error => {
        console.log(error);
        const { title, body } = error.response.data;
        handleAlert('error', title, body);
      });
  };

  React.useEffect(() => {
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
  }, []);

  if (!user || user.email_verified) return <Redirect to="/plan-selection" />;

  return (
    <>
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      <div className="page_wrapper signUp_message_wrapper">
        <div className="info_box">
          <img
            src="assets/images/email_verification_envelope.png"
            width={smScreen ? 132 : 148.13}
            height={smScreen ? 74.98 : 84.14}
            className="box_image"
          ></img>
          <span className="almost_there">You're almost there!</span>
          <span className="sent_email">
            We have sent an email to <span className="email">{user && user.email}</span> <br />{' '}
            Please click on the verification link in your email to continue.
          </span>
          <Button
            width={smScreen ? 333 : 256}
            height={smScreen ? 42 : 44}
            label="I have verified my email"
            buttonStyle="primary"
            fontSize={16}
            addClass="email_verification_button"
            onClick={() => loginWithRedirect({ screen_hint: 'login' })}
          />
          <Link onClick={handleResendEmail} className="resend_email interactive_element" to="#">
            Resend verification email
          </Link>
        </div>
      </div>
    </>
  );
}

EmailVerification.propTypes = {};

export default EmailVerification;
