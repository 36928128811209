import React from 'react';
import { Grid } from '@material-ui/core';
import ProgressCircle from '../../../designSystem/components/interactiveElements/progressCircle';
import DifficultLevel from "../../../designSystem/components/interactiveElements/difficultLevel";

export default function InteractiveElementsSection(props) {
  return (
    <Grid container className="designSystem_section-wrapper">
      <Grid item xs={12} className="designSystem-sections">
        <h2 className="title">Interactive elements</h2>
        <Grid item xs={12}>
          <h2 className="subTitle">Visual Elements</h2>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
          <Grid item>
            <ProgressCircle progress_value={20} />
          </Grid>
          <Grid item>
            <DifficultLevel difficult_level={3} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
