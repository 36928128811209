import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../../../designSystem/components/buttons/button';
import './dialogs.scss';

function LockedSubscription({ handleClose, open, handleConfirm, hideClose}) {
  const  { user } = useAuth0(); 
  const userType = user['https://www.geniusforest.ca/user_type'];
  
  const userText = [
    'Ready to enjoy fun and effective math learning experiences and stand out from peers? Resume your subscription by selecting the membership to resume on the Membership page.', 
    'Ready to enjoy fun and effective math learning experiences and stand out from peers? Ask your parent to renew your subscription or update their payment method.'
  ]
  const buttonText = [
    'Resume subscription', 
     'Logout'

  ]
  return (
    <div>
      <Dialog
        className="early_dialog"
        aria-labelledby="Early access dialog"
        open={open}
        maxWidth="md"
      >
        {!hideClose && (
          <button
            className="button_icon interactive_element close_dialog"
            onClick={handleClose}
            tabIndex="0"
          >
            <span className="sr_only">Close Dialog</span>
            <img src="/assets/icons/close-icon-black.svg" alt="Genius Forest Logo" />
          </button>
        )}
        <Box className="early_logo">
          <img src="/assets/logos/logo.svg" alt="Genius Forest Logo" className="logo" />
        </Box>
        <img
          src="/assets/images/early-access-image.png"
          alt="early access"
          className="early_image"
        />
        <Box className="dialog_body">
          <h1 className="title">Subscription paused</h1>
          <h5 className="body_text">
          {userType === 'learner' ? userText[1] : userText[0]}
          </h5>
        </Box>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="action_section"
        >
          <h5 className="body_text-mobile">
          {userType === 'learner' ? userText[1] : userText[0]}
          </h5>
          <Button
            label={userType === 'learner' ? buttonText[1] : buttonText[0]}
            onClick={handleConfirm}
            width={175}
            height={40}
            fontSize={14}
            buttonStyle="primary"
            type="button"
          />
        </Grid>
      </Dialog>
    </div>
  );
}

export default withRouter(LockedSubscription);
