import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, makeStyles } from '@material-ui/core';

SecondaryTab.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSelectedTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default function SecondaryTab({ list, handleSelectedTab, selectedTab, width }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiButtonBase-root': {
        color: '#19252A',
        fontFamily: theme.fontFamily.default,
        fontSize: '18px',
        lineHeight: '27px',
        textTransform: 'capitalize',
        width: width,
        borderRadius: '4px'
      },

      '& .Mui-selected': {
        color: '#19252A',
        fontWeight: 'bold',
        background: '#FFFFFF',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: '#FFFFFF',
      },
      '& .MuiTab-wrapper': {
        fontFamily: theme.fontFamily.default,
        color: '#19252A',
        fontSize: '18px',
        lineHeight: '27px',
        textTransform: 'capitalize',
        borderRadius: '4px'
      },
      '@media only screen and (max-width: 6000px)': {
        '& .MuiButtonBase-root': {
          width: 'auto',
          fontSize: '14px',
        },
      },
    },
  }));
  const classes = useStyles();

  return (
    <Tabs 
      className={classes.root} 
      value={selectedTab} 
      indicatorColor="primary" 
      textColor="primary" 
      onChange={handleSelectedTab} 
      aria-label="tab options"
      centered
    >
      {list.map((item, index) => (
        <Tab label={item.label} value={item.value} key={index} />
      ))}
    </Tabs>
  );
}
