import React from 'react';
import { Grid } from '@material-ui/core';
import PrimaryTab from '../../../designSystem/components/tabs/primaryTab';
import DefaultTab from '../../../designSystem/components/tabs/defaultTab';
import SecondaryTab from '../../../designSystem/components/tabs/secondaryTab';

export default function TabsSection(props) {
  const list = [
    {
      label: 'Engaging',
      icon: '/assets/images/home_tab_engaging.png',
    },
    {
      label: 'Interactive',
      icon: '/assets/images/home_tab_interactive.png',
    },
    {
      label: 'Reporting',
      icon: '/assets/images/home_tab_reporting.png',
    },
    {
      label: 'Diagnostic',
      icon: '/assets/images/home_tab_diagnostic.png',
    },
    {
      label: 'Curriculum',
      icon: '/assets/images/home_tab_curriculum.png',
    },
  ];

  const handleSelectedTab = (value) => {

  };

  const [default_tab_value, setDefaultTab] = React.useState(0);
  const [secondary_tab_value, setSecondaryTab] = React.useState(0);

  const handleDefaultSelectedTab = (event, value) => {
    setDefaultTab(value);
  };

  const default_list = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Not-selected',
      value: 'not-selected',
    },
    {
      label: 'Not-selected',
      value: 'not-selected',
    },
  ];

  return (
    <Grid container className="designSystem_section-wrapper">
      <Grid item xs={12} className="designSystem-sections">
        <h2 className="title">Tabs</h2>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Default Tab</h2>
          </Grid>
          <Grid item xs={12} className="designSystem-elements">
            <DefaultTab 
              list={default_list} 
              selected_tab={default_tab_value} 
              handleSelectedTab={handleDefaultSelectedTab} 
              width={200} 
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Primary Tab</h2>
          </Grid>
          <Grid item xs={12} className="designSystem-elements">
            <PrimaryTab list={list} handleSelectedTab={handleSelectedTab} />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Secondary Tab</h2>
          </Grid>
          <Grid item xs={12} className="designSystem-elements">
            <SecondaryTab 
              list={list} 
              selectedTab={secondary_tab_value}
              handleSelectedTab={(event, value) => setSecondaryTab(value)} 
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
