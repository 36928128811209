import React from 'react';
import { Divider, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import TextField from '../../../designSystem/components/textFields/textField';
import '../subscriptionPayment.scss';
import Button from '../../../designSystem/components/buttons/button';

export default function PlanInformation(props) {
  const theme = useTheme();
  const sScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var date = new Date();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();

  const { planNum }  = props;
  const  { planType } =props
  const { totalPrice }=props
  const { handlePromoText }=props
  const { isDisable }=props
  const { promo }=props
  const { handleValidatePromo }=props
  const { isPromo }=props
  const { isError } =props
  const { number_of_children } =props

  return (
    <>
      <div className="order_div">
        <span className="order_summary">Order Summary</span>
        <span className="summary_plan">{planType[planNum].plan} plan</span>
        <span className="price_calc">
          ${planType[planNum].price}
          <span className="frequency">/{planType[planNum].duration}</span>
          <span className="studentCount">&nbsp;x {number_of_children} students</span>
        </span>
      </div>
      <Divider className="divider" />
      <div className="promo_code">
        <TextField
          id="promoCode"
          label="Enter promotional code"
          type="promo"
          error={isError}
          helperText={isError ? "Invalid Promo Code" : ''} 
          value={promo}
          handleChanges={handlePromoText}
          width={sScreen ? 190 : 250}
        />
        <Button buttonStyle="primary" width={90} label="Apply" disable={isDisable} onClick={handleValidatePromo} height={47}>
          {' '}
        </Button>
      </div>
      <Divider className="divider2" />
      <Grid
        container
        className="pricing_grid"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid>
          <span className="due_current">
            Due {monthNames[month]} {day} {year}
          </span>
        </Grid>
        <Grid>
          <span className="total_price">${totalPrice.toFixed(2)}</span>
        </Grid>
      </Grid>
      <Grid
        container
        className="pricing_grid"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid>
          <span className="due_today">
            Due Today <span className="days_free">(30 days free)</span>
          </span>
        </Grid>
        <Grid>
          <span className="today_price">$0.00</span>
        </Grid>
      </Grid>
    </>
  );
}
