import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, Cell, XAxis, YAxis, ResponsiveContainer } from 'recharts';

export default class SkillGraphMobile extends PureComponent {
  render() {
    const { handleClick, active_index, average, data } = this.props;

    // const calculateBarSize = skill => {
    //   const result = (skill.skill_wrong_attempts / skill.skill_attempts) * 100;
    //   return `${result}%`;
    // };

    const positionAverageLine = () => {
      const maxValue = data[0].skill_points;
      return (average / maxValue) * 100;
    };

    const calculateLevel = skill => {
      const difficult_level = (skill.skill_wrong_attempts / skill.skill_attempts) * 100;
      if (difficult_level <= 50) return 1;
      if (difficult_level > 50 && difficult_level <= 70) return 2;
      if (difficult_level > 70 && difficult_level <= 90) return 3;
      if (difficult_level > 90 && difficult_level <= 100) return 4;
    };

    return (
      <div className="performance_chart_wrapper">
        <img src="/assets/icons/graph_side_arrow.svg" className="side_arrow" alt="graph arrow" />
        <div className="active_title">{data[active_index].skill_name}</div>
        <span
          className={
            calculateLevel(data[active_index]) === 4 ? 'level_master active_level' : 'level_master'
          }
        >
          <img className="master_level" src="/assets/icons/master_level.svg" alt="master coin" />
          Master
        </span>
        <span
          className={
            calculateLevel(data[active_index]) === 3
              ? 'level_advanced active_level'
              : 'level_advanced'
          }
        >
          Advanced
        </span>
        <span
          className={
            calculateLevel(data[active_index]) === 2
              ? 'level_intermediate active_level'
              : 'level_intermediate'
          }
        >
          Intermediate
        </span>
        <span
          className={
            calculateLevel(data[active_index]) === 1
              ? 'level_fundamental active_level'
              : 'level_fundamental'
          }
        >
          Fundamental
        </span>
        <img
          className="performance_line"
          style={{ top: `${positionAverageLine()}%` }}
          src="/assets/icons/performance_average-line.svg"
          alt="performance line"
        />
        <ResponsiveContainer width="100%" height={290} className="performance_chart">
          <BarChart width={150} height={40} data={data} barSize={32}>
            <XAxis
              dataKey="skill_learner_points"
              domain={[0, `dataMax+${data[active_index].skill_points}`]}
            />
            <Bar dataKey="skill_learner_points" onClick={handleClick}>
              {data.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill={
                    index === active_index ? 'rgba(111, 242, 194, .4)' : 'rgba(80, 93, 98, 0.5)'
                  }
                  key={`cell-${index}`}
                  radius={[10, 10, 0, 0]}
                ></Cell>
              ))}
            </Bar>
            <YAxis domain={[0, `dataMax+${data[active_index].skill_points}`]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

SkillGraphMobile.propTypes = {
  handleClick: PropTypes.func,
  activeIndex: PropTypes.number,
  average: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  ).isRequired,
};
