import React from 'react';
// import PropTypes from 'prop-types';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import Button from '../../../../designSystem/components/buttons/button';

function ReportCard(props) {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    answer_sheet: { skills },
    practice_controller: { current_skill },
    continuePracticing,
    finish_practice,
    history,
  } = props;

  // TODO: Used for testing only
  // const answer_sheet = JSON.parse(sessionStorage.getItem('answer_sheet'));
  // const { skills } = answer_sheet;


  const { points: skill_round_points } = skills[current_skill].validation_control;

  const handleKeepGoing = () => {
    if (finish_practice) {
      return history.push('/my-practice');
    }
    return continuePracticing();
  };

  const renderProgressIndicator = validation => {
    if (validation === true) {
      return (
        <img
          className="cursor_pointer icon_indicator"
          alt="progress icon"
          width="24px"
          height="24px"
          src="/assets/icons/question_check_icon.png"
        />
      );
    } else {
      return (
        <img
          className="cursor_pointer icon_indicator"
          alt="progress icon"
          width="24px"
          height="24px"
          src="/assets/icons/question_error_icon.png"
        />
      );
    }
  };

  const calculateAccuracy = questions => {
    let question_validations = 0;
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].validation_control.validation) {
        question_validations += 1;
      }
    }
    return Math.round((question_validations / questions.length) * 100);
  };

  return (
    <Grid container className="report_card">
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={12} sm className="card_title">
            {skills[current_skill] && skills[current_skill].skill_name}
            <div className="level_info_box">
              <div className="level_label">Fundamental</div>
            </div>
          </Grid>
          <Grid item xs={12} sm className="report_accuracy-desktop">
            <Grid container direction="row" justify="flex-end" alignItems="flex-end">
              <Grid item className="top_label">
                <img
                  src="/assets/icons/report_accuracy.svg"
                  alt="Accuracy icon"
                  aria-hidden={true}
                  className="accuracy_icon"
                />
                <div>Accuracy</div>
              </Grid>
              <Grid item className="top_label">
                <img
                  src="/assets/icons/report_points.svg"
                  alt="Accuracy icon"
                  aria-hidden={true}
                  className="points_icon"
                />
                <div>Points</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr className="report_card_hr" />
      </Grid>
      <Grid item xs={12}>
        {skills[current_skill].tasks.map(
          task =>
            task.questions && (
              <Grid container key={task.name}>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className="task_top_wrapper"
                  >
                    <Grid item className="card_task_name">
                      {task.name}
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item className="top_label task_accuracy">
                          {calculateAccuracy(task.questions)}%
                        </Grid>
                        <Grid item className="top_label task_points">
                          <img
                            src="/assets/icons/report_points.svg"
                            alt="Points icon"
                            aria-hidden={true}
                            width="20px"
                          />
                          <span>{task.validation_control && task.validation_control.points}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {task.questions.map((question, index) => (
                  <Grid item xs={12} key={index}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className="question_wrapper"
                    >
                      <Grid item className="card_question_name">
                        {task.name}
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" justify="center" alignItems="center">
                          <Grid item className="top_label">
                            {renderProgressIndicator(question.validation_control.validation)}
                          </Grid>
                          <Grid item className="top_label_"></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <hr className="report_card_hr" />
                </Grid>
              </Grid>
            ),
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className="question_wrapper"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className="bottom_summary"
            >
              <Grid item className="top_label total_point_label">
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className="question_wrapper"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className="bottom_summary"
            >
              <Grid item className="top_label total_point_label">
                Total Points Earned
              </Grid>
              <Grid item className="top_label task_points bottom_points">
                <img
                  src="/assets/icons/report_points.svg"
                  alt="Accuracy icon"
                  aria-hidden={true}
                  width="20px"
                />
                <span>{skill_round_points.toFixed(1)}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className="report_button">
        <img
          src="/assets/icons/question_result_icon.svg"
          alt="keep going"
          aria-hidden={true}
          className="keep_going_icon"
        />
        <Button
          label={finish_practice ? 'Finish practice' : 'Keep going!'}
          addId="check_button"
          onClick={handleKeepGoing}
          width={mdScreen ? 120 : 200}
          height={mdScreen ? 44 : 54}
          buttonStyle="primary"
          type="button"
        />
      </Grid>
    </Grid>
  );
}

ReportCard.propTypes = {};

export default ReportCard;
