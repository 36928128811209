export const alertMessage = (alertMessage) => {
  const alertWrapper = document.getElementById('alert_wrapper');
  var alertText = document.getElementById('text_alert');
  alertText.textContent = '';
  alertWrapper.style.clip = 'auto';
  alertText.setAttribute('status', 'alert');
  alertText.setAttribute('role', 'alert');
  alertText.style.display = 'none';
  alertText.style.display = 'inline';
  alertText.style.height = '0px';
  alertText.style.color = 'transparent';
  alertText.textContent = alertMessage;
};
