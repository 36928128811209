import React from 'react';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import './interactiveStyle.scss';

function AnswerHelper(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid item xs={12} className="answer_helper">
      <button type="button" className="answer_helper_label" tabIndex="0" onClick={handleClickOpen}>
        I can’t answer this question
      </button>
      <AlertDialog open={open} handleClose={handleClose} />
    </Grid>
  );
}

AnswerHelper.propTypes = {};

export default AnswerHelper;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialog(props) {
  const { open, handleClose } = props;
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="dialog_title"
        aria-describedby="dialog_body"
        className="answer_dialog_wrapper"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle id="dialog_title" className="dialog_title">
          Instructions
          <button className="close_dialog" onClick={handleClose} type="button">
            <span className="sr_only">Close Helper Dialog</span>
            <CloseIcon />
          </button>
        </DialogTitle>
        <DialogContent id="dialog_body" className="dialog_body">
          <DialogContentText>
            <div className="body_title">You can use symbols below to answer the questions.</div>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
              <Grid item className="body_text">
                Multiply
              </Grid>
              <Grid item className="symbol_box">
                <div className="symbol">x</div>
              </Grid>
              <Grid item className="body_text">
                e.g. 6 x 2 = 12
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
              className="content_division"
            >
              <Grid item className="body_text">
                Division
              </Grid>
              <Grid item className="symbol_box">
                <div className="symbol">/</div>
              </Grid>
              <Grid item className="body_text">
                e.g. 12/4 = 3
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
              className="content_division"
            >
              <Grid item className="body_text">
                Fraction
              </Grid>
              <Grid item className="symbol_box">
                <div className="symbol">/</div>
              </Grid>
              <Grid item className="body_text">
                e.g. 4/7
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
