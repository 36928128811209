import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import Moment from 'react-moment';
import { Grid } from '@material-ui/core';
import { topicsAPI } from '../../../../API/topics';
import TopicSelection from './components/topicSelection';
import PracticeSummary from './components/practiceSummary';
import PracticeActivity from './components/practiceActivity';
import Loading from '../../../../designSystem/components/interactiveElements/loading';
import NothingToShow from "../../../../designSystem/components/messages/notFound/nothingToShow";
import './parentSummary.scss';

function Summary(props) {
  const {
    subscription,
    selectedSubject,
    setMobileTopics,
    addTopicsToList,
    handleTopic,
    selectedTopicIndex,
    setSelectedTopicIndex,
    selectedTopic,
    setSelectedTopic,
    setTopics,
    topics,
    practiceActivities,
    getTopicPracticeActivities,
    practiceSummary,
    getTopicPracticeSummary,
    learner,
  } = props;
  const { getAccessTokenSilently } = useAuth0();

  // const [topics, setTopics] = React.useState(false);
  // const [selectedTopic, setSelectedTopic] = React.useState(null);

  // const [practiceSummary, setPracticeSummary] = React.useState(false);

  // const getTopicPracticeSummary = React.useCallback(
  //   async topic => {
  //     const token = await getAccessTokenSilently();
  //     await topicsAPI.GET.getTopicPracticeSummary(topic.id, learner.id, token)
  //       .then(response => {
  //         const { practice_summary } = response.data;
  //         setPracticeSummary(practice_summary);
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //   },
  //   [getAccessTokenSilently, learner.id],
  // );

  // const getTopicPracticeActivities = React.useCallback(
  //   async topic => {
  //     const token = await getAccessTokenSilently();
  //     await practiceAPI.GET.getPracticeActivities(topic.id, learner.id, token)
  //       .then(response => {
  //         // console.log(topic);
  //         // console.log(learner);
  //         // console.log(response.data);
  //         console.log('bou bou')
  //         const { practice_activities } = response.data;
  //         if (practice_activities.length > 0) {
  //           setPracticeActivities(practice_activities);
  //         } else {
  //           setPracticeActivities(false);
  //         }
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //   },
  //   [getAccessTokenSilently, learner.id],
  // );

  const handleSelectTopic = React.useCallback(
    topic => {
      console.log(topic)
      if (topic === 'all') {
        // getAllData();
      } else {
        setSelectedTopic(topic);
        getTopicPracticeSummary(topic);
        getTopicPracticeActivities(topic);
      }
    },
    [getTopicPracticeActivities, getTopicPracticeSummary, setSelectedTopic],
  );

  const getTopics = React.useCallback(
    async selectedSubject => {
      const token = await getAccessTokenSilently();
      topicsAPI.GET.getLearnerTopicsBySubjectID(token, selectedSubject.id, learner.id)
        .then(response => {
          setTopics(response.data);
          addTopicsToList(response.data);
          setMobileTopics(response.data);
          handleSelectTopic(response.data[0]);
          getTopicPracticeSummary(response.data[0]);
          getTopicPracticeActivities(response.data[0]);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [
      getAccessTokenSilently,
      getTopicPracticeSummary,
      getTopicPracticeActivities,
      handleSelectTopic,
      learner.id,
    ],
  );

  React.useEffect(() => {
    if (subscription && selectedSubject) {
      getTopics(selectedSubject);
    }
  }, [getTopics, subscription, selectedSubject]);

  if (!practiceSummary)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <>
      <Grid container className="summary_top_content">
        <Grid container className="topic_selection_wrapper">
          <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
            <Grid item>
              <TopicSelection
                topics={topics}
                selectedTopic={selectedTopic}
                handleSelectTopic={handleSelectTopic}
                selectedTopicIndex={selectedTopicIndex}
                setSelectedTopicIndex={setSelectedTopicIndex}
                handleTopic={handleTopic}
                type={'summary'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="summary_body_wrapper">
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <h1 className="topic_name">{selectedTopic.name}</h1>
            </Grid>
            {practiceSummary[0] && (
              <Grid item className="date_wrapper">
                <Moment date={new Date(practiceSummary[0].created_at)} format="MMM DD, YYYY" />
                {' To '}
                <Moment
                  date={new Date(practiceSummary[practiceSummary.length - 1].created_at)}
                  format="MMM DD, YYYY"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {practiceActivities ? (
          <>
            <PracticeSummary practiceSummary={practiceSummary} />
            <PracticeActivity practiceActivities={practiceActivities} />
          </>
        ) : (
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className="pages_wrapper nothing_to_show"
          >
            <NothingToShow title="No results" message="No activities for the selected topic yet. Keep practicing." />
          </Grid>
        )}
      </Grid>
    </>
  );
}

Summary.propTypes = {
  subscription: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.any)]).isRequired,
  selectedSubject: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.any)])
    .isRequired,
};

export default Summary;
