import axios from 'axios';
import url from './url';

export const contactUsAPI = {
  POST: {
    submitContactForm(data) {
      return axios.post(`${url}api/contact-us`, data);
    },
  },
};
