import React from 'react';
import ReactGoogleAnalytics from 'react-ga';
import SectionOne from './components/sectionOne';
import { Grid } from '@material-ui/core';
import CourseBox from './components/courseBox';
import DiagnosticDialog from './components/diagnosticDialog';
import { subjectsAPI } from '../../API/subjects';
import { topicsAPI } from '../../API/topics';
import Loading from '../../designSystem/components/interactiveElements/loading';
import OntarioCurriculum from './components/ontarioCurriculum';
import SectionThree from './components/sectionThree';
import SelectGrade from './components/selectGrade';
import './curriculum.scss';

export default function Curriculum({ history }) {
  const [subjects, setSubjects] = React.useState([]);
  const [selectedSubject, setSelectedSubject] = React.useState(false);

  const [topics, setTopics] = React.useState([]);
  const [selectedTopic, setSelectedTopic] = React.useState(false);

  const getTopics = React.useCallback(async subject => {
    topicsAPI.GET.getTopicBySubjectID(subject.id)
      .then(response => {
        setTopics(response.data.topics);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSelectSubject = React.useCallback(
    subject => {
      setSelectedSubject(subject);
      getTopics(subject);
    },
    [getTopics],
  );

  const getSubjects = React.useCallback(async () => {
    await subjectsAPI.GET.getSubjectsByCourseID(1)
      .then(response => {
        setSubjects(response.data.rows);
        handleSelectSubject(response.data.rows[0]);
        getTopics(response.data.rows[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }, [getTopics, handleSelectSubject]);

  const [openDiagnose, setOpenDiagnose] = React.useState(false);

  const handleDiagnoseDialog = topic => {
    setSelectedTopic(topic);
    if (openDiagnose) {
      setOpenDiagnose(false);
    } else {
      setOpenDiagnose(true);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await getSubjects();
    };
    fetchData();
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
    const goToTop = history.listen(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    return () => {
      goToTop();
    };
  }, [getSubjects]);

  if (!topics)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <div className="curriculum_wrapper">
      <SectionOne />
      <OntarioCurriculum />
      <SelectGrade subjects={subjects} handleSelectSubject={handleSelectSubject} />
      <CourseBox
        selectedSubject={selectedSubject}
        topics={topics}
        handleDiagnose={handleDiagnoseDialog}
      />
      <DiagnosticDialog
        isOpen={openDiagnose}
        handleClose={handleDiagnoseDialog}
        selectedTopic={selectedTopic}
      />
      <SectionThree />
    </div>
  );
}
