import React from 'react';
import ReactGoogleAnalytics from 'react-ga';
import { hotjar } from 'react-hotjar';
import { ThemeProvider } from '@material-ui/core/';
import { createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { subscriptionAPI } from './API/subscription';
import CookieConsentForm from './designSystem/components/cookieConsent/cookieConsentForm';

/* <----- Authorization routes ------> */
import PrivateRoute from './auth/privateRoute';
import AdminRoute from './auth/adminRoute';

/* <----- layout ------> */
import TopNav from './layout/topNav';
import Footer from './layout/footer';

import DesignSystem from './pages/designSystem/designSystem';
import ActionPage from './pages/actionPage/actionPage';

/* <----- landing pages ------> */
import LandingPage from './pages/landingPage/landingPage';
import Curriculum from './pages/curriculum/curriculum';
import Pricing from './pages/pricing/pricing';
import FAQ from './pages/FAQ/FAQ';
import ContactUs from './pages/contactUs/contactUs';
import DiagnosticDemo from './pages/diagnosticDemo/diagnosticDemo';
import Product from './pages/productPage/productPage';

// Dialog to instruct users to download PWA app
// import PwaInstallation from './designSystem/components/dialogs/pwaInstallation';

/* <----- Registration pages ------> */
import PreSignUp from './pages/preSignup/preSignup';
import EmailVerification from './pages/emailVerification/emailVerification';
import PlanSelection from './pages/planSelection/planSelection';
import SubscriptionPayment from './pages/subscriptionPayment/subscriptionPayment';

/* <----- Account pages ------> */
import AccountSettings from './pages/account/settings/accountSettings';
import MembershipAndBilling from './pages/account/membershipAndBilling/membershipAndBilling';
import BillingHistory from './pages/account/billingHistory/billingHistory';
import MyStudents from './pages/parent/myStudents/myStudents';
import AskParent from './pages/askParent/askParent';
import AddMembership from './pages/account/membershipAndBilling/components/addMembership/addmembership';

import MyPractice from './pages/myPractice/myPractice';

/* <----- Parent pages ------> */
import ParentSummary from './pages/parent/parentSummary/parentSummary';
import ParentProgress from './pages/parent/parentProgress/parentProgress';

import DataUpload from './pages/adminPortal/dataUpload/dataUpload';
import PracticeController from './pages/practice/practiceController';
import PracticePortal from './pages/practicePortal/practicePortal';
import PracticeReport from './pages/practiceReport/practiceReport';
import DiagnosticTool from './pages/diagnosticTool/diagnosticController';
import DiagnosticReport from './pages/diagnosticReport/diagnosticReport';
import MyProgress from './pages/myProgress/myProgress';
import QuestionsList from './pages/adminPortal/questionsList/questionsList';
import Question from './pages/adminPortal/question/question';
import AddQuestion from './pages/adminPortal/questionAdd/questionAdd';

ReactGoogleAnalytics.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID);

const theme = createMuiTheme({
  palette: {
    default: {
      main: '#FFFFFF',
      main_darker: '#505D62',
      focus: '#172E38',
      hover: '#172E38',
      border: '#19252A',
      disabled: '#ECECEC',
      disabledText: '#6A6969',
      disabledBorder: '#D6D6D6',
      text: '#19252A',
      buttonOutlined: '#172E38',
    },
    primary: {
      main: '#6FF2C2',
      text: '#172E38',
      textDark: '#19252A',
      focus: '#31D196',
      hover: '#31D196',
      disabled: '#ECECEC',
      disabledText: '#6A6969',
      textField: '#666666',
    },
    secondary: {
      main: '#172E38',
      text: '#FFFFFF',
      focus: '#3E4A4F',
      hover: '#3E4A4F',
      headerText: '#4A4A4A',
      disabled: '#ECECEC',
      disabledText: '#6A6969',
    },
    lightButton: {
      main: 'none',
      text: '#ffffff',
      focus: '#ffffff',
      hover: '#3E4A4F',
      focusText: '#19252A',
      headerText: '#4A4A4A',
      disabled: '#ECECEC',
      disabledText: '#6A6969',
    },
    error: {
      main: '#E51937',
    },
  },
  fontFamily: {
    default: 'Open Sans, sans-serif',
  },
});

function App() {
  const location = useLocation();
  const { user, getAccessTokenSilently } = useAuth0();
  const [subscription, setSubscription] = React.useState(false);
  const [hideLayout, setHideLayout] = React.useState(false);
  const [updateLayout, setUpdateLayout] = React.useState(false);
  const [change_layout, setChangeLayout] = React.useState({
    header: {
      background: '#19252A',
    },
    footer: {
      display: 'none',
      visibility: 'hidden',
    },
  });

  //local cookie
  const [localCookieShow, setLocalCookieShow] = React.useState(
    window.localStorage.getItem('localCookieShow'),
  );
  const [localCookieStatus, setLocalCookieStatus] = React.useState(
    window.localStorage.getItem('localCookieStatus'),
  );

  const [cookieAccept, setCookieAccept] = React.useState();
  const [cookieShow, setCookieShow] = React.useState(true);

  const getSubscription = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    const userType = user['https://www.geniusforest.ca/user_type'];
    await subscriptionAPI.GET.getOneSubscription(token, user.sub, userType)
      .then(success => {
        const paths = location.pathname;
        if (success.data.message) {
          if (paths.includes('action-page')) {
            setSubscription(null);
            return false;
          }
          if (
            !paths.includes('subscription-payment') &&
            !paths.includes('email-verification') &&
            !paths.includes('admin') &&
            !paths.includes('plan-selection')
          )
            return (window.location = '/plan-selection');
        } else {
          setSubscription(success.data);
        }
      })
      .catch(error => {
        console.log(error);
        setSubscription(null);
        // TODO: if title === Server Error redirect to an error page
      });
  }, [setSubscription, getAccessTokenSilently, location.pathname, user]);

  React.useEffect(() => {
    async function fetchData() {
      if (user) {
        await getSubscription();
      }
    }

    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
    hotjar.stateChange(window.location.pathname + window.location.search);

    fetchData();
  }, [user, getSubscription, cookieShow]);

  const handleLayoutUpdate = async () => {
    await getSubscription();
    setUpdateLayout(!updateLayout);
  };

  const handleChangeLayout = value => {
    if (value.toString() === 'reset') {
      return setChangeLayout({
        header: {
          background: '#19252A',
        },
        footer: {
          display: 'none',
          visibility: 'hidden',
        },
      });
    }
    return setChangeLayout(value);
  };

  const cookieAcceptHandler = () => {
    setCookieAccept(true);
    setCookieShow(false);
  };

  const cookieDenyHandler = () => {
    setCookieAccept(false);
    setCookieShow(false);
  };

  React.useEffect(() => {
    const show = window.localStorage.getItem('localCookieStatus');
    setCookieAccept(show);
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('localCookieShow', cookieShow);
    window.localStorage.setItem('localCookieStatus', cookieAccept);
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {!hideLayout && <TopNav subscription={subscription} change_layout={change_layout} />}
          <div id="app_wrapper">
            <Switch>
              <AdminRoute
                path="/admin-portal/question/:question_id"
                component={Question}
                handleHideLayout={value => setHideLayout(value)}
              />
              <AdminRoute
                path="/admin-portal/questions/:course_id?/:subject_id?/:topic_id?/:skill_id?"
                component={QuestionsList}
                handleHideLayout={value => setHideLayout(value)}
              />
              <AdminRoute
                path="/admin-portal/addQuestion"
                component={AddQuestion}
                handleHideLayout={value => setHideLayout(value)}
              />
              <PrivateRoute
                path="/subscription-payment/:subscription_plan/:number_of_children"
                component={SubscriptionPayment}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/plan-selection/:subscription_plan?/:number_of_children?"
                component={PlanSelection}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/account/add-membership/:subscription_plan?/:number_of_children?"
                component={AddMembership}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/ask-parent/:tab?"
                component={AskParent}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />

              <PrivateRoute
                path="/account/billing-history"
                component={BillingHistory}
                subscription={subscription}
              />

              <PrivateRoute
                path="/account/membership-and-billing"
                component={MembershipAndBilling}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/my-students/:tab?/:addStudent?"
                component={MyStudents}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/my-practice/:tab?"
                component={MyPractice}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/account/settings/:tab?"
                component={AccountSettings}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/my-progress/:tab?"
                component={MyProgress}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/practice/topic-report/:topic_id"
                component={PracticeReport}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
                handleChangeLayout={value => handleChangeLayout(value)}
              />
              <PrivateRoute
                path="/practice/:topic_id/:skill_id?"
                component={PracticeController}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/diagnostic-tool/:topic_id"
                component={DiagnosticTool}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/diagnostic-report/:topic_id"
                component={DiagnosticReport}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/practice-portal/:topic_id"
                component={PracticePortal}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
                handleChangeLayout={value => handleChangeLayout(value)}
              />
              <PrivateRoute
                path="/parent/summary/:learner_id"
                component={ParentSummary}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <PrivateRoute
                path="/parent/progress/:learner_id"
                component={ParentProgress}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <Route
                path="/email-verification"
                component={EmailVerification}
                subscription={subscription}
                updateLayout={() => handleLayoutUpdate()}
              />
              <Route
                path="/pre-signup/:auto_login?"
                render={props => (
                  <PreSignUp handleChangeLayout={value => handleChangeLayout(value)} {...props} />
                )}
              />
              <Route
                path="/action-page/:action?"
                render={props => <ActionPage subscription={subscription} {...props} />}
              />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/curriculum" component={Curriculum} />
              <Route path="/diagnostic-demo/:topic_id/:step" component={DiagnosticDemo} />
              <Route path="/design-system" component={DesignSystem} />
              <Route path="/product" component={Product} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/data-upload" component={DataUpload} />
              <Route path="/faq/:category?" component={FAQ} />
              <Route path="*" component={LandingPage} />
            </Switch>
          </div>
          {/* {cookieShow && (
            <div id="cookie_consent_form">
              {(!cookieAccept || !localCookieStatus || localCookieStatus === 'null') && (
                <CookieConsentForm
                  cookieAcceptHandler={cookieAcceptHandler}
                  setCookieShow={setCookieShow}
                  cookieDenyHandler={cookieDenyHandler}
                />
              )}
            </div>
          )} */}
          {!hideLayout && <Footer change_layout={change_layout} />}
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}
export default App;
