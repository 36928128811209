import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import './buttons.scss';

SecondaryToggleButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSelection: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      validation: PropTypes.bool,
    }),
  ),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SecondaryToggleButton.defaultProps = {
  fontSize: '16px',
  value: '',
};

export default function SecondaryToggleButton({ value, handleSelection, list, height, width, fontSize }) {
  const toggleStyles = makeStyles(theme => ({
    root: {
      marginRight: '10px',
      color: '#161616',
      height: height,
      fontSize: fontSize,
      fontWeight: 600,
      lineHeight: '16px',
      minWidth: width,
      padding: '5px 10px !important',
      borderWidth: 1,
      borderRadius: '25px !important',
      border: `1px solid ${theme.palette.default.border} !important`,
      fontFamily: theme.fontFamily.default,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.secondary.hover,
        border: `1px solid ${theme.palette.default.hover}`,
        color: 'white'
      },
      '&:focus': {
        color: theme.palette.default.main,
        boxShadow: `0 0 0 4px white,0 0 0 5px ${theme.palette.default.focus}`,
        backgroundColor: theme.palette.secondary.focus,
        border: `1px solid ${theme.palette.default.focus}`,
      },
      '&.MuiButton-outlined.Mui-disabled': {
        background: theme.palette.default.disabled,
        color: theme.palette.default.disabledText,
        border: `1px solid ${theme.palette.default.disabledBorder}`,
      },
      '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.default.hover}`,
        color: '#fff',
      },
    },
    '@media screen and (max-width: 600px)': {
      root: {
        marginRight: '5px',
      },
    },
  }));

  const classes = toggleStyles();

  const capitalizeLabel = label => {
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  return (
    <ToggleButtonGroup
      value={value}
      onChange={handleSelection}
      aria-label="text alignment"
      exclusive
    >
      {list.map((item, index) => (
        <ToggleButton
          key={index}
          className={classes.root}
          value={item.value}
        >
          {capitalizeLabel(item.label)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
