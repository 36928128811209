import React from 'react';
import ReactGoogleAnalytics from 'react-ga';
import { Grid } from '@material-ui/core';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import Practice from './components/diagnosticTool/diagnosticTool';
import { useAuth0 } from '@auth0/auth0-react';
import { diagnosticToolAPI } from '../../API/diagnosticTool';
import Loading from '../../designSystem/components/interactiveElements/loading';
import LockedSubscription from '../../designSystem/components/dialogs/lockedSubscription';

// import Button from '../../designSystem/components/buttons/button';
import './diagnosticTool.scss';

function DiagnosticController(props) {
  const { match, subscription, history } = props;
  const { getAccessTokenSilently, user, logout } = useAuth0();

  const getLearner = () => {
    if (!subscription) return false;
    let result;
    subscription.learners.map(learner => {
      if (learner.user_id === user.sub) return (result = learner);
    });
    return result;
  };

  const learner = getLearner();

  const [openLock, setOpenLock] = React.useState(subscription.status === 'paused' ? true : false);

  const [allow_page_leave, setAllowPageLeave] = React.useState(true);
  const [test_results, setTestResults] = React.useState([]);
  const [finish, setFinish] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [answer_sheet, setAnswerSheet] = React.useState({
    skills: [],
    tasks: [],
    questions: [],
    practice_results: {
      total_time: 0,
      attempts_counter: 0,
      wrong_attempts: 0,
      points: 0,
      total_questions: 10,
    },
  });

  const [practice_controller, setPracticeController] = React.useState({
    current_skill: 0,
    current_task: 0,
    current_question: {},
    current_question_number: 0,
    top_progress_bar: [null, null, null, null, null, null, null, null, null, null],
    total_time: 0,
    score: 100,
    submitted: false,
  });

  const updatePracticeController = (name, value) => {
    setPracticeController(oldObject => ({
      ...oldObject,
      [name]: value,
    }));
  };

  const saveAnswerSheet = (name, value) => {
    if (name === 'practice_results') {
      console.log(value);
    }
    setAnswerSheet(oldObject => ({
      ...oldObject,
      [name]: value,
    }));
  };

  const updateSkill = current_skill => {
    const { skills, practice_results } = answer_sheet;
    let updated_skills = skills;
    updated_skills[current_skill].validation_control.total_questions = 10;
    updated_skills[current_skill].skill_learner_points += practice_results.points;
    saveAnswerSheet('skills', updated_skills);
  };

  const updatePerformancePoints = (validation_control, max_points) => {
    let result = max_points;
    if (validation_control.wrong_attempts !== 0) {
      const performance = 10 - validation_control.wrong_attempts;
      result = max_points * (performance / 10);
    }
    return result;
  };

  const saveSkillRound = async answer_sheet_to_save => {
    const token = await getAccessTokenSilently();
    const { topic_id } = match.params;

    // TODO: needs to better explain the calculation below, this calculation is only valid for the diagnostic tool to set a starting point for the users
    answer_sheet_to_save.practice_results.points = updatePerformancePoints(
      answer_sheet_to_save.practice_results,
      answer_sheet_to_save.skills[0].skill_points,
    );

    const current_skill_index = answer_sheet_to_save.current_skill;
    const skill = answer_sheet_to_save.skills[current_skill_index];

    answer_sheet_to_save.skills[
      current_skill_index
    ].validation_control.points = updatePerformancePoints(
      skill.validation_control,
      skill.skill_points,
    );

    await diagnosticToolAPI.POST.saveDiagnosticTest(
      topic_id,
      learner.id,
      answer_sheet_to_save,
      token,
    )
      .then(response => {
        console.log(response);
        //TODO: Probably we could send the points for course, subject, topic, skill, and task in the response, and add an opo up to tell you have upgraded a level and so on
        // So, it will not need to update the skill point now. We can compare it first and update it later.
      })
      .catch(error => {
        console.log(error);
      });
  };

  const resetAnswerSheet = () => {
    saveAnswerSheet('tasks', []);
    saveAnswerSheet('questions', []);
    saveAnswerSheet('practice_results', {
      total_time: 0,
      attempts_counter: 0,
      wrong_attempts: 0,
      points: 0,
      total_questions: 10,
    });
  };

  const resetPracticeController = () => {
    updatePracticeController('current_task', 0);
    updatePracticeController('current_question_number', 0);
    updatePracticeController('top_progress_bar', [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    updatePracticeController('score', 100);
    updatePracticeController('total_time', 0);
  };

  const goToNextSkill = () => {
    const { current_skill } = practice_controller;
    console.log('** next skill **');
    updatePracticeController('current_skill', current_skill + 1);
    resetPracticeController();
    resetAnswerSheet();
  };

  const handleSaveRound = async current_skill => {
    updateSkill(current_skill);
    answer_sheet.current_skill = current_skill;
    test_results.push(answer_sheet);
    goToNextSkill();
  };

  const handleFinishTest = async () => {
    setAllowPageLeave(false);
    setFinish(1);
    sessionStorage.setItem('test_results', JSON.stringify(test_results));
    setLoading(true);
    for (let i = 0; i < test_results.length; i++) {
      await saveSkillRound(test_results[i]);
    }
    setLoading(false);
  };

  const addQuestionToAnswerSheet = question => {
    const { questions } = answer_sheet;
    questions.push(question);
    saveAnswerSheet('questions', questions);
  };

  const addTaskToAnswerSheet = task => {
    const { tasks } = answer_sheet;
    tasks.push(task);
    saveAnswerSheet('tasks', tasks);
  };

  const addQuestionToTask = (question, current_task, skills, current_skill) => {
    const updated_skills = skills;
    const TASK_QUESTIONS = updated_skills[current_skill].tasks[current_task].questions;
    if (!TASK_QUESTIONS) {
      updated_skills[current_skill].tasks[current_task].questions = [];
    }
    updated_skills[current_skill].tasks[current_task].questions.push(question);
    saveAnswerSheet('skills', updated_skills);
  };

  const getQuestion = React.useCallback(
    async (current_task, skill, difficult_level, skills, current_skill) => {
      const token = await getAccessTokenSilently();

      await diagnosticToolAPI.GET.getDiagnosticQuestion(skill.skill_id, difficult_level, token)
        .then(response => {
          const { current_question } = response.data;
          console.log(current_question);
          updatePracticeController('current_question', current_question);
          addQuestionToAnswerSheet(current_question);
          addQuestionToTask(current_question, current_task, skills, current_skill);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [
      practice_controller.current_task,
      practice_controller.current_question_number,
      addQuestionToAnswerSheet,
      getAccessTokenSilently,
    ],
  );

  const updatePracticeResults = () => {
    const {
      current_question: { validation_control },
    } = practice_controller;

    let updated_results = answer_sheet.practice_results;

    updated_results.points += validation_control.points;
    updated_results.attempts_counter += validation_control.attempts_counter;
    updated_results.wrong_attempts += validation_control.wrong_attempts;
    updated_results.total_time += validation_control.total_time;
    saveAnswerSheet('practice_results', updated_results);
  };

  const updateSkillResults = () => {
    const {
      current_question: { validation_control },
      current_skill,
    } = practice_controller;
    const { skills } = answer_sheet;
    let updated_skills = skills;
    const skill_to_update = skills[current_skill];

    if (skill_to_update.validation_control) {
      skill_to_update.validation_control.points += validation_control.points;
      skill_to_update.validation_control.attempts_counter += validation_control.attempts_counter;
      skill_to_update.validation_control.wrong_attempts += validation_control.wrong_attempts;
      skill_to_update.validation_control.total_time += validation_control.total_time;
    } else {
      const new_validation_control = {
        points: validation_control.points,
        attempts_counter: validation_control.attempts_counter,
        wrong_attempts: validation_control.wrong_attempts,
        total_time: validation_control.total_time,
      };
      skill_to_update.validation_control = new_validation_control;
    }

    updated_skills[current_skill] = skill_to_update;

    saveAnswerSheet('skills', updated_skills);
  };

  const updateTaskResult = () => {
    const {
      current_question: { validation_control },
      current_skill,
      current_task,
    } = practice_controller;
    const { skills } = answer_sheet;
    let updated_skills = skills;
    const task_to_update = skills[current_skill].tasks[current_task];

    if (task_to_update.validation_control) {
      task_to_update.validation_control.points += validation_control.points;
      task_to_update.validation_control.attempts_counter += validation_control.attempts_counter;
      task_to_update.validation_control.wrong_attempts += validation_control.wrong_attempts;
      task_to_update.validation_control.total_time += validation_control.total_time;
      task_to_update.validation_control.total_questions += 1;
    } else {
      const new_validation_control = {
        points: validation_control.points,
        attempts_counter: validation_control.attempts_counter,
        wrong_attempts: validation_control.wrong_attempts,
        total_time: validation_control.total_time,
        total_questions: 1,
      };
      task_to_update.validation_control = new_validation_control;
      addTaskToAnswerSheet(task_to_update);
    }

    updated_skills[current_skill].tasks[current_task] = task_to_update;

    saveAnswerSheet('skills', updated_skills);
  };

  const handleGoToNextQuestion = (next_task, skill, difficult_level, next_skill) => {
    const { skills } = answer_sheet;
    const { current_skill } = practice_controller;
    if (current_skill === next_skill) {
      updatePracticeResults();
    }
    updateSkillResults();
    updateTaskResult();
    getQuestion(next_task, skill, difficult_level, skills, next_skill);
  };

  const calculateDifficultLevel = skill => {
    const learner_points = skill.skill_learner_points ? skill.skill_learner_points : 0;
    const difficult_level = (learner_points / skill.skill_points) * 100;
    if (difficult_level <= 20) return 1;
    if (difficult_level > 20 && difficult_level <= 40) return 2;
    if (difficult_level > 40 && difficult_level <= 60) return 3;
    if (difficult_level > 60 && difficult_level <= 80) return 4;
    if (difficult_level > 80) return 5;
  };

  const getDiagnosticSkills = React.useCallback(async () => {
    const { topic_id } = match.params;
    const token = await getAccessTokenSilently();

    await diagnosticToolAPI.GET.getDiagnosticSkills(topic_id, learner.id, token)
      .then(response => {
        const { skills } = response.data;
        const { current_skill, current_task } = practice_controller;
        setAnswerSheet({ ...answer_sheet, skills: skills });
        getQuestion(
          current_task,
          skills[current_skill],
          calculateDifficultLevel(skills[current_skill]),
          skills,
          current_skill,
        );
      })
      .catch(error => {
        console.log(error);
      });
  }, [getAccessTokenSilently, learner]);

  const handleConfirm = () => {
    logout({ returnTo: `${window.location.origin}/action-page/login` });
  }

  React.useEffect(() => {
    async function fetchData() {
      await getDiagnosticSkills();
    }
    fetchData();
    window.scroll({ top: 0, behavior: 'smooth' });
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
    ReactGoogleAnalytics.event({
      category: 'Diagnostic tool',
      action: 'Start diagnostic tool',
      label: 'Diagnostic tool',
    });
    if (subscription.status === 'paused') return setOpenLock(true);
  }, [getDiagnosticSkills, subscription]);

  const handleSave = async () => {
    const result = JSON.parse(sessionStorage.getItem('test_results'));
    for (let i = 0; i < result.length; i++) {
      await saveSkillRound(result[i]);
    }
  };

  if (answer_sheet.skill || !learner.id)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <>
      <Practice
        answer_sheet={answer_sheet}
        practice_controller={practice_controller}
        updatePracticeController={updatePracticeController}
        getNextQuestion={handleGoToNextQuestion}
        saveRound={handleSaveRound}
        finishPractice={handleFinishTest}
        loading={loading}
        {...props}
      />
      <LockedSubscription
        open={openLock}
        handleClose={() => history.push('/settings')}
        handleConfirm={handleConfirm}
        hideClose={true}
      />

      <div className="sr_only">{finish}</div>
      {/* <Button
        label="Next"
        onClick={handleSave}
        width={150}
        height={42}
        buttonStyle="primary"
        type="submit"
      /> */}
      {!openLock && (
        <Prompt
          when={allow_page_leave}
          message="You progress will be lost, are you sure you want to leave?"
        />
      )}
    </>
  );
}

DiagnosticController.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      topic_id: PropTypes.string.isRequired,
    }),
  }),
};

export default DiagnosticController;
