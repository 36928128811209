import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, makeStyles } from '@material-ui/core';

DefaultTab.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSelectedTab: PropTypes.func.isRequired,
  selected_tab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default function DefaultTab({ list, handleSelectedTab, selected_tab, width }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiButtonBase-root': {
        borderBottom: '3px solid #ECECEC',
        color: '#19252A',
        fontFamily: theme.fontFamily.default,
        fontSize: '18px',
        lineHeight: '27px',
        textTransform: 'capitalize',
        width: width,
      },

      '& .Mui-selected': {
        color: '#19252A',
        fontWeight: 'bold',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: '#19252A',
      },
      '@media only screen and (max-width: 6000px)': {
        '& .MuiButtonBase-root': {
          width: 'auto',
          fontSize: '14px',
        },
      },
    },
  }));
  const classes = useStyles();

  return (
    <Tabs className={classes.root} value={selected_tab} indicatorColor="primary" textColor="primary" onChange={handleSelectedTab} aria-label="tab options">
      {list.map((item, index) => (
        <Tab label={item.label} key={index} />
      ))}
    </Tabs>
  );
}
