import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Paper, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import '../FAQ.scss';
import Button from '../../../designSystem/components/buttons/button';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  boxShadow: 'none',
}));

export default function SectionTwo(props) {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let { push } = useHistory();
  const { data, search, handleSelectQuestion } = props;

  const renderCategories = category => {
    let result = false;
    category.questions.forEach(question => {
      if (
        category.label.toLowerCase().includes(search.toLowerCase()) ||
        question.label.toLowerCase().includes(search.toLowerCase())
      ) {
        result = true;
      }
    });
    return result;
  };

  return (
    <Box className="section_two_wrapper">
      <h1 className="page_title">Frequently Asked Questions</h1>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={5}
          style={{
            margin: 0,
            width: '100%',
          }}
        >
          {data &&
            data.map((item, index) => (
              <>
                {renderCategories(item) && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Link to={`/faq/${item.label}`} className="top_link">
                      {item.label == 'PlansAndPricing' ? (
                        <Item>Plans &amp; Pricing</Item>
                      ) : (
                        <Item className="interactive_element">{item.label}</Item>
                      )}
                    </Link>
                    {item.questions.map(question => (
                      <Link
                        key={item.label}
                        to={`/faq/${item.label}`}
                        className="regular_link"
                        onClick={() => handleSelectQuestion(question.label)}
                      >
                        <Item className="interactive_element">{question.label}</Item>
                      </Link>
                    ))}
                    {/* <Link
                      to={`/faq/${item.label}`}
                      className="bottom_link"
                      onClick={() => handleSelectQuestion('all')}
                    >
                      <Item className="interactive_element">View all questions</Item>
                    </Link> */}
                  </Grid>
                )}
                {!smScreen && index === 2 && (
                  <Grid item xs={12}>
                    <Divider sx={{ bgcolor: '#ECE8E8' }} />
                  </Grid>
                )}
              </>
            ))}
        </Grid>
      </Box>
      <div className="bottomDiv-text">Still can't find what you're looking for?</div>
      <div className="bottomDiv">
        <Button
          label="Contact Us"
          addId="default"
          onClick={() => push('contact-us')}
          width={144}
          height={56}
          buttonStyle="default"
          type="submit"
          fontSize={16}
        />
      </div>
    </Box>
  );
}
