import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../designSystem/components/interactiveElements/loading';
import AdminSideBar from '../layout/adminSideBar';
import NothingToShow from "../designSystem/components/messages/notFound/nothingToShow";

const AdminRoute = ({ component: Component, path, ...rest }) => {
  const { isLoading, user } = useAuth0();

  if (isLoading) return <LoadingPage />;
  if (!user) return <NotRegistered />;

  if (!user['https://www.geniusforest.ca/roles'].includes('teacher')) {
    return <NotRegistered />;
  }

  return (
    <div className="page_wrapper admin_wrapper">
      <Grid container direction="row" justifyContent="center" alignItems="stretch">
        <AdminSideBar />
        <Grid item xs={10} className="admin_content_wrapper">
          <Route path={path} {...rest} render={props => <Component {...props} />} />
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(AdminRoute);

const NotRegistered = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Box>
        <NothingToShow title="Restricted access" message="Please login, to enjoy all the benefits Genius Forest has to offer." />
      </Box>
    </Grid>
  );
};

const LoadingPage = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Loading />
    </Grid>
  );
};
