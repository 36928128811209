import React from 'react';
import { Grid, Box } from '@material-ui/core';
import TextField from '../../../designSystem/components/textFields/textField';

export default function TextFieldsSection(props) {
  return (
    <Grid container className="designSystem_section-wrapper">
      <Grid item xs={12} className="designSystem-sections">
        <h2 className="title">Text Fields</h2>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Primary Text Field</h2>
          </Grid>
          <Box className="designSystem-elements">
            <TextField id="primary" label="Primary" type="text" value={''} helperText={'This is the helper text'} error={false} handleChanges={() => {}} borderColor="#ECECEC" />
          </Box>
          <Box className="designSystem-elements">
            <TextField id="primary_error" label="Primary" type="text" value={''} helperText="Error message" error={true} handleChanges={() => {}} borderColor="#ECECEC" />
          </Box>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center" className="designSystem-elements-wrapper">
          <Grid item xs={12}>
            <h2 className="subTitle">Search Text Field</h2>
          </Grid>
          <Box className="designSystem-elements">
            <TextField id="search" label="Search" type="search" value={''} helperText={'Search user by name or phone number'} error={false} handleChanges={() => {}} borderColor="#ECECEC" icon />
          </Box>
          <Box className="designSystem-elements">
            <TextField id="search_error" label="Search" type="search" value={''} helperText={'Error message'} error={true} handleChanges={() => {}} borderColor="#ECECEC" icon />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
