import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';

Customers.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  learn_more: PropTypes.string.isRequired,
};

function Customers({ imageUrl, title, body, learn_more }) {
  return (
    <Grid container>
      <Grid item xs={12} className="carousel_box">
        {/* Removed by client request
         <Box className="new_button" tabIndex="-1">
          New
        </Box> */}
        <Box className="carousel_title" tabIndex="-1">
          {title}
        </Box>
        <Box className="carousel_body" tabIndex="-1">
          {body}
        </Box>
        {/* Removed by client request
        <Box className="learn_more-wrapper">
          <Link to={learn_more}  className="learn_more" >
            Learn more
          </Link>
        </Box> */}
        <img className={(title === 'Math Jam' || title === 'Daily Challenge' ? 'imageUrl bigger_image' : 'imageUrl')} alt="slider" src={imageUrl} />
      </Grid>
    </Grid>
  );
}

export default Customers;
