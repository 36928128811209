import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './buttons.scss';

RoundIconButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  arrow_direction: PropTypes.string.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

RoundIconButton.defaultProps = {
  size: 'md',
  disabled: false,
};

function RoundIconButton({ handleClick, arrow_direction, size, disabled }) {
  return (
    <button
      className={
        size === 'md'
          ? `round_icon_button ${disabled && 'round_icon_button-disabled'}`
          : `round_icon_button  round_icon_button-sm ${disabled && 'round_icon_button-disabled'}`
      }
      onClick={handleClick}
      type="button"
      tabIndex={disabled ? -1 : 0}
    >
      {arrow_direction === 'left' && <ChevronLeftIcon color="black" className="icon" />}
      {arrow_direction === 'right' && <ChevronRightIcon color="black" className="icon" />}
      {arrow_direction === 'up' && <ExpandMoreIcon color="black" className="icon" />}
      {arrow_direction === 'down' && <ExpandLessIcon color="black" className="icon" />}
    </button>
  );
}

export default RoundIconButton;

/* 
  <ButtonIconRound 
    arrow_direction="down" 
    handleClick={handleExpand} 
    size="md"
    disabled
  />  
*/
