import axios from 'axios';
import url from './url';

export const subscriptionAPI = {
  POST: {
    createNewSubscription(token, data) {
      return axios.post(`${url}api/createNewSubscription`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    validatePromoCode(data) {
      return axios.post(`${url}api/validatePromoCode`, data);
    },
  },

  PUT: {
    updateSubscription(token, user_id, payment_id, data) {
      return axios.put(`${url}api/updateSubscription/${user_id}/${payment_id}`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    cancelSubscription(token, subscription, learner_id) {
      return axios.put(
        `${url}api/subscription/cancel/${learner_id}`,
        {
          subscription,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );
    },
    resumeSubscription(token, subscription, learner_id) {
      return axios.put(
        `${url}api/subscription/resume/${learner_id}`,
        {
          subscription,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );
    },
  },
  GET: {
    getOneSubscription(token, user_id, user_type) {
      return axios.get(`${url}api/getOneSubscription/${user_id}/${user_type}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
};
