import axios from 'axios';
import url from './url';

export const imageUploadAPI = {
  POST: {
    uploadImage(files, token) {
      console.log(files);
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append('filesToSave', files[i]);
      }
      return axios.post(`${url}api/admin/uploadImage`, data, {
        headers: { authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      });
    },
    uploadProfileImage(file, token, user_id, old_pic) {
      console.log(file);
      const data = new FormData();
      data.append('profilePicFile', file);
      data.append('user_id', user_id);
      data.append('old_pic', old_pic);
      return axios.post(`${url}api/admin/uploadProfileImage`, data, {
        headers: { authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      });
    },
  },
};
