import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';

function HeaderBar(props) {
  const {
    practice_controller: { current_skill, top_progress_bar },
    answer_sheet,
  } = props;
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderProgressIndicator = (validation, question_number) => {
    if (validation === null) {
      return <div className="number_indicator">{question_number + 1}</div>;
    } else if (validation === true) {
      return (
        <img
          className="icon_indicator"
          alt="progress icon"
          width="24px"
          height="24px"
          src="/assets/icons/question_check_icon.png"
        />
      );
    } else {
      return (
        <img
          className="icon_indicator"
          alt="progress icon"
          width="24px"
          height="24px"
          src="/assets/icons/question_error_icon.png"
        />
      );
    }
  };

  const skill_name =
    answer_sheet.skills[current_skill] && answer_sheet.skills[current_skill].skill_name;

  return (
    <div>
      <Grid container className="page_wrapper practice_header_bar">
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Box className="title_wrapper">
              <h1 className="header_title">Step 2: Answer Questions</h1>
            </Box>
            <Box className="practice_tracker">
              <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <h2 className="header_text">
                    Current Playing: {'  '}
                    <h2 className="header_skill_name-mobile">{skill_name}</h2>
                  </h2>
                  <h2 className="header_text">Questions:</h2>
                </Grid>
                <Grid item>
                  <h2 className="header_skill_name">{skill_name}</h2>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={smScreen ? 1 : 2}
                  >
                    {top_progress_bar.map((item, index) => (
                      <Grid item key={index}>
                        {renderProgressIndicator(item, index)}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

HeaderBar.propTypes = {
  answer_sheet: PropTypes.shape({
    current_skill: PropTypes.number,
  }).isRequired,
  practice_controller: PropTypes.shape({
    current_skill: PropTypes.number,
  }).isRequired,
  handleHederButton: PropTypes.func.isRequired,
};

export default HeaderBar;
