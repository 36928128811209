import React from 'react';
import { makeStyles, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/InfoOutlined';

PrimaryTextField.defaultProps = {
  id: '',
  readOnly: false,
  type: 'text',
  icon: false,
  width: 300,
  height: 48,
  inputTextColor: '#19252A',
  paymentHeight: '8px',
  autocomplete: false
};

function PrimaryTextField({
  id,
  name,
  label,
  value,
  helperText,
  error,
  type,
  readOnly,
  handleChanges,
  icon,
  width,
  height,
  register,
  disabled,
  inputTextColor,
  paymentHeight,
  defaultValue,
  backgroundColor,
  placeholder,
  marginBottom,
  borderColor,
  requiredField,
  autocomplete
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      width: width,
      fontSize: '15px',
      marginBottom: marginBottom || '8px',
      fontFamily: theme.fontFamily.default,
      '& label': {
        fontSize: '16px',
        top: '-4px',
        color: theme.palette.primary.textField,
      },
      '& .MuiFormLabel-asterisk': {
        color: 'red',
        fontSize: '25px',
        position: 'absolute',
        top: '-27px',
        marginLeft: '2px',
      },
      '& .MuiFormHelperText-root': {
        fontSize: '12px',
        display: 'block',
      },
      '& .MuiSvgIcon-root': {
        color: '#989898',
        marginTop: '2px',
        fontSize: '28px',
      },
      '& label.Mui-focused': {
        color: !error ? theme.palette.primary.focus : theme.palette.error.main,
      },
      '& .MuiOutlinedInput-root': {
        height: height,
        marginBottom: helperText.length || icon > 0 ? '0px' : '22px',
        fontSize: '16px',
        paddingLeft: icon ? '5px' : '0px',
        borderRadius: '8px',
        backgroundColor: backgroundColor,
        '& .MuiInputBase-input': {
          height: paymentHeight,
          color: inputTextColor,
        },
        '& fieldset': {
          fontSize: '15px',
          border: `solid ${borderColor || theme.palette.primary.textField}`,
          borderWidth: '1px',
        },
        '&:hover fieldset': {
          borderColor: !error ? theme.palette.primary.hover : theme.palette.error.main,
          borderWidth: '2px',
        },
        '&.Mui-focused fieldset': {
          borderColor: !error ? theme.palette.primary.focus : theme.palette.error.main,
          borderWidth: '2px',
          '& .MuiSvgIcon-root': {
            color: 'red',
          },
        },
      },
    },
    errorColor: {
      color: theme.palette.error.main + '!important',
    },
    requiredIcon: {
      color: 'red',
      marginLeft: '2px',
      fontSize: '18px',
    },
  }));

  const classes = useStyles();

  if (error === '') {
    error = false;
  }

  const displayLabel = () => {
    if (label && label !== '') return true;
    return false;
  };

  return (
    <TextField
      id={id ? id : name}
      name={name}
      title={label}
      label={
        displayLabel() ? (
          <div>
            {label}
            {requiredField && <span className={classes.requiredIcon}>&#42;</span>}
          </div>
        ) : (
          ''
        )
      }
      value={value === null ? '' : value}
      onChange={handleChanges}
      helperText={helperText}
      error={error}
      type={type}
      className={classes.root}
      disabled={disabled}
      variant="outlined"
      inputRef={register}
      defaultValue={defaultValue}
      placeholder={placeholder}
      autocomplete={autocomplete}
      InputProps={
        icon
          ? {
            readOnly,
            endAdornment: !error ? (
              <InputAdornment position="end">{<SearchIcon />}</InputAdornment>
            ) : (
              <InputAdornment position="end">
                {<ErrorIcon className={classes.errorColor} />}
              </InputAdornment>
            ),
          }
          : {
            readOnly,
            endAdornment: error && (
              <InputAdornment position="end">
                {<ErrorIcon className={classes.errorColor} />}
              </InputAdornment>
            ),
          }
      }
    />
  );
}
export default PrimaryTextField;

// Example of use:
/*
<TextField
  id="phoneNumber"
  label="Phone Number"
  type="tel"
  value={values.phoneNumber}
  helperText={messages.phoneNumber}
  error={errors.phoneNumber}
  handleChanges={yourFunction()}
  inputTextColor="blue"
/>
*/
// Example of use with search icon:
/*<TextField
  id="phoneNumber"
  label="Phone Number"
  type="tel"
  value={values.phoneNumber}
  helperText={messages.phoneNumber}
  error={errors.phoneNumber}
  handleChanges={yourFunction()}
  icon
  inputTextColor="blue"
/>*/
