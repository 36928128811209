import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import Button from './../../../designSystem/components/buttons/button';

const Section_1 = ({ size }) => {
  let history = useHistory();

  const [imageShowNumber, setImageShowNumber] = useState(1);
  const [slideCount, setSlideCount] = useState(1);

  const [firstImageZIndex, setFirstImageZIndex] = useState(1);
  const [secondImageZIndex, setSecondImageZIndex] = useState(2);
  const [thirdImageZIndex, setThirdImageZIndex] = useState(3);

  const [demoButtonSize, setDemoButtonSize] = useState(149);

  const imageNumberHandler = () => {
    if (imageShowNumber === 3) {
      document.getElementById('screen_two').classList.remove('active');
      document.getElementById('screen_one').classList.add('active');
      setImageShowNumber(1);
    } else {
      setImageShowNumber(imageShowNumber + 1);
    }
    zIndexHandler();
    imageSliderHandler();
    if (slideCount <= 3) {
      setSlideCount(slideCount + 1);
    }
  };

  const imageSliderHandler = () => {
    let element;
    if (imageShowNumber === 1) {
      element = document.getElementById('screen_two');
      element.classList.add('active');
      document.getElementById('screen_three').classList.remove('active');
    } else if (imageShowNumber === 2) {
      element = document.getElementById('screen_three');
      element.classList.add('active');
      document.getElementById('screen_one').classList.remove('active');
    } else {
      element = document.getElementById('screen_one');
      element.classList.add('active');
    }
  };

  const zIndexHandler = () => {
    if (slideCount > 3) {
      setFirstImageZIndex(thirdImageZIndex + 1);
      setSecondImageZIndex(firstImageZIndex + 1);
      setThirdImageZIndex(secondImageZIndex + 1);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => imageNumberHandler(), 4e3);
    return () => clearTimeout(timer);
  });

  const mouseEnterHandler = () => {
    if (size.width > 1400) {
      document.getElementById('bg_image_one').classList.add('active');
      document.getElementById('bg_image_two').classList.add('active');
      document.getElementById('bg_image_three').classList.add('active');
      document.getElementById('bg_image_five').classList.add('active');
      document.getElementById('bg_image_six').classList.add('active');
      document.getElementById('bg_image_seven').classList.add('active');
    }
  };
  const mouseLeaveHandler = () => {
    if (size.width > 1400) {
      document.getElementById('bg_image_one').classList.remove('active');
      document.getElementById('bg_image_two').classList.remove('active');
      document.getElementById('bg_image_three').classList.remove('active');
      document.getElementById('bg_image_five').classList.remove('active');
      document.getElementById('bg_image_six').classList.remove('active');
      document.getElementById('bg_image_seven').classList.remove('active');
    }
  };

  const buttonHandler = action => {
    if (action === 'enter') {
      document.getElementById('button_cover').classList.add('active');
      document.getElementById('button').classList.add('active');
    } else {
      document.getElementById('button_cover').classList.remove('active');
      document.getElementById('button').classList.remove('active');
    }
  };

  useEffect(() => {
    if (size.width > 400) {
      setDemoButtonSize(149);
    } else {
      setDemoButtonSize(139);
    }
  }, [size]);

  return (
    <div className="section_1_wrapper landing_page_wrapper">
      {/* need to manually set background image for each shape */}
      <img
        src="/assets/images/section_one_bg_image_one.png"
        alt="section_one_bg_image_one"
        className="section_one_bg_image_one"
        id="bg_image_one"
      />
      <img
        src="/assets/images/section_one_bg_image_two.png"
        alt="section_one_bg_image_two"
        className="section_one_bg_image_two"
        id="bg_image_two"
      />
      <img
        src="/assets/images/section_one_bg_image_three.png"
        alt="section_one_bg_image_three"
        className="section_one_bg_image_three"
        id="bg_image_three"
      />
      <img
        src="/assets/images/section_one_bg_image_four.png"
        alt="section_one_bg_image_four"
        className="section_one_bg_image_four"
        id="bg_image_four"
      />
      <img
        src="/assets/images/section_one_bg_image_five.png"
        alt="section_one_bg_image_five"
        className="section_one_bg_image_five"
        id="bg_image_five"
      />
      <img
        src="/assets/images/section_one_bg_image_six.png"
        alt="section_one_bg_image_six"
        className="section_one_bg_image_six"
        id="bg_image_six"
      />
      <img
        src="/assets/images/section_one_bg_image_seven.png"
        alt="section_one_bg_image_seven"
        className="section_one_bg_image_seven"
        id="bg_image_seven"
      />
      {/*  */}
      <div
        className="section_one_card_wrapper"
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
      >
        <h2>Master Math Skills</h2>
        <p>
          Genius Forest helps accelerate your math skills, by helping you identify areas of
          improvements.
        </p>
        <div className="section_one_buttons_wrapper">
          <div
            className="sign_up_button_wrapper"
            onMouseEnter={() => {
              buttonHandler('enter');
            }}
            onMouseLeave={() => {
              buttonHandler('leave');
            }}
            onClick={() => history.push('/pre-signup')}
            style={{ cursor: 'pointer' }}
            onFocus={() => {
              buttonHandler('enter');
            }}
            onBlur={() => {
              buttonHandler('leave');
            }}
          >
            <div className="sign_up_button_cover" id="button_cover"></div>
            <button
              className="sign_up_button"
              id="button"
              onClick={() => history.push('/pre-signup')}
            >
              Sign up for free
            </button>
          </div>
          <Button
            label="Try live demo"
            width={demoButtonSize}
            height={57}
            buttonStyle="default"
            onClick={() =>
              history.push(`/diagnostic-demo/${Math.floor(Math.random() * (5 - 1 + 1) + 1)}/1`)
            }
            addClass="try_demo"
          />
        </div>
      </div>
      <img
        src="/assets/images/section_one_outer.png"
        alt="section_one_outer"
        className="section_one_outer"
      />
      <div className="image_slider">
        <div className="images">
          <img
            src="/assets/images/section_one_image_two.png"
            alt="section_one_image_two"
            className="section_one_image_two inner_images"
            id="screen_two"
            style={{ zIndex: firstImageZIndex }}
          />
          <img
            src="/assets/images/section_one_image_three.png"
            alt="section_one_image_three"
            className="section_one_image_three inner_images"
            id="screen_three"
            style={{ zIndex: secondImageZIndex }}
          />
          <img
            src="/assets/images/section_one_image_one.png"
            alt="section_one_image_one"
            className="section_one_image_one inner_images"
            id="screen_one"
            style={{ zIndex: thirdImageZIndex }}
          />
        </div>
      </div>
      <img
        src="/assets/images/section_one_outer.png"
        alt="macbook_mobile"
        className="macbook_mobile"
      />
    </div>
  );
};

export default Section_1;
