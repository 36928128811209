import React from 'react';
// import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ReportCard from './reportCard';

function RoundReport(props) {
  const {
    practice_controller: { score },
  } = props;

  return (
    <Grid item xs={12} className="page_wrapper round_report">
      <Grid container>
        <Grid item xs={12} className="report_mobile_title">
          <h1 className="title">You’ve finished this round !</h1>
          <h2 className="sub_title">You’re {score}% done practicing this skill</h2>
        </Grid>
        <Grid item xs={12} className="report_card_mobile">
          <ReportCard {...props} />
        </Grid>
        <Grid item xs={12} className="report_content_desktop">
          <Grid container direction="row" justify="center" alignItems="stretch">
            <Grid item xs={12} md={4} lg={6}>
              <h1 className="title">You’ve finished this round !</h1>
              <h2 className="sub_title">You’re {score}% done practicing this skill</h2>
              <img
                src="/assets/images/report_engaging.svg"
                alt="engaging image"
                className="engaging_image"
                aria-hidden={true}
              />
            </Grid>
            <Grid item xs={6}>
              <ReportCard {...props} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Grid>
  );
}

RoundReport.propTypes = {};

export default RoundReport;
