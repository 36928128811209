import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Button from '../../../designSystem/components/buttons/button';
import ButtonGroup from '../../../designSystem/components/buttons/group-button';
import ToggleButtons from '../../../designSystem/components/buttons/toggle-buttons';

export default function McuTextFields(props) {
  const buttonsList = [
    { label: 'Parent', value: 0 },
    { label: 'Learner', value: 1 },
  ];

  const toggle_button_list = [
    {
      label: 'ones',
      value: 'ones',
    },
    {
      label: 'tens',
      value: 'tens',
    },
    {
      label: 'hundreds',
      value: 'hundreds',
    },
    {
      label: 'thousands',
      value: 'thousands',
    },
  ];

  return (
    <Grid container className="designSystem_section-wrapper">
      <Grid item xs={12} className="designSystem-sections">
        <h2 className="title">Buttons</h2>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Default Buttons</h2>
          </Grid>
          <Box className="designSystem-elements">
            <Button label="Default" addId="default" onClick={() => {}} width={91} height={42} buttonStyle="default" />
          </Box>
          <Box className="designSystem-elements">
            <Button label="Default Long Label" addId="default_long_label" onClick={() => {}} width={158} height={42} buttonStyle="default" />
          </Box>
          <Box className="designSystem-elements">
            <Button label="Default" addId="Default_disabled" onClick={() => {}} width={91} height={42} buttonStyle="default" disable />
          </Box>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Primary Buttons</h2>
          </Grid>
          <Box className="designSystem-elements">
            <Button label="Primary" addId="primary" onClick={() => {}} width={91} height={42} buttonStyle="primary" />
          </Box>
          <Box className="designSystem-elements">
            <Button label="Primary Long Label" addId="primary_long_label" onClick={() => {}} width={158} height={42} buttonStyle="primary" />
          </Box>
          <Box className="designSystem-elements">
            <Button label="Primary" addId="primary_disabled" onClick={() => {}} width={91} height={42} buttonStyle="primary" disable />
          </Box>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Secondary Buttons</h2>
          </Grid>
          <Box className="designSystem-elements">
            <Button label="Secondary" addId="secondary" onClick={() => {}} width={91} height={42} buttonStyle="secondary" />
          </Box>
          <Box className="designSystem-elements">
            <Button label="Secondary Long Label" addId="secondary_long_label" onClick={() => {}} width={165} height={42} buttonStyle="secondary" />
          </Box>
          <Box className="designSystem-elements">
            <Button label="Secondary" addId="secondary_disabled" onClick={() => {}} width={91} height={42} buttonStyle="secondary" disable />
          </Box>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Default Button Icon</h2>
          </Grid>
          <Box className="designSystem-elements">
            <Button label="Default" addId="default_button_icon" onClick={() => {}} width={91} height={42} buttonStyle="default" type="submit" icon="/assets/icons/diagnose_button_icon.svg" />
          </Box>
          <Box className="designSystem-elements">
            <Button
              label="Default"
              addId="default_button_icon_disabled"
              onClick={() => {}}
              width={91}
              height={42}
              buttonStyle="default"
              type="submit"
              disable
              icon="/assets/icons/diagnose_button_icon.svg"
            />
          </Box>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Primary Button Icon</h2>
          </Grid>
          <Box className="designSystem-elements">
            <Button label="Primary" addId="primary_button_icon" onClick={() => {}} width={91} height={42} buttonStyle="primary" type="submit" icon="/assets/icons/diagnose_button_icon.svg" />
          </Box>
          <Box className="designSystem-elements">
            <Button label="Primary" addId="primary_button_icon" onClick={() => {}} width={91} height={42} buttonStyle="primary" type="submit" disable icon="/assets/icons/diagnose_button_icon.svg" />
          </Box>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Secondary Button Icon</h2>
          </Grid>
          <Box className="designSystem-elements">
            <Button label="Secondary" addId="secondary_button_icon" onClick={() => {}} width={120} height={42} buttonStyle="secondary" type="submit" icon="/assets/icons/diagnose_button_icon.svg" />
          </Box>
          <Box className="designSystem-elements">
            <Button
              label="Secondary"
              addId="secondary_button_icon_disabled"
              onClick={() => {}}
              width={120}
              height={42}
              buttonStyle="secondary"
              type="submit"
              disable
              icon="/assets/icons/diagnose_button_icon.svg"
            />
          </Box>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Button Group</h2>
          </Grid>
          <Box className="designSystem-elements">
            <ButtonGroup ariaLabel="Sign up Options" buttonsList={buttonsList} handleClick={() => {}} width={183} height={50} fontSize={16} />
          </Box>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Toggle Buttons</h2>
          </Grid>
          <Box className="designSystem-elements">
            <ToggleButtons list={toggle_button_list} value={'ones'} handleSelection={() => {}} height={42} width={69} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
