import React from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { topicsAPI } from '../../../API/topics';
import Loading from '../../../designSystem/components/interactiveElements/loading';
import TopicBox from './topicBox';

function MyPlan(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { selectedSubject, learner } = props;
  const [topics, setTopics] = React.useState([]);

  const getTopics = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    topicsAPI.GET.getLearnerTopicsBySubjectID(token, selectedSubject.id, learner.id)
      .then(response => {
        setTopics(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [selectedSubject, getAccessTokenSilently, learner.id]);

  React.useEffect(() => {
    const fetchTopicsData = async () => {
      await getTopics();
    };
    if (selectedSubject) {
      fetchTopicsData();
    }
  }, [getTopics, selectedSubject]);

  if (topics.length <= 0)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <Grid item xs={12} className="my_plan_wrapper">
      {topics.map(item => (
        <TopicBox key={item.name} topic={item} {...props} selectedSubject={selectedSubject} />
      ))}
    </Grid>
  );
}

MyPlan.propTypes = {
  selectedSubject: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default MyPlan;
