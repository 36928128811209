import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import MultiLineTextField from '../../../../designSystem/components/textFields/multilineTextField';
import TextField from '../../../../designSystem/components/textFields/textField';
import Button from '../../../../designSystem/components/buttons/button';
import { Grid } from '@material-ui/core';
import FormDropDown from '../../../../designSystem/components/dropdowns/formDropdown';
import QuestionPreview from './questionPreview';

function QuestionForm({ question, update, filters, selectedFilter, handleFilter }) {
  const { register, handleSubmit, errors, setValue, getValues, control } = useForm({
    defaultValues: {
      title: question.title,
      answer: question.answer,
      description: question.description,
      points: question.points,
      difficult_level: question.difficult_level,
      course_id: question.course_id,
      topic_id: question.topic_id,
      subject_id: question.subject_id,
      skill_id: question.skill_id,
      task_id: question.task_id,
    },
  });

  const [questionOptions, setQuestionOptions] = useState(question.question_options);
  const [filesToSave, setFilesToSave] = useState();
  const [image, setImage] = useState(question.image_url);
  const [courseList, setCourseList] = useState(filters.courses);
  const [subjectList, setSubjectList] = useState(filters.subjects);
  const [topicList, setTopicList] = useState(filters.topics);
  const [skillList, setSkillList] = useState(filters.skills);
  const [taskList, setTaskList] = useState(filters.tasks);

  const fileSelectedHandler = event => {
    const { files } = event.target;
    if (validateFileSize(files) && validateFileFormat(files)) {
      setFilesToSave(Array.from(files));
      console.log('passed');
      console.log(files[0]);
      console.log(filesToSave);
    }
  };

  const validateFileSize = files => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 100000000) {
        console.log('File Size too large! Please, select a file smaller than 100MB!');
        return false;
      }
    }
    return true;
  };

  const validateFileFormat = files => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== 'image/png' && files[i].type !== 'image/jpeg') {
        console.log('Files must be in .pdf or .jpeg format');
        return false;
      }
    }
    return true;
  };

  const handleAnswer = (event, index) => {
    questionOptions[index] = {
      label: event.target.value,
      name: event.target.value,
    };
    console.log(questionOptions);
  };

  const removeOption = index => {
    // questionOptions.splice(index, 1)
    setQuestionOptions(prevState => prevState.filter((value, i) => i !== index));
  };

  const appendInput = () => {
    setQuestionOptions(prevState => {
      return [
        ...prevState,
        {
          label: '',
          value: '',
        },
      ];
    });
  };

  const handleForm = (formData, e) => {
    const editData = {
      title: formData.title,
      description: formData.description,
      answer: formData.answer,
      difficult_level: formData.difficult_level,
      points: formData.points,
      question_options: questionOptions,
      course_id: formData.course_id,
      topic_id: formData.topic_id,
      subject_id: formData.subject_id,
      skill_id: formData.skill_id,
      task_id: formData.task_id,
    };
    console.log(editData);
    update(editData);
  };

  const difficultLevels = [
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
  ];

  const onError = (errors, e) => {
    console.log(errors, e);
  };

    //filter handlers@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  
    const subjectHandler = course_id => {
      if (!course_id) return;
      let listToReturn = [];
      for (let i = 0; i < filters.subjects.length; i++) {
        if (course_id.toString() === filters.subjects[i].course_id.toString()) {
          listToReturn.push(filters.subjects[i]);
        }
      }
      setSubjectList(listToReturn);
    };
  
    const topicHandler = subject_id => {
      if (!subject_id) return;
      let listToReturn = [];
      for (let i = 0; i < filters.topics.length; i++) {
        if (subject_id.toString() === filters.topics[i].subject_id.toString()) {
          listToReturn.push(filters.topics[i]);
        }
      }
      setTopicList(listToReturn);
      setSkillList([]);
    };
  
    const skillHandler = topic_id => {
      if (!topic_id) return;
      let listToReturn = [];
      for (let i = 0; i < filters.skills.length; i++) {
        if (topic_id.toString() === filters.skills[i].topic_id.toString()) {
          listToReturn.push(filters.skills[i]);
        }
      }
      setSkillList(listToReturn);
      setTaskList([]);
    };
  
    const taskHandler = skill_id => {
      if (!skill_id) return;
      let listToReturn = [];
      for (let i = 0; i < filters.tasks.length; i++) {
        if (skill_id.toString() === filters.tasks[i].skill_id.toString()) {
          listToReturn.push(filters.tasks[i]);
        }
      }
      // console.log(taskHandler)
      setTaskList(listToReturn);
    };
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  React.useEffect(() => {
    subjectHandler(selectedFilter.course_id);
    topicHandler(selectedFilter.subject_id);
    skillHandler(selectedFilter.topic_id);
    taskHandler(selectedFilter.skill_id);
  }, [selectedFilter]);

  React.useEffect(()=>{
    handleFilter('course_id', question.course_id);
    handleFilter('subject_id', question.subject_id);
    handleFilter('topic_id', question.topic_id);
    handleFilter('skill_id', question.skill_id);
    handleFilter('task_id', question.task_id);
  },[])



  return (
    <form onSubmit={handleSubmit(handleForm, onError)} id="question_edit_form">
      <Grid container spacing={3}>
        {/* left side */}
        <Grid item xs={6} style={{ maxWidth: '380px' }}>
          <p className="input_label">Title</p>
          <MultiLineTextField
            type="text"
            name="title"
            label=""
            multiline
            helperText={errors.title ? errors.title.message : ''}
            error={errors.title && true}
            width="350px"
            height="110px"
            style={{ textAlign: 'right' }}
            register={register({
              required: 'Title is required',
              minLength: { value: 1, message: 'Min length 1' },
              maxLength: { value: 500, message: 'Must be under 500 characters' },
            })}
          />
          <p className="input_label">Answer</p>
          <MultiLineTextField
            type="text"
            name="answer"
            label=""
            multiline
            helperText={errors.answer ? errors.answer.message : ''}
            error={errors.answer && true}
            width="350px"
            height="110px"
            register={register({
              required: 'Answer is required',
              minLength: { value: 1, message: 'Min length 1' },
              maxLength: { value: 500, message: 'Must be under 500 characters' },
            })}
            />
          <p className="input_label">Answer Options</p>
          {questionOptions ? questionOptions.map((option, index)=>{
            return(
              <div key={index} className="flex_container" onChange={(e)=>{handleAnswer(e, index)}}>
                <div style={{marginRight: 17}}>
                  <p className="answer_option">{`Opt. ${index + 1}`}</p>
                </div>
                <TextField
                  type="text"
                  name={`answer_option_${index+1}`}
                  label=""
                  helperText={errors.points ? errors.points.message : ''}
                  error={errors.points && true}
                  width="190px"
                  height= "50px"
                  register={register({
                    minLength: { value: 1, message: 'Min length 1' },
                    required: 'Points Section is required',
                  })}
                  defaultValue={option ? option.value : ''}
                />
                <div style={{marginLeft: 10, cursor: 'pointer'}}>
                  <p className="remove_button" onClick={() => removeOption(index)}>Remove</p>
                </div>
              </div>
            )
          }) : 'There are no options for this question'}
          <div id="add_button" className="interactive_element" onClick={() => appendInput()}>
            <div id="add_icon">
                <img src="/assets/icons/add_icon.svg" alt="add icon"/>
            </div>
                <p>Add Options</p>
          </div>


          <p className="input_label">Image</p>
          <div className="flex_container">
            <p className="image_text">{image ? image : 'Image has not been added yet'}</p>
            {image && <div style={{marginLeft: 10}}>
              <p className="remove_button">Remove</p>
            </div>}
          </div>
          <label id="add_button" className="interactive_element" for="upload_image_input">
              <div id="add_icon">
                  <img src="/assets/icons/add_icon.svg" alt="add icon"/>
              </div>
                  <p>Add Images</p>
          </label>
          <input type="file" name="image" id="upload_image_input" onChange={fileSelectedHandler}/>
        </Grid>
        {/* right side */}
        <Grid item xs={6} style={{ maxWidth: '380px' }}>
          <p className="input_label">Description</p>
          <MultiLineTextField
            type="text"
            name="description"
            label=""
            multiline
            helperText={errors.description ? errors.description.message : ''}
            error={errors.description && true}
            width="350px"
            height="110px"
            register={register({
              maxLength: { value: 500, message: 'Must be under 500 characters' },
            })}
          />
          <p className="input_label">Points</p>
          <TextField
            type="number"
            name="points"
            label=""
            helperText={errors.points ? errors.points.message : ''}
            error={errors.points && true}
            width="350px"
            height="45px"
            register={register({
              required: 'Points Section is required',
            })}
          />
          <p className="input_label" style={{ marginBottom: 0 }}>
            Difficult Level
          </p>
          <FormDropDown
            id="difficult_level"
            name="difficult_level"
            list={difficultLevels}
            control={control}
            variant="outlined"
            margin="normal"
            width={350}
            height={45}
          ></FormDropDown>
          <p className="input_label" style={{marginBottom: 0}}>Filters</p>
          <FormDropDown
              id="course_id"
              name="course_id"
              label="Course"
              list={courseList}
              control={control}
              margin="normal"
              width={350}
              height={45}
              onClick={(e)=> handleFilter('course_id', e.target.value)}
            >
          </FormDropDown>
          <FormDropDown
              id="subject_id"
              name="subject_id"
              label="Subject"
              list={subjectList}
              control={control}
              margin="normal"
              width={350}
              height={45}
              onClick={(e)=> handleFilter('subject_id', e.target.value)}
            >
            </FormDropDown>
          <FormDropDown
              id="topic_id"
              name="topic_id"
              label="Topic"
              list={topicList}
              control={control}
              margin="normal"
              width={350}
              height={45}
              onClick={(e)=> handleFilter('topic_id', e.target.value)}
            >
            </FormDropDown>
            <FormDropDown
              id="skill_id"
              name="skill_id"
              label="Skill"
              list={skillList}
              control={control}
              margin="normal"
              width={350}
              height={45}
              onClick={(e)=> handleFilter('skill_id', e.target.value)}
            >
          </FormDropDown>
          <FormDropDown
              id="task_id"
              name="task_id"
              label="task"
              list={taskList}
              control={control}
              margin="normal"
              width={350}
              height={45}
              onClick={(e)=> handleFilter('task_id', e.target.value)}
            >
          </FormDropDown>
        </Grid>
        <Grid item xs={7}>
          <QuestionPreview question={question} />
        </Grid>
        <div id="buttons_container">
          <div style={{ float: 'right' }}>
            <Button
              label="Save"
              addId="save_button"
              width={133}
              height={44}
              buttonStyle="primary"
              type="submit"
            />
          </div>
        </div>
      </Grid>
    </form>
  );
}

QuestionForm.propTypes = {};

export default QuestionForm;
