import axios from 'axios';
import url from './url';

export const paymentAPI = {
  POST: {
    getUserInvoices(token, data) {
      return axios.post(`${url}api/payment/user/invoices`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
  PUT: {
    removeCustomerCard(token, card_id) {
      return axios.put(
        `${url}api/card/remove/${card_id}`,
        {},
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );
    },
    editCustomerCard(token, data, card_id, customer_id, subscription_id) {
      return axios.put(
        `${url}api/card/edit/${card_id}/${customer_id}/${subscription_id}`,
        { data },
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );
    }
  }
};
