import React from 'react';
import PropTypes from 'prop-types';
import './interactiveStyle.scss';

TimeElapsed.propTypes = {
  getElapsedTime: PropTypes.func.isRequired,
  pause_time: PropTypes.bool.isRequired,
  reset_timer: PropTypes.bool.isRequired,
};

function TimeElapsed({ getElapsedTime, pause_time, reset_timer }) {
  const increment = React.useRef(null);

  const [timer, setTimer] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);

  const handleStart = () => {
    if (!isActive) {
      setIsActive(true);
      // setIsPaused(true);
      increment.current = setInterval(() => {
        setTimer(timer => timer + 1);
      }, 1000);
    }
  };

  handleStart();

  React.useEffect(() => {
    if (pause_time === true) {
      getElapsedTime(timer);
    }
    if (reset_timer) {
      setTimer(0);
    }
  }, [reset_timer, pause_time]);

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    // if (timer < 1) {
    //   return 'Expired';
    // }
    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  return (
    <div className="time_elapsed">
      <div className="time_elapsed_title">Time Elapsed</div>
      <div id="time_elapsed_counter" className="counter">
        {formatTime()}
      </div>
    </div>
  );
}

export default TimeElapsed;

// TODO: In case of future need

// const handlePause = () => {
//   clearInterval(increment.current);
//   setIsPaused(false);
// };

// const handleResume = () => {
//   setIsPaused(true);
//   increment.current = setInterval(() => {
//     setTimer((timer) => timer + 1);
//   }, 1000);
// };

// const handleReset = () => {
//   clearInterval(increment.current);
//   setIsActive(false);
//   setIsPaused(false);
//   setTimer(0);
// };

// const calculateMinutes = () => {
//   if (start_time === 5) return 300;
//   if (start_time === 10) return 600;
//   if (start_time === 15) return 900;
//   if (start_time === 20) return 1200;
//   if (start_time === 30) return 1800;
//   if (start_time === 40) return 2400;
//   if (start_time === 50) return 3000;
// };
