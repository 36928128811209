import React from 'react';
import { useHistory } from 'react-router-dom';
import MarketingCarousel from '../../../designSystem/components/carousel/marketingDefaultCarousel';
import Button from './../../../designSystem/components/buttons/button';

const Section_5 = () => {
  const history = useHistory();
  const dummyData = [
    {
      id: 1,
      name: 'Jared A',
      type: 'Parent',
      title: 'It was a good experience',
      comment:
        "I've seen an incredible increase in math confidence and classroom engagement in my kids. The bonus is that they are having fun.",
      ratings: 5,
      imageUrl: 'sample_user_pic',
    },
    {
      id: 2,
      name: 'Bonnie L',
      type: 'Parent',
      title: 'Excellent experience.',
      comment:
        "Genius Forest has completely changed my daughter's attitude towards math. Tracking her progress has helped me understand how I can better support her learning.",
      ratings: 5,
      imageUrl: 'sample_user_pic',
    },
    {
      id: 3,
      name: 'Alex K',
      type: 'Parent',
      title: 'My son loved it!',
      comment:
        'My son learned so much and I would recommend Genius Forest to any kid who is having a hard time having fun while learning.',
      ratings: 4,
      imageUrl: 'sample_user_pic',
    },
    {
      id: 4,
      name: 'James K',
      type: 'Parent',
      title: 'Impressive experience',
      comment:
        'Excellent platform that will aid your chilren to excel in any academic field that they may be lacking',
      ratings: 5,
      imageUrl: 'sample_user_pic',
    },
  ];

  return (
    <div className="section_5_wrapper">
      <img
        src="/assets/images/section_five_background_image.png"
        alt="section_five_background_image"
        className="section_five_image"
      />
      <div className="section_5_title">
        <div className="section_5_text_desktop">
          <h2>Genius Forest is</h2>
          <div style={{ display: 'flex' }}>
            <h2>trusted by</h2>
            <h2 style={{ color: '#6FF2C2' }}>&nbsp;thousands</h2>
          </div>
          <h2>of students</h2>
        </div>
        <div className="section_5_text_mobile">
          <h2 style={{ display: 'inline-block' }}>
            Genius Forest is trusted by
            <span style={{ color: '#6FF2C2' }}>&nbsp;thousands&nbsp;</span>
            of students
          </h2>
        </div>
        <p>Build stronger confidence in areas that need improvement!</p>
        <Button
          label="Sign up for free"
          width={171}
          height={57}
          buttonStyle="primary"
          onClick={() => history.push('/pre-signup')}
        />
      </div>
      <div className="section_5_content">
        <MarketingCarousel list={dummyData} />
      </div>
    </div>
  );
};

export default Section_5;
