import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import Button from '../../../../designSystem/components/buttons/button';
import SubjectSelection from './subjectSelection';
import DefaultDropdown from '../../../../designSystem/components/dropdowns/dropdown';

function TopicSelection(props) {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { topics, handleSelectTopic, getTopicPracticeSummary, getTopicPracticeActivities } = props;

  const [selectedTopicIndex, setSelectedTopicIndex] = React.useState(0);
  const [topicDropdownList, setTopicDropdownList] = React.useState([]);

  const handleTopic = (topic, index) => {
    getTopicPracticeSummary(topics[index]);
    getTopicPracticeActivities(topics[index]);
    handleSelectTopic(topic);
    setSelectedTopicIndex(index);
  };

  console.log(topics);

  const formateDropdown = (item, index) => {
    return {
      label: item.name,
      value: index,
      disabled: false,
    };
  };

  const addTopicsToList = React.useCallback(topics => {
    const dropdownList = [];
    for (let i = 0; i < topics.length; i++) {
      dropdownList.push(formateDropdown(topics[i], i));
    }
    // dropdownList.unshift({
    //   label: "Overview",
    //   value: 0,
    //   disabled: false
    // })
    setTopicDropdownList(dropdownList);
  }, []);

  React.useEffect(() => {
    addTopicsToList(topics);
  }, []);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={2}
      className="selection_wrapper"
    >
      <SubjectSelection
        subjects={props.subjects}
        selectedSubject={props.selectedSubject}
        handleSelectSubject={props.handleSelectSubject}
      />
      <div className="mobile_dropdown">
        <DefaultDropdown
          list={topicDropdownList}
          selectedItem={selectedTopicIndex}
          handleChange={value => handleTopic(value.target.value, value.target.value)}
          width={160}
          height={58}
          bgColor="white"
        />
      </div>
      {/* <Grid item className="topic_button_wrapper">
        <Button
          label="Overview"
          addId="overview"
          onClick={() => handleTopic('all', 'all')}
          width={xsScreen ? 130 : 140}
          height={xsScreen ? 49 : 59}
          buttonStyle="light"
          type="button"
          icon={
            selectedTopicIndex === 'all'
              ? '/assets/icons/active-green-icon.png'
              : '/assets/icons/regular_button_list_icon.png'
          }
          active={selectedTopicIndex === 'all'}
        />
      </Grid> */}
      {topics &&
        topics.map((topic, index) => (
          <Grid item key={topic.name} className="topic_button_wrapper">
            <Button
              label={topic.name}
              addId={topic.name}
              onClick={() => handleTopic(topic, index)}
              width={
                xsScreen
                  ? topic.name === 'financial literacy'
                    ? 175
                    : 130
                  : topic.name === 'financial literacy'
                    ? 175
                    : 140
              }
              height={xsScreen ? 49 : 59}
              buttonStyle="light"
              type="button"
              icon={
                selectedTopicIndex === index
                  ? '/assets/icons/active-green-icon.png'
                  : '/assets/icons/regular_button_list_icon.png'
              }
              active={selectedTopicIndex === index}
            />
          </Grid>
        ))}
    </Grid>
  );
}

TopicSelection.propTypes = {
  handleSelectTopic: PropTypes.func.isRequired,
  selectedTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  topics: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.any)]).isRequired,
};

export default withRouter(TopicSelection);
