import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import Button from '../../../../designSystem/components/buttons/button';
import '../myStudents.scss';

export default function StudentCard(props) {
  const { user, current_subject } = props.learner;
  const history = useHistory();

  const getUserName = () => {
    if (user.name.includes('@')) return user.nickname;
    return user.name;
  };

  return (
    <Grid item>
      <div className="student_box">
        <div className="student_box_top">
          <img src="/assets/images/student_box_header.png" className="top_bg" alt="background" />
          <img width={103} height={103} src={user.picture} alt="student" className="child_img" />
        </div>
        <Divider className="divider" />
        <span className="student_name">{getUserName()}</span>
        <div className="student_info">
          <div className="current_grade">
            <span className="current_grade_text">Current Grade</span>
            <span className="grade">Grade {current_subject}</span>
          </div>
          <div className="last_active">
            <span className="last_active_text">Last Active</span>
            <span className="last_active_date">
              {user.last_login ? (
                <Moment fromNow ago>
                  {new Date(user.last_login)}
                </Moment>
              ) : (
                <>No logins</>
              )}
            </span>
          </div>
        </div>
        <div className="buttons_wrapper">
          <Button
            fontSize={16}
            label="View Progress"
            width={200}
            height={44}
            buttonStyle="default"
            addClass="button"
            onClick={() => history.push(`/parent/progress/${props.learner.id}`)}
          />
          <Button
            fontSize={16}
            label="View Summary"
            width={200}
            height={44}
            buttonStyle="default"
            addClass="button"
            onClick={() => history.push(`/parent/summary/${props.learner.id}`)}
          />
        </div>
      </div>
    </Grid>
  );
}
