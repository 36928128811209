import React, { useState, useEffect } from 'react';
import SectionOne from './components/sectionOne';
import SectionTwo from './components/sectionTwo';
import SectionThree from './components/sectionThree';
import SectionFour from './components/sectionFour';
import SectionFive from './components/sectionFive';
import SectionSix from './components/sectionSix';
import SectionSeven from './components/sectionSeven';
import './landingPage.scss';
import './landingPage2.scss';

//window size listenrer.
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    window.fbq('track', 'ViewContent');
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

const LandingPage = () => {
  const size = useWindowSize();

  return (
    <>
      <SectionOne size={size} />
      <SectionTwo />
      <SectionFour size={size} />
      <SectionThree />
      <SectionFive />
      <SectionSix />
      <SectionSeven size={size} />
    </>
  );
};

export default LandingPage;
