import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Button from '../../../designSystem/components/buttons/button';
import ProgressCircle from '../../../designSystem/components/interactiveElements/progressCircle';
import ButtonIconRound from '../../../designSystem/components/buttons/round-icon-button';
import LockedSubscription from '../../../designSystem/components/dialogs/lockedSubscription';

function TopicBox({ topic, history, subscription, selectedSubject }) {
  const [expand, setExpand] = React.useState(false);
  const learner_topic_points = topic.learner_topic_points ? topic.learner_topic_points : 0;
  const learner_topic_progress = (learner_topic_points / topic.points) * 100;
  const topic_points = topic.learner_topic_points;

  const [openLock, setOpenLock] = React.useState(false);

  const handleExpand = () => {
    !expand ? setExpand(true) : setExpand(false);
  };

  const handleStartPractice = () => {
    if (subscription.status === 'paused') {
      setOpenLock(true);
    } else {
      if (topic_points) return history.push(`/practice/${topic.id}`);
      return history.push(`/practice-portal/${topic.id}`);
    }
  };

  const getSkillProgress = skill => {
    const learner_skill_points = skill.learner_skill_points ? skill.learner_skill_points : 0;
    const learner_skill_progress = (learner_skill_points / skill.points) * 100;
    return learner_skill_progress;
  };

  const renderLevel = skill => {
    if (getSkillProgress(skill) <= 25) return 'Fundamental';
    if (getSkillProgress(skill) > 25 && getSkillProgress(skill) <= 50) return 'Intermediate';
    if (getSkillProgress(skill) > 50 && getSkillProgress(skill) <= 75) return 'Advanced';
    if (getSkillProgress(skill) > 75 && getSkillProgress(skill) <= 100) return 'Master';
  };

  const handleDiagnose = () => {
    if (subscription.status === 'paused') {
      setOpenLock(true);
    } else {
      history.push(`/diagnostic-tool/${topic.id}`);
    }
  };

  const handleReport = () => {
    history.push(`/practice/topic-report/${topic.id}`);
  };

  return (
    <Grid container className="topic_box">
      <div className="grade_number">
        <span className="grade_label">Grade</span>
        <span className="number">{selectedSubject.id}</span>
      </div>
      <LockedSubscription
        open={openLock}
        handleClose={() => setOpenLock(false)}
        handleConfirm={() => history.push('/resume-subscription')}
      />
      <Grid item xs>
        <h2 className="title">
          {topic.name}
          <span className="button_wrapper desktop_diagnose">
            <Button
              label={topic_points ? 'Report' : 'Diagnose'}
              addId="diagnose_button"
              onClick={topic_points ? handleReport : handleDiagnose}
              width={105}
              height={28}
              buttonStyle={topic_points ? 'default' : 'secondary'}
              type="submit"
              icon="/assets/icons/diagnose_button_icon.svg"
              fontSize={14}
            />
          </span>
        </h2>
      </Grid>
      <Grid item xs={2} md>
        <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={2}>
          <Grid item className="topic_control-desktop">
            <ProgressCircle progress_value={learner_topic_progress} />
          </Grid>
          <Grid item className="topic_control-desktop">
            <Button
              label="Practice"
              addId="practice_button"
              onClick={handleStartPractice}
              width={96}
              height={44}
              buttonStyle="primary"
              type="button"
            />
          </Grid>
          <Grid item id="progress_mobile">
            <ProgressCircle progress_value={learner_topic_progress} />
          </Grid>
          <Grid item id="desktop_collapse">
            {expand ? (
              <ButtonIconRound arrow_direction="down" handleClick={handleExpand} />
            ) : (
              <ButtonIconRound arrow_direction="up" handleClick={handleExpand} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="description">{topic.description}</div>
      </Grid>
      <Grid item xs={12} className="topic_control-mobile">
        <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={2}>
          <Grid id="progress_desktop">
            <ProgressCircle progress_value={learner_topic_progress} />
          </Grid>
          <Grid item className="diagnose_practice_buttons">
            <span className="button_wrapper mobile_diagnose">
              <Button
                label={topic_points ? 'Report' : 'Diagnose'}
                addId="diagnose_button"
                onClick={topic_points ? handleReport : handleDiagnose}
                width={105}
                height={28}
                buttonStyle={topic_points ? 'default' : 'secondary'}
                type="submit"
                icon="/assets/icons/diagnose_button_icon.svg"
              />
            </span>
            <Button
              label="Practice"
              addId="practice_button"
              onClick={handleStartPractice}
              width={96}
              height={44}
              buttonStyle="primary"
              type="button"
            />
          </Grid>
          <Grid item id="mobile_collapse">
            {expand ? (
              <ButtonIconRound arrow_direction="down" handleClick={handleExpand} />
            ) : (
              <ButtonIconRound arrow_direction="up" handleClick={handleExpand} />
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* <----- Skills Collapsed section -----> */}
      <Grid container className={expand ? 'expansion_section visible' : 'expansion_section'}>
        <Grid item xs={12}>
          <hr className="custom_hr" />
        </Grid>
        {topic.skills.map((item, index) => (
          <Grid item xs={12} key={index} className="">
            <div className="links_wrapper">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={2}
                id="expansion_container"
              >
                <Grid item className="links">
                  {index + 1}. {item.name}
                </Grid>
                <Grid item className="links_progress">
                  <span className="grade_level_box">
                    <span className="level">{renderLevel(item)}</span>
                  </span>
                  <div className="circle_wrapper">
                    <ProgressCircle progress_value={getSkillProgress(item)} size={32} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ))}
      </Grid>
      <img alt="box_image" src="/assets/images/course_box_image.png" className="box_image" />
    </Grid>
  );
}

export default withRouter(TopicBox);

TopicBox.propTypes = {
  topic: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    points: PropTypes.number,
    description: PropTypes.string,
    learner_topic_points: PropTypes.number,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
