import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '../../../../designSystem/components/buttons/button';
import Loading from '../../../../designSystem/components/interactiveElements/loading';
import { subscriptionAPI } from '../../../../API/subscription';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CancelMembership(props) {
  const { open, handleClose, learner, subscription, handleFinish } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleConfirm = async () => {
    const token = await getAccessTokenSilently();
    setIsLoading(true);
    await subscriptionAPI.PUT.cancelSubscription(token, subscription, learner.id)
      .then(async (response) => {
        console.log(response.data);
        handleFinish('done')
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        handleFinish(error)
      });
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="xs"
      aria-labelledby="dialog_title"
      aria-describedby="dialog_body"
      className="cancelMembership"
      disableScrollLock
      style={{ borderRadius: '10px' }}
    >
      <DialogTitle id="dialog_title">
        <div className="title">Cancel Subscription</div>
        <button className="close_dialog" onClick={handleClose} type="button" disabled={isLoading}>
          <span className="sr_only">Close Dialog</span>
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent id="dialog_body" className="body-text">
        <DialogContentText>
          <div className="body-text">
            By cancelling your membership, you will lose all access to your Premium benefits.
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="buttons-wrapper">
        {isLoading && (
          <Loading />
        )}
        <Button
          label="Cancel"
          onClick={handleClose}
          width={111}
          height={44}
          fontSize={14}
          buttonStyle="default"
          disable={isLoading}
          type="button"
        />
        <Button
          label="Confirm"
          onClick={handleConfirm}
          width={129}
          height={44}
          fontSize={14}
          buttonStyle="primary"
          disable={isLoading}
          type="button"
        />
      </DialogActions>
    </Dialog>
  );
}

export default CancelMembership;
