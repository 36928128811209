import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ProgressCircle from '../../../designSystem/components/interactiveElements/progressCircle';
import DifficultLevel from '../../../designSystem/components/interactiveElements/difficultLevel';
import ButtonIconRound from '../../../designSystem/components/buttons/round-icon-button';
import Button from '../../../designSystem/components/buttons/button';
import './cards.scss';

function SkillBox(props) {
  const {
    skill_name,
    skill_points,
    skill_learner_points,
    skill_total_time,
    skill_total_questions,
    skill_attempts,
    skill_wrong_attempts,
    tasks,
    subject_id,
    topic_id,
    skill_id,
  } = props.skill;

  const { handlePracticeButton, showButton } = props;

  const tasks_plan = tasks.slice(0, 3);

  const learner_progress = props.skill.diagnostic_demo_result
    ? props.skill.diagnostic_demo_result
    : (skill_learner_points / skill_points) * 100;
  const [expand_skill, setExpandSkill] = React.useState(false);

  const calculateDifficultLevel = learner_progress => {
    if (learner_progress <= 20) return 1;
    if (learner_progress > 20 && learner_progress <= 40) return 2;
    if (learner_progress > 40 && learner_progress <= 60) return 3;
    if (learner_progress > 60 && learner_progress <= 80) return 4;
    if (learner_progress > 80) return 5;
  };

  const formatTime = timer => {
    // const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    if (parseInt(getHours, 10) === 0) {
      return `${getMinutes}min`;
    }
    return `${getHours}:${getMinutes} `;
  };

  const calculateScore = () => {
    return Math.round(Math.abs((skill_wrong_attempts / skill_attempts) * 100));
  };

  const calculateLevel = () => {
    if (learner_progress <= 25) return 'Fundamental';
    if (learner_progress > 25 && learner_progress <= 50) return 'Intermediate';
    if (learner_progress > 50 && learner_progress <= 75) return 'Advanced';
    if (learner_progress > 75) return 'Master';
  };

  return (
    <Grid container className="skill_box">
      <Grid
        item
        xs={12}
        className={`skill_top_content-collapsed ${expand_skill && 'skill_top_content'}`}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item className="title">
                {skill_name}
              </Grid>
              <Grid item>
                {expand_skill ? (
                  <ButtonIconRound
                    arrow_direction="down"
                    handleClick={() => setExpandSkill(!expand_skill)}
                    size="sm"
                  />
                ) : (
                  <ButtonIconRound
                    arrow_direction="up"
                    handleClick={() => setExpandSkill(!expand_skill)}
                    size="sm"
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="skill_box_row">
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
              className="level_information_mobile"
            >
              <Grid item>
                <div className="grade_info_box">
                  <span className="grade_number">{subject_id}</span>
                  <span className="grade_type">{calculateLevel()}</span>
                </div>
              </Grid>
              <Grid item>
                <ProgressCircle progress_value={learner_progress} size={40} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="skill_box_row">
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
              className="skill_box_categories_mobile"
            >
              <Grid item className="skill_box_mobile_items difficult">
                <div className="sub_title difficulty_level">Difficulty</div>
                <div className="difficulty_bar">
                  <DifficultLevel difficult_level={calculateDifficultLevel(learner_progress)} />
                </div>
              </Grid>
              <Grid item className="skill_box_mobile_items">
                <div className="sub_title">Hours spent</div>
                <div className="text">{formatTime(skill_total_time)}</div>
              </Grid>
              <Grid item className="skill_box_mobile_items">
                <div className="sub_title">Questions answered</div>
                <div className="text">{skill_total_questions}</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="skill_box_row">
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
              className="skill_box_categories_mobile"
            >
              <Grid item className="skill_box_mobile_items score">
                <div className="sub_title">Score</div>
                <div className="text">{calculateScore()}%</div>
              </Grid>
              {/* 
              TODO: removed from first MVP
              <Grid item>
                <div className="sub_title">Estimated Master Date</div>
                <div className="text">Mar 28 2022</div>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {expand_skill && (
        <Grid item xs={12}>
          <Grid item xs={12} className="practice_plan_wrapper">
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                <Grid container>
                  <Grid item xs={12} className="plan_title">
                    Practice Plan
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={12} className="plan_difficulty_title">
                    Difficulty
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                {tasks_plan.map(item => (
                  <Grid item xs={12} key={item.name} className="unit_name">
                    <div className="list_icon">
                      <img
                        src="/assets/icons/list_black_icon.svg"
                        alt="Active Icon"
                        aria-hidden={true}
                      />
                    </div>
                    {item.name}
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                {tasks_plan.map((item, index) => (
                  <Grid item xs={12} key={`question ${index}`} className="unit_difficult">
                    <DifficultLevel difficult_level={calculateDifficultLevel(learner_progress)} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {showButton && (
        <Grid item xs={12} className="button_wrapper">
          <Button
            label="Start to Practice"
            addId="practice_button"
            onClick={() => handlePracticeButton(topic_id, skill_id)}
            width={148}
            height={42}
            buttonStyle="default"
            type="button"
          />
        </Grid>
      )}
    </Grid>
  );
}

SkillBox.propTypes = {
  skill: PropTypes.shape({
    skill_name: PropTypes.string,
    skill_points: PropTypes.string,
    skill_learner_points: PropTypes.number,
    skill_total_time: PropTypes.number,
    skill_total_questions: PropTypes.number,
    skill_attempts: PropTypes.number,
    skill_wrong_attempts: PropTypes.number,
    tasks: PropTypes.arrayOf(PropTypes.any),
    subject_id: PropTypes.number,
  }),
  handlePracticeButton: PropTypes.func,
  showButton: PropTypes.bool,
};

SkillBox.defaultProps = {
  handlePracticeButton: () => { },
  showButton: false,
};

export default withRouter(SkillBox);
