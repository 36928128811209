import React, { useState, useEffect } from 'react';
import { authAPI } from './../../../API/auth';

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}

const Section_4 = ({ size }) => {
  const [activeUsers, setActiveUsers] = useState();
  const getActiveUsers = async () => {
    await authAPI.GET.getUserNumber()
      .then(res => {
        setActiveUsers(nFormatter(res.data.result, 1));
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getActiveUsers();
  }, []);

  return (
    <div className="section_4_wrapper landing_page_wrapper">
      {size.width > 600 && (
        <img
          src="/assets/images/section_four_image.png"
          alt="section_four_image"
          className="section_four_image"
        />
      )}
      {size.width < 599 && (
        <img
          src="/assets/images/section_four_image_mobile.png"
          alt="section_four_image_mobile"
          className="section_four_image_mobile"
        />
      )}
      <div className="why_wrapper">
        <div className="why_card">
          {/* TODO: Allow when we have 100+ users
           <h3>{activeUsers}+</h3> */}
          <h3>100+</h3>
          <p>Active Users</p>
        </div>
        <div className="why_card">
          <h3>50+</h3>
          <p>Classrooms</p>
        </div>
        <div className="why_card">
          <h3>95%</h3>
          <p>Increased Results</p>
        </div>
      </div>
    </div>
  );
};

export default Section_4;
