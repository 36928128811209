import React from 'react';
import ReactGoogleAnalytics from 'react-ga';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../../../designSystem/components/interactiveElements/loading';
import Button from '../../../designSystem/components/buttons/button';
import SkillGraph from './components/skillGraph';
import SkillGraphMobile from './components/skillGraphMobile';
import MoreInformation from './components/moreInformation';
import SkillInformation from './components/skillInformation';
import './diagnosticReport.scss';

function DiagnosticReport(props) {
  const { match, history } = props;
  const [skills, setSkills] = React.useState(false);
  const [selected_skill, setSelectedSkill] = React.useState(0);
  const { loginWithRedirect } = useAuth0();

  const handleMoreInformation = () => {
    document.getElementById('more_information').scrollIntoView();
  };

  const handlePractice = () => {
    const { topic_id } = match.params;
    history.push(`/practice/${topic_id}`);
  };

  const handleSkillPractice = () => {
    const { topic_id } = match.params;
    const skill_id = skills[selected_skill].skill_id;
    history.push(`/practice/${topic_id}/${skill_id}`);
  };

  React.useEffect(async () => {
    window.scroll({ top: 0, behavior: 'smooth' });
    const data = JSON.parse(sessionStorage.getItem('test_results'));
    setSkills(data[0].skills);
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
    ReactGoogleAnalytics.event({
      category: 'Diagnostic demo',
      action: 'diagnostic tool',
      label: 'diagnostic demo',
    });
  }, []);

  if (!skills)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <>
      {/* Top Content */}
      <Grid
        container
        className="page_wrapper diagnostic_report_top_bar diagnostic_report_background"
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <h1 className="diagnostic_step_title">Diagnostic Report</h1>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm className="top_button_wrapper">
          <Button
            label="Start to Practice"
            addId="practice_button"
            onClick={() => loginWithRedirect({ screen_hint: 'login' })}
            width={148}
            height={42}
            buttonStyle="secondary"
            type="button"
          />
        </Grid>
      </Grid>
      <Grid container className="page_wrapper diagnostic_report_wrapper">
        <Grid item xs={12} className="top_report_wrapper">
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <h2 className="top_report_title">{skills[0].topic_name}</h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} lg={6} className="report_body">
              <span className="performance_chart-desktop">
                <SkillGraph
                  active_index={selected_skill}
                  data={skills}
                  handleClick={value => setSelectedSkill(value)}
                  average={3500}
                />
              </span>
              <span className="performance_chart-mobile">
                <SkillGraphMobile
                  active_index={selected_skill}
                  data={skills}
                  handleClick={(data, value) => setSelectedSkill(value)}
                  average={3500}
                />
              </span>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SkillInformation selected_skill={selected_skill} skills={skills} />
              <div className="practice_skill_button_wrapper">
                <Button
                  label="Start to Practice"
                  addId="practice_button"
                  onClick={() => loginWithRedirect({ screen_hint: 'login' })}
                  width={148}
                  height={42}
                  buttonStyle="primary"
                  type="button"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="text_center m_top_5 ">
          <button type="button" onClick={handleMoreInformation} className="display_more_button">
            More Information
            <div className="text_center m_top_1">
              <img
                alt="display more "
                src="/assets/icons/result_display_more.svg"
                aria-hidden={true}
              />
            </div>
          </button>
        </Grid>
      </Grid>
      <Grid container className="page_wrapper diagnostic_report_more" id="more_information">
        <MoreInformation skills={skills} />
      </Grid>
    </>
  );
}

export default withRouter(DiagnosticReport);
