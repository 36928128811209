import axios from 'axios';
import url from './url';

export const subjectsAPI = {
  GET: {
    getSubjectsByCourseID(course_id) {
      return axios.get(`${url}api/getSubjectsByCourseID/${course_id}`, {
      });
    },
    getSubjectsByLearnerID(token, course_id, learner_id) {
      return axios.get(`${url}api/getSubjectsByLearnerID/${course_id}/${learner_id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
};
