import React, { useEffect, useState } from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import MarketingCarouselContent from './marketingCarouselContent';
import './carousel.scss';

//window size listenrer.
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

const MarketingDefaultCarousel = ({ list }) => {
  const size = useWindowSize();

  const [sliderValue, setSliderValue] = React.useState(0);

  const setFocusOnSelectSlide = value => {
    const slide = document.getElementsByClassName(`review_slide_${value}`);
    if (!slide[3]) return false;
    // slide[3].style.visibility = 'visible';
    setTimeout(() => {
      slide[3].focus();
    }, [400]);
  };

  const handleSlideChange = value => {
    if (value > list.length - 1) {
      // hideLeftSlideElements(value);
      setFocusOnSelectSlide(0);
      return setSliderValue(0);
    }
    if (value === -1) {
      // hideLeftSlideElements(value);
      setFocusOnSelectSlide(list.length - 1);
      return setSliderValue(list.length - 1);
    }
    // hideLeftSlideElements(value);
    setFocusOnSelectSlide(value);
    setSliderValue(value);
  };

  React.useEffect(() => {
    // hideLeftSlideElements(list.length);
  }, [sliderValue]);

  return (
    <div id="marketing_carousel" className="marketing_carousel">
      <Carousel
        value={sliderValue}
        slidesPerPage={3}
        infinite
        addArrowClickHandler
        draggable={false}
        offset={
          size.width >= 1600
            ? -20
            : size.width >= 1500
            ? -75
            : size.width >= 1450
            ? -55
            : size.width >= 1400
            ? -45
            : size.width >= 1350
            ? -190
            : size.width >= 1250
            ? -140
            : size.width >= 1200
            ? -130
            : size.width >= 1100
            ? -100
            : size.width >= 1000
            ? -130
            : size.width >= 950
            ? -90
            : size.width >= 900
            ? -80
            : size.width >= 850
            ? -60
            : size.width >= 800
            ? -45
            : size.width >= 750
            ? -30
            : size.width >= 700
            ? -10
            : size.width >= 600
            ? 10
            : size.width >= 550
            ? 50
            : size.width >= 500
            ? 70
            : size.width >= 450
            ? 120
            : size.width >= 425
            ? 140
            : size.width >= 400
            ? 170
            : size.width >= 350
            ? 220
            : 260
        }
        breakpoints={{
          2100: {
            slidesPerPage: 3,
          },
          1400: {
            slidesPerPage: 2,
          },
        }}
      >
        {list.map((item, index) => {
          return (
            <MarketingCarouselContent
              key={index}
              id={item.id}
              name={item.name}
              type={item.type}
              comment={item.comment}
              ratings={item.ratings}
              imageUrl={item.imageUrl}
              title={item.title}
              setSelectSlide={value => handleSlideChange(value)}
              selectedSlide={sliderValue}
            />
          );
        })}
      </Carousel>
      <div className="marketing_carousel_dots">
        <div className="arrow_container">
          <button
            type="button"
            onClick={() => handleSlideChange(sliderValue - 1)}
            className="marketing_carousel_button_wrapper"
          >
            <img
              src="/assets/icons/right_carousel_arrow.png"
              alt="right_carousel_arrow"
              className="right_carousel_arrow"
              aria-hidden={true}
            />
            <div className="sr_only">Go to slider {sliderValue === -1 ? 3 : sliderValue - 1}</div>
          </button>
        </div>
        <div className="arrow_container">
          <button
            type="button"
            onClick={() => handleSlideChange(sliderValue + 1)}
            className="marketing_carousel_button_wrapper"
          >
            <img
              src="/assets/icons/left_carousel_arrow.png"
              alt="left_carousel_arrow"
              className="left_carousel_arrow"
              aria-hidden={true}
            />
            <div className="sr_only">Go to slider {sliderValue === 3 ? 1 : sliderValue + 1}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarketingDefaultCarousel;
