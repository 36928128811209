import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { learnersAPI } from '../../../API/learners';

function LearnerInfoCoin(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { subscription, learner, imageHeight, imageWidth } = props;

  const [userInfo, setUserInfo] = React.useState({});

  const getLearnerInfo = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    learnersAPI.GET.getLearnerCoursePoints(1, learner.id, token).then(response => {
      const { learner_info } = response.data;
      setUserInfo(learner_info[0]);
    });
  }, [getAccessTokenSilently, subscription, learner]);

  const getUserName = () => {
    const { user } = learner;
    if (!user) return '';
    if (user.name.includes('@')) return user.nickname;
    return user.name;
  };

  React.useEffect(() => {
    if (subscription) {
      getLearnerInfo();
    }
  }, [getLearnerInfo, subscription]);

  return (
    <div className="learner_info_wrapper">
      <div className="learner_info_content">
        <img
          src="/assets/images/learner_avatar.svg"
          alt="learner avatar"
          className="learner_token"
          style={{ height: imageHeight, width: imageWidth }}
        />
        <div className="learner_name">{getUserName()}</div>
        <div className="learner_points">{userInfo.points}</div>
      </div>
    </div>
  );
}

export default LearnerInfoCoin;
