import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Loading from '../../designSystem/components/interactiveElements/loading';

export default function ActionPage(props) {
  const {
    match: { params },
    subscription,
  } = props;
  const { loginWithRedirect, logout, user } = useAuth0();

  let user_type = false;

  if (user) {
    user_type = user['https://www.geniusforest.ca/user_type'];
  }

  React.useEffect(() => {
    if (params.action === 'login') return loginWithRedirect({ screen_hint: 'login' });
    if (params.action === 'logout')
      return logout({ returnTo: `${window.location.origin}/action-page/login` });
  }, [params, subscription, user, user_type]);


  if (subscription === null) return <LoadingPage {...props} />;

  if (subscription && user_type === 'parent') return <Redirect to="/my-students" />;

  if (subscription && user_type === 'learner') return <Redirect to="/my-practice" />;

  return <LoadingPage {...props} />;
}

const LoadingPage = props => {
  const { subscription } = props;
  const { user } = useAuth0();

  let user_type = false;

  if (user) {
    user_type = user['https://www.geniusforest.ca/user_type'];
  }

  if (subscription === null && user_type === 'parent') return <Redirect to="/plan-selection" />;

  if (subscription === null && user_type === 'learner') return <Redirect to="/ask-parent" />;

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Loading />
    </Grid>
  );
};
