import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

function ProgressCircle(props) {
  const useStylesFacebook = makeStyles(theme => ({
    root: {
      position: 'relative',
      marginTop: '5px',
    },
    bottom: {
      color: props.background,
    },
    top: {
      position: 'absolute',
      left: 0,
    },
    label: {
      color: props.text,
    },
  }));
  const classes = useStylesFacebook();
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="static"
        className={classes.bottom}
        size={props.size}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="static"
        className={classes.top}
        size={props.size}
        thickness={5}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <span className={ (Math.round(props.value)===100) ? `circle_progress_label_hundred ${classes.label}` : `circle_progress_label ${classes.label}`}>{`${Math.round(
          props.value,
        )}%`}</span>
      </Box>
    </Box>
  );
}

ProgressCircle.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
  size: PropTypes.number,
  background: PropTypes.string,
};
ProgressCircle.defaultProps = {
  size: 45,
  background: '#eeeeee',
};

export default function CircularStatic({ progress_value, size, background, text }) {
  return <ProgressCircle value={progress_value} size={size} background={background} text={text} />;
}

// * EXAMPLE *
//  <ProgressCircle progress_value={20} />;
