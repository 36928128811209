import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { Alert, AlertTitle } from '@material-ui/lab';

DefaultAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default function DefaultAlert({ open, severity, title, body }) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: 'fit-content',
      position: 'fixed',
      right: '10px',
      marginTop: '10px',
      top: '10px',
      zIndex: 2000,
      '& .MuiAlert-filledSuccess': {
        background: theme.palette.primary.hover,
        color: theme.palette.primary.text,
      },
      '& .MuiAlert-message': {
        fontFamily: theme.fontFamily.default,
        paddingBottom: '5px',
        color: theme.palette.primary.text,
      },
      '& .MuiAlertTitle-root ': {
        padding: '0px',
        marginBottom: '0px',
      },
      '& > * + *': {
        marginTop: theme.spacing(2),
        right: '-10px',
      },
    },
  }));

  const classes = useStyles();

  React.useEffect(() => {
    window.onscroll = function() {
      const alert = document.getElementById('default_alert');
      if (window.pageYOffset < 100 && alert) {
        alert.style.top = '80px';
      }
    };
  }, []);

  return (
    <div className={classes.root} id="default_alert">
      <Collapse in={open}>
        <Alert severity={severity} variant="filled">
          <AlertTitle>
            <b>{title}</b>
          </AlertTitle>
          {body}
        </Alert>
      </Collapse>
    </div>
  );
}

// Function to change the response value from the server
// const handleAlert = (severity, title, body) => {
//   setDefaultAlert({
//     open: true,
//     severity,
//     title,
//     body,
//   })
//   setTimeout(() => {
//     setDefaultAlert({
//       open: false,
//       severity,
//       title,
//       body,
//     })
//   }, 5000)
// }

// Calling the function
// handleAlert('success', response.data.title, response.data.body)

// Severities
// "error"
// "warning"
// "info"
// "success"
