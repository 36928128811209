import React from 'react';
import './accountSettings.scss';
import { Grid } from '@material-ui/core';
import Loading from '../../../designSystem/components/interactiveElements/loading';
import { useAuth0 } from '@auth0/auth0-react';
import AccountSettingsParent from './components/accountSettingsParent';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import AccountSettingsStudent from './components/accountSettingsStudent';
import DefaultAlert from '../../../designSystem/components/alerts/defaultAlert';
import { settingsAPI } from '../../../API/settings';
import { imageUploadAPI } from '../../../API/imageUpload';

export default function AccountSettings(props) {
  const { subscription, handleUpdateData } = props;
  const { user, getAccessTokenSilently, logout, loginWithPopup } = useAuth0();
  const { history } = useHistory();
  let { tab } = useParams();
  const [selectedTab, setSelectedTab] = React.useState(tab);
  const [isLoading, setIsLoading] = React.useState(false);
  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  React.useEffect(() => {
    if (selectedTab !== tab) {
      return setSelectedTab(tab);
    }
  }, [selectedTab, tab]);

  const getNewToken = async () => {
    await getAccessTokenSilently({ ignoreCache: true });
  };

  const validateFileSize = files => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 100000000) {
        handleAlert(
          'error',
          'File size error',
          'File size is too large! Please select a file smaller than 100MB',
        );
        return false;
      }
    }
    return true;
  };

  const validateFileFormat = files => {
    for (let i = 0; i < files.length; i++) {
      if (
        files[i].type !== 'image/png' &&
        files[i].type !== 'image/jpeg' &&
        files[i].type !== 'image/gif'
      ) {
        handleAlert('error', 'File format error', 'Accepted file format:  jpeg, png, and gif');
        return false;
      }
    }
    return true;
  };

  const refreshData = async () => {
    await getNewToken();
    await handleUpdateData();
  };

  const fileSelectedHandler = async (event, data) => {
    setIsLoading(true);
    const { files } = event.target;

    const token = await getAccessTokenSilently();

    if (validateFileSize(files) && validateFileFormat(files)) {
      imageUploadAPI.POST.uploadProfileImage(files[0], token, data.user_id, data.user_img)
        .then(async response => {
          handleAlert('success', 'Your image was successfully added');
          if (data.user_id === user.sub) {
            await getNewToken();
            await loginWithPopup();
          } else {
            await handleUpdateData();
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            const { title, body } = error.response.data;
            handleAlert('error', title, body);
          }
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const isLoggedUser = (data) => {
    return data.user_id === user.sub
  }

  const handleFormSubmit = async data => {
    setIsLoading(true);
    //{ ignoreCache: true }
    const token = await getAccessTokenSilently();
    await settingsAPI.POST.updateAccount(token, data)
      .then(async res => {
        if (isLoggedUser(data)) {
          if (data.form && data.form.email && user.email !== data.form.email) {
            return logout({ returnTo: `${window.location.origin}/action-page/login` });
          }
          if (data.form && data.form.password) {
            return logout({ returnTo: `${window.location.origin}/action-page/login` });
          }
          if (data.email) {
            return logout({ returnTo: `${window.location.origin}/action-page/login` });
          }
          if (data.password) {
            return logout({ returnTo: `${window.location.origin}/action-page/login` });
          }
        }
        await refreshData();
        handleAlert('success', 'Your account was successfully updated');
        setIsLoading(false);
      })
      .catch(err => {
        console.log('Error')
        console.log(err)
        handleAlert('error', 'Error', 'Account not successfully updated');
        setIsLoading(false);
      });
  };

  const handleAlert = (severity, title, body) => {
    setDefaultAlert(
      {
        open: true,
        severity,
        title,
        body,
      },
      setTimeout(() => {
        setDefaultAlert({
          open: false,
          severity,
          title,
          body,
        });
      }, 5000),
    );
  };

  const HandleTabSelect = () => {
    if (!selectedTab && user.user_type !== 'student') {
      return (
        <AccountSettingsParent
          subscription={subscription}
          fileSelectedHandler={fileSelectedHandler}
          handleFormSubmit={handleFormSubmit}
          isLoading={isLoading}
        />
      );
    } else if (selectedTab) {
      for (let i = 0; i < subscription.learners.length; i++) {
        if (subscription.learners[i].user.user_id === selectedTab) {
          return (
            <AccountSettingsStudent
              subscription={subscription.learners[i]}
              fileSelectedHandler={fileSelectedHandler}
              handleFormSubmit={handleFormSubmit}
              isLoading={isLoading}
            />
          );
        }
      }
    }
    return (
      <AccountSettingsParent
        subscription={subscription}
        fileSelectedHandler={fileSelectedHandler}
        handleFormSubmit={handleFormSubmit}
        isLoading={isLoading}
      />
    );
  };

  if (!subscription) return <LoadingPage />;

  return (
    <div className="accountSettings_wrapper">
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      <HandleTabSelect {...props} />
    </div>
  );
}

const LoadingPage = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Loading />
    </Grid>
  );
};
