import axios from 'axios';
import url from './url';

export const learnersAPI = {
  GET: {
    getLearnersBySubscriptionID(subscription_id, token) {
      return axios.get(`${url}api/getLearnersBySubscriptionID/${subscription_id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    getBeatenStudents(skill_id, learner_id, learner_points, token) {
      return axios.get(`${url}api/getBeatenStudents/${skill_id}/${learner_id}/${learner_points}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    getLearnerCoursePoints(course_id, learner_id, token) {
      return axios.get(`${url}api/getLearnerCoursePoints/${course_id}/${learner_id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
  POST: {
    addExistingStudent(data, token) {
      return axios.post(`${url}api/addExistingStudent`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    addNewStudent(data, token) {
      return axios.post(`${url}api/addNewStudent`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    validateLearnerEmail(data, token) {
      return axios.post(`${url}api/validateLearnerEmail`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
};
