import React from 'react';
import { Grid } from '@material-ui/core';
import DefaultDropdown from '../../../designSystem/components/dropdowns/dropdown';

export default function McuDropdowns(props) {
  const default_list = [
    {
      label: 'Option 1',
      value: 'option 1',
    },
    {
      label: 'Option 2',
      value: 'option 2',
    },
    {
      label: 'Option 3',
      value: 'option 3',
    },
  ];

  const [defaultItem, setDefaultItem] = React.useState('option 1');
  return (
    <Grid container className="designSystem_section-wrapper">
      <Grid item xs={12} className="designSystem-sections">
        <h2 className="title">Dropdowns</h2>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <h2 className="subTitle">Default Dropdown</h2>
          </Grid>
          <Grid item xs={12} className="designSystem-elements">
            <DefaultDropdown list={default_list} selectedItem={defaultItem} handleChange={(value) => setDefaultItem(value.target.value)} width={160} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
