import React from 'react';
import ReactGoogleAnalytics from 'react-ga';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@material-ui/core';
import GradeSelection from './components/gradeSelection';
import SecondaryTabs from '../../designSystem/components/tabs/secondaryTab';
import MyPlan from './components/myPlan';
import LockedSubscription from '../../designSystem/components/dialogs/lockedSubscription';
import ReinforceSkills from './components/reinforceSkills';
import { subjectsAPI } from '../../API/subjects';
import { coursesAPI } from '../../API/courses';
import Loading from '../../designSystem/components/interactiveElements/loading';

import './myPractice.scss';

export default function MyPractice(props) {
  const { subscription, match, history } = props;
  const { getAccessTokenSilently, logout, user } = useAuth0();
  const getLearner = () => {
    if (!subscription) return false;
    let result;
    subscription.learners.map(learner => {
      if (learner.user_id === user.sub) return (result = learner);
    });
    return result;
  };

  const checkSub = () => {
    return subscription.status != 'active' || learner.status === false
  }

  const handleClose = () => {
    history.push('/')
  }

  const handleConfirm = () => {
    logout({ returnTo: `${window.location.origin}/action-page/login` });
  }

  const learner = getLearner();
  const { tab } = match.params;

  const [selectedTab, setSelectedTab] = React.useState(tab ? parseInt(tab, 10) : 0);
  const settingsTabs = [
    { label: 'My Plan', value: 0 },
    { label: 'Reinforce', value: 1 },
  ];
  const [subjects, setSubjects] = React.useState();
  const [selectedSubject, setSelectedSubject] = React.useState(null);

  const [course, setCourse] = React.useState();

  const handleTab = (event, value) => {
    setSelectedTab(value);
    history.push(`/my-practice/${value}`);
  };

  const handleSelectSubject = (subject, index) => {
    setSelectedSubject(subject);
  };

  const getCourses = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    coursesAPI.GET.getCourseByID(token, selectedSubject.course_id)
      .then(response => {
        console.log(response);
        setCourse(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  });

  const getSubjects = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    subjectsAPI.GET.getSubjectsByLearnerID(token, 1, learner.id)
      .then(response => {
        setSubjects(response.data);
        setSelectedSubject(response.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }, [subscription, getAccessTokenSilently, learner.id]);

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    const fetchSubjectsData = async () => {
      await getSubjects();
    };
    if (subscription) {
      fetchSubjectsData();
    }
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
  }, [getSubjects, subscription]);

  React.useEffect(() => {
    const fetchCourseData = async () => {
      await getCourses();
    };
    if (selectedSubject) {
      fetchCourseData();
    }
  }, [selectedSubject, subjects]);

  if (!subscription) return <LoadingPage />;

  return (
    <>
      <div>
        <LockedSubscription open={checkSub()} handleClose={handleClose} handleConfirm={handleConfirm} />
        <Grid item xs={12} className="myPractice_tab">
          <SecondaryTabs
            list={settingsTabs}
            selectedTab={selectedTab}
            handleSelectedTab={handleTab}
          />
        </Grid>
      </div>
      <div className="myPractice_wrapper page_wrapper">
        <Grid container>
          {course && (
            <div id="subject_description_container">
              <h2 style={{ textTransform: 'capitalize' }}>{course.topics.rows[0].name}</h2>
              <p>{course.topics.rows[0].description}</p>
            </div>
          )}
          <GradeSelection subjects={subjects} handleSelectSubject={handleSelectSubject} />
          {selectedTab === 0 && (
            <MyPlan selectedSubject={selectedSubject} learner={learner} {...props} />
          )}
          {selectedTab === 1 && (
            <ReinforceSkills selectedSubject={selectedSubject} learner={learner} {...props} />
          )}
        </Grid>
      </div>
    </>
  );
}

const LoadingPage = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Loading />
    </Grid>
  );
};