import React from 'react';
import '../accountSettings.scss';
import { Grid } from '@material-ui/core';
import Loading from '../../../../designSystem/components/interactiveElements/loading';
import DefaultAlert from '../../../../designSystem/components/alerts/defaultAlert';
import SettingsBoxStudent from './settingsBoxStudent';

export default function AccountSettingsStudent(props) {
  const { subscription, fileSelectedHandler, handleFormSubmit, isLoading } = props;

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert(
      {
        open: true,
        severity,
        title,
        body,
      },
      setTimeout(() => {
        setDefaultAlert({
          open: false,
          severity,
          title,
          body,
        });
      }, 5000),
    );
  };
  if (!subscription) return <LoadingPage />;
  return (
    <div className="accountSettings_wrapper">
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      <SettingsBoxStudent
        learner={subscription.user}
        subscription={subscription}
        handleAlert={handleAlert}
        fileSelectedHandler={fileSelectedHandler}
        handleFormSubmit={handleFormSubmit}
        isLoading={isLoading}
      />
    </div>
  );
}
const LoadingPage = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Loading />
    </Grid>
  );
};