import React from 'react';
import PaymentForm from '../../../designSystem/components/payments/sdkPaymentForm';

const PaymentDetails = ({ handleSubmitPayment, planInformation, paymentStatus }) => {
  return (
    <div className="payment_details_container">
      <h2 className='enter_details'>Enter Payment Details</h2>
      <PaymentForm
        handleSubmitPayment={handleSubmitPayment}
        planInformation={planInformation}
        paymentStatus={paymentStatus}
      />
    </div>
  );
};

export default PaymentDetails;
