import React from 'react';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import ContactUsForm from '../../designSystem/components/contactUsForm/contactUsForm';
import DefaultAlert from '../../designSystem/components/alerts/defaultAlert';
import { contactUsAPI } from '../../API/contactUs';
import './contactUs.scss';

function ContactUs({ history }) {
  const useFormMethods = useForm();
  const { handleSubmit, setValue, register } = useFormMethods;

  const [step, setStep] = React.useState(0);

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert({
      open: true,
      severity,
      title,
      body,
    });
    setTimeout(() => {
      setDefaultAlert({
        open: false,
        severity,
        title,
        body,
      });
    }, 5000);
  };

  const submitContactForm = data => {
    contactUsAPI.POST.submitContactForm(data)
      .then(response => {
        setStep(1);
        window.scrollTo({ top: 100, behavior: 'smooth' });
      })
      .catch(error => {
        console.log(error.response);
        const { data } = error.response;
        handleAlert('error', data.title, data.body);
      });
  };

  const handleFormSubmit = formData => {
    submitContactForm(formData);
  };

  React.useEffect(() => {
    register('role');
    setValue('role', 'parent');
    const goToTop = history.listen(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    return () => {
      goToTop();
    };
  }, []);

  return (
    <>
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      {step === 0 ? (
        <form onSubmit={handleSubmit(handleFormSubmit)} className="page_wrapper contact-us-bg">
          <ContactUsMain {...useFormMethods} />
        </form>
      ) : (
        <ThankYou />
      )}
    </>
  );
}

function ContactUsMain(props) {
  return (
    <Grid container className="contact-us-wrapper">
      <Grid item xs={12} lg={4} className="contact-us-top">
        <h1 className="title">Contact Us</h1>
        <h2 className="sub-title">
          Tell us a bit about yourself and we’ll get in touch as soon as we can.
        </h2>
        <img src="/assets/icons/mail.svg" alt="mail" className="mobile-mail-icon" />
      </Grid>
      <Grid item xs lg={8}>
        <ContactUsForm {...props} />
      </Grid>
    </Grid>
  );
}

function ThankYou(props) {
  return (
    <Grid container className="contact-us-thankyou">
      <Grid item xs={12} className="thankyou-wrapper">
        <h1 className="title">Thank you!</h1>
        <h2 className="sub-title">
          We’ve received your request and will get back to you shortly. If you don’t hear back from
          us, you can reach us directly at <b>contact@geniusforest.com</b>
        </h2>
        <div>
          <img src="/assets/icons/mail-big.svg" alt="mail" className="mail-icon-big" />
          <img src="/assets/icons/mail.svg" alt="mail" className="mobile-mail-icon" />
        </div>
      </Grid>
    </Grid>
  );
}

export default ContactUs;
