import React from 'react';
// import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
// import { useAuth0 } from '@auth0/auth0-react';
// import { topicsAPI } from '../../../../API/topics';
import Button from '../../../../designSystem/components/buttons/button';
// import PrimaryTab from '../../../../designSystem/components/tabs/primaryTab';

function TopicSelection(props) {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    selectedSubject,
    type,
    getTopics,
    handleAnalysisTopic,
    analysisTopics,
    selectedAnalysisTopic,
  } = props;

  React.useEffect(() => {
    const fetchTopicsData = async () => {
      await getTopics();
    };
    if (selectedSubject) {
      fetchTopicsData();
    }
  }, [getTopics, selectedSubject]);

  return (
    <Grid container className="topic_selection_wrapper">
      {/* <Grid item xs={12}>
        <PrimaryTab list={list} handleSelectedTab={handleSelectedTab} />
      </Grid> */}
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          className="selection_wrapper"
        >
          {(type === 'analysis') &&
            <Grid item className="topic_button_wrapper">
              <Button
                label="Overview"
                addId="overview"
                onClick={() => handleAnalysisTopic(0, 0)}
                width={xsScreen ? 150 : 158}
                height={xsScreen ? 49 : 59}
                buttonStyle="light"
                type="button"
                icon={selectedAnalysisTopic === 0 && '/assets/icons/active-green-icon.png'}
                active={selectedAnalysisTopic === 0}
              />
            </Grid>
          }
          {analysisTopics &&
            analysisTopics.map((topic, index) => (
              <Grid item key={topic.name} className="topic_button_wrapper">
                <Button
                  label={topic.name}
                  addId={topic.name}
                  onClick={() => handleAnalysisTopic(topic, index + 1)}
                  width={
                    xsScreen
                      ? topic.name === 'financial literacy'
                        ? 175
                        : 130
                      : topic.name === 'financial literacy'
                        ? 175
                        : 140
                  }
                  height={xsScreen ? 49 : 59}
                  buttonStyle="light"
                  type="button"
                  icon={selectedAnalysisTopic === index + 1 && '/assets/icons/active-green-icon.png'}
                  active={selectedAnalysisTopic === index + 1}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

TopicSelection.propTypes = {
  handleSelectTopic: PropTypes.func.isRequired,
};

export default withRouter(TopicSelection);
