import React from 'react';
import TextField from '../../../designSystem/components/textFields/textField';
import { Grid } from '@material-ui/core';
import '../FAQ.scss';
function SectionOne({ handleSearch, search }) {
  return (
    <div className="section_one_wrapper">
      <Grid container direction="column" xs={12} alignItems="center">
        <h1 className="textOne">How can we help?</h1>
        <div className="textDiv">
          <TextField
            id="FAQsearch"
            type="text"
            value={search}
            helperText=""
            icon
            width="100%"
            inputTextColor="black"
            backgroundColor="white"
            className="FAQSearch"
            placeholder="Type keywords to find answers"
            handleChanges={handleSearch}
          />{' '}
        </div>{' '}
        <br />
        <span className="bottom_text"> Search for a question or browse the categories below</span>
      </Grid>
    </div>
  );
}

export default SectionOne;
