import React from 'react';
import { Grid } from '@material-ui/core';
import { dataUploadAPI } from '../../../API/data-upload';

function DataUpload(props) {
  const tsvToJson = tsv => {
    const [headers, ...rows] = tsv
      .trim()
      .split('\n')
      .map(r => r.split('\t'));
    return rows.reduce(
      (a, r) => [
        ...a,
        Object.assign(
          ...r.map((x, i, _, c = x.trim()) => ({ [headers[i].trim()]: isNaN(c) ? c : Number(c) })),
        ),
      ],
      [],
    );
  };

  const sendDataIndividually = data => {
    // let countTasks = [];
    // let difficult_level = 0;
    for (let i = 0; i < data.length; i++) {
      // data[i].difficult_level = difficult_level;
      // difficult_level += 1;
      // if (difficult_level === 5) {
      //   difficult_level = 0;
      // }
      dataUploadAPI
        .addQuestions(data[i])
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
      // TODO: to get the tasks
      // if (!countTasks.includes(data[i].question_url)) {
      //   countTasks.push(data[i].question_url);
      // }
    }
  };

  const handleQuestionUpload = event => {
    const selectedFile = event.target.files;
    const reader = new FileReader();
    reader.readAsText(selectedFile[0]);

    reader.onload = read => {
      const data = read.target.result;
      sendDataIndividually(tsvToJson(data));
    };
  };

  const handleQuestionHelperUpload = event => {
    const selectedFile = event.target.files;
    const reader = new FileReader();
    reader.readAsText(selectedFile[0]);

    reader.onload = read => {
      const data = read.target.result;
      dataUploadAPI
        .addQuestionHelper(tsvToJson(data))
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    };
  };

  const handleTasksUpload = event => {
    const selectedFile = event.target.files;
    const reader = new FileReader();
    reader.readAsText(selectedFile[0]);

    reader.onload = read => {
      const data = read.target.result;
      dataUploadAPI
        .addTasks(tsvToJson(data))
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    };
  };

  return (
    <Grid container className="page_wrapper">
      <Grid item xs={12}>
        <h3 className="body_text_1 max_width_600">Please, upload the questions TSV file:</h3>
        <input
          className="file-upload"
          type="file"
          name="dataUpload"
          accept=".tsv"
          onChange={handleQuestionUpload}
        />
        <h3 className="body_text_1 max_width_600">Please, upload the question helper TSV file:</h3>
        <input
          className="file-upload"
          type="file"
          name="dataUpload"
          accept=".tsv"
          onChange={handleQuestionHelperUpload}
        />
        <h3 className="body_text_1 max_width_600">Please, upload the Tasks TSV file:</h3>
        <input
          className="file-upload"
          type="file"
          name="dataUpload"
          accept=".tsv"
          onChange={handleTasksUpload}
        />
      </Grid>
    </Grid>
  );
}

export default DataUpload;
