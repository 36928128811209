import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

const Section_3 = () => {
  const cardData = [
    {
      id: 1,
      title: 'Get Rewarded for Hard Work',
      message:
        'We enhance students’ learning experiences by utlizing gamified learning and rewards systems like badges, points and certificates.',
      messageLineTwo:
        'Genius Forest motivates students to reach their goals faster, while feeling more satifised and accomplished when they make achievements.',
    },
    {
      id: 2,
      title: 'Fun & Interactive Questions',
      message:
        'Having more than one stimuli always helps with student engagement. In fact, it is considered to be vital in learning difficult topics. With Genius Forest, lessons are not just listed down for students to read. We made sure that each lesson stimulates more than one of the senses to help with learning and retention. Students can even participate from anywhere in the world.',
    },
    {
      id: 3,
      title: 'Keep Track of Progress',
      message:
        'Take advantage of our Dynamic Reporting to thoroughly analyze your strong points. Make use of our tailored practice plan option to create learning paths specifically designed to improve areas of weaknesses.',
      messageLineTwo: 'Keep track of your students’ progress to see where they need reinforcement.',
    },
    {
      id: 4,
      title: 'Evaluate your skills',
      message:
        'As the saying goes, “no two learners are the same”, it’s best that a student knows what level they are on for different topics.',
      messageLineTwo:
        'At GeniusForest, students can evaluate their current skill levels comprehensively to establish a baseline and assess where to start.',
    },
    {
      id: 5,
      title: 'Focuses on Ontario Curriculum Rubric',
      message:
        'In this age and time, we know that there are a lot of learning platforms out there, but one thing that GeniusForest is really proud of is that our skill outline is designed to meet the standards set by the Ontario Curriculum Rubric.',
      messageLineTwo:
        'Rest assured that we designed our lessons according to the skills required from a student to qualify for specific levels.',
    },
  ];

  const [cardImage, setCardImage] = useState(1);

  const buttonHandler = buttonNumber => {
    setCardImage(buttonNumber);
  };

  useEffect(() => {
    document.getElementById('section_three_card_image').classList.remove('active');
    document.getElementById('section_three_card_content').classList.remove('active');
    document.getElementById('section_three_card_image').classList.add('active');
    document.getElementById('section_three_card_content').classList.add('active');
    let timer1 = setTimeout(() => {
      document.getElementById('section_three_card_image').classList.remove('active');
      document.getElementById('section_three_card_content').classList.remove('active');
    }, 800);
    return () => {
      clearTimeout(timer1);
    };
  }, [cardImage]);

  return (
    <div className="section_3_wrapper">
      <h2>How We Help Accelerate Learning</h2>
      <div className="section_three_card_container">
        {/* <div > */}
        {cardImage === 1 && (
          <img
            src="/assets/images/section_three_engage_card.svg"
            alt="section_three_engage_card"
            className="section_three_card_image"
            id="section_three_card_image"
          />
        )}
        {cardImage === 2 && (
          <img
            src="/assets/images/section_three_interact_card.svg"
            alt="section_three_interact_card"
            className="section_three_card_image"
            id="section_three_card_image"
          />
        )}
        {cardImage === 3 && (
          <img
            src="/assets/images/section_three_report_card.svg"
            alt="section_three_report_card"
            className="section_three_card_image"
            id="section_three_card_image"
          />
        )}
        {cardImage === 4 && (
          <img
            src="/assets/images/section_three_diagnostic_card.svg"
            alt="section_three_diagnostic_card"
            className="section_three_card_image"
            id="section_three_card_image"
          />
        )}
        {cardImage === 5 && (
          <img
            src="/assets/images/section_three_curriculum_card.svg"
            alt="section_three_curriculum_card"
            className="section_three_card_image"
            id="section_three_card_image"
          />
        )}
        {/* </div> */}
        <div className="section_three_card_content" id="section_three_card_content">
          <h2>
            {cardImage === 1
              ? cardData[0].title
              : cardImage === 2
              ? cardData[1].title
              : cardImage === 3
              ? cardData[2].title
              : cardImage === 4
              ? cardData[3].title
              : cardData[4].title}
          </h2>
          <p>
            {cardImage === 1
              ? cardData[0].message
              : cardImage === 2
              ? cardData[1].message
              : cardImage === 3
              ? cardData[2].message
              : cardImage === 4
              ? cardData[3].message
              : cardData[4].message}
          </p>
          <p>
            {cardImage === 1
              ? cardData[0].messageLineTwo
              : cardImage === 2
              ? cardData[1].messageLineTwo
              : cardImage === 3
              ? cardData[2].messageLineTwo
              : cardImage === 4
              ? cardData[3].messageLineTwo
              : cardData[4].messageLineTwo}
          </p>
        </div>
      </div>
      <div className="dotted_lines_container">
        <div className="dotted_wrapper">
          <img
            src="/assets/images/section_three_dotted_lines.png"
            alt="section_three_dotted_lines"
            className="section_three_dotted_lines"
          />
          <div className="section_three_buttons_wrapper">
            <div>
              <div className="button_image_container">
                <button
                  type="button"
                  onClick={() => {
                    buttonHandler(1);
                  }}
                  onFocus={() => {
                    buttonHandler(1);
                  }}
                  className="section_three_button_wrapper"
                >
                  <img
                    src="/assets/images/section_three_engage_button.png"
                    alt="section_three_engage_button"
                    className="section_three_buttons active"
                    aria-hidden={true}
                  />
                  <div className="button_shadow" id="engage_shadow"></div>
                  <div className="sr_only">Engage, {cardData[cardImage - 1].message}</div>
                </button>
              </div>
              <p>Engage</p>
            </div>
            <div>
              <div className="button_image_container">
                <button
                  type="button"
                  onClick={() => {
                    buttonHandler(2);
                  }}
                  onFocus={() => {
                    buttonHandler(2);
                  }}
                  className="section_three_button_wrapper"
                >
                  <img
                    src="/assets/images/section_three_interact_button.png"
                    alt="section_three_interact_button"
                    className="section_three_buttons"
                    aria-hidden={true}
                  />
                  <div className="sr_only">Interact, {cardData[cardImage - 1].message}</div>
                  <div className="button_shadow" id="interact_shadow"></div>
                </button>
              </div>
              <p>Interact</p>
            </div>
            <div>
              <div className="button_image_container">
                <button
                  type="button"
                  onClick={() => {
                    buttonHandler(3);
                  }}
                  onFocus={() => {
                    buttonHandler(3);
                  }}
                  className="section_three_button_wrapper"
                >
                  <img
                    src="/assets/images/section_three_report_button.png"
                    alt="section_three_report_button"
                    className="section_three_buttons"
                    aria-hidden={true}
                  />
                  <div className="button_shadow" id="report_shadow"></div>
                  <div className="sr_only">Report, {cardData[cardImage - 1].message}</div>
                </button>
              </div>
              <p>Report</p>
            </div>
            <div>
              <div className="button_image_container">
                <button
                  type="button"
                  onClick={() => {
                    buttonHandler(4);
                  }}
                  onFocus={() => {
                    buttonHandler(4);
                  }}
                  className="section_three_button_wrapper"
                >
                  <img
                    src="/assets/images/section_three_diagnostic_button.png"
                    alt="section_three_diagnostic_button"
                    className="section_three_buttons"
                    aria-hidden={true}
                  />
                  <div className="button_shadow" id="diagnostic_shadow"></div>
                  <div className="sr_only">Diagnostic, {cardData[cardImage - 1].message}</div>
                </button>
              </div>
              <p>Diagnostic</p>
            </div>
            <div>
              <div className="button_image_container">
                <button
                  type="button"
                  onClick={() => {
                    buttonHandler(5);
                  }}
                  onFocus={() => {
                    buttonHandler(5);
                  }}
                  className="section_three_button_wrapper"
                >
                  <img
                    src="/assets/images/section_three_curriculum_button.png"
                    alt="section_three_curriculum_button"
                    className="section_three_buttons"
                    aria-hidden={true}
                  />
                  <div className="button_shadow" id="curriculum_shadow"></div>
                  <div className="sr_only">Curriculum, {cardData[cardImage - 1].message}</div>
                </button>
              </div>
              <p>Curriculum</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_3;
