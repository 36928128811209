import React from 'react';
import { Grid } from '@material-ui/core';
import Moment from 'react-moment';
import Button from '../../buttons/button';
import './membershipCards.scss';

export default function MembershipCards(props) {
  const { picture, name, date, plan, handleCancel, handleResume, handleDetails, handleAddStudent, learner } = props;

  return (
    <Grid container className="membership_card_wrapper" tabIndex="0">
      <Grid item xs={12} className="top_section">
        <div className="information_wrapper">
          {picture ? (
            <img src={picture} alt="student" className="picture" />
          ) : (
            <button type="button" className="no_picture_wrapper" onClick={handleAddStudent}>
              <img
                src="/assets/icons/add_student.svg"
                alt="student"
                className="no_picture"
                aria-hidden={true}
              />
              <div className="sr_only">Add new student.</div>
            </button>
          )}
          <div className="name">{name}</div>
        </div>
      </Grid>
      <Grid item xs={12} className="bottom_section">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <p>
              {learner.scheduled_cancellation && date !== "FREE" && learner.status && (
                <>Membership ends on</>
              )}
              {!learner.status && (
                <>Membership paused</>
              )}
              {!learner.scheduled_cancellation && learner.status && date !== "FREE" && (
                <>Membership renews on</>
              )}
              {date === "FREE" && (
                <>Free subscription plan.</>
              )
              }
            </p>

            {learner.status && date !== "FREE" && date !== "FREE" && (
              <div className="date">{<Moment date={date} format="MMM D, YYYY" />}</div>
            )}

            <button type="button" onClick={handleDetails} className="more_details">
              View premium benefits
            </button>
          </Grid>
          <Grid item>
            <div className="price_wrapper">
              {date !== "FREE" ? (
                <>
                  <span className="sign">$</span>
                  <span className="price">{plan.toString() === '1' ? '9.99' : '89.91'}</span>
                  <span className="plan">/{plan.toString() === '1' ? 'month' : 'Annual'}</span>
                </>
              ) : (
                <span className="price">FREE</span>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingTop: '25px' }}
        >
          {learner.scheduled_cancellation || !learner.status ? (
            <Button
              label="Resume premium"
              onClick={handleResume}
              width={175}
              height={40}
              fontSize={14}
              buttonStyle="secondary"
              type="button"
            />
          ) : date === "FREE" ? (
            <></>
          ) : (
            <Button
              label="Cancel premium"
              onClick={handleCancel}
              width={175}
              height={40}
              fontSize={14}
              buttonStyle="default"
              type="button"
            />
          )}

        </Grid>
      </Grid>
    </Grid >
  );
}
