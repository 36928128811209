import React from 'react';
import ReactGoogleAnalytics from 'react-ga';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Button from '../../designSystem/components/buttons/button';
// import ProgressCircle from '../../../master-control-ui/components/interactive-elements/progress-circle';

function DiagnosticWelcome({ history, topic }) {
  const [questionNumber] = React.useState(topic.skills.length * 5);

  const calculateMinTime = () => {
    return 0.3 * questionNumber;
  };

  const calculateMaxTime = () => {
    return 0.6 * questionNumber;
  };

  const handleGetStarted = () => {
    history.push(`/diagnostic-demo/${topic.id}/${2}`);
  };

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {/* Top content */}
      <Grid container className="page_wrapper top_step_indicator">
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <h1 className="diagnostic_step_title">Step 1 : Get Started</h1>
          </Grid>
        </Grid>
      </Grid>

      {/* Welcome Box */}
      <div className="page_wrapper m_bottom_5">
        <div className="welcome_wrapper">
          <Grid container className="diagnostic_welcome_box">
            <div className="grade_number">
              <span className="grade_label">Grade</span>
              <span className="number">{topic.subject[0].id}</span>
            </div>
            <Grid item xs={12}>
              <h2 className="title">Welcome to Diagnostics Test</h2>
              <h3 className="topic">{topic.name}</h3>
              <h4 className="description">
                These results helps you identify your weak points in a subject, determining the
                perfect starting point. The demo version is not the full version, sign up today to
                try out the full diagnostics tool!
              </h4>
              <h5 className="timeline">
                {questionNumber} questions <span className="dot">&#183;</span> {calculateMinTime()}{' '}
                - {calculateMaxTime()} minutes
              </h5>
            </Grid>
            <Grid item xs={12} className="button_wrapper">
              <Button
                label="Let’s Go"
                addId="lets_go_button"
                onClick={handleGetStarted}
                width={96}
                height={44}
                buttonStyle="primary"
                type="button"
              />
            </Grid>
            <img
              alt="box_image"
              src="/assets/images/diagnostic_welcome_image.png"
              className="box_image"
            />
          </Grid>
        </div>
      </div>
    </>
  );
}

export default withRouter(DiagnosticWelcome);

DiagnosticWelcome.propTypes = {
  topic: PropTypes.objectOf(PropTypes.any).isRequired,
};
