import React from 'react';
import { useParams } from 'react-router-dom';
import TextField from '../../../../designSystem/components/textFields/textField';
import Button from '../../../../designSystem/components/buttons/button';
import DefaultDropdown from '../../../../designSystem/components/dropdowns/dropdown';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { settingsAPI } from '../../../../API/settings';
import { learnersAPI } from '../../../../API/learners';
import { subjectsAPI } from '../../../../API/subjects';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={150} ref={ref} {...props} />;
});


export default function AddNewStudent(props) {
  const [default_list, setDefaultList] = React.useState([]);
  const { subscription } = props;
  const [grade, setGrade] = React.useState('');
  const { getAccessTokenSilently } = useAuth0();
  const [isError, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const history = useHistory();
  const [selectedAddStudent, setSelectedAddStudent] = React.useState(addStudent);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('501'));
  const [open, setOpen] = React.useState(true);
  let { addStudent } = useParams();

  React.useEffect(() => {
    subjectsAPI.GET.getSubjectsByCourseID(1).then(res => {
      for (let i = 0; i < res.data.rows.length; i++) {
        setDefaultList(default_list => [
          ...default_list,
          {
            value: res.data.rows[i].id,
            label:
              res.data.rows[i].name[0].toUpperCase() + res.data.rows[i].name.slice(1).toLowerCase(),
          },
        ]);
      }
    }, []);
  }, []);

  const checkGrade = () => {
    if (grade === '') {
      setError(true);
      setErrorMessage('No grade selected');
      return false;
    }
  };
  const handleGradeSelect = e => {
    setError(false);
    setGrade(e.target.value);
  };

  const handleClick = () => {
    checkGrade();
    handleSubmit(handleFormSubmit);
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const handleFormSubmit = async formData => {
    setIsLoading(true);
    const data = {
      form: formData,
      grade: grade,
      subscription,
    }
    const token = await getAccessTokenSilently();

    const result = await learnersAPI.POST.addNewStudent(data, token)
    if (result.status === 200) {
      history.push('/my-students')
      history.go(0);
      setIsLoading(false)
    } else {
      console.log(result.error);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      history.push('/my-students/');
    }, 150);
  }
  const useFormMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormMethods;


  React.useEffect(() => {
    if (selectedAddStudent !== addStudent) {
      return setSelectedAddStudent(addStudent);
    }
  }, [selectedAddStudent, addStudent]);

  const checkValue = async value => {
    const data = {
      email: value,
    };
    const token = await getAccessTokenSilently();
    const response = await settingsAPI.POST.validateSettingsEmail(data, token);
    return response;
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      disableScrollLock
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
          position: 'unset',
          margin: 'unset',
        },
      }}
    >
      <DialogContent className='addNewDialog'>
        <div className="addNew">
          <img src="/assets/images/add_existing_trees.png" className="tree_image" />
          <button
            role="button"
            className="interactive_element close_button"
            onClick={handleClose}
          >
            <img
              src="/assets/icons/close_icon.png"
              width={smScreen ? 11 : 15}
              height={smScreen ? 11 : 15}
            ></img>
          </button>
          <span className="addNewText">Create student account</span>
          <div className="newStudentInfo">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <TextField
                id="studentName"
                placeholder="Name"
                name="name"
                type="studentName"
                width={smScreen ? 250 : 390}
                marginBottom={0}
                requiredField
                helperText={errors.name ? errors.name.message : ''}
                error={!!errors.name}
                register={register({
                  required: ' Name is required',
                  minLength: { value: 2, message: 'Min length 2' },
                  maxLength: { value: 30, message: 'Max length 30' },
                  pattern: {
                    value: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/i,
                    message: 'Can only contain letters and numbers.',
                  },
                })}
              />
              <TextField
                id="studentEmail"
                placeholder="Enter your email"
                type="text"
                name="email"
                width={smScreen ? 250 : 390}
                requiredField
                helperText={errors.email ? errors.email.message : ''}
                error={!!errors.email}
                autocomplete={false}
                register={register({
                  required: 'Email is required',
                  maxLength: { value: 100, message: 'Max length 100' },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email format',
                  },
                  validate: {
                    value: async value => {
                      const res = await checkValue(value);
                      if (res.status !== 200) {
                        return res.data.message;
                      } else {
                        return true;
                      }
                    },
                  },
                })}
              />
              <TextField
                id="studentPassword"
                placeholder="Password"
                name='password'
                type='password'
                width={smScreen ? 250 : 390}
                requiredField
                helperText={errors.password ? errors.password.message : ''}
                error={!!errors.password}
                autocomplete={false}
                register={register({
                  required: 'Password is required',
                  minLength: { value: 8, message: 'Min length 8' },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i,
                    message: 'At least one Special character (!@#$%^&*), Lowercase, uppercase, and numbers ',
                  }
                })}
              />
              <DefaultDropdown
                list={default_list}
                name="studentGrade"
                disabled={false}
                selectedItem={grade}
                error={isError}
                helperText={isError ? errorMessage : ''}
                handleChange={handleGradeSelect}
                width={smScreen ? 250 : 390}
                label="Current grade level"
                height={50}
                requiredField
                register={register({
                  required: 'Grade is required',
                })}
              />
              <div className="addNewButton">
                <Button
                  buttonStyle="primary"
                  label="Next"
                  height={50}
                  marginTop={20}
                  width={smScreen ? 250 : 390}
                  type="submit"
                  onClick={handleClick}
                  disable={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
