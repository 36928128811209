import axios from 'axios';
import url from './url';

export const questionsAPI = {
  POST: {
    addQuestion(data, token) {
      console.log(data);
      return axios.post(`${url}api/admin/addquestion`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
  GET: {
    getFilteredQuestions(token) {
      return axios.get(`${url}api/admin/questions`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    getQuestionById(question_id, token) {
      return axios.get(`${url}api/admin/question/${question_id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    getQuestionPreview(skill_id) {
      return axios.get(`${url}api/admin/question/preview/${skill_id}`);
    },
  },
  PUT: {
    editQuestion(question_id, level_assigned, data, token) {
      return axios.put(`${url}api/admin/update-question/${question_id}/${level_assigned}`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
};
