import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../../designSystem/components/buttons/button';
import { diagnosticToolAPI } from '../../API/diagnosticTool';
import './practicePortal.scss';

function PracticePortal({ history, match, handleChangeLayout, subscription }) {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { getAccessTokenSilently, user } = useAuth0();

  const getLearner = () => {
    if (!subscription) return false;
    let result;
    subscription.learners.map(learner => {
      if (learner.user_id === user.sub) return (result = learner);
    });
    return result;
  };

  const learner = getLearner();

  const { topic_id } = match.params;

  const [questionNumber, setQuestionNumber] = React.useState();

  const getSkills = React.useCallback(async () => {
    const { topic_id } = match.params;
    const token = await getAccessTokenSilently();

    await diagnosticToolAPI.GET.getDiagnosticSkills(topic_id, learner.id, token)
      .then(response => {
        const { skills } = response.data;
        setQuestionNumber(skills.length * 10);
      })
      .catch(error => {
        console.log(error);
      });
  }, [getAccessTokenSilently]);

  const calculateMinTime = () => {
    return 1.5 * questionNumber;
  };

  const calculateMaxTime = () => {
    return 2.0 * questionNumber;
  };

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    getSkills();
    handleChangeLayout({
      header: {
        css: 'header_dark',
      },
      footer: {
        css: 'hide_element',
      },
    });
    return () => {
      handleChangeLayout('reset');
    };
  }, [getSkills]);

  return (
    <Grid container className="practice_portal_wrapper">
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="page_wrapper practice_portal_body"
        >
          <Grid item className="practice_portal_content">
            <h1 className="title">Welcome to Diagnostics Test</h1>
            <h2 className="sub_title">
              These results helps you identify your weak points in a subject, determining the
              perfect starting point. Try the diagnostic tool now.
            </h2>
            <h3 className="Info_text">
              {questionNumber} questions{' '}
              <img
                src={`/assets/icons/${smScreen ? 'list_white_icon.svg' : 'list_black_icon.svg'}`}
                alt="black icon"
                aria-hidden={true}
                className="separation_icon"
              />{' '}
              {calculateMinTime()} - {calculateMaxTime()} minutes
            </h3>
            <div style={{ marginBottom: smScreen ? '5px' : '10px' }}>
              <Button
                label="Let's go"
                addId="diagnose_button"
                onClick={() => history.push(`/diagnostic-tool/${topic_id}`)}
                width={mdScreen ? 100 : 182}
                height={mdScreen ? 35 : 44}
                buttonStyle="primary"
                type="submit"
              />
            </div>
            <Button
              label="Skip"
              addId="skip_button"
              onClick={() => history.push(`/practice/${topic_id}`)}
              width={mdScreen ? 100 : 182}
              height={mdScreen ? 35 : 44}
              buttonStyle="default"
              type="submit"
              addClass="skip_button"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PracticePortal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      topic_id: PropTypes.string,
    }),
  }),
};

export default PracticePortal;
