import React from 'react';
import Button from '../../../../designSystem/components/buttons/button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { useTheme, useMediaQuery } from '@material-ui/core';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={150} ref={ref} {...props} />;
});


export default function AcceptDialog(props) {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('601'));
  const { open, handleClose, form, handleFormSubmit } = props;

  const handleButtonClick = async formData => {
    await handleFormSubmit(form);
    document.getElementById('password_dialog').style.display = 'block';
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      disableScrollLock
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
          position: 'unset',
          margin: 'unset',
        },
      }}
    >
      <DialogContent className='acceptDialogDialog'>
        <div className="acceptDialog">
          <img src="/assets/images/add_existing_trees.png" className="tree_image" />
          <button
            role="button"
            className="interactive_element close_button"
            onClick={handleClose}
          >
            <img
              src="/assets/icons/close_icon.png"
              width={smScreen ? 11 : 15}
              height={smScreen ? 11 : 15}
            ></img>
          </button>
          <span className="acceptButtonText">A new login is required.</span>
          <span className="acceptButtonSubText">You will need to login using your new credentials.</span>
          <div className='newStudentInfo'>
            <div className="changePwButton">
              <Button
                buttonStyle="primary"
                label="Confirm and logout"
                height={50}
                marginTop={20}
                width={smScreen ? 250 : 350}
                type="submit"
                addClass='acceptButton'
                onClick={handleButtonClick}
              //   disable={isLoading}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
} 