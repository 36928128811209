import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import Button from '../../../../../designSystem/components/buttons/button';

function TopicSelection(props) {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { topics, selectedTopicIndex, handleTopic } = props;

  // const handleTopic = (topic, index) => {
  //   handleSelectTopic(topic);
  //   setSelectedTopicIndex(index);
  // };

  // console.log(selectedTopicIndex);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={2}
      className="selection_wrapper"
    >
      {/* <Grid item className="topic_button_wrapper">
        <Button
          label="Overview"
          addId="overview"
          onClick={() => handleTopic('all', 'all')}
          width={xsScreen ? 130 : 140}
          height={xsScreen ? 49 : 59}
          buttonStyle="light"
          type="button"
          icon={
            selectedTopicIndex === 'all'
              ? '/assets/icons/active-green-icon.png'
              : '/assets/icons/regular_button_list_icon.png'
          }
          active={selectedTopicIndex === 'all'}
        />
      </Grid> */}
      {topics &&
        topics.map((topic, index) => (
          <Grid item key={topic.name} className="topic_button_wrapper">
            <Button
              label={topic.name}
              addId={topic.name}
              onClick={() => handleTopic(topic, index)}
              width={
                xsScreen
                  ? topic.name === 'financial literacy'
                    ? 175
                    : 130
                  : topic.name === 'financial literacy'
                    ? 175
                    : 140
              }
              height={xsScreen ? 49 : 59}
              buttonStyle="light"
              type="button"
              icon={
                selectedTopicIndex === index
                  ? '/assets/icons/active-green-icon.png'
                  : '/assets/icons/regular_button_list_icon.png'
              }
              active={selectedTopicIndex === index}
            />
          </Grid>
        ))}
    </Grid>
  );
}

TopicSelection.propTypes = {
  handleSelectTopic: PropTypes.func.isRequired,
  selectedTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  topics: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.any)]).isRequired,
};

export default withRouter(TopicSelection);
