import React from 'react';
import Moment from 'react-moment';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import './billingCard.scss';

export default function BillingCard(props) {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { date, amount, last_four, card_expiration, handleHistory, handleEditCard, handleAddPaymentMethod } = props;

  return (
    <Grid container className="billing_card_wrapper" tabIndex="0">
      <Grid item xs={12}>
        <h1>Billing</h1>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction={mdScreen ? 'column-reverse' : 'row'}
          justifyContent={mdScreen ? 'flex-end' : 'space-between'}
          alignItems={mdScreen ? 'flex-start' : 'center'}
        >
          <Grid item>
            <div className="charge_date">
              Your account will be charged <b>${amount}</b> on{' '}
              <b>{<Moment date={date} format="YYYY-MM-DD" />}</b>.
            </div>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <button type="button" className="view_history" onClick={handleHistory}>
                View History
              </button>
            </Grid>
          </Grid>
          <Grid item className="billing_card">
            <div className="card_wrapper">
              <div>
                <img src="/assets/icons/Card-Flags.svg" alt="card" className="card_image" />
              </div>
              {
                console.log(last_four)
              }
              <div>
                <h3>{last_four ? (
                  <>Your card ending in {last_four}</>
                ) : (
                  <button type="button" className="view_history" onClick={handleAddPaymentMethod}>
                    Add Payment Method
                  </button>
                )}</h3>
                <h4>{card_expiration && (
                  <>Expires: {card_expiration}</>
                )}</h4>
              </div>
            </div>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              {last_four && (
                <button type="button" className="edit" onClick={handleEditCard}>
                  Edit
                </button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
