import React from 'react';
import { useParams } from 'react-router-dom';
import MyStudentEmpty from './components/myStudentEmpty';
import MyStudentFill from './components/myStudentFill';
import { useAuth0 } from '@auth0/auth0-react';
import AddExisting from './components/addExisting';
import AddNewStudent from './components/addNewStudent';
import AddStudent from './components/addStudent';
import { learnersAPI } from '../../../API/learners';
import { useHistory } from 'react-router-dom';
import './myStudents.scss';
import LockedSubscription from '../../../designSystem/components/dialogs/lockedSubscription';

export default function MyStudent(props) {
  const { getAccessTokenSilently, user } = useAuth0();
  const userType = user['https://www.geniusforest.ca/user_type'];
  const { subscription } = props;

  let { tab } = useParams();
  let history = useHistory();
  const [studentAccountMade, setStudentAccountMade] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(tab);

  const isLearnersAvailable = learners => {
    let result = false;
    learners.map(learner => {
      if (learner.user_id) return (result = true);
    });
    return result;
  };
  const checkSub = () => {
    return subscription.status !== 'active';
  };

  const handleClose = () => {
    history.push('/account/membership-and-billing');
  };

  const handleConfirm = () => {
    history.push('/account/membership-and-billing');
  };
  React.useEffect(() => {
    sessionStorage.clear();
    if (selectedTab !== tab) {
      return setSelectedTab(tab);
    }
    if (subscription.learners.length > 0) {
      setStudentAccountMade(true);
    } else {
      setStudentAccountMade(false);
    }
  }, [selectedTab, tab, subscription.learners]);

  const handleAddExistingStudent = async () => {
    const studentInfo = sessionStorage.getItem('studentInfo').split(',');
    const data = {
      subscription: subscription,
      student_email: studentInfo[0],
      student_name: studentInfo[1],
      student_grade: studentInfo[2],
    };
    const token = await getAccessTokenSilently();
    await learnersAPI.POST.addExistingStudent(data, token)
      .then(res => {
        sessionStorage.clear();
        history.push('/my-students/');
        history.go(0);
      })
      .catch(err => {
        console.log(err);
      });
  };
  function HandleStudentAccountMade() {
    if (studentAccountMade === true) {
      return <MyStudentFill subscription={subscription} />;
    } else {
      return <MyStudentEmpty subscription={subscription} />;
    }
  }

  function HandleTabChange() {
    if (!selectedTab) {
      return <HandleStudentAccountMade subscription={subscription} />;
    } else if (selectedTab === 'add-student') {
      return (
        <>
          <div className="student_page_wrapper blurElement">
            <HandleStudentAccountMade />
          </div>
          <AddStudent subscription={subscription} />
        </>
      );
    } else if (selectedTab === 'add-existing') {
      return (
        <>
          <div className="student_page_wrapper blurElement">
            <HandleStudentAccountMade />
          </div>
          <AddExisting handleAddExistingStudent={handleAddExistingStudent} />
        </>
      );
    } else if (selectedTab === 'add-new') {
      return (
        <>
          <div className="student_page_wrapper blurElement">
            <HandleStudentAccountMade />
          </div>
          <AddNewStudent subscription={subscription} />
        </>
      );
    } else {
      return <HandleStudentAccountMade />;
    }
  }
  return (
    <div className="student_page_wrapper">
      <LockedSubscription
        open={checkSub()}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
      <HandleTabChange />
    </div>
  );
}
