import axios from 'axios';
import url from './url';

export const topicsAPI = {
  GET: {
    getTopicByID(topic_id) {
      return axios.get(`${url}api/getTopicByID/${topic_id}`);
    },
    getTopicBySubjectID(subject_id) {
      return axios.get(`${url}api/getTopicBySubjectID/${subject_id}`);
    },
    getLearnerTopicsBySubjectID(token, subject_id, learner_id) {
      return axios.get(`${url}api/getLearnerTopicsBySubjectID/${subject_id}/${learner_id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    getTopicPracticeSummary(topic_id, learner_id, token) {
      return axios.get(`${url}api/getTopicPracticeSummary/${topic_id}/${learner_id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
};
