import React from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { subjectsAPI } from '../../../API/subjects';
import DefaultDropdown from '../../../designSystem/components/dropdowns/dropdown';

function SubjectSelection(props) {
  const { subscription, handleSelectSubject, status, width, learner } = props;
  const { getAccessTokenSilently } = useAuth0();

  const [subjects, setSubjects] = React.useState([]);
  const [subjectsList, setSubjectsList] = React.useState([]);
  const [selectedSubject, setSelectedSubject] = React.useState(0);

  const formateDropdown = (item, index) => {
    if (status === 'mobile') {
      return {
        label: item.id,
        value: index,
        disabled: false,
      };
    }
    return {
      label: item.name,
      value: index,
      disabled: false,
    };
  };

  const addSubjectsToList = React.useCallback(subjects => {
    const dropdownList = [];
    for (let i = 0; i < subjects.length; i++) {
      dropdownList.push(formateDropdown(subjects[i], i));
    }
    setSubjectsList(dropdownList);
  }, []);

  const getSubjects = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    subjectsAPI.GET.getSubjectsByLearnerID(token, 1, learner.id)
      .then(response => {
        addSubjectsToList(response.data);
        setSubjects(response.data);
        handleSelectSubject(response.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }, [
    subscription,
    getAccessTokenSilently,
    learner.id,
    addSubjectsToList,
    handleSelectSubject,
  ]);

  const handleSubject = index => {
    handleSelectSubject(subjects[index]);
    setSelectedSubject(index);
  };

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    const fetchSubjectsData = async () => {
      await getSubjects();
    };
    if (subscription) {
      fetchSubjectsData();
    }
  }, [getSubjects, subscription]);
  return (
    <Grid container className="subject_selection">
      <Grid item xs={12}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <DefaultDropdown
              list={subjectsList}
              selectedItem={selectedSubject}
              handleChange={value => handleSubject(value.target.value)}
              width={width}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SubjectSelection.propTypes = {
  handleSelectSubject: PropTypes.func.isRequired,
  subscription: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.any)]).isRequired,
};

export default withRouter(SubjectSelection);
