import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import DefaultDropdown from '../../../../designSystem/components/dropdowns/dropdown';
import TextField from '../../../../designSystem/components/textFields/textField';

const QuestionsFilter = ({
  filter,
  filters,
  handleFilter,
  selectedFilters,
  resetFilter,
  handleSearch,
  handleLevels,
  search,
  level,
}) => {
  const [courseList, setCourseList] = useState(filters.courses);
  const [subjectList, setSubjectList] = useState(filters.subjects);
  const [topicList, setTopicList] = useState(filters.topics);
  const [skillList, setSkillList] = useState(filters.skills);

  const handleReset = () => {
    resetFilter();
  };

  const subjectHandler = course_id => {
    if (!course_id) return;
    let listToReturn = [];
    for (let i = 0; i < filters.subjects.length; i++) {
      if (course_id.toString() === filters.subjects[i].course_id.toString()) {
        listToReturn.push(filters.subjects[i]);
      }
    }
    setSubjectList(listToReturn);
  };

  const topicHandler = subject_id => {
    if (!subject_id) return;
    let listToReturn = [];
    for (let i = 0; i < filters.topics.length; i++) {
      if (subject_id.toString() === filters.topics[i].subject_id.toString()) {
        listToReturn.push(filters.topics[i]);
      }
    }
    setTopicList(listToReturn);
    setSkillList([]);
  };

  const skillHandler = topic_id => {
    if (!topic_id) return;
    let listToReturn = [];
    for (let i = 0; i < filters.skills.length; i++) {
      if (topic_id.toString() === filters.skills[i].topic_id.toString()) {
        listToReturn.push(filters.skills[i]);
      }
    }
    setSkillList(listToReturn);
  };

  const statusList = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Assigned',
      value: 'assigned',
    },
    {
      label: 'Unassigned',
      value: 'unassigned',
    },
  ];

  React.useEffect(() => {
    subjectHandler(filter.course_id);
    topicHandler(filter.subject_id);
    skillHandler(filter.topic_id);
  }, [filter]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item className="m_bottom_2 ">
          <TextField
            id="search"
            label="Search"
            type="search"
            value={search}
            helperText=""
            error={false}
            handleChanges={handleSearch}
            borderColor="#ECECEC"
            width={160}
            height={45}
            icon
          />
        </Grid>
        <Grid item>
          <DefaultDropdown
            list={statusList}
            selectedItem={level}
            handleChange={value => handleLevels(value.target.value)}
            width={160}
            height={45}
            label="Status"
            id={'levels_dropdown'}
          />
        </Grid>
        <Grid item>
          {selectedFilters && (
            <DefaultDropdown
              list={courseList}
              selectedItem={selectedFilters.course_id}
              handleChange={value => handleFilter('course_id', value.target.value)}
              width={160}
              height={45}
              label="Course"
              id={'course_dropdown'}
            />
          )}
        </Grid>
        <Grid item>
          {selectedFilters.course_id && (
            <DefaultDropdown
              list={subjectList}
              selectedItem={selectedFilters.subject_id}
              handleChange={value => handleFilter('subject_id', value.target.value)}
              width={160}
              height={45}
              label="Subject"
            />
          )}
        </Grid>
        <Grid item>
          {selectedFilters.subject_id && (
            <DefaultDropdown
              list={topicList}
              selectedItem={selectedFilters.topic_id}
              handleChange={value => handleFilter('topic_id', value.target.value)}
              width={160}
              height={45}
              label="Topic"
            />
          )}
        </Grid>
        <Grid item>
          {selectedFilters.topic_id && (
            <DefaultDropdown
              list={skillList}
              selectedItem={selectedFilters.skill_id}
              handleChange={value => handleFilter('skill_id', value.target.value)}
              width={160}
              height={45}
              label="Skill"
            />
          )}
        </Grid>
        <Grid item>
          <button
            type="button"
            id="resetFilter"
            className="reset_filter"
            onClick={() => handleReset()}
          >
            <div id="resetIcon">
              <img src="/assets/icons/Vector.png" alt="reset icon" />
            </div>
            <p>Reset Filters</p>
          </button>
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionsFilter;
