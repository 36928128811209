import React from 'react';
import { Box } from '@mui/material';
import '../pricing.scss';
import { useHistory } from 'react-router-dom';
import Button from '../../../designSystem/components/buttons/button';

export default function SectionOne() {
  let history = useHistory();
  const buttonHandler = action => {
    if (action === 'enter') {
      document.getElementById('button_cover').classList.add('active');
      document.getElementById('button').classList.add('active');
    } else {
      document.getElementById('button_cover').classList.remove('active');
      document.getElementById('button').classList.remove('active');
    }
  };
  return (
    <Box container className="section_one_wrapper">
      <div style={{ overflow: 'hidden' }} className="section_one_image_container"></div>
      <div>
        <h2 className="freeTrial">Start your 30-day free trial</h2>
        <p className="just9">Then, just $9.99/month, per student.</p>
      </div>
      <div className="section_one_buttons_wrapper">
        <div
          className="sign_up_button_wrapper"
          onMouseEnter={() => {
            buttonHandler('enter');
          }}
          onMouseLeave={() => {
            buttonHandler('leave');
          }}
          onClick={() => history.push('/pre-signup')}
          style={{ cursor: 'pointer' }}
          onFocus={() => {
            buttonHandler('enter');
          }}
          onBlur={() => {
            buttonHandler('leave');
          }}
        >
          <div className="sign_up_button_cover" id="button_cover"></div>
          <button
            className="sign_up_button"
            id="button"
            onClick={() => history.push('/pre-signup')}
          >
            Sign up for free
          </button>
        </div>
        <Button
          label="Try live demo"
          width={149}
          height={57}
          fontSize={16}
          buttonStyle="default"
          onClick={() =>
            history.push(`/diagnostic-demo/${Math.floor(Math.random() * (5 - 1 + 1) + 1)}/1`)
          }
          addClass="try_demo"
        />
      </div>
      <div className="newMemberText">
        30-day free trial, valid for new members only. Cancel anytime.
      </div>
    </Box>
  );
}
