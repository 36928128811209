import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

const QuestionsTable = ({ questions, search }) => {
  const session_page = sessionStorage.getItem('questions_page');
  const session_rows = sessionStorage.getItem('questions_rows');

  const [page, setPage] = useState(session_page ? parseInt(session_page) : 0);
  const [rowsPerPage, setRowsPerPage] = useState(session_rows ? parseInt(session_rows) : 5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    sessionStorage.setItem('questions_page', newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    sessionStorage.setItem('questions_rows', event.target.value);
  };

  const columns = [
    { id: 'id', align: 'center', row_align: 'left', label: 'Id', maxWidth: 93 },
    { id: 'title', align: 'center', row_align: 'left', label: 'Title', maxWidth: 93 },
    {
      id: 'description',
      align: 'center',
      row_align: 'left',
      label: 'Description',
      maxWidth: 113.5,
    },
    { id: 'answer', align: 'center', row_align: 'center', label: 'Answer', maxWidth: 118 },
    {
      id: 'question_options',
      align: 'left',
      row_align: 'left',
      label: 'Question Options',
      maxWidth: 133.5,
    },
    {
      id: 'difficult_level',
      align: 'center',
      row_align: 'center',
      label: 'Difficult Level',
      maxWidth: 82,
    },
    { id: 'edit', align: 'center', row_align: 'left', maxWidth: 65.5 },
    { id: 'update_date', minWidth: 20, padding: 'none' },
  ];

  const calculateCurrentPage = () => {
    if (page * rowsPerPage + rowsPerPage > questions.length) return 0;
    return page;
  };

  React.useEffect(() => {
    if (search !== '') setPage(0);
  }, [search, setPage]);

  return (
    <>
      <Paper
        sx={{ width: 'fit-content', overflow: 'scroll', overflowX: 'auto' }}
        className="table_wrapper"
      >
        <TableContainer sx={{ minHeight: 679, minWidth: 1000 }}>
          <Table>
            <TableHead style={{ background: '#F8F8F8' }}>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      maxWidth: column.maxWidth,
                      paddingTop: '26px',
                      paddingBottom: '9px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                    padding={column.padding}
                  >
                    <p className="tableHeader">{column.label}</p>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {questions
                .slice(
                  calculateCurrentPage() * rowsPerPage,
                  calculateCurrentPage() * rowsPerPage + rowsPerPage,
                )
                .map(question => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={question.id}>
                      {columns.map(column => {
                        const value = question[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.row_align}
                            padding={column.padding}
                            style={{ position: 'relative' }}
                          >
                            <div
                              className="tableContent"
                              style={{ maxWidth: column.maxWidth, minWidth: column.minWidth }}
                            >
                              {column.format && typeof value === 'number' ? (
                                <p>{column.format(value)}</p>
                              ) : Array.isArray(value) ? (
                                value.map((element, index) => {
                                  if(element != null || element != undefined)
                                    return <p id="options">{`${index + 1}-${element.value}`}</p>;
                                  else
                                    return <p></p>
                                })
                              ) : column.id === 'edit' ? (
                                <Link
                                  to={`/admin-portal/question/${question.id}`}
                                  rel="noopener noreferrer"
                                  className="edit_question"
                                >
                                  <img src="/assets/icons/edit.svg" alt="edit icon" />
                                </Link>
                              ) : column.id === 'update_date' ? (
                                question.updated_at ? (
                                  <div id="updated"></div>
                                ) : (
                                  <div id="not_updated"></div>
                                )
                              ) : (
                                value
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 25, 50, 75, 100]}
          component="div"
          page={calculateCurrentPage()}
          rowsPerPage={rowsPerPage}
          count={questions.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default QuestionsTable;
