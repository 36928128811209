import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import Button from '../../../designSystem/components/buttons/button';

export default function ParentBox() {
  const { logout } = useAuth0();

  const handleParentLogin = () => {
    logout({ returnTo: `${window.location.origin}/action-page/login` });
  };

  return (
    <div className="info_box">
      <div className="text_box">
        <span className="ask_parent">Your parent must add your account under My Students</span>
        <span className="share_code">They will need your email address to do so!</span>
        <div className="info_box_button">
          <Button
            label="Parent log in"
            onClick={handleParentLogin}
            width={294}
            height={44}
            buttonStyle="primary"
            addClass="box_button"
          />
        </div>
        <Link to="/ask-parent/" className="parent_already_has interactive_element">
          My parent does not have an account
        </Link>
      </div>
      <img src="/assets/images/pricingillustration.png" className="info_box_img"></img>
    </div>
  );
}
//useState
