import React from 'react';
import TextField from '../../../../designSystem/components/textFields/textField';
import DefaultDropdown from '../../../../designSystem/components/dropdowns/dropdown';
import Button from '../../../../designSystem/components/buttons/button';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { subjectsAPI } from '../../../../API/subjects';

export default function AddExistingNameGrade(props) {
  const history = useHistory();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('501'));
  const { handleAddExistingStudent } = props;
  const useFormMethods = useForm();
  const [grade, setGrade] = React.useState('');
  const [default_list, setDefaultList] = React.useState([]);
  const [isError, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormMethods;

  React.useEffect(() => {
    if (sessionStorage.getItem('studentInfo') === null) {
      history.push('/my-students/add-existing/');
    }

    subjectsAPI.GET.getSubjectsByCourseID(1).then(res => {
      for (let i = 0; i < res.data.rows.length; i++) {
        setDefaultList(default_list => [
          ...default_list,
          {
            value: res.data.rows[i].id,
            label:
              res.data.rows[i].name[0].toUpperCase() + res.data.rows[i].name.slice(1).toLowerCase(),
          },
        ]);
      }
    }, []);
  }, []);

  const checkGrade = () => {
    if (grade === '') {
      setError(true);
      setErrorMessage('No grade selected');
      return false;
    }
  };

  const handleAddStudentButton = formData => {
    if (grade === '') {
      setError(true);
      setErrorMessage('No grade selected');
      return;
    }
    sessionStorage.setItem(
      'studentInfo',
      sessionStorage.getItem('studentInfo') + ',' + formData.name + ',' + grade,
    );
    handleAddExistingStudent();
  };

  const handleClick = () => {
    checkGrade();
    handleSubmit(handleAddStudentButton);
  };

  const handleGradeSelect = e => {
    setError(false);
    setGrade(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit(handleAddStudentButton)}>
      <div className="studentNameField">
        <TextField
          id="studentName"
          placeholder="Name"
          name="name"
          type="studentName"
          width={smScreen ? 250 : 390}
          marginBottom={0}
          requiredField
          helperText={errors.name ? errors.name.message : ''}
          error={!!errors.name}
          register={register({
            required: ' Name is required',
            minLength: { value: 2, message: 'Min length 2' },
            maxLength: { value: 30, message: 'Max length 30' },
            pattern: {
              value: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/i,
              message: 'Can only contain letters and numbers.',
            },
          })}
        />
        <DefaultDropdown
          list={default_list}
          name="studentGrade"
          disabled={false}
          selectedItem={grade}
          error={isError}
          helperText={isError ? errorMessage : ''}
          handleChange={handleGradeSelect}
          width={smScreen ? 250 : 390}
          label="Current grade level"
          height={50}
          requiredField
          register={register({
            required: 'Grade is required',
          })}
        />
        <div className="addStudent_button_div">
          <Button
            buttonStyle="primary"
            label="Add student"
            addClass="addStudentButton"
            height={50}
            width={smScreen ? 250 : 390}
            type="submit"
            onClick={handleClick}
          />
        </div>
      </div>
    </form>
  );
}
