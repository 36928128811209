import React from 'react';
import PropTypes from 'prop-types';
import './interactiveStyle.scss';

QuestionScore.propTypes = {
  score: PropTypes.number.isRequired,
};

export default function QuestionScore({ score }) {
  return (
    <div className="question_score">
      <div className="question_score_title">score</div>
      <div className="question_score_counter">{score} %</div>
    </div>
  );
}
