import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TopicSelection from './topicSelection';
import SkillsInformation from './skillsInformation';

function Analysis(props) {

  const { 
    selectedSubject, 
    skills, 
    getAllSkills, 
    handleSelectTopic, 
    // getTopics, 
    handleAnalysisTopic,
    analysisTopics,
    setAnalysisTopics,
    selectedAnalysisTopic,
    setSelectedAnalysisTopic,
    getTopics
   } = props;

  React.useEffect(() => {
    if (selectedSubject !== null) {
      getAllSkills(selectedSubject);
    }
  }, [getAllSkills, selectedSubject]);


  return (
    <Grid item xs={12} className="my_plan_wrapper">
      <TopicSelection 
        {...props} 
        handleSelectTopic={handleSelectTopic} 
        type={'analysis'}
        getTopics={getTopics}
        handleAnalysisTopic={handleAnalysisTopic}
        analysisTopics={analysisTopics}
        setAnalysisTopics={setAnalysisTopics}
        selectedAnalysisTopic={selectedAnalysisTopic}
        setSelectedAnalysisTopic={setSelectedAnalysisTopic}
        />
      <SkillsInformation {...props} skills={skills} />
    </Grid>
  );
}

Analysis.propTypes = {
  subscription: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.any)]).isRequired,
  selectedSubject: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.any)])
    .isRequired,
};

export default Analysis;
