import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@material-ui/core';
import QuestionsTable from './components/questionsTable';
import { questionsAPI } from '../../../API/questions';
import QuestionsFilter from './components/questionsFilter';
import Loading from '../../../designSystem/components/interactiveElements/loading';
import './questionsTable.scss';
import Button from '../../../designSystem/components/buttons/button';
import { useHistory } from 'react-router-dom';

function QuestionsList(props) {

  let history = useHistory();

  const addInitialFilter = name => {
    const session_filter = JSON.parse(sessionStorage.getItem('questions_filter'));
    if (name === 'course_id' && !session_filter) return '';
    if (session_filter) return session_filter[name] ? session_filter[name] : undefined;
  };

  const { getAccessTokenSilently } = useAuth0();
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [filters, setFilters] = useState();
  const [filter, setFilter] = useState({
    course_id: addInitialFilter('course_id'),
    subject_id: addInitialFilter('subject_id'),
    topic_id: addInitialFilter('topic_id'),
    skill_id: addInitialFilter('skill_id'),
  });
  const [search, setSearch] = React.useState('');
  const [originalSearch, setOriginalSearch] = React.useState([]);

  const [level, setLevel] = React.useState('all');

  const getQuestions = async () => {
    const token = await getAccessTokenSilently();
    await questionsAPI.GET.getFilteredQuestions(token)
      .then(response => {
        setQuestions(response.data.questions);
        setFilters(response.data.filter_options);
        setFilteredQuestions(response.data.questions);
        const session_filter = JSON.parse(sessionStorage.getItem('questions_filter'));
        setInitialLevel(response.data.questions);
        handleQuestions(response.data.questions, session_filter);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleFilter = (name, value) => {
    switch (name) {
      case 'course_id':
        setFilter(oldObject => ({
          ...oldObject,
          [name]: value,
          subject_id: '',
          topic_id: undefined,
          skill_id: undefined,
        }));
        filter.subject_id = '';
        break;
      case 'subject_id':
        setFilter(oldObject => ({
          ...oldObject,
          [name]: value,
          topic_id: '',
          skill_id: undefined,
        }));
        filter.topic_id = '';
        break;
      case 'topic_id':
        setFilter(oldObject => ({
          ...oldObject,
          [name]: value,
          skill_id: '',
        }));
        filter.skill_id = '';
        break;
      default:
        setFilter(oldObject => ({
          ...oldObject,
          [name]: value,
        }));
        break;
    }
    setLevel('all');
    sessionStorage.setItem('level_filter', 'all');
    filter[name] = value;
    sessionStorage.setItem('questions_filter', JSON.stringify(filter));
  };

  const setInitialLevel = data => {
    const session_level = sessionStorage.getItem('level_filter');
    if (session_level && session_level !== level && data.length > 0) {
      handleLevels(session_level, data);
    }
  };

  // Question dropdown filters
  const handleQuestions = (questions, filter) => {
    setSearch('');

    let arrayToRender = questions;
    if (filter.course_id && filter.subject_id && filter.topic_id && filter.skill_id) {
      arrayToRender = arrayToRender.filter(
        obj =>
          obj.course_id.toString() === filter.course_id.toString() &&
          obj.subject_id.toString() === filter.subject_id.toString() &&
          obj.topic_id.toString() === filter.topic_id.toString() &&
          obj.skill_id.toString() === filter.skill_id.toString(),
      );
    } else if (filter.course_id && filter.subject_id && filter.topic_id) {
      arrayToRender = arrayToRender.filter(
        obj =>
          obj.course_id.toString() === filter.course_id.toString() &&
          obj.subject_id.toString() === filter.subject_id.toString() &&
          obj.topic_id.toString() === filter.topic_id.toString(),
      );
    } else if (filter.course_id && filter.subject_id) {
      arrayToRender = arrayToRender.filter(
        obj =>
          obj.course_id.toString() === filter.course_id.toString() &&
          obj.subject_id.toString() === filter.subject_id.toString(),
      );
    } else if (filter.course_id) {
      arrayToRender = arrayToRender.filter(
        obj => obj.course_id.toString() === filter.course_id.toString(),
      );
    } else {
      return;
    }

    setFilteredQuestions(arrayToRender);
    setOriginalSearch(arrayToRender);
    setInitialLevel(arrayToRender);
  };

  // Question search textfield filter
  const handleSearch = event => {
    const { value } = event.target;
    setSearch(value);
    let arrayToRender =
      originalSearch.length > 0
        ? originalSearch
        : filteredQuestions.length > 0
        ? filteredQuestions
        : questions;
    setOriginalSearch(arrayToRender);
    arrayToRender = arrayToRender.filter(obj => {
      const title = obj.title ? obj.title.toString().toLowerCase() : '';
      const id = obj.id ? obj.id.toString().toLowerCase() : '';
      const description = obj.description ? obj.description.toString().toLowerCase() : '';

      return (
        title.includes(value.toString().toLowerCase()) ||
        id.includes(value.toString().toLowerCase()) ||
        description.includes(value.toString().toLowerCase())
      );
    });
    setFilteredQuestions(arrayToRender);
    if (value === '') {
      setFilteredQuestions(originalSearch.length > 0 ? originalSearch : questions);
    }
  };

  const handleLevels = (value, defaultData = false) => {
    setLevel(value);
    sessionStorage.setItem('level_filter', value);

    let arrayToRender =
      originalSearch.length > 0
        ? originalSearch
        : filteredQuestions.length > 0
        ? filteredQuestions
        : defaultData
        ? defaultData
        : questions;
    setOriginalSearch(arrayToRender);
    arrayToRender = arrayToRender.filter(obj => {
      const updated_at = obj.updated_at ? 'assigned' : 'unassigned';
      return value === updated_at;
    });
    setFilteredQuestions(arrayToRender);
    if (value === 'all') {
      setFilteredQuestions(originalSearch.length > 0 ? originalSearch : questions);
    }
  };

  const resetFilter = () => {
    handleFilter('skill_id', undefined);
    handleFilter('topic_id', undefined);
    handleFilter('subject_id', undefined);
    handleFilter('course_id', undefined);
    setSearch('');
    setLevel('all');
    setFilteredQuestions(questions);
    setOriginalSearch([]);
    sessionStorage.clear();
  };

  useEffect(() => {
    if (questions) {
      setInitialLevel(questions);
      handleQuestions(questions, filter);
    }
  }, [filter]);

  useEffect(() => {
    const fetchQuestionsData = async () => {
      await getQuestions();
    };
    fetchQuestionsData();
  }, []);

  console.log(filters);
  if (!questions || !filters) {
    return (
      <>
        <h1>Tests</h1>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className="pages_wrapper"
        >
          <Loading />
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className="question_table_top_container">
        <h1>Tests</h1>
        <Button
          label="Add new question"
          addId="add_question_button"
          width={200}
          height={44}
          buttonStyle="primary"
          type="submit"
          onClick={() => history.push('/admin-portal/addQuestion')}
        />
      </div>
      <QuestionsFilter
        filter={filter}
        filters={filters}
        handleFilter={handleFilter}
        selectedFilters={filter}
        resetFilter={resetFilter}
        handleSearch={handleSearch}
        search={search}
        handleLevels={handleLevels}
        level={level}
      />
      <QuestionsTable questions={filteredQuestions} search={search} />
    </>
  );
}

QuestionsList.propTypes = {};

export default QuestionsList;
