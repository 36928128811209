import React, { useState } from 'react';
import PricingCard from './pricingCard';
import '../pricing.scss';

export default function SectionTwo() {
  const [cardNum, setCardNum] = useState(0);
  function toggleEvent() {
    if (cardNum === 0) {
      setCardNum(1);
    } else {
      setCardNum(0);
    }
  }

  const cardContent = [
    {
      title: 'Free Trial Premium',
      price: '9.99',
      duration: 'month',

      cardList: [
        '30-day free trial',
        'Access to internal diagnostics',
        'Access to internal diagnostics',
        'Access to internal diagnostics',
      ],
    },
    {
      title: 'Free Trial',
      price: '89.91',
      duration: 'year',
      cardList: [
        '30-day free trial',
        'Access to internal diagnostics',
        'Access to internal diagnostics',
        'Access to internal diagnostics',
      ],
    },
  ];
  return (
    <div className="section_two_wrapper">
      <div className="switch_button">
        <input
          className="switch_button_checkbox"
          type="checkbox"
          onClick={e => toggleEvent()}
        ></input>
        <label className="switch_button_label" for="">
          <span className="switch_button_label_span">Monthly</span>
        </label>
      </div>
      <PricingCard cardContent={cardContent[cardNum]} />
      <img src="assets\images\pricingillustration.png" className="price_img"></img>
    </div>
  );
}
