import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../designSystem/components/buttons/button';
// import ButtonIconRound from '../../../master-control-ui/components/buttons/round-icon-button';

function DiagnosticDialog({ handleClose, isOpen, history, selectedTopic }) {
  const handleTryDemo = () => {
    history.push(`/diagnostic-demo/${selectedTopic.id}/1`);
    handleClose();
  };

  return (
    <div>
      <Dialog
        className="diagnostic_dialog"
        aria-labelledby="Diagnostic Tool Dialog"
        open={isOpen}
        maxWidth="md"
      >
        <Grid container className="step_body">
          <Grid item xs={12} className="top_content">
            <button
              className="button_icon interactive_element close_dialog"
              onClick={handleClose}
              tabIndex="0"
            >
              <span className="sr_only">Close Diagnostic Dialog</span>
              <img src="/assets/icons/close-icon-black.svg" alt="Genius Forest Logo" />
            </button>
            <Box className="diagnostic_dialog_logo">
              <img src="/assets/logos/logo.svg" alt="Genius Forest Logo" className="logo" />
            </Box>
          </Grid>
          <img
            alt="diagnostic"
            src="/assets/images/diagnostic_dialog_image.svg"
            className="diagnostic_image"
          />
          <Grid item xs={12}>
            <h1 className="title">Diagnostic Tool</h1>
          </Grid>
          <Grid item xs={12} className="body_item">
            <img
              alt="check icon"
              src="/assets/icons/diagnostic_dialog_check.svg"
              className="body_icon"
            />
            Track and Identify your Weaknesses
          </Grid>
          <Grid item xs={12} className="body_item">
            <img
              alt="check icon"
              src="/assets/icons/diagnostic_dialog_check.svg"
              className="body_icon"
            />
            Determine the perfect starting point
          </Grid>
          <Grid item xs={12} className="body_item">
            <img
              alt="check icon"
              src="/assets/icons/diagnostic_dialog_check.svg"
              className="body_icon"
            />
            Tailor course plan for your children
          </Grid>
          <Grid item xs={12} className="subTitle-mobile">
            Tailor your learning plan
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="steps_control"
        >
          <Grid container className="body_items_wrapper">
            <Grid item xs={12} className="body_item-mobile">
              <img
                alt="check icon"
                src="/assets/icons/diagnostic_dialog_check.svg"
                className="body_icon"
              />
              Track and Identify your Weaknesses
            </Grid>
            <Grid item xs={12} className="body_item-mobile">
              <img
                alt="check icon"
                src="/assets/icons/diagnostic_dialog_check.svg"
                className="body_icon"
              />
              Determine the perfect starting point
            </Grid>
            <Grid item xs={12} className="body_item-mobile">
              <img
                alt="check icon"
                src="/assets/icons/diagnostic_dialog_check.svg"
                className="body_icon"
              />
              Tailor course plan for your children
            </Grid>
          </Grid>
          {/* Removed from promotional website
           <ButtonIconRound
            arrow_direction="left"
            handleClick={() => {
              setCurrentStep(currentStep - 1);
            }}
            size={window.innerWidth < 520 ? 'sm' : 'md'}
          />
          {steps.map((item, index) => (
            <div className="step_navigation" key={index}>
              <button type="button" onClick={() => setCurrentStep(index)} className={item.value === currentStep ? 'navigation_item active' : 'navigation_item'}></button>
            </div>
          ))}
          <ButtonIconRound
            arrow_direction="right"
            handleClick={() => {
              setCurrentStep(currentStep + 1);
            }}
            size={window.innerWidth < 520 ? 'sm' : 'md'}
          /> */}
        </Grid>
        <div className="action_section">
          <Button
            label="Try Live Demo"
            addId="try_demo"
            onClick={handleTryDemo}
            width={150}
            height={44}
            buttonStyle="primary"
            type="button"
          />
        </div>
      </Dialog>
    </div>
  );
}

export default withRouter(DiagnosticDialog);

DiagnosticDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  selectedTopic: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]).isRequired,
};
