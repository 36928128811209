import axios from 'axios';
import url from './url';

export const diagnosticToolAPI = {
  GET: {
    getDiagnosticSkills(topic_id, learner_id) {
      return axios.get(`${url}api/getDiagnosticSkills/${topic_id}/${learner_id}`);
    },
    getDemoDiagnosticSkills(topic_id) {
      return axios.get(`${url}api/getDemoDiagnosticSkills/${topic_id}`);
    },
    getDiagnosticQuestion(skill_id, difficult_level) {
      return axios.get(`${url}api/getDiagnosticQuestion/${skill_id}/${difficult_level}`);
    },
    getDiagnosticBeatenStudents(skill_id, learner_id, learner_performance, token) {
      return axios.get(
        `${url}api/getDiagnosticBeatenStudents/${skill_id}/${learner_id}/${learner_performance}`,
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );
    },
  },
  POST: {
    saveDiagnosticTest(topic_id, learner_id, data, token) {
      return axios.post(`${url}api/saveDiagnosticTest/${topic_id}/${learner_id}`,
        {
          data
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
    },
  },
};
