import React from 'react';
import StarReviews from './starReviews';

const MarketingCarouselContent = ({
  id,
  name,
  type,
  comment,
  ratings,
  imageUrl,
  title,
  setSelectSlide,
  selectedSlide,
}) => {
  const handleSelectSlide = value => {
    setSelectSlide(value);
  };

  return (
    <div
      role="application"
      className={`review_slides review_slide_${id - 1}`}
      id={`review_slide_${id - 1}`}
      onClick={() => handleSelectSlide(id - 1)}
      aria-label={name + ', ' + type + ', ' + ratings + ' stars rating, ' + title + ', ' + comment}
      tabIndex={-1}
    >
      <div className="review_slides_content_wrapper">
        <div className="review_top_wrapper">
          <div className="review_top_container">
            <h2 style={{ margin: '0 0 0 0', whiteSpace: 'nowrap' }}>{name}</h2>
            <p style={{ margin: '0 0 0 0', whiteSpace: 'nowrap' }}>{type}</p>
            <div className="mobile_star">
              <StarReviews ratings={ratings} />
            </div>
          </div>
          <div className="destop_star">
            <StarReviews ratings={ratings} />
          </div>
        </div>
      </div>
      <h2 className="review_title">{title}</h2>
      <p className="review_comment">{comment}</p>
    </div>
  );
};

export default MarketingCarouselContent;
