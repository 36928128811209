import React from 'react';
import QuestionSelection from '../../../questionModels/questionSelection';
import { questionsAPI } from '../../../API/questions';
import Button from '../buttons/button';
import './questionPreview.scss';

function QuestionPreview({ skill }) {
  const [question, setQuestion] = React.useState();

  const getQuestion = React.useCallback(async () => {
    questionsAPI.GET.getQuestionPreview(skill.id)
      .then(response => {
        response.data.validation_control = { selected_answer: '' };
        setQuestion(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  });

  React.useEffect(() => {
    const fetchData = async () => {
      await getQuestion();
      const preview = document.getElementById('question_preview');
      if (preview)
        preview.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    };
    fetchData();
  }, [skill]);

  return (
    <div className="question_preview_wrapper">
      <div className="question_preview_body" id="question_preview">
        <h4 className="preview_title">Sample Question</h4>
        <QuestionSelection question={question} handleAnswer={(event, value) => {}} />
      </div>
    </div>
  );
}

export default QuestionPreview;
