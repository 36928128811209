import React from 'react';
import PropTypes from 'prop-types';
import ReactGoogleAnalytics from 'react-ga';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { subjectsAPI } from '../../../API/subjects';
import { topicsAPI } from '../../../API/topics';
import { practiceAPI } from '../../../API/practice';
import SkillGraph from './components/skillGraph';
import SkillGraphMobile from './components/skillGraphMobile';
import SkillInformation from './components/skillInformation';
import MoreInformation from './components/moreInformation';
// import EstimateGraph from './components/estimateGraph';
import TopicSelection from './components/topicSelection';
import Loading from '../../../designSystem/components/interactiveElements/loading';
import LearnerInfoCoin from '../../../designSystem/components/interactiveElements/learnerInfoCoin';
import './parentProgress.scss';

function ParentProgress(props) {
  const { subscription, history, match } = props;
  const { getAccessTokenSilently } = useAuth0();

  const getLearner = () => {
    if (!subscription) return false;
    if (subscription === null) return false;
    const { learner_id } = match.params;
    let result;
    subscription.learners.map(leaner => {
      if (learner_id.toString() === leaner.id.toString()) return (result = leaner);
    });
    return result;
  };

  const learner = getLearner();

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [subjects, setSubjects] = React.useState([]);
  const [selectedSubject, setSelectedSubject] = React.useState(null);

  const [topics, setTopics] = React.useState([]);
  const [selectedTopic, setSelectedTopic] = React.useState(null);

  const [skills, setSkills] = React.useState(false);
  const [selected_skill, setSelectedSkill] = React.useState(0);

  const handleSelectSubject = subject => {
    setSelectedSubject(subject);
  };

  const handleMoreInformation = () => {
    document.getElementById('more_information').scrollIntoView();
  };

  const getPracticeSkills = React.useCallback(
    async topic => {
      const token = await getAccessTokenSilently();
      await practiceAPI.GET.getPracticeSkills(topic.id, learner.id, token)
        .then(response => {
          const { skills } = response.data;
          setSkills(skills);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [getAccessTokenSilently, setSkills, learner.id],
  );

  const handleSelectTopic = React.useCallback(
    topic => {
      console.log(selectedTopic)
      setSelectedTopic(topic);
      getPracticeSkills(topic);
    },
    [getPracticeSkills],
  );

  const getTopics = React.useCallback(
    async selectedSubject => {
      const token = await getAccessTokenSilently();
      topicsAPI.GET.getLearnerTopicsBySubjectID(token, selectedSubject.id, learner.id)
        .then(response => {
          setTopics(response.data);
          handleSelectTopic(response.data[0]);
          getPracticeSkills(response.data[0]);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [getAccessTokenSilently, learner.id, handleSelectTopic, getPracticeSkills],
  );

  const getSubjects = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    subjectsAPI.GET.getSubjectsByLearnerID(token, 1, learner.id)
      .then(response => {
        setSubjects(response.data);
        handleSelectSubject(response.data[0]);
        getTopics(response.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }, [subscription, getAccessTokenSilently, learner.id, getTopics]);

  React.useEffect(() => {
    async function fetchData() {
      await getSubjects();
    }
    if (subscription) fetchData();
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
  }, [getSubjects, subscription]);

  if (!learner || !skills)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <>
      <Grid container className="page_wrapper progress_subject_wrapper"></Grid>
      <Grid container className="progress_report_wrapper">
        <Grid item xs={12} className="progress_top_content">
          <Grid container className="topic_selection_wrapper">
            {smScreen && <LearnerInfoCoin {...props} learner={learner} />}
            <Grid container alignItems="center" spacing={4}>
              {!smScreen && (
                <Grid item xs={1}>
                  <LearnerInfoCoin {...props} learner={learner} />
                </Grid>
              )}
              <Grid item xs>
                <TopicSelection
                  topics={topics}
                  selectedTopic={selectedTopic}
                  handleSelectTopic={handleSelectTopic}
                  subjects={subjects}
                  selectedSubject={selectedSubject}
                  handleSelectSubject={handleSelectSubject}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="top_report_wrapper">
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <h1 className="top_report_title">{skills.length > 0 && skills[0].topic_name}</h1>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} lg={6} className="report_body">
              <span className="performance_chart-desktop">
                <SkillGraph
                  active_index={selected_skill}
                  data={skills}
                  handleClick={value => setSelectedSkill(value)}
                  average={2500}
                />
              </span>
              <span className="performance_chart-mobile">
                <SkillGraphMobile
                  active_index={selected_skill}
                  data={skills}
                  handleClick={(data, value) => setSelectedSkill(value)}
                  average={2500}
                />
              </span>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SkillInformation active_index={selected_skill} skills={skills} learner={learner} />
              {/* 
              TODO: Removed from first MVP
              <div>
                <EstimateGraph />
              </div> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="text_center m_top_5 ">
          <button type="button" onClick={handleMoreInformation} className="display_more_button">
            More Information
            <div className="text_center m_top_1">
              <img
                alt="display more "
                src="/assets/icons/result_display_more.svg"
                aria-hidden={true}
              />
            </div>
          </button>
        </Grid>
      </Grid>

      <Grid container className="page_wrapper progress_report_more" id="more_information">
        <MoreInformation skills={skills} />
      </Grid>
    </>
  );
}

ParentProgress.propTypes = {
  subscription: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.any)]).isRequired,
};

export default ParentProgress;
