import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Box } from '@material-ui/core';
import { assets } from '../../utils/config';
import './preSignup.scss';

function Signup(props) {
  const { loginWithRedirect, user } = useAuth0();
  const { match, history } = props;

  const [parentImage, setParentImage] = React.useState(false);
  const [learnerImage, setLearnerImage] = React.useState(false);
  const [signupObject, setSignupObject] = React.useState({
    screen_hint: 'signup',
    user_type: 'parent',
  });

  const handleImage = name => {
    if (name === 'learner') return setLearnerImage(!learnerImage);
    if (name === 'parent') return setParentImage(!parentImage);
  };

  React.useEffect(async () => {

    if (window.location.href.includes('Unsupported')) {
      loginWithRedirect({ screen_hint: 'login', prevent_sign_up: true });
    }

    if (match.params.auto_login === 'google') {
      signupObject.auto_login = 'google';
      setSignupObject(oldObject => ({
        ...oldObject,
        auto_login: 'google',
      }));
    }

    if (match.params.auto_login === 'facebook') {
      signupObject.auto_login = 'facebook';
      setSignupObject(oldObject => ({
        ...oldObject,
        auto_login: 'facebook',
      }));
    }

    if (user) {
      history.push('/onboarding');
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });

    window.fbq('track', 'InitiateCheckout');
  }, [match.params.auto_login, user]);

  return (
    <Grid container className="signup_wrapper">
      <Grid item xs={12} md={8} lg={6}>
        <img
          src={`${assets.url}/registration-signup-desktop.svg`}
          alt="signup information"
          className="back_image"
        />
      </Grid>
      <Grid item xs={12} sm className="tablet_image_wrapper">
        <img
          src={`/assets/images/sign-image-table.svg`}
          alt="signup information"
          className="tablet_image"
        />
        <img
          src={`/assets/images/sign-image-tablet-2.svg`}
          alt="signup information"
          className="tablet_image_2"
        />
      </Grid>
      <Grid item xs={12} sm md={3} lg={6}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box className="sign_up_box">
            <h1 className="sign_up_title">Sign Up</h1>
            <p className="sign_up_box_subtext">Create your free account as a...</p>
            <button
              className="account_type_button"
              type="button"
              onClick={() => loginWithRedirect(signupObject)}
              onMouseEnter={() => handleImage('parent')}
              onMouseLeave={() => handleImage('parent')}
              onFocus={() => handleImage('parent')}
              onBlur={() => handleImage('parent')}
            >
              <img
                src={
                  parentImage
                    ? `${assets.url}/registration-parent-icon-shadow.png`
                    : `${assets.url}/registration-parent-icon.png`
                }
                alt="parent icon"
                className={`account_type_icon ${parentImage && 'parent_shadow_icon'}`}
              />
              <p className="account_type_label">Parent</p>
            </button>
            <button
              className="account_type_button"
              type="button"
              onClick={() =>
                loginWithRedirect({
                  screen_hint: 'signup',
                  user_type: 'learner',
                })
              }
              onMouseEnter={() => handleImage('learner')}
              onMouseLeave={() => handleImage('learner')}
              onFocus={() => handleImage('learner')}
              onBlur={() => handleImage('learner')}
            >
              <img
                src={
                  learnerImage
                    ? `${assets.url}/registration-student-icon-shadow.png`
                    : `${assets.url}/registration-student-icon.png`
                }
                alt="parent icon"
                className={`account_type_icon ${learnerImage && 'shadow_icon'}`}
              />
              <p className="account_type_label">Student</p>
            </button>
            <div className="sign_up_have_account">
              Already have an account?&nbsp;
              <button
                type="button"
                className="login_button"
                onClick={() => loginWithRedirect({ screen_hint: 'login' })}
              >
                Log in
              </button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Signup;
