import React from 'react';
import { Box } from '@material-ui/core';
import Accordion from './accordionUpdate';
import Button from '../../../designSystem/components/buttons/button';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router-dom';
import '../FAQ.scss';

export default function QuestionPage(props) {
  let { category } = useParams();
  let { push } = useHistory();

  const { data, search, handleSelectQuestion } = props;

  const getCategoryData = () => {
    let result = '';
    data.forEach(item => {
      if (item.label.toLowerCase() === category.toLowerCase()) {
        result = item;
      }
    });
    return result;
  };

  const renderQuestions = question => {
    if (question.label.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  return (
    <Box className="section_two_wrapper">
      <Link
        to="/faq"
        className="regular_link interactive_element"
        onClick={() => handleSelectQuestion('all')}
      >
        FAQ
      </Link>
      {category == 'PlansAndPricing' ? (
        <span className="category_label"> &gt; Plans &amp; Pricing </span>
      ) : (
        <span className="category_label">{` > ${category}`}</span>
      )}
      {category == 'PlansAndPricing' ? (
        <h1 className="category_label">Plans &amp; Pricing </h1>
      ) : (
        <h1 className="category_label">{category}</h1>
      )}
      {category &&
        getCategoryData().questions.map(question => (
          <>
            {renderQuestions(question) && (
              <Accordion key={question.label} question={question.label} answer={question.answer} />
            )}
          </>
        ))}
      <div className="bottomDiv-text">Still can't find what you're looking for?</div>
      <div className="bottomDiv">
        <Button
          label="Contact Us"
          addId="default"
          onClick={() => push('/contact-us')}
          width={144}
          height={56}
          buttonStyle="default"
          type="submit"
          fontSize={16}
        />
      </div>
    </Box>
  );
}
