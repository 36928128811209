import React from 'react'

const starReviews = (props) => {
  return (
    <div className="star_review_wrapper">
        {
            props.ratings >= 1 ? <img src='/assets/images/star_filled.png' alt='star_filled'/> : <img src='/assets/images/star_empty.png' alt='star_empty' />
        }
        {
            props.ratings >= 2 ? <img src='/assets/images/star_filled.png' alt='star_filled'/> : <img src='/assets/images/star_empty.png' alt='star_empty' />
        }
        {
            props.ratings >= 3 ? <img src='/assets/images/star_filled.png' alt='star_filled'/> : <img src='/assets/images/star_empty.png' alt='star_empty' />
        }
        {
            props.ratings >= 4 ? <img src='/assets/images/star_filled.png' alt='star_filled'/> : <img src='/assets/images/star_empty.png' alt='star_empty' />
        }
        {
            props.ratings >= 5 ? <img src='/assets/images/star_filled.png' alt='star_filled'/> : <img src='/assets/images/star_empty.png' alt='star_empty' />
        }
    </div>
  )
}

export default starReviews