import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import MembershipCards from '../../../designSystem/components/cards/membershipCards/membershipCards';
import BillingCard from '../../../designSystem/components/cards/billingCard/billingCard';
import DefaultAlert from '../../../designSystem/components/alerts/defaultAlert';
import Loading from '../../../designSystem/components/interactiveElements/loading';
import { paymentAPI } from '../../../API/payment';
import CancelMembership from './components/cancelMembership';
import ResumeMembership from './components/resumeMembership';
import EditPaymentMethod from './components/editPaymentMethod/editPaymentMethod';
import LockedSubscription from '../../../designSystem/components/dialogs/lockedSubscription';
import './membershipAndBilling.scss';
import Button from './../../../designSystem/components/buttons/button';

export default function MembershipAndBilling(props) {
  const { subscription, history, handleUpdateData } = props;
  const { getAccessTokenSilently } = useAuth0();

  const [data, setData] = React.useState(false);
  const [openCancelMembership, setOpenCancelMembership] = React.useState(false);
  const [openResumeMembership, setOpenResumeMembership] = React.useState(false);
  const [openEditPaymentMethod, setOpenEditPaymentMethod] = React.useState(false);
  const [selectedLearner, setSelectedLearner] = React.useState(null);
  const [openPausedSubscriptionAlert, setOpenPausedSubscriptionAlert] = React.useState(false);
  const [popUpOption, setPopUpOption] = useState(0);

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert(
      {
        open: true,
        severity,
        title,
        body,
      },
      setTimeout(() => {
        setDefaultAlert({
          open: false,
          severity,
          title,
          body,
        });
      }, 5000),
    );
  };

  const getUserName = learner => {
    if (!learner.user) return 'Add student';
    if (learner.user.name.includes('@')) return learner.user.nickname;
    return learner.user.name;
  };

  const createNewArray = size => {
    var data = [];
    for (var i = 0; i < size; i++) {
      data.push(false);
    }
    return data;
  };

  const getLearners = () => {
    if (subscription.learners && subscription.learners.length > 0) {
      return subscription.learners.concat(subscription.empty_learners);
    }
    return subscription.empty_learners;
  };

  const calculateNextBillingDate = date => {
    let billingDate = new Date(date);
    if (parseInt(subscription.subscription_plan) === 1) {
      billingDate.setMonth(billingDate.getMonth() + 1);
    } else {
      billingDate.setFullYear(billingDate.getFullYear() + 1);
    }
    return billingDate;
  };

  const isFreeTrial = () => {
    return (!data && !data[0]) || (data.cards && data.cards[0]);
  };

  const getNextBillingDate = () => {
    if (isFreeTrial()) return calculateNextBillingDate(subscription.created_at);
    if (subscription.payment_id === 'FREE') return 'FREE';
    return calculateNextBillingDate(data[0].createdAt);
  };

  const getCardNumber = () => {
    if (!data.cards) return false;
    if (!data.cards && !data[0].customer.cards) return false;
    if (isFreeTrial()) return data.cards[0].last4;
    return data[0].customer.cards[0].last4;
  };

  const getExpirationDate = () => {
    if (!data.cards) return false;
    if (!data.cards && !data[0].customer.cards) return false;
    if (isFreeTrial()) return data.cards[0].expYear;
    return data[0].customer.cards[0].expYear;
  };

  const getUserPicture = learner => {
    if (!learner.user) return false;
    return learner.user.picture;
  };

  const handleCancelSelectedMembership = learner => {
    setSelectedLearner(learner);
    setOpenCancelMembership(true);
  };

  const handleResumeSelectedMembership = learner => {
    setSelectedLearner(learner);
    setOpenResumeMembership(true);
  };

  const handleFinish = async (status, title, message) => {
    if (status === 'done') {
      setOpenCancelMembership(false);
      setOpenResumeMembership(false);
      setOpenEditPaymentMethod(false);
      await handleUpdateData();
      handleAlert('success', title, message);
    } else {
      setOpenCancelMembership(false);
      setOpenResumeMembership(false);
      setOpenEditPaymentMethod(false);
      handleAlert(
        'error',
        'Server error',
        'An internal error has occurred, please contact the support or try again later.',
      );
    }
  };

  const handleResumeSubscription = () => {
    setOpenPausedSubscriptionAlert(false);
    history.push('/account/membership-and-billing');
  };

  const handleAddStudent = () => {
    if (subscription.status === 'paused') return setOpenPausedSubscriptionAlert(true);
    return history.push('/my-students');
  };

  const planType = [
    {
      plan: 'Monthly',
      price: 9.99,
      duration: 'month',
    },
    {
      plan: 'Yearly',
      price: 89.91,
      duration: 'year',
    },
  ];

  const getNextBillingAmount = () => {
    const price = planType[parseInt(subscription.subscription_plan) - 1].price;
    const final_price = price * subscription.number_of_children;
    return final_price;
  };


  const handleAddMembership = () => {
    if (subscription.status === 'paused') return setOpenPausedSubscriptionAlert(true);
    history.push('/account/add-membership');
    sessionStorage.setItem('nextBillingDate', getNextBillingDate());
  };

  const handleEditPaymentMethod = () => {
    setOpenEditPaymentMethod(true);
    setPopUpOption(0);
  };

  const handleAddPaymentMethod = () => {
    setOpenEditPaymentMethod(true);
    setPopUpOption(0);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const fetchData = async () => {
      const token = await getAccessTokenSilently();
      await paymentAPI.POST.getUserInvoices(token, subscription)
        .then(response => {
          if (response.data.data) return setData(JSON.parse(response.data.data));
          setData(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    };
    if (subscription) return fetchData();
  }, [subscription]);

  if (!subscription || !data) return <LoadingPage />;

  return (
    <Grid container className="membership_billing_wrapper page_wrapper">
      <Grid item xs={12} className="main_box">
        <div className="header_box">
          <h1>Membership & Billing</h1>
          {getNextBillingDate() !== 'FREE' && (
            <div className="desk_add_member_button">
              <Button
                label="Add membership"
                width={147}
                height={44}
                buttonStyle="primary"
                onClick={handleAddMembership}
                fontSize={15}
                capitalize="none"
              />
            </div>
          )}
        </div>
        <h2>Premium Memberships</h2>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={6}>
          {getLearners().map(learner => (
            <Grid item>
              <MembershipCards
                picture={getUserPicture(learner)}
                name={getUserName(learner)}
                date={getNextBillingDate()}
                plan={subscription.subscription_plan}
                handleCancel={() => handleCancelSelectedMembership(learner)}
                handleResume={() => handleResumeSelectedMembership(learner)}
                handleDetails={() => history.push('/product')}
                handleAddStudent={handleAddStudent}
                learner={learner}
              />
            </Grid>
          ))}
        </Grid>
        <div className="mobile_add_member_button">
          <Button
            label="Add membership"
            width={332}
            height={44}
            buttonStyle="primary"
            onClick={handleAddMembership}
            fontSize={15}
            capitalize="none"
          />
        </div>
        {getNextBillingDate() !== 'FREE' && (
          <Grid item xs={12}>
            <div className="main_box_mobile">
              <h1>Billing</h1>
            </div>
            <BillingCard
              date={getNextBillingDate()}
              amount={getNextBillingAmount()}
              last_four={getCardNumber()}
              card_expiration={getExpirationDate()}
              handleHistory={() => history.push('/account/billing-history')}
              handleEditCard={handleEditPaymentMethod}
              handleAddPaymentMethod={handleAddPaymentMethod}
            />
          </Grid>
        )}
      </Grid>
      <CancelMembership
        open={openCancelMembership}
        handleClose={() => setOpenCancelMembership(false)}
        learner={selectedLearner}
        handleFinish={status =>
          handleFinish(status, 'Membership canceled', 'Membership successfully canceled.')
        }
        {...props}
      />
      <ResumeMembership
        open={openResumeMembership}
        handleClose={() => setOpenResumeMembership(false)}
        learner={selectedLearner}
        handleFinish={status =>
          handleFinish(status, 'Membership resumed', 'Membership successfully resumed.')
        }
        {...props}
      />
      <EditPaymentMethod
        open={openEditPaymentMethod}
        handleClose={() => setOpenEditPaymentMethod(false)}
        popUpOption={popUpOption}
        setPopUpOption={setPopUpOption}
        handleFinish={handleFinish}
        data={data}
        cardNumber={getCardNumber()}
        {...props}
      />
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      <LockedSubscription
        handleClose={() => setOpenPausedSubscriptionAlert(false)}
        open={openPausedSubscriptionAlert}
        handleConfirm={handleResumeSubscription}
        hideClose={false}
      />
    </Grid>
  );
}

const LoadingPage = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="pages_wrapper"
    >
      <Loading />
    </Grid>
  );
};
