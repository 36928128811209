import React from 'react';
import TextField from '../textFields/textField';
import { useForm } from 'react-hook-form';
import Button from '../../../designSystem/components/buttons/button';
import Loading from '../interactiveElements/loading';
import './payments.scss'

const SdkPaymentForm = ({ handleSubmitPayment, planInformation, paymentStatus }) => {
  const { register, handleSubmit, errors, setValue, getValues, setError, clearErrors } = useForm();

  const [disableButton, setDisableButton] = React.useState(false);

  const paymentStyle = {
    '.input-container.is-focus': {
      borderColor: '#31D196',
    },
  };

  const applicationId = process.env.REACT_APP_APPLICATION_ID;
  const locationId = process.env.REACT_APP_SQUARE_LOCATION_ID;

  async function initializeCard(payments) {
    const card = await payments.card({
      style: paymentStyle,
    });
    await card.attach('#card-container');
    return card;
  }

  // Call this function to send a payment token, buyer name, and other detail
  // This function tokenizes a payment method.
  // The ‘error’ thrown from this async function denotes a failed tokenization,
  // which is due to buyer error (such as an expired card). It is up to the
  // developer to handle the error and provide the buyer the chance to fix
  // their mistakes.
  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      throw new Error(errorMessage);
    }
  }

  // Helper method for displaying the Payment Status on the screen.
  // status is either SUCCESS or FAILURE;
  function displayPaymentResults(status) {
    const statusContainer = document.getElementById('payment-status-container');
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  }

  //loads the input field for credit card
  async function loaded() {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }
    const payments = window.Square.payments(applicationId, locationId);
    let card;
    try {
      card = await initializeCard(payments);
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }

    async function handlePaymentMethodSubmission(event, paymentMethod, full_name) {
      event.preventDefault();
      const cardButton = document.getElementById('card-button');

      try {
        cardButton.disabled = true;
        setDisableButton(true);
        const token = await tokenize(paymentMethod);
        console.log('token is: ' + token);
        displayPaymentResults('SUCCESS');

        await handleSubmitPayment(token, paymentMethod, full_name);
      } catch (e) {
        setDisableButton(false);
        cardButton.disabled = false;
        displayPaymentResults('FAILURE');
        console.error(e.message);
      }
    }

    const cardButton = document.getElementById('card-button');

    cardButton.addEventListener('click', async function (event) {
      let full_name = getValues('full_name');
      if (full_name === '' || full_name === undefined || full_name === null) {
        setError('full_name', { message: 'Full Name is required' });
      } else if (full_name.length < 2) {
        setError('full_name', { message: 'Minimum length must be 2 characters' });
      } else if (full_name.length > 30) {
        setError('full_name', { message: 'Maximum length must be 30 characters' });
      } else {
        clearErrors('full_name');
        await handlePaymentMethodSubmission(event, card, full_name);
      }
    });
  }

  React.useEffect(() => {
    if (paymentStatus === true) return setDisableButton(false);
    loaded();
  }, [paymentStatus]);

  const handleForm = (formData, e) => { };

  const onError = (errors, e) => {
    console.log(errors, e);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit(handleForm, onError)}>
        <TextField
          type="text"
          name="full_name"
          label="Name"
          helperText={errors.full_name ? errors.full_name.message : ''}
          error={errors.full_name && true}
          width="100%"
          register={register({
            required: 'Full Name is required',
            minLength: { value: 2, message: 'Min length 2' },
            maxLength: { value: 30, message: 'Max length 30' },
            pattern: {
              value: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/i,
              message: 'Can only contain letters and numbers.',
            },
          })}
          marginBottom="0px"
          borderColor="#D3D3D3"
        />
        <div id="card-container"></div>
        {planInformation}
        <div className="button_wrapper">
          {disableButton && (
            <Loading />
          )}
          <Button
            disable={disableButton}
            label="Get your free trial"
            width="100%"
            height={44}
            buttonStyle="primary"
            type="submit"
            addId="card-button"
          />

        </div>
      </form>
      <div id="payment-status-container"></div>
    </>
  );
};

export default SdkPaymentForm;
