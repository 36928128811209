import React from 'react';
import ReactGoogleAnalytics from 'react-ga';
import { useAuth0 } from '@auth0/auth0-react';
import Moment from 'react-moment';
import { Grid } from '@material-ui/core';
import { subjectsAPI } from '../../../API/subjects';
import { topicsAPI } from '../../../API/topics';
import { practiceAPI } from '../../../API/practice';
import TopicSelection from './components/topicSelection';
import PracticeSummary from './components/practiceSummary';
import PracticeActivity from './components/practiceActivity';
import Loading from '../../../designSystem/components/interactiveElements/loading';
import LearnerInfoCoin from '../../../designSystem/components/interactiveElements/learnerInfoCoin';
import NothingToShow from "../../../designSystem/components/messages/notFound/nothingToShow";
import './parentSummary.scss';

function ParentSummary(props) {
  const { subscription, history, match } = props;
  const { getAccessTokenSilently } = useAuth0();

  const getLearner = () => {
    if (!subscription) return false;
    if (subscription === null) return false;
    const { learner_id } = match.params;
    let result;
    subscription.learners.map(leaner => {
      console.log(leaner)
      if (learner_id.toString() === leaner.id.toString()) return (result = leaner);
    });
    return result;
  };

  const learner = getLearner();

  const [subjects, setSubjects] = React.useState([]);
  const [selectedSubject, setSelectedSubject] = React.useState(null);

  const [topics, setTopics] = React.useState(false);
  const [selectedTopic, setSelectedTopic] = React.useState(null);

  const [practiceSummary, setPracticeSummary] = React.useState(false);
  const [practiceActivities, setPracticeActivities] = React.useState(false);

  const handleSelectSubject = subject => {
    setSelectedSubject(subject);
  };

  const getTopicPracticeSummary = React.useCallback(
    async topic => {
      const token = await getAccessTokenSilently();

      await topicsAPI.GET.getTopicPracticeSummary(topic.id, learner.id, token)
        .then(response => {
          const { practice_summary } = response.data;
          setPracticeSummary(practice_summary);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [getAccessTokenSilently, learner.id],
  );

  const getTopicPracticeActivities = React.useCallback(
    async topic => {
      const token = await getAccessTokenSilently();

      await practiceAPI.GET.getPracticeActivities(topic.id, learner.id, token)
        .then(response => {
          const { practice_activities } = response.data;
          if (practice_activities.length > 0) {
            setPracticeActivities(practice_activities);
          } else {
            setPracticeActivities(false);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    [getAccessTokenSilently, learner.id],
  );

  const handleSelectTopic = React.useCallback(
    topic => {
      if (topic === 'all') {
        // getAllData();
      } else {
        setSelectedTopic(topic);
        getTopicPracticeSummary(topic);
        getTopicPracticeActivities(topic);
      }
    },
    [getTopicPracticeActivities, getTopicPracticeSummary],
  );

  const getTopics = React.useCallback(
    async selectedSubject => {
      const token = await getAccessTokenSilently();
      topicsAPI.GET.getLearnerTopicsBySubjectID(token, selectedSubject.id, learner.id)
        .then(response => {
          setTopics(response.data);
          handleSelectTopic(response.data[0]);
          getTopicPracticeSummary(response.data[0]);
          getTopicPracticeActivities(response.data[0]);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [
      getAccessTokenSilently,
      learner.id,
      getTopicPracticeActivities,
      getTopicPracticeSummary,
      handleSelectTopic,
    ],
  );

  const getSubjects = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    subjectsAPI.GET.getSubjectsByLearnerID(token, 1, learner.id)
      .then(response => {
        setSubjects(response.data);
        handleSelectSubject(response.data[0]);
        getTopics(response.data[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }, [subscription, getAccessTokenSilently, learner.id, getTopics]);

  React.useEffect(() => {
    async function fetchData() {
      await getSubjects();
    }
    if (subscription) fetchData();
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
  }, [getSubjects, subscription]);

  if (!learner || !practiceSummary)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <>
      {/* <Grid container className="page_wrapper summary_subject_wrapper">
        <SubjectSelection
          subjects={subjects}
          selectedSubject={selectedSubject}
          handleSelectSubject={handleSelectSubject}
        />
      </Grid> */}
      <Grid container className="summary_top_content">
        <Grid container className="topic_selection_wrapper">
          <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
            <Grid item>
              <LearnerInfoCoin {...props} learner={learner} />
            </Grid>
            <Grid item>
              <TopicSelection
                topics={topics}
                selectedTopic={selectedTopic}
                handleSelectTopic={handleSelectTopic}
                subjects={subjects}
                selectedSubject={selectedSubject}
                handleSelectSubject={handleSelectSubject}
                getTopicPracticeSummary={getTopicPracticeSummary}
                getTopicPracticeActivities={getTopicPracticeActivities}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="page_wrapper summary_body_wrapper">
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <h1 className="topic_name">{selectedTopic.name}</h1>
            </Grid>
            {practiceSummary[0] && (
              <Grid item className="date_wrapper">
                <Moment date={new Date(practiceSummary[0].created_at)} format="MMM DD, YYYY" />
                {' To '}
                <Moment
                  date={new Date(practiceSummary[practiceSummary.length - 1].created_at)}
                  format="MMM DD, YYYY"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {practiceActivities ? (
          <>
            <PracticeSummary practiceSummary={practiceSummary} />
            <PracticeActivity practiceActivities={practiceActivities} />
          </>
        ) : (
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className="pages_wrapper nothing_to_show"
          >
            <NothingToShow message="No activities for the selected topic yet. Keep practicing." />
          </Grid>
        )}
      </Grid>
    </>
  );
}
export default ParentSummary;
