import React from 'react';
import Button from '../../../designSystem/components/buttons/button';
import Cookies from 'js-cookie';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

export default function MainBox() {
  const { logout } = useAuth0();

  const handleLogout = () => {
    sessionStorage.clear();
    Cookies.remove('parent');
    Cookies.remove('learner');
    Cookies.remove('parent_selected_learner');
    logout({ returnTo: window.location.origin + '/pre-signup' });
  };
  
  return (
    <div className="info_box">
      <div className="text_box">
        <span className="ask_parent">Ask a parent to help you get started on Genius Forest</span>
        <span className="parent_must_activate">
          Your parent must activate your membership to access all features on this platform
        </span>
        <div className="info_box_button">
          <Button
            label="Create a free parent account"
            onClick={handleLogout}
            width={294}
            height={44}
            buttonStyle="primary"
            addClass="box_button"
          />
        </div>
        <Link to="/ask-parent/parent-code" className="parent_already_has interactive_element">
          My parent already has an account
        </Link>
      </div>
      <img src="/assets/images/student_signup_img.png" className="info_box_img"></img>
    </div>
  );
}
