import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Button from '../../../designSystem/components/buttons/button';

function GradeSelection(props) {
  const { subjects, handleSelectSubject } = props;

  const [selectedSubjectIndex, setSelectedSubjectIndex] = React.useState(0);

  const selectedIndex = (subject, index) => {
    setSelectedSubjectIndex(index);
    handleSelectSubject(subject, index);
  };

  return (
    <Grid container className="student_grade_wrapper">
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
          className="selection_wrapper"
        >
          {subjects &&
            subjects.map((subject, index) => (
              <Grid item key={subject.name} className="grade_button_wrapper">
                <Button
                  label={subject.name}
                  addId={subject.name}
                  onClick={() => selectedIndex(subject, index)}
                  width={138}
                  height={59}
                  buttonStyle="light"
                  type="button"
                  icon={selectedSubjectIndex === index && '/assets/icons/active-green-icon.png'}
                  active={selectedSubjectIndex === index}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

GradeSelection.propTypes = {
  subjects: PropTypes.arrayOf(PropTypes.any),
  subscription: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.any)]),
};

export default withRouter(GradeSelection);
