import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import Moment from 'react-moment';
import { Divider } from '@mui/material';
import Button from '../../../../../designSystem/components/buttons/button';
import DefaultAlert from '../../../../../designSystem/components/alerts/defaultAlert';
import { subscriptionAPI } from '../../../../../API/subscription';
import { useTheme, useMediaQuery, Grid } from '@material-ui/core';
import { useHistory, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../../../../../designSystem/components/interactiveElements/loading';
import './addMembership.scss';

export default function AddMembership(props) {
  const { subscription, match } = props;
  const theme = useTheme();
  const sScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const [userType] = React.useState(user['https://www.geniusforest.ca/user_type']);
  const [isLoading, setIsLoading] = React.useState(false);

  let history = useHistory();

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert(
      {
        open: true,
        severity,
        title,
        body,
      },
      setTimeout(() => {
        setDefaultAlert({
          open: false,
          severity,
          title,
          body,
        });
      }, 5000),
    );
  };

  const getNumberOfChildren = () => {
    return match.params.number_of_children ? Number(match.params.number_of_children) : 1;
  };

  const getSubscriptionPlan = () => {
    return match.params.subscription_plan ? Number(match.params.subscription_plan) : 2;
  };

  const [number_of_children, setNumberOfChildren] = React.useState(getNumberOfChildren());
  const [subscription_plan, setSubscriptionPlan] = React.useState(getSubscriptionPlan());
  const [current_number_of_children, setCurrentNumberOfChildren] = React.useState();
  let totalPrice = 0;

  const planType = [
    {
      plan: 'Monthly',
      price: 9.99,
      duration: 'month',
    },
    {
      plan: 'Yearly',
      price: 89.91,
      duration: 'year',
    },
  ];

  if (!subscription) {
    totalPrice = planType[subscription_plan - 1].price * number_of_children;
  } else {
    totalPrice =
      planType[subscription_plan - 1].price * (number_of_children + current_number_of_children);
  }

  const handleCLose = () => {
    sessionStorage.clear();
    Cookies.remove('parent');
    Cookies.remove('learner');
    Cookies.remove('parent_selected_learner');
    history.push('/account/membership-and-billing');
  };

  const handleChange = e => {
    const { value } = e.target;
    if (value === 'monthly') {
      setSubscriptionPlan(1);
    } else {
      setSubscriptionPlan(2);
    }
  };

  const nextBillingDate = sessionStorage.getItem(nextBillingDate);
  var date = new Date();

  const studentNumberHandler = action => {
    if (action === 'minus') {
      if (number_of_children > 1) {
        setNumberOfChildren(number_of_children - 1);
      }
    } else {
      if (number_of_children < 50) {
        setNumberOfChildren(number_of_children + 1);
      } else {
        handleAlert('error', 'Error', 'You can only add maximum 50 students.');
      }
    }
  };

  const handleNextButton = () => {
    if (subscription) {
      const formData = {};
      formData.payment_id = subscription.payment_id;
      formData.current_children_number = current_number_of_children;
      formData.new_children_number = number_of_children;
      formData.total_children_number = current_number_of_children + number_of_children;
      formData.amount =
        planType[parseInt(subscription.subscription_plan) - 1].price * number_of_children;
      formData.user_id = user.sub;
      updateSubscription(formData);
    } else {
      history.push(`/subscription-payment/${subscription_plan}/${number_of_children}`);
    }
  };

  useEffect(() => {
    //cases where the user has monthly sub
    if (subscription && subscription.subscription_plan === '1') {
      document.getElementById('parent_save').style.display = 'none';
      document.getElementById('parent_card').style.display = 'none';
      document.getElementById('parent_card').style.marginLeft = 'auto';
      setSubscriptionPlan(1);
    }
    if (subscription && subscription.subscription_plan === '2') {
      document.getElementById('student_card').style.display = 'none';
      setSubscriptionPlan(2);
    }
    if (subscription) {
      setCurrentNumberOfChildren(subscription.number_of_children);
    }
  }, [subscription]);

  const updateSubscription = async submissionData => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    submissionData.subscription = subscription;
    submissionData.number_of_new_children = number_of_children;
    await subscriptionAPI.PUT.updateSubscription(
      token,
      submissionData.user_id,
      submissionData.payment_id,
      submissionData,
    )
      .then(async response => {
        handleAlert('success', 'Membership successfully added!');
        setIsLoading(false);
        setTimeout(() => {
          window.location = '/account/membership-and-billing';
        }, 3000);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
        handleAlert(
          'error',
          'Payment Validation Failed ',
          'There was an error validating the payment. Please re-enter the card information again or, try again later.',
        );
      });
  };

  if (!subscription && userType === 'learner') return <Redirect to="/ask-parent" />;
  // if (subscription && subscription.user_type === 'parent' && subscription.number_of_children > 0) return <Redirect to="/my-students" />;
  if (subscription && subscription.user_type === 'learner') return <Redirect to="/ask-parent" />;

  return (
    <div className="plan_section_wrapper">
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      <div className="payment_box">
        <img src="/assets/images/plan_selection_trees.png" className="tree_image" />
        <button role="button" className="interactive_element close_button" onClick={handleCLose}>
          <img src="/assets/icons/close_icon.png" width={15} height={15}></img>
        </button>
        <span className="try_genius_forest">Add Membership</span>
        <span className="thirty_day_trial">You will be charged in your next billing date.</span>
        <span className="select_students">Select number of students</span>
        <div className="student_number_container">
          <button
            role="button"
            className="button"
            onClick={() => {
              studentNumberHandler('minus');
            }}
          >
            <img
              src="/assets/icons/minus_icon.png"
              alt="minus_icon"
              className="billing_card_icons"
              style={{ cursor: 'pointer' }}
            />
          </button>
          <div className="student_number_container">
            <p>{number_of_children}</p>
          </div>
          <button
            role="button"
            className="button"
            onClick={() => {
              studentNumberHandler('plus');
            }}
          >
            <img
              src="/assets/icons/plus_icon.png"
              alt="plus_icon"
              className="billing_card_icons"
              style={{ cursor: 'pointer' }}
            />
          </button>
        </div>
        <div className="grid" id="grid">
          <div className="save_percent" id="parent_save">
            SAVE 25%
          </div>
          <label className="card" id="parent_card">
            <input
              name="plan"
              className="radio"
              type="radio"
              value="yearly"
              checked={subscription_plan === 2}
              onClick={handleChange}
            />
            <span className="plan_details">
              <span className="plan_type">
                Yearly Plan<span className="striked">$119.88</span>
              </span>
              <span className="plan_price">${planType[1].price}</span>
              <span className="plan_length">/{planType[1].duration}, per student</span>
              <span className="bill_freq">Billed every 12 months</span>
            </span>
          </label>
          <label className="card" id="student_card">
            <input
              name="plan"
              className="radio"
              type="radio"
              checked={subscription_plan === 1}
              value="monthly"
              onClick={handleChange}
            />
            <span className="plan_details">
              <span className="plan_type">Monthly Plan</span>
              <span className="plan_price">${planType[0].price}</span>
              <span className="plan_length">/{planType[0].duration}, per student</span>
              <span className="bill_freq">Billed every month</span>
            </span>
          </label>
        </div>
        <div className="order_div">
          <span className="order_summary">Order Summary</span>
          <span className="summary_plan">{planType[subscription_plan - 1].plan} plan</span>
          <span className="price_calc">
            ${planType[subscription_plan - 1].price}{' '}
            <span className="frequency">/{planType[subscription_plan - 1].duration}</span>
            {!subscription ? (
              <span className="studentCount">&nbsp;x {number_of_children} students</span>
            ) : (
              <span className="studentCount">
                &nbsp;x &#40;{current_number_of_children} current students&#41; +{' '}
                {number_of_children} new students
              </span>
            )}
          </span>
        </div>
        <Divider className="divider" />
        <Grid
          container
          className="pricing_grid"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <span className="due_current">
              Due <Moment date={date} format="MMM DD YYYY" />
            </span>
          </Grid>
          <Grid>
            <span className="total_price">${totalPrice.toFixed(2)}</span>
          </Grid>
        </Grid>
        <Grid
          container
          className="pricing_grid"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <span className="due_today">Due today</span>
          </Grid>
          <Grid>
            <span className="today_price">$0.00</span>
          </Grid>
        </Grid>

        <div className="next_button">
          {isLoading && (
            <div>
              <Loading />
            </div>
          )}
          <Button
            label={subscription ? 'Add new members' : 'Next'}
            width={sScreen ? 345 : 550}
            height={sScreen ? 42 : 44}
            fontSize={16}
            buttonStyle="primary"
            className="next_button"
            onClick={handleNextButton}
            disable={isLoading}
            capitalize="none"
          />
        </div>
        <div className="accountCharge">
          Your account will be charged ${totalPrice.toFixed(2)} on{' '}
          <Moment date={date} format="YYYY-MM-DD" />. Cancel anytime.
        </div>
      </div>
    </div>
  );
}
