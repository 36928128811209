import React from 'react';
// import PropTypes from 'prop-types';
import ReactGoogleAnalytics from 'react-ga';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@material-ui/core';
import { practiceAPI } from '../../API/practice';
import SkillGraph from './components/skillGraph';
import SkillGraphMobile from './components/skillGraphMobile';
import SkillInformation from './components/skillInformation';
import MoreInformation from './components/moreInformation';
// TODO: Removed until next MVP
// import EstimateGraph from './components/estimateGraph';
import Loading from '../../designSystem/components/interactiveElements/loading';
import './practiceReport.scss';

function PracticeReport(props) {
  const { match, handleChangeLayout, subscription } = props;
  const { getAccessTokenSilently, user } = useAuth0();

  const getLearner = () => {
    if (!subscription) return false;
    let result;
    subscription.learners.map(learner => {
      if (learner.user_id === user.sub) return (result = learner);
    });
    return result;
  };

  const learner = getLearner();
  const { topic_id } = match.params;

  const [skills, setSkills] = React.useState(false);
  const [selected_skill, setSelectedSkill] = React.useState(0);

  const handleMoreInformation = () => {
    document.getElementById('more_information').scrollIntoView();
  };

  const getPracticeSkills = React.useCallback(async () => {
    const token = await getAccessTokenSilently();

    await practiceAPI.GET.getPracticeSkills(topic_id, learner.id, token)
      .then(response => {
        const { skills } = response.data;
        setSkills(skills);
      })
      .catch(error => {
        console.log(error);
      });
  }, [getAccessTokenSilently, learner.id, topic_id]);

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    handleChangeLayout({
      header: {
        css: 'header_dark',
      },
      footer: {
        css: 'hide_element',
      },
    });
    getPracticeSkills();
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);

    return () => {
      handleChangeLayout('reset');
    };
    // TODO: When we add the handleChangeLayout to the dependency list it renders too many times
    // eslint-disable-next-line
  }, [getPracticeSkills]);

  if (!skills)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  return (
    <>
      <Grid container className="topic_report_wrapper">
        <Grid item xs={12} className="top_report_wrapper">
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <h2 className="top_report_sm_title">Practice Report</h2>
              <h1 className="top_report_title">{skills.length > 0 && skills[0].topic_name}</h1>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} lg={6} className="report_body">
              <span className="performance_chart-desktop">
                <SkillGraph
                  active_index={selected_skill}
                  data={skills}
                  handleClick={value => setSelectedSkill(value)}
                  average={2500}
                />
              </span>
              <span className="performance_chart-mobile">
                <SkillGraphMobile
                  active_index={selected_skill}
                  data={skills}
                  handleClick={(data, value) => setSelectedSkill(value)}
                  average={2500}
                />
              </span>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SkillInformation active_index={selected_skill} skills={skills} learner={learner} />
              {/* 
              TODO: Removed until next MVP
              <div>
                <EstimateGraph />
              </div> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="text_center m_top_5 ">
          <button type="button" onClick={handleMoreInformation} className="display_more_button">
            More Information
            <div className="text_center m_top_1">
              <img
                alt="display more "
                src="/assets/icons/result_display_more.svg"
                aria-hidden={true}
              />
            </div>
          </button>
        </Grid>
      </Grid>
      <Grid container className="page_wrapper topic_report_more" id="more_information">
        <MoreInformation skills={skills} learner={learner} />
      </Grid>
    </>
  );
}

PracticeReport.propTypes = {};

export default PracticeReport;
