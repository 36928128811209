import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select, FormHelperText, InputLabel, FormControl } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

Dropdown.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
};

Dropdown.defaultProps = {
  width: '100%',
  height: 56,
  bgColor: false,
  id: 'custom-dropdown',
  error: false,
  helperText: '',
  label: false,
};

export default function Dropdown({
  list,
  selectedItem,
  handleChange,
  width,
  height,
  bgColor,
  id,
  error,
  helperText,
  label,
  shrink,
  notched,
}) {
  const useStyles = makeStyles(theme => ({
    label: {
      top: '-7px',
      left: '15px',
      background: 'white',
      zIndex: 1000,
      padding: '0px 5px',
    },
    errorText: {
      color: theme.palette.error.main,
      marginLeft: '14px',
    },
    customSelect: {
      zIndex: 1,
      width,
      height,
      marginBottom: error ? '0px' : '22px',
      fontFamily: theme.fontFamily.default,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '27px',
      color: theme.palette.primary.textField,
      '& .MuiSelect-select': {
        width: '100%',
      },
      '& .MuiSelect-select:focus': {
        background: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        zIndex: -1,
        background: bgColor,
        borderRadius: '7px',
        color: theme.palette.primary.textField,
        fontFamily: theme.fontFamily,
        border: `1px solid ${theme.palette.primary.textField}`,
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${theme.palette.primary.hover} !important`,
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          boxShadow: bgColor && `0 0 0 4px white,0 0 0 5px ${bgColor}`,
          border: bgColor ? 'none' : `2px solid ${theme.palette.primary.focus}`,
        },
      },
      '& .MuiSelect-icon': {
        color: theme.palette.default.text,
      },
    },
  }));

  const classes = useStyles();

  const [icon, setIcon] = React.useState(false);

  return (
    <FormControl className="d_block">
      {label && (
        <InputLabel className={classes.label} id={id} shrink={shrink}>
          {label}
        </InputLabel>
      )}
      <Select
        variant="outlined"
        labelId={id}
        id={id}
        value={selectedItem}
        onChange={handleChange}
        onClick={() => setIcon(icon ? false : true)}
        error={error}
        className={classes.customSelect}
        notched={notched}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {list.map((item, index) => {
          return (
            <MenuItem value={item.value} key={index} disabled={item.disabled}>
              {item.label || item.name}
            </MenuItem>
          );
        })}
      </Select>
      {/* TODO: DECIDE WHEREVER IS NEED THE HELPER MESSAGE */}
      <FormHelperText className={error ? classes.errorText : ''}>{helperText}</FormHelperText>
    </FormControl>
  );
}
/*
  < ---- Example usage ----- >
  <DefaultDropdown 
    list={default_list} 
    selectedItem={defaultItem} 
    handleChange={(value) => setDefaultItem(value.target.value)} 
    width={160} 
  /> 
  */
