import React from 'react';
import Cookies from 'js-cookie';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';
import Button from '../../designSystem/components/buttons/button';
import CloseIcon from '@material-ui/icons/Close';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import '../layoutSideBar.scss';

function SideNavBar(props) {
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const history = useHistory();

  const {
    topNavLinks,
    menuState,
    setMenuState,
    subscription,
    changeBodyColor,
    collapsedMenuItems,
    userName,
    userType,
  } = props;

  const handleCloseSideBar = () => {
    setMenuState(false);
    changeBodyColor(false);
  };

  const handleMenuSelection = (event, selectedItem) => {
    if (event.type === 'click' || event.keyCode === 13 || event.keyCode === 0) {
      setMenuState(false);
      changeBodyColor(false);
      if (selectedItem.name === 'log-out') return handleLogout();
      return history.push(`/${selectedItem.url}`);
    }
  };

  const addVisibleItems = menuItem => {
    var itemsResult = [];
    for (var i = 0; i < menuItem.length; i++) {
      if (i === 0 && window.innerWidth < 1080) {
        itemsResult.push(menuItem[i]);
      } else if (i === 1 && window.innerWidth < 940) {
        itemsResult.push(menuItem[i]);
      } else if (i === 2 && window.innerWidth < 810) {
        itemsResult.push(menuItem[i]);
      } else if (i === 3 && window.innerWidth < 730) {
        itemsResult.push(menuItem[i]);
      }
    }
    return itemsResult;
  };

  const setFocusOn = (action, visibleItems) => {
    for (var i = 0; i < visibleItems.length; i++) {
      var currentElement = visibleItems[i];
      var firstNavElement = visibleItems[0];
      var lastNavElement = visibleItems[visibleItems.length - 1];
      var navElementToFocus;

      if (action === 'first') {
        return firstNavElement.focus();
      }

      if (action === 'last') {
        return lastNavElement.focus();
      }

      if (action === 'nextItem') {
        if (i === visibleItems.length - 1) {
          return firstNavElement.focus();
        }
        if (document.activeElement === currentElement) {
          navElementToFocus = visibleItems[i + 1];
          return navElementToFocus.focus();
        }
      }

      if (action === 'previousItem') {
        if (document.activeElement === visibleItems[0]) return lastNavElement.focus();
        console.log(document.activeElement);
        navElementToFocus = visibleItems[document.activeElement.value];
        if (document.activeElement.id === 'side_menu_signIn') {
          navElementToFocus = visibleItems[visibleItems.length - 2];
        }
        if (document.activeElement.id === 'side_menu_login') {
          navElementToFocus = visibleItems[visibleItems.length - 3];
        }
        return navElementToFocus.focus();
      }
    }
  };

  const handleMenuNavigation = event => {
    console.log(event.target);
    var navItems = document.querySelectorAll('.menu_side_items');
    switch (event.keyCode) {
      case 9:
        event.preventDefault();
      case 32:
      case 40:
        event.preventDefault();
        setFocusOn('nextItem', navItems);
        break;
      case 38:
        event.preventDefault();
        setFocusOn('previousItem', navItems);
        break;
      default:
        return false;
    }
  };

  const handleOpenMenu = event => {
    if (event.type === 'click') {
      setMenuState(true);
      changeBodyColor(true);
    } else {
      var navItems = document.querySelectorAll('.menu_nav_items');
      var visibleItems = addVisibleItems(navItems);
      switch (event.keyCode) {
        case 13:
        case 32:
        case 40:
          if (!menuState) {
            event.preventDefault();
            setMenuState(true);
            changeBodyColor(true);
            setFocusOn('first', visibleItems);
          }
          break;
        case 38:
          if (!menuState) {
            event.preventDefault();
            setMenuState(true);
            changeBodyColor(true);
            setFocusOn('last', visibleItems);
          }
          break;
        default:
          return false;
      }
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    Cookies.remove('parent');
    Cookies.remove('learner');
    Cookies.remove('parent_selected_learner');
    logout({ returnTo: window.location.origin });
  };

  const escFunction = event => {
    if (event.keyCode === 27) {
      handleCloseSideBar();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  });

  return (
    <>
      {!menuState && (
        <Box className="buttons_wrapper buttons_menu_spacing">
          <button
            className="button_icon button_menu_wrapper"
            onClick={handleOpenMenu}
            onKeyDown={handleOpenMenu}
            id="menu_button"
          >
            <img
              src="/assets/icons/header_menu_icon.svg"
              alt="Menu Icon"
              className="menu_icon"
              width="40px"
              height="32px"
            />
          </button>
        </Box>
      )}
      <Box
        className={menuState ? 'menu_sideBar menu_open' : 'menu_sideBar'}
        onKeyDown={handleMenuNavigation}
      >
        <Box>
          <img src="/assets/logos/logo.svg" alt="Genius Forest Logo" width="86px" height="45px" />
          <button
            className="button_icon interactive_element close_menu menu_side_items"
            onClick={handleCloseSideBar}
            tabIndex={menuState ? 0 : -1}
          >
            <span className="sr_only">Close menu</span>
            <CloseIcon fontSize="large" />
          </button>
        </Box>
        {isAuthenticated && (
          <Grid
            container
            direction="row"
            justifyContent={xsScreen ? 'flex-start' : 'center'}
            alignItems="center"
          >
            <div className="user_image_wrapper">
              {user.picture ? (
                <img
                  src={user.picture}
                  alt="Parent icon"
                  className="profile_picture"
                  aria-hidden={true}
                />
              ) : (
                <PersonOutlineIcon className="profile_icon_default" />
              )}
            </div>
            <div className="user_name">{userName}</div>
          </Grid>
        )}
        {isAuthenticated && subscription && <></>}
        <ul className="navbar-nav-mobile text_center">
          {topNavLinks.map((item, index) => (
            <li
              key={item.name}
              className="menu_nav_items menu_side_items"
              id={item.mobile_id}
              role="button"
              tabIndex={menuState ? 0 : -1}
              onClick={event => handleMenuSelection(event, item)}
              onKeyPress={event => handleMenuSelection(event, item)}
              value={index}
            >
              {item.name}
            </li>
          ))}
          {isAuthenticated && subscription && (
            <>
              {collapsedMenuItems.map((item, index) => (
                <li
                  className="menu_nav_items menu_side_items"
                  id={item.name}
                  role="button"
                  tabIndex={menuState ? 0 : -1}
                  onClick={event => handleMenuSelection(event, item)}
                  onKeyDown={event => handleMenuSelection(event, item)}
                  value={index}
                >
                  {item.label}
                </li>
              ))}
            </>
          )}
          {!subscription && userType === 'learner' && (
            <>
              {collapsedMenuItems.map((item, index) => (
                <li
                  className="menu_nav_items menu_side_items"
                  id={item.name}
                  role="button"
                  tabIndex={menuState ? 0 : -1}
                  onClick={event => handleMenuSelection(event, item)}
                  onKeyDown={event => handleMenuSelection(event, item)}
                  value={index}
                >
                  {item.label}
                </li>
              ))}
            </>
          )}
          {!isAuthenticated && (
            <>
              <li>
                <Button
                  label="Login"
                  onClick={() => loginWithRedirect({ screen_hint: 'login' })}
                  width="100%"
                  height={50}
                  buttonStyle="default"
                  addClass="menu_side_items menu_side_buttons"
                  addId="side_menu_login"
                  tabIndex={menuState ? 0 : -1}
                />
              </li>
              <li>
                <Button
                  label="Sign up"
                  onClick={event => handleMenuSelection(event, { url: 'pre-signup' })}
                  width="100%"
                  height={50}
                  buttonStyle="primary"
                  addClass="menu_side_items menu_side_buttons"
                  addId="side_menu_signIn"
                  tabIndex={menuState ? 0 : -1}
                />
              </li>
            </>
          )}
          {isAuthenticated && userType === 'parent' && !subscription && (
            <>
              <li>
                <Button
                  label="Subscription"
                  onClick={event => handleMenuSelection(event, { url: 'plan-selection' })}
                  width="100%"
                  height={50}
                  buttonStyle="secondary"
                  addClass="menu_side_items m_top_2 menu_side_buttons"
                  addId="side_menu_login"
                  tabIndex={menuState ? 0 : -1}
                />
              </li>
              <li>
                <Button
                  label="Logout"
                  onClick={handleLogout}
                  width={91}
                  height={42}
                  buttonStyle="default"
                  addClass="menu_side_items menu_side_buttons"
                  addId="side_menu_signIn"
                  fontSize={16}
                />
              </li>
            </>
          )}
          {isAuthenticated && userType === 'learner' && !subscription && (
            <>
              <li>
                <Button
                  label="Onboarding"
                  onClick={() => history.push('/plan-selection')}
                  width="100%"
                  height={50}
                  buttonStyle="secondary"
                  addClass="menu_side_items m_top_2 menu_side_buttons"
                  addId="side_menu_login"
                  tabIndex={menuState ? 0 : -1}
                />
              </li>
              <li>
                <Button
                  label="Logout"
                  onClick={handleLogout}
                  width={91}
                  height={42}
                  buttonStyle="default"
                  addClass="menu_side_items menu_side_buttons"
                  addId="side_menu_signIn"
                  fontSize={16}
                />
              </li>
            </>
          )}
        </ul>
      </Box>
    </>
  );
}

SideNavBar.propTypes = {};

export default SideNavBar;
