import React from 'react';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function CollapsedMenu({
  subscription,
  history,
  handleCloseMenu,
  openCollapsedMenu,
  collapsedMenuItems,
}) {
  const { logout } = useAuth0();

  const escFunction = React.useCallback(
    event => {
      if (event.keyCode === 27) {
        handleCloseMenu();
      }
    },
    [handleCloseMenu],
  );

  const handleLogout = () => {
    sessionStorage.clear();
    Cookies.remove('parent');
    Cookies.remove('learner');
    Cookies.remove('parent_selected_learner');
    logout({ returnTo: window.location.origin });
  };

  const handleMenuSelection = (event, selectedItem) => {
    if (event.type === 'click' || event.keyCode === 13) {
      if (selectedItem.name === 'log-out') return handleLogout();
      handleCloseMenu();
      return history.push(`/${selectedItem.url}`);
    }
  };

  const setFocusOn = (action, visibleItems) => {
    for (var i = 0; i < visibleItems.length; i++) {
      var currentElement = visibleItems[i];
      var firstNavElement = visibleItems[0];
      var lastNavElement = visibleItems[visibleItems.length - 1];
      var navElementToFocus;

      if (action === 'nextItem') {
        if (i === visibleItems.length - 1) {
          return firstNavElement.focus();
        }
        if (document.activeElement === currentElement) {
          navElementToFocus = visibleItems[i + 1];
          return navElementToFocus.focus();
        }
      }

      if (action === 'previousItem') {
        if (document.activeElement === visibleItems[0]) return lastNavElement.focus();
        navElementToFocus = visibleItems[document.activeElement.value - 1];
        return navElementToFocus.focus();
      }
    }
  };

  const handleMenuNavigation = event => {
    var navItems = document.querySelectorAll('.collapsed_menu_items');
    switch (event.keyCode) {
      case 9:
        event.preventDefault();
      case 40:
        event.preventDefault();
        setFocusOn('nextItem', navItems);
        break;
      case 38:
        event.preventDefault();
        setFocusOn('previousItem', navItems);
        break;
      default:
        return false;
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction, subscription, openCollapsedMenu]);

  return (
    <div
      className={openCollapsedMenu ? 'profile_header_wrapper' : 'profile_header_closed'}
      onKeyDown={handleMenuNavigation}
      id="profile_header_wrapper"
    >
      <ul className="collapsed_menu_list">
        {collapsedMenuItems.map((item, index) => (
          <li
            className="collapsed_menu_items"
            id={item.name}
            role="button"
            tabIndex={openCollapsedMenu ? 0 : -1}
            onClick={event => handleMenuSelection(event, item)}
            onKeyDown={event => handleMenuSelection(event, item)}
            value={index}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default withRouter(CollapsedMenu);
