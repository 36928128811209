import React from 'react';
import Button from '../../../../designSystem/components/buttons/button';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import StudentCard from './studentCard';
import '../myStudents.scss';

export default function MyStudentFill(props) {
  const history = useHistory();
  const { subscription } = props;
  const [buttonDisable, setDisable] = React.useState(false);

  const getStudentsAvailable = () => {
    const totalStudents = subscription.empty_learners.length + subscription.learners.length;
    const onboardStudents = subscription.learners.length;
    return totalStudents - onboardStudents;
  }

  React.useEffect(() => {
    if (subscription.learners !== null) {
      if (getStudentsAvailable() === 0) {
        setDisable(true);
      }
    }
  }, []);

  return (
    <div className="student_page_wrapper">
      <div className="top_area">
        <span className="my_students">My Students</span>
        <div className="top_area_button">
          <Button
            fontSize={16}
            label="Add student"
            width={135}
            height={44}
            disable={buttonDisable}
            buttonStyle="primary"
            onClick={() => history.push('/my-students/add-student')}
            addClass="button"
          />
        </div>
      </div>
      <Grid
        container
        className="student_grid"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        {subscription.learners &&
          subscription.learners.map(learner => <StudentCard learner={learner} />)}
      </Grid>
      <div className="bottom_button">
        <Button
          fontSize={16}
          label="Add student"
          width={332}
          height={44}
          disable={buttonDisable}
          buttonStyle="primary"
          onClick={() => history.push('/my-students/add-student')}
        />
      </div>
    </div>
  );
}
