import React from 'react';
// import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Grid } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import DifficultLevel from '../../../designSystem/components/interactiveElements/difficultLevel';
import { learnersAPI } from '../../../API/learners';

function SkillInformation(props) {
  const { skills, selected_skill, learner } = props;

  const tasks_plan = skills[selected_skill].tasks.slice(0, 3);
  const skill_wrong_attempts = skills[selected_skill].skill_wrong_attempts;
  const skill_attempts = skills[selected_skill].skill_attempts;

  const { getAccessTokenSilently } = useAuth0();

  const [beaten_students, setBeatenStudents] = React.useState(0);
  const learner_progress = Math.abs((skill_wrong_attempts / skill_attempts) * 100 - 100);

  const calculateLevel = skill => {
    const difficult_level = Math.abs((skill_wrong_attempts / skill_attempts) * 100 - 100);
    if (difficult_level <= 25) return 'Fundamental';
    if (difficult_level > 25 && difficult_level <= 50) return 'Intermediate';
    if (difficult_level > 50 && difficult_level <= 75) return 'Advanced';
    if (difficult_level > 75) return 'Master';
  };

  const calculateDifficultLevel = () => {
    if (learner_progress <= 20) return 1;
    if (learner_progress > 20 && learner_progress <= 40) return 2;
    if (learner_progress > 40 && learner_progress <= 60) return 3;
    if (learner_progress > 60 && learner_progress <= 80) return 4;
    if (learner_progress > 80) return 5;
  };

  const getBeatenValue = React.useCallback(
    async skill => {
      const token = await getAccessTokenSilently();
      const { skill_id, skill_learner_points } = skill;
      if (skill_id) {
        learnersAPI.GET.getBeatenStudents(skill_id, learner.id, skill_learner_points, token)
          .then(response => {
            setBeatenStudents(response.data.result);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    [setBeatenStudents, getAccessTokenSilently, learner.id],
  );

  React.useEffect(() => {
    getBeatenValue(skills[selected_skill]);
  }, [skills, selected_skill, getBeatenValue]);

  return (
    <Grid item className="skill_information">
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item className="skill_name">
          {skills[selected_skill].skill_name}
        </Grid>
        <Grid item className="skill_level_title">
          Current level
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className="skill_level_wrapper-mobile"
        spacing={2}
      >
        <Grid item className="skill_level_title">
          Current level
        </Grid>
        <Grid item className="skill_level">
          {calculateLevel(skills[selected_skill])}
        </Grid>
      </Grid>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item className="beaten_text">
          You’ve outperformed <span className="beaten_percentage">{Math.round(beaten_students)}% </span>
          students
        </Grid>
        <Grid item className="skill_level">
          {calculateLevel(skills[selected_skill])}
        </Grid>
      </Grid>
      <div>
        <hr className="custom_hr" />
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12} className="practice_plan_wrapper">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className="practice_plan_top"
            >
              <Grid item>
                <Grid container>
                  <Grid item xs={12} className="skill_name">
                    Practice Plan
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={12} className="skill_level_title">
                    Difficulty
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                {tasks_plan.map((item, index) => (
                  <Grid item xs={12} key={item.name} className="unit_name">
                    <div className="list_icon">
                      <img
                        src="/assets/icons/list_white_icon.svg"
                        alt="Active Icon"
                        aria-hidden={true}
                      />
                    </div>
                    {item.name}
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                {tasks_plan.map((item, index) => (
                  <Grid item xs={12} key={`question ${index}`} className="unit_difficult">
                    <DifficultLevel difficult_level={calculateDifficultLevel()} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SkillInformation.propTypes = {};

export default SkillInformation;
