import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ButtonIconRound from '../../../designSystem/components/buttons/round-icon-button';

QuestionOutlineMobile.propTypes = {
  handleExpansion: PropTypes.func.isRequired,
  task: PropTypes.shape({
    skill_name: PropTypes.string,
  }).isRequired,
};

function QuestionOutlineMobile({ handleExpansion, task }) {
  const task_name = task && task.name;
  return (
    <div className="question_outline_mobile_wrapper">
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item className="outline_mobile_title">
          Test Outline
        </Grid>
        <Grid item>
          <ButtonIconRound disabled arrow_direction="up" handleClick={handleExpansion} size="sm" />
        </Grid>
      </Grid>
      <div className="outline_question_title">{task_name}</div>
    </div>
  );
}

QuestionOutlineMobile.propTypes = {};

export default QuestionOutlineMobile;
