import React from 'react';
import '../accountSettings.scss';
import ChangePassword from './changePassword';
import TextField from '../../../../designSystem/components/textFields/textField';
import Button from '../../../../designSystem/components/buttons/button';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import DefaultDropdown from '../../../../designSystem/components/dropdowns/dropdown';
import Loading from '../../../../designSystem/components/interactiveElements/loading';
import { settingsAPI } from '../../../../API/settings';
import { subjectsAPI } from '../../../../API/subjects';
import AcceptDialog from './acceptDialog';

export default function SettingsBoxStudent(props) {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('601'));
  const { subscription, learner, fileSelectedHandler, handleFormSubmit, isLoading } = props;
  const { getAccessTokenSilently, user } = useAuth0();
  const history = useHistory();
  const [grade, setGrade] = React.useState('');
  const [default_list, setDefaultList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState([])
  const [acceptOpen, setAcceptOpen] = React.useState(false);

  const checkSocial = () => {
    return learner.user_id.includes('oauth2')
  }

  const useFormMethods = useForm({
    defaultValues: {
      name: learner.nickname,
      email: learner.email,
    },
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormMethods;

  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }
  const handleAcceptClose = () => {
    setAcceptOpen(false);
  }
  const handleGradeSelect = e => {
    setGrade(e.target.value);
  };

  React.useEffect(() => {
    subjectsAPI.GET.getSubjectsByCourseID(1).then(res => {
      for (let i = 0; i < res.data.rows.length; i++) {
        setDefaultList(default_list => [
          ...default_list,
          {
            value: res.data.rows[i].id,
            label:
              res.data.rows[i].name[0].toUpperCase() + res.data.rows[i].name.slice(1).toLowerCase(),
          },
        ]);
      }
      setGrade(subscription.current_subject);
    }, []);
  }, []);

  const checkValue = async value => {
    const data = {
      email: value,
    };
    if (data.email.toLowerCase() == learner.email.toLowerCase()) {
      const res = {
        status: 200,
      };
      return res;
    }
    const token = await getAccessTokenSilently({ ignoreCache: true });
    const response = await settingsAPI.POST.validateSettingsEmail(data, token);
    return response;
  };

  const handleButtonClick = async formData => {
    const data = {
      form: formData,
      user_id: learner.user_id,
      user_type: learner.user_metadata.user_type,
      old_email: learner.email,
      old_name: learner.nickname,
      grade: grade,
    };
    if ((formData.email !== user.email) && learner.user_id == user.sub) {
      setAcceptOpen(true);
      setForm(data);
      return;
    }
    await handleFormSubmit(data);
    if (data.user_id !== user.sub) return history.push('/account/settings');
    return history.push(`/account/settings/${data.user_id}`)
  };

  return (
    <div className="accountSettingsStudent_box">
      <AcceptDialog open={acceptOpen} handleClose={handleAcceptClose} form={form} handleFormSubmit={handleFormSubmit} />
      <ChangePassword open={open} handleClose={handleClose} handleFormSubmit={handleFormSubmit} user_id={learner.user_id} learner={learner} />
      <div className="accountSettings_text">Account Settings</div>
      <div className="accountSettings_profile">Profile</div>
      <div className="accountSettings_password">Password</div>
      <img src={learner.picture} className="profile_img" alt="profile" width={136} height={136} />
      <label>
        <img className="interactive_element add_photo" src="/assets/images/add_photo.png" />
        <input
          type="file"
          id="image_upload"
          name="dataUpload"
          accept=".png,.jpg,.jpeg"
          onChange={event =>
            fileSelectedHandler(event, {
              user_id: learner.user_id,
              user_img: learner.picture,
            })
          }
          className="file-upload"
        />
      </label>
      <form onSubmit={handleSubmit(handleButtonClick)}>
        <div className="accountSettings_info">
          <span className="info_text">Name</span>
          <TextField
            id="profile_name"
            placeholder="Name"
            type="text"
            width={smScreen ? 310 : 514}
            requiredField
            name="name"
            helperText={errors.name ? errors.name.message : ''}
            error={!!errors.name}
            register={register({
              required: ' Name is required',
              minLength: { value: 2, message: 'Min length 2' },
              maxLength: { value: 30, message: 'Max length 30' },
              pattern: {
                value: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/i,
                message: 'Can only contain letters and numbers.',
              },
            })}
          />

          <span className="info_text">Email address</span>
          <TextField
            id="profile_email"
            placeholder="email@example.com"
            type="text"
            requiredField
            disabled={checkSocial()}
            helperText={errors.email ? errors.email.message : ''}
            error={!!errors.email}
            name="email"
            register={register({
              required: 'Email is required',
              maxLength: { value: 100, message: 'Max length 100' },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid Email Format',
              },
              validate: {
                value: async value => {
                  const res = await checkValue(value);
                  if (res.status !== 200) {
                    return res.data.message;
                  } else {
                    return true;
                  }
                },
              },
            })}
            width={smScreen ? 310 : 514}
          />
          <span className="info_text">Grade level</span>
          <DefaultDropdown
            list={default_list}
            name="studentGrade"
            disabled={false}
            selectedItem={grade}
            handleChange={handleGradeSelect}
            width={smScreen ? 310 : 514}
            placeholder="Current grade level"
            height={50}
            requiredField
            register={register({
              required: 'Grade is required',
            })}
          />
          <span className="info_text pass_text">Password</span>
          <span className='pass_placeholder'>●●●●●●●●●●●</span>
          <button className="change_button" type="button" disabled={checkSocial()} onClick={handleOpen}>
            <span className="change_password">Reset</span>
          </button>
        </div>
        <div className="save_changes_button">
          {isLoading && (
            <Loading />
          )}
          <Button
            label="Save Changes"
            width={smScreen ? 310 : 142}
            height={44}
            fontSize={16}
            type="submit"
            addClass="button_text"
            onClick={() => { }}
            disable={isLoading}
          />
        </div>
      </form>
    </div>
  );
}
