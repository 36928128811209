import React, { PureComponent } from 'react';
import { BarChart, Bar, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import Moment from 'react-moment';

export default class StackedBarChart extends PureComponent {
  render() {
    function CustomTooltip({ payload, label, active }) {
      if (active) {
        return (
          <div className="custom_tooltip">
            <div className="label">
              Date:{' '}
              <span className="value">
                {' '}
                <Moment
                  date={new Date(payload[0] && payload[0].payload.created_at)}
                  format="MMM D, YYYY"
                />
              </span>
            </div>
            <div className="label">
              Attempts:{' '}
              <span className="value">
                {payload[0] && payload[0] && payload[0].payload.attempts}
              </span>
            </div>
            <div className="label">
              Wrong Attempts:{' '}
              <span className="value">
                {payload[0] && payload[0] && payload[0].payload.wrong_attempts}
              </span>
            </div>
          </div>
        );
      }

      return null;
    }

    const { data } = this.props;

    for (let i = 0; i <= 14; i++) {
      if (!data[i]) {
        data.push({ total_points: null });
      }
    }
    return (
      <ResponsiveContainer width="100%" height="94%">
        <BarChart width={77} height={40} data={data}>
          <Tooltip content={CustomTooltip} />
          <Legend
            payload={[
              {
                id: 'Correct answer',
                value: 'Correct answer',
                type: 'square',
                color: '#19252A',
              },
              {
                id: 'Incorrect answer',
                value: 'Incorrect answer',
                type: 'square',
                color: '#FF8878',
              },
            ]}
            className="stackedBarChart_legend"
            layout="horizontal"
            verticalAlign="top"
            align="right"
          />
          <Bar dataKey="attempts" stackId="a" fill="#19252A" />
          <Bar dataKey="wrong_attempts" stackId="a" fill="#FF8878" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
