import axios from 'axios';
import url from './url';

export const settingsAPI = {
    POST: { 
        updateAccount(token, data) {
            return axios.post(`${url}api/accountSettings/update-account/`, data, {
                headers: { authorization: `Bearer ${token}` },
            });
        },
        validateSettingsEmail(data, token) { 
            return axios.post(`${url}api/accountSettings/validate-email/`, data, {
                headers: { authorization: `Bearer ${token}` },
            });
        }
    } 
}