import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import DefaultListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import './layoutSideBar.scss';

const ListItem = withStyles({
  root: {
    padding: '18px',
    fontWeight: 'bold',
    color: '#6FF2C2',
    fontSize: '22px',
    textAlign: 'center',
    '&:hover': {
      background: ' #6FF2C2',
      cursor: 'pointer',
      width: '100%',
      fontWeight: 'bold',
      color: '#19252A',
    },

    '&$selected': {
      background: '#6FF2C2',
      cursor: 'pointer',
      width: '100%',
      fontWeight: 'bold',
      color: 'white',
      fontSize: '22px',
      padding: '20px',
      color: '#19252A',
      '&:hover': {
        background: '#6FF2C2',
        cursor: 'pointer',
        width: '100%',
        fontWeight: 'bold',
        color: '#19252A',
      },
    },
  },
  selected: {},
})(DefaultListItem);

function AdminSideBar(props) {
  const [selectedItem, setSelectedItem] = React.useState(window.location.pathname);

  const displayItem = item => {
    // if (validateUserType(item)) {
    //   return '';
    // }
    return 'hidden_item';
  };

  const handleSelectedItem = name => {
    setSelectedItem(`/admin-portal/${name}`);
  };

  return (
    <Grid item xs className="desktop_side_bar">
      <Link
        to="/admin-portal/questions"
        className={`${displayItem('question')} side_bar_links`}
        onClick={() => handleSelectedItem('questions')}
      >
        <ListItem
          button
          selected={selectedItem.includes('question')}
          className="col-12"
          tabIndex="-1"
        >
          Questions
        </ListItem>
      </Link>
    </Grid>
  );
}

AdminSideBar.propTypes = {};

export default AdminSideBar;
