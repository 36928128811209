import axios from 'axios';
import url from './url';

export const skillsAPI = {
  GET: {
    getReinforceSkills(token, subject_id, learner_id) {
      return axios.get(`${url}api/getReinforceSkills/${subject_id}/${learner_id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    getSkillsBySubjectID(token, subject_id, learner_id) {
      return axios.get(`${url}api/getSkillsBySubjectID/${subject_id}/${learner_id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
};
