import React, { PureComponent } from 'react';
import { BarChart, Bar, ResponsiveContainer, Tooltip } from 'recharts';
import Moment from 'react-moment';

export default class SummaryChart extends PureComponent {
  render() {
    const { data, dataLegends } = this.props;

    const formatTime = totalTime => {
      const getSeconds = `0${totalTime % 60}`.slice(-2);
      const minutes = `${Math.floor(totalTime / 60)}`;
      const getMinutes = `0${minutes % 60}`.slice(-2);
      const getHours = `0${Math.floor(totalTime / 3600)}`.slice(-2);
      return `${getHours}:${getMinutes}:${getSeconds}`;
    };

    function CustomTooltip({ payload, label, active }) {
      if (active) {
        return (
          <div className="custom_tooltip">
            <div className="label">
              Date:{' '}
              <span className="value">
                {' '}
                <Moment
                  date={new Date(payload[0] && payload[0].payload.created_at)}
                  format="MMM D, YYYY"
                />
              </span>
            </div>
            <div className="label">
              {dataLegends.dataName}:{' '}
              <span className="value">
                {dataLegends.dataName === 'Hours'
                  ? formatTime(payload[0] && payload[0].payload.total_time)
                  : dataLegends.dataKey === 'level'
                  ? masteredSkills
                  : payload[0] && payload[0].value}
              </span>
            </div>
          </div>
        );
      }

      return null;
    }

    let dataToRender = data;
    let masteredSkills = 0;
    if (dataLegends.dataKey === 'level') {
      dataToRender = [];
      for (let i = 0; i < data[0].skills_levels.length; i++) {
        if (data[0].skills_levels[i] && data[0].skills_levels[i].level === 4) {
          dataToRender.push(data[0].skills_levels[i]);
          masteredSkills += 1;
        }
      }
    }

    for (let i = 0; i <= 14; i++) {
      if (!dataToRender[i]) {
        dataToRender.push({ total_points: null });
      }
    }
    return (
      <ResponsiveContainer width="100%" height="94%">
        <BarChart width={77} height={40} data={dataToRender}>
          <Tooltip content={CustomTooltip} />
          <Bar dataKey={`${dataLegends.dataKey}`} fill="#19252A" stackId="SD" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
