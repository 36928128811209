import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import Hyperlink from '../designSystem/components/hyperlink';
import { newsletterEmailAPI } from '../API/newsletterEmail';
import DefaultAlert from '../designSystem/components/alerts/defaultAlert';
import { useAuth0 } from '@auth0/auth0-react';
import NewsLetterForm from '../designSystem/components/newsLetterForm/newsLetterForm';
import './layoutFooter.scss';

function Footer(props) {
  const { history } = props;
  const { user } = useAuth0();
  const [errorTitle, setErrorTitle] = useState();
  const [errorState, setErrorState] = useState(false);

  const { change_layout } = props;

  const handleAddNewsletterEmail = async formData => {
    newsletterEmailAPI.POST.addNewsletterEmail(formData)
      .then(response => {
        handleAlert('success', 'Your Email was successfully added');
      })
      .catch(error => {
        const { title, body } = error.response.data;
        handleAlert('error', title, body);
      });
  };

  const errorHandler = (title, body) => {
    setErrorState(true);
    setErrorTitle(title);
    setTimeout(() => {
      setErrorState(false);
    }, 5000);
  };

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  const handleAlert = (severity, title, body) => {
    setDefaultAlert(
      {
        open: true,
        severity,
        title,
        body,
      },
      setTimeout(() => {
        setDefaultAlert({
          open: false,
          severity,
          title,
          body,
        });
      }, 5000),
    );
  };

  const date = new Date();

  React.useEffect(() => {
    const goToTop = history.listen(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    return () => {
      goToTop();
    };
  }, []);

  return (
    <>
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      <Grid container className="footer_wrapper">
        <Grid item xs={12}>
          <div id="footer_wrapper_content">
            <img
              src="/assets/logos/logo.svg"
              alt="Genius Forest Logo"
              className="logo"
              tabIndex="0"
              role="button"
              onClick={() => (window.location.href = '/')}
            />
            {!user && change_layout.footer && !change_layout.footer.hide_newsletter && (
              <NewsLetterForm
                handleAddNewsletterEmail={handleAddNewsletterEmail}
                errorTitle={errorTitle}
                errorState={errorState}
              />
            )}
          </div>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing="2"
          >
            <Grid item>
              <a href="https://www.instagram.com/genius_forest/" className="btn-footer-links">
                <img
                  src="https://geniusforest.blob.core.windows.net/assets/ig_icon.png"
                  alt="instagram"
                  style={{ verticalAlign: 'bottom', marginBottom: '-2px' }}
                />
              </a>
            </Grid>
            <Grid item>
              <a href="https://www.facebook.com/profile.php?id=100084201398190" className="btn-footer-links">
                <img
                  src="https://geniusforest.blob.core.windows.net/assets/fb_icon.png"
                  alt="instagram"
                  style={{ verticalAlign: 'bottom', marginBottom: '-2px' }}
                />
              </a>
            </Grid>
            <Grid item>
              <a href="https://www.linkedin.com/company/genius-forest" className="btn-footer-links">
                <img
                  src="https://geniusforest.blob.core.windows.net/assets/lk_icon.png"
                  alt="instagram"
                  height={24}
                  style={{ verticalAlign: 'bottom', marginBottom: '-1px' }}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr className="footer_line" />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Grid container>
                <Grid item xs={12} sm className="links_wrapper">
                  <button
                    type="button"
                    class="btn-footer-links"
                    onClick={() => history.push('/product')}
                  >
                    Product
                  </button>
                </Grid>
                <Grid item xs={12} sm className="links_wrapper">
                  <button
                    type="button"
                    class="btn-footer-links"
                    onClick={() => history.push('/curriculum')}
                  >
                    Curriculum
                  </button>
                </Grid>
                <Grid item xs={12} sm className="links_wrapper">
                  <button
                    type="button"
                    class="btn-footer-links"
                    onClick={() => history.push('/pricing')}
                  >
                    Pricing
                  </button>
                </Grid>
              </Grid>
            </Box>
            <Box className="copyright_wrapper">
              <div
                tabIndex="0"
                role="application"
                aria-label="2020 Genius Forest"
                className="copyright"
              >
                ©{date.getFullYear()} Genius Forest. Made with <span style={{ color: '#fa4321', fontSize: '12px' }}>♥︎</span> in Toronto.
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(Footer);
