import axios from 'axios';
import url from './url';

export const practiceAPI = {
  GET: {
    getPracticeSkills(topic_id, learner_id, skill_id, token) {
      return axios.get(`${url}api/getPracticeSkills/${topic_id}/${learner_id}/${skill_id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    getPracticeActivities(topic_id, learner_id, token) {
      return axios.get(`${url}api/getPracticeActivities/${topic_id}/${learner_id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
  PUT: {
    getPracticeQuestion(skill_id, difficult_level, answer_sheet, token) {
      console.log(answer_sheet)
      return axios.put(`${url}api/getPracticeQuestion/${skill_id}/${difficult_level}`, {
        answer_sheet,
      }, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
  POST: {
    saveSkillRound(topic_id, learner_id, data, token) {
      return axios.post(`${url}api/saveSkillRound/${topic_id}/${learner_id}`, {
        data,
        headers: { authorization: `Bearer ${token}` },
      });
    },
  },
};
