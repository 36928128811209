import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DefaultDropdown from '../../../../designSystem/components/dropdowns/dropdown';

function SubjectSelection(props) {
  const { subjects, handleSelectSubject } = props;

  const [subjectsList, setSubjectsList] = React.useState([]);
  const [selectedSubjectIndex, setSelectedSubjectIndex] = React.useState(0);

  const formateDropdown = (item, index) => {
    return {
      label: item.id,
      value: index,
      disabled: false,
    };
  };

  const addSubjectsToList = React.useCallback(subjects => {
    const dropdownList = [];
    for (let i = 0; i < subjects.length; i++) {
      dropdownList.push(formateDropdown(subjects[i], i));
    }
    setSubjectsList(dropdownList);
  }, []);

  const handleSubject = index => {
    handleSelectSubject(subjects[index]);
    setSelectedSubjectIndex(index);
  };

  React.useEffect(() => {
    addSubjectsToList(subjects);
  }, [subjects, addSubjectsToList]);

  return (
    // <Grid container className="subject_selection">
    //   <Grid item xs={12}>
    <Grid alignItems="center" className="subject_dropdown_container">
      <Grid item>
        <p className="dropdown_text">Grade</p>
      </Grid>
      <Grid item className="subject_dropdown">
        <DefaultDropdown
          list={subjectsList}
          selectedItem={selectedSubjectIndex}
          handleChange={value => handleSubject(value.target.value)}
          width={80}
          bgColor="white"
        />
      </Grid>
    </Grid>
    //   </Grid>
    // </Grid>
  );
}

SubjectSelection.propTypes = {
  handleSelectSubject: PropTypes.func.isRequired,
  subjects: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default SubjectSelection;
