import React from 'react';
import { Grid } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { paymentAPI } from '../../../API/payment';
import BillingTable from './components/billingTable';
import BillingDialog from './components/billingDialog';
import './billingHistory.scss';

export default function BillingHistory(props) {
  const { subscription } = props;
  const { getAccessTokenSilently } = useAuth0();

  const [data, setData] = React.useState(false);
  const [selectedInvoice, setSelectedInvoice] = React.useState(false);

  const handleViewInvoice = invoice => {
    setSelectedInvoice(invoice);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const fetchData = async () => {
      const token = await getAccessTokenSilently();
      await paymentAPI.POST.getUserInvoices(token, subscription)
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    };
    if (subscription) return fetchData();
  }, [subscription]);

  return (
    <Grid container className="billing-history-wrapper page_wrapper">
      <Grid item xs={12} className="main_box">
        <h1>Billing History</h1>
        <div>
          <BillingTable data={data} handleButton={handleViewInvoice} />
        </div>
      </Grid>
      <BillingDialog
        data={selectedInvoice}
        subscription={subscription}
        handleClose={() => setSelectedInvoice(false)}
      />
    </Grid>
  );
}
