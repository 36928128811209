import React from 'react';
import { Grid } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles } from '@material-ui/core/styles';
import './buttons.scss';

ToggleButtons.defaultProps = {
  fontSize: '16px',
  value: '',
};

export default function ToggleButtons({ value, handleSelection, list, height, width, fontSize }) {
  const toggleStyles = makeStyles(theme => ({
    root: {
      marginRight: '22px',
      color: '#161616',
      height: height,
      fontSize: fontSize,
      fontWeight: 600,
      lineHeight: '16px',
      minWidth: width,
      padding: '0px 5px !important',
      borderWidth: 1,
      borderRadius: 10,
      border: `1px solid ${theme.palette.default.border} !important`,
      fontFamily: theme.fontFamily.default,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.default.hover,
        border: `1px solid ${theme.palette.default.hover}`,
        color: 'white',
      },
      '&:focus': {
        color: theme.palette.default.main,
        boxShadow: `0 0 0 4px white,0 0 0 5px ${theme.palette.default.focus}`,
        backgroundColor: theme.palette.default.focus,
        border: `1px solid ${theme.palette.default.focus}`,
      },
      '&.MuiButton-outlined.Mui-disabled': {
        background: theme.palette.default.disabled,
        color: theme.palette.default.disabledText,
        border: `1px solid ${theme.palette.default.disabledBorder}`,
      },
      '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: theme.palette.default.hover,
        border: `1px solid ${theme.palette.default.hover}`,
        color: '#fff',
      },
    },
    '@media screen and (max-width: 600px)': {
      root: {
        marginRight: '10px',
      },
    },
  }));

  const classes = toggleStyles();

  const capitalizeLabel = label => {
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  return (
    <>
      {list.map((item, index) => (
        <Grid item>
          <ToggleButton
            key={index}
            selected={item.value === value}
            className={classes.root}
            value={item.value}
            aria-label={item.label}
            onClick={event => handleSelection(event, item, index)}
          >
            {capitalizeLabel(item.label)}
          </ToggleButton>
        </Grid>
      ))}
    </>
  );
}

/*

 <ToggleButtons list={toggle_button_list} value={'ones'} handleSelection={() => {}} height={42} width={69} />

 */
