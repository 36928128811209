import React from 'react';
import PropTypes from 'prop-types';

DifficultLevel.propTypes = {
  difficult_level: PropTypes.number.isRequired,
};

export default function DifficultLevel({ difficult_level }) {
  const boxes = [0, 1, 2, 3, 4];

  return (
    <div 
      className="difficult_level_wrapper"
      role="application" 
      aria-label={`Difficult level ${difficult_level}`}
    >
      {boxes.map((item) => (
        <div 
          key={item} 
          className={difficult_level > item ? 'level_box active' : 'level_box'} 
        >

      </div>
      ))}
    </div>
  );
}
