import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DefaultTab from '../../../designSystem/components/tabs/defaultTab';
import SkillBox from '../../../designSystem/components/cards/skillBox';
import NothingToShow from "../../../designSystem/components/messages/notFound/nothingToShow";

function MoreInformation(props) {
  const { skills } = props;
  const [selected_tab, setSelectedTab] = React.useState(0);

  const handleSelectedTab = (event, value) => {
    setSelectedTab(value);
  };

  const info_options = [
    {
      label: 'Trouble Skills',
      value: 0,
    },
    {
      label: 'Skills I am good at',
      value: 1,
    },
  ];

  const calculateScore = skill => {
    const { skill_wrong_attempts, skill_attempts } = skill;
    return Math.round(Math.abs((skill_wrong_attempts / skill_attempts) * 100 - 100));
  };

  const renderTroubleSkills = () => {
    const result = [];
    for (let i = 0; i < skills.length; i++) {
      if (calculateScore(skills[i]) <= 70) {
        result.push(skills[i]);
      }
    }
    return result;
  };

  const renderGoodSkills = () => {
    const result = [];
    for (let i = 0; i < skills.length; i++) {
      if (calculateScore(skills[i]) > 70) {
        result.push(skills[i]);
      }
    }
    return result;
  };

  return (
    <Grid container className="more_information">
      <Grid item xs={12} className="more_info_tab">
        <Grid container direction="row" justify="center" alignItems="center">
          <DefaultTab
            list={info_options}
            selected_tab={selected_tab}
            handleSelectedTab={handleSelectedTab}
            width={300}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="skill_boxes_wrapper">
        <Grid container spacing={3}>
          {selected_tab === 0 && (
            <>
              {renderTroubleSkills().length > 0 ? (
                <>
                  {renderTroubleSkills().map(skill => (
                    <Grid item xs={12} md={6} key={skill.skill_name}>
                      <SkillBox skill={skill} />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item xs={12}>
                  <NothingToShow title="No results" message="Great Job! There are no trouble skills. Keep practicing." />
                </Grid>
              )}
            </>
          )}

          {selected_tab === 1 && (
            <>
              {renderGoodSkills().length > 0 ? (
                <>
                  {renderGoodSkills().map(skill => (
                    <Grid item xs={12} md={6} key={skill.skill_name}>
                      <SkillBox skill={skill} />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item xs={12}>
                  <NothingToShow title="No results" message="Looks like there are no Good skills yet. Keep practicing." />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

MoreInformation.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default MoreInformation;
