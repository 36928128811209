import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '../../../../../designSystem/components/buttons/button';
import Loading from '../../../../../designSystem/components/interactiveElements/loading';
import { paymentAPI } from "../../../../../API/payment";
import EditPaymentForm from './../../../../../designSystem/components/payments/editSdkPaymentForm';
import { useForm } from 'react-hook-form';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditPaymentMethod(props) {
  const {
    open,
    handleClose,
    data,
    subscription,
    popUpOption,
    setPopUpOption,
    handleFinish,
    cardNumber
  } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = React.useState(false);

  const getCardID = () => {
    if (!data.cards) return false;
    if (data.cards) return data.cards[0].id;
    if (data[0].customer.cards) return data[0].customer.cards[0].id;
    return false;
  }

  const handleRemoveCard = async () => {
    const token = await getAccessTokenSilently();
    setIsLoading(true);
    const card_id = getCardID();
    paymentAPI.PUT.removeCustomerCard(token, card_id).then((response) => {
      console.log(response.data)
      setIsLoading(false);
      handleFinish('done', 'Card removed!', 'Your card was successfully removed!');
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
      handleFinish('error', 'Error to remove the card.', 'Please try again later.');
    })
  };

  const getCustomerId = () => {
    if (data.customer) return data.customer.id;
    if (data[0].customer) return data[0].customer.id;
    return false;
  }

  const handleEditCard = async (nonce, paymentMethod, full_name) => {
    const token = await getAccessTokenSilently();
    const subscription_id = subscription.id;
    const customer_id = getCustomerId();
    const card_id = getCardID();
    nonce.full_name = full_name;
    paymentAPI.PUT.editCustomerCard(token, nonce, card_id, customer_id, subscription_id).then((response) => {
      console.log(response);
      setIsLoading(false);
      handleFinish('done', 'Card updated!', 'Your card was successfully updated.');
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
      handleFinish('error', 'Error to updated the card.', 'Please try again later.');
    })
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="xs"
      aria-labelledby="dialog_title"
      aria-describedby="dialog_body"
      className="editPayment"
      disableScrollLock
      style={{ borderRadius: '10px' }}
    >
      <div>
        <DialogTitle id="dialog_title">
          {popUpOption == 0 ? (
            <div className="title">Edit payment method</div>
          ) : popUpOption == 1 ? (
            <div className="title">Remove payment method</div>
          ) : (
            <div className="title">Add payment method</div>
          )}
          <button className="close_dialog" onClick={handleClose} type="button" disabled={isLoading}>
            <span className="sr_only">Close Dialog</span>
            <CloseIcon />
          </button>
        </DialogTitle>
        <DialogContent id="dialog_body" className="body-text">
          <DialogContentText>
            {popUpOption == 0 ? (
              <div className="edit_card_box">
                <EditPaymentForm
                  handleClose={handleClose}
                  setPopUpOption={setPopUpOption}
                  showRemove={getCardID()}
                  handleEditCard={handleEditCard}
                  paymentStatus={isLoading}
                />
              </div>
            ) : (
              <div className="remove_box">
                <div className="card_info">
                  <img
                    src="/assets/icons/Card-Flags-color.svg"
                    alt="cardFlagColor"
                    className="cardFlag"
                  />
                  <p className="card_info_text">Mastercard ending in {cardNumber}</p>
                </div>
                <p className="cancel_warning">
                  If you no longer wish to keep this payment method on your account, click “Confirm
                  remove”.
                </p>
                <div className="buttons-wrapper">
                  {isLoading && (
                    <Loading />
                  )}
                  <Button
                    label="Cancel"
                    onClick={handleClose}
                    width={111}
                    height={44}
                    fontSize={14}
                    buttonStyle="default"
                    disable={isLoading}
                    type="button"
                  />
                  <Button
                    label="Confirm remove"
                    onClick={handleRemoveCard}
                    width={132}
                    height={44}
                    fontSize={14}
                    buttonStyle="primary"
                    disable={isLoading}
                    type="button"
                    addClass="remove_confirm"
                  />
                </div>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
      </div>
      {/* <DialogActions className="buttons-wrapper">
        {isLoading && <Loading />}
        <Button
          label="Cancel"
          onClick={handleClose}
          width={111}
          height={44}
          fontSize={14}
          buttonStyle="default"
          disable={isLoading}
          type="button"
        />
        <Button
          label="Save"
          onClick={handleConfirm}
          width={129}
          height={44}
          fontSize={14}
          buttonStyle="primary"
          disable={isLoading}
          type="button"
        />
      </DialogActions> */}
    </Dialog>
  );
}

export default EditPaymentMethod;
