import React from 'react';
// import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { skillsAPI } from '../../../API/skills';
import Loading from '../../../designSystem/components/interactiveElements/loading';
import ReinforcementBox from './reinforcementBox';
import NothingToShow from "../../../designSystem/components/messages/notFound/nothingToShow";

function ReinforceSkills(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { selectedSubject, learner } = props;
  const [skills, setSkills] = React.useState([]);

  const calculateScore = skill => {
    const { skill_wrong_attempts, skill_attempts } = skill;
    return Math.round((skill_wrong_attempts / skill_attempts) * 100 - 100);
  };

  const calculateLevel = skill => {
    const { skill_points, skill_learner_points } = skill;
    const difficult_level = (skill_learner_points / skill_points) * 100;
    if (difficult_level < 25) return 1; // 'Fundamental';
    if (difficult_level > 25 && difficult_level < 50) return 2; //'Intermediate';
    if (difficult_level > 50 && difficult_level < 75) return 3; //'Advanced';
    if (difficult_level > 75 && difficult_level <= 100) return 4; //'Master';
  };

  const getSkills = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    skillsAPI.GET.getReinforceSkills(token, selectedSubject.id, learner.id)
      .then(response => {
        setSkills(response.data.skills);
      })
      .catch(error => {
        console.log(error);
      });
  }, [selectedSubject, getAccessTokenSilently, learner.id]);

  React.useEffect(() => {
    const fetchTopicsData = async () => {
      await getSkills();
    };
    if (selectedSubject) {
      fetchTopicsData();
    }
  }, [getSkills, selectedSubject]);

  if (skills.length <= 0)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className="pages_wrapper"
      >
        <Loading />
      </Grid>
    );

  const renderReinforceSkills = () => {
    const result = [];
    for (let i = 0; i < skills.length; i++) {
      if (
        skills[i].skill_learner_points !== null &&
        calculateScore(skills[i]) < 70 &&
        calculateLevel(skills[i]) !== 4
      ) {
        result.push(skills[i]);
      }
    }
    return result;
  };

  return (
    <Grid item xs={12} className="my_plan_wrapper">
      {renderReinforceSkills().length === 0 && (
        <div className="nothing_to_show m_bottom_3"><NothingToShow title="No results" message="Great Job! There are no skills to improve yet. Keep practicing." /></div>
      )}
      {renderReinforceSkills().map(item => (
        <div key={item.skill_name}>
          <ReinforcementBox key={item.skill_name} skill={item} {...props} />
        </div>
      ))}
    </Grid>
  );
}

ReinforceSkills.propTypes = {};

export default ReinforceSkills;
