import axios from 'axios';
import url from './url';

const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
export const authAPI = {
  POST: {
    resendEmailVerification(token, data) {
      return axios.post(`${url}api/email/verification`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
    },
    checkUserPassword(email, password) {
      var options = {
        method: 'POST',
        url: 'https://dev-genius-forest.us.auth0.com/oauth/token',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: new URLSearchParams({
          grant_type: 'password',
          username: email,
          password: password,
          audience: 'https://dev-genius-forest/api',
          scope: 'read:sample',
          client_id: clientID,
          client_secret: 'pipIzm-Qh7jvrMHg2INukfwh1NvixCQDNLfKoCT38CE9ZGjzKCmCDJ9oiC3M2b4L',
        }),
      };
      return axios.request(options);
    },
  },
  GET: {
    getUserNumber() {
      return axios.get(`${url}api/users/getUserNumber`);
    },
  },
};
