import React from 'react';
import { Grid } from '@material-ui/core';
import BarGraph from './barChart';
import StackedBarChart from './stackedBarChart';

function PracticeSummary(props) {
  const { practiceSummary } = props;
  const [selectedTab, setSelectedTab] = React.useState(0);

  const headerList = [
    {
      topLegend: 'Points scored by day',
      dataName: 'Points',
      dataKey: 'total_points',
    },
    {
      topLegend: 'Questions answered by day',
      dataName: 'Questions',
      dataKey: 'total_questions',
    },
    {
      topLegend: 'Skill levels by day',
      dataName: 'Skills',
      dataKey: 'level',
    },
    {
      topLegend: 'Total Hours by day',
      dataName: 'Hours',
      dataKey: 'total_time',
    },
  ];

  return (
    <>
      <Grid item xs={12} className="summary_practice">
        <Grid container>
          {practiceSummary.summaryValues.map((item, index) => (
            <Grid
              item
              xs={12}
              md={3}
              key={item.name}
              className={`practice_boxes ${selectedTab === index && 'box_active'}`}
              onClick={() => setSelectedTab(index)}
              onKeyPress={() => setSelectedTab(index)}
              tabIndex="0"
            >
              <div className="titles">
                {selectedTab === index && (
                  <img
                    src="/assets/icons/active-green-icon.png"
                    alt="active"
                    className="active_box"
                  />
                )}
                {item.name}
              </div>
              <div className="values">{item.value}</div>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} className="summary_practice_graph">
        <div className="graph_label">{headerList[selectedTab].topLegend}</div>
        {selectedTab !== 1 ? (
          <BarGraph data={practiceSummary.graphValues} dataLegends={headerList[selectedTab]} />
        ) : (
          <StackedBarChart data={practiceSummary.graphValues} />
        )}
      </Grid>
    </>
  );
}

export default PracticeSummary;
