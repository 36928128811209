import React from "react";
import { useParams } from "react-router-dom";
import AddExistingEmail from "./addExistingEmail";
import AddExistingNameGrade from "./addExistingNameGrade";
import { useHistory } from "react-router-dom";
import { useTheme, useMediaQuery } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={150} ref={ref} {...props} />;
});

export default function AddExisting(props) {
  const { handleAddExistingStudent } = props;
  const [open, setOpen] = React.useState(true);
  const history = useHistory();
  const [selectedAddStudent, setSelectedAddStudent] =
    React.useState(addStudent);

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("501"));
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      history.push('/my-students/');
    }, 150);
  }

  let { addStudent } = useParams();

  React.useEffect(() => {
    if (selectedAddStudent !== addStudent) {
      return setSelectedAddStudent(addStudent);
    }
  }, [selectedAddStudent, addStudent]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      disableScrollLock
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
          position: 'unset',
          margin: 'unset',
        },
      }}
    >
      <DialogContent className='addExistingDialog'>
        <div className="addExisting">
          <img src="/assets/images/add_existing_trees.png" className="tree_image" />
          <button
            role="button"
            className="interactive_element close_button"
            onClick={() => history.push("/my-students/")}
          >
            <img
              src="/assets/icons/close_icon.png"
              width={smScreen ? 11 : 15}
              height={smScreen ? 11 : 15}
            ></img>
          </button>
          <span className="addExistingText">Add existing student account</span>
          {selectedAddStudent ? (
            <AddExistingNameGrade
              handleAddExistingStudent={handleAddExistingStudent}
            />
          ) : (
            <AddExistingEmail />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
