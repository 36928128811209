import React, { useState, useEffect } from 'react';
import SectionOne from './components/section_one';
import SectionTwo from './components/section_two';
import SectionThree from './components/section_three';
import './productPage.scss';

//window size listenrer.
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

const ProductPage = ({ history }) => {
  const size = useWindowSize();

  React.useEffect(() => {
    const gotToTop = history.listen(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    return () => {
      gotToTop();
    };
  }, []);

  return (
    <div className="product_page_wrapper">
      <SectionOne size={size} />
      <SectionTwo />
      <SectionThree size={size} />
    </div>
  );
};

export default ProductPage;
