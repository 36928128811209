import React from 'react';
import { Redirect } from 'react-router-dom';
import ReactGoogleAnalytics from 'react-ga';
import { useAuth0 } from '@auth0/auth0-react';
import { subscriptionAPI } from '../../API/subscription';
// import { paymentAPI } from '../../API/payment';
import PaymentDetails from './components/paymentDetails';
import PlanInformation from './components/planInformation';
import DefaultAlert from '../../designSystem/components/alerts/defaultAlert';
import './subscriptionPayment.scss';

export default function SubscriptionPayment({ history, subscription, match }) {
  const { subscription_plan, number_of_children } = match.params;
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const [data, setData] = React.useState({});
  const userType = React.useState(user['https://www.geniusforest.ca/user_type']);
  const [paymentStatus, setPaymentStatus] = React.useState(false);
  const [promo, setPromo] = React.useState('');
  const [isDisable, setDisable] = React.useState(true);
  const [isPromo, setIsPromo] = React.useState(false);
  const [percentOff, setPercentOff] = React.useState(0);
  const [isError, setIsError] = React.useState(false);
  const handleLogout = () => {
    sessionStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  const handlePromoText = event => {
    event.preventDefault();
    const { value } = event.target;
    setPromo(value);
    if (value == '') {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  const planType = [
    {
      plan: 'Monthly',
      price: 9.99,
      duration: 'month',
    },
    {
      plan: 'Yearly',
      price: 89.91,
      duration: 'year',
    },
  ];

  const handleValidatePromo = async () => {
    const data = {
      code_number: promo,
    };
    try {
      const response = await subscriptionAPI.POST.validatePromoCode(data);
      console.log(response);
      setIsPromo(true);
      setPercentOff(response.data.percent_off);
      handleAlert('success', 'Success', 'Promo code successfully applied.');
      // setIsError(false)
    } catch (error) {
      console.log(error);
      // setIsError(true);
      setIsPromo(false);
      setPromo('');
      handleAlert('error', 'Failure', 'Promo code is not valid, please enter a new code.');
    }
  };

  React.useEffect(() => {
    sessionStorage.setItem('promoData', promo + ',' + calculatePrice(isPromo, percentOff));
  }, [isPromo, percentOff]);

  const handleAlert = (severity, title, body) => {
    setDefaultAlert(
      {
        open: true,
        severity,
        title,
        body,
      },
      setTimeout(() => {
        setDefaultAlert({
          open: false,
          severity,
          title,
          body,
        });
      }, 5000),
    );
  };

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  const calculatePromoPrice = () => {
    const { subscription_plan, number_of_children } = match.params;
    if (parseInt(subscription_plan) === 1) {
      return planType[0].price * parseInt(number_of_children) * (1 - percentOff / 100);
    } else if (parseInt(subscription_plan) === 2) {
      return planType[1].price * parseInt(number_of_children) * (1 - percentOff / 100);
    }
  };

  const calculatePrice = () => {
    const { subscription_plan, number_of_children } = match.params;
    if (isPromo === true) {
      return calculatePromoPrice();
    }
    if (parseInt(subscription_plan) === 1) {
      return planType[0].price * parseInt(number_of_children);
    }
    if (parseInt(subscription_plan) === 2) {
      return planType[1].price * parseInt(number_of_children);
    }
  };

  const formatePaymentFormData = (nonce, card_data, full_name) => {
    let promoData = sessionStorage.getItem('promoData').split(',');
    let usePromo = false;
    if (promoData[0] !== '') {
      usePromo = true;
    }
    const formData = {};
    formData.number_of_children = match.params.number_of_children;
    formData.nonce = nonce.token;
    formData.postalCode = nonce.details.billing.postalCode;
    formData.exp_month = nonce.details.card.expMonth;
    formData.exp_year = nonce.details.card.expYear;
    formData.last4 = nonce.details.card.last4;
    formData.subscription_plan = subscription_plan;
    formData.confirmation = true;
    formData.full_name = full_name;
    formData.email = user.email;
    formData.user_name = user.nickname;
    formData.user_type = user['https://www.geniusforest.ca/user_type'];
    formData.amount = parseFloat(promoData[1]).toFixed(2);
    formData.promo_code = promoData[0];
    formData.usePromo = usePromo;
    // formData.card_data = card_data;
    //later to be changed...
    return formData;
  };

  const handleSubmitPayment = async (nonce, card_data, full_name) => {
    onSubmit(formatePaymentFormData(nonce, card_data, full_name));
  };

  const handleGoBack = () => {
    history.push(`/plan-selection/${subscription_plan}/${number_of_children}`);
  };

  var date = new Date();
  var numMonth = String(date.getMonth() + 2).padStart(2, '0');
  var numDay = String(date.getDate()).padStart(2, '0');
  var year = date.getFullYear();

  const saveData = newData => {
    let copy_data = data;
    copy_data = { ...data, ...newData };
    setData(copy_data);
    return copy_data;
  };

  const onSubmit = formData => {
    formData.reference_number = Math.floor(100000 + Math.random() * 900000000);
    formData.user_id = user.sub;
    // formData.payment_id = subscription.payment_id;
    return submitSubscription(saveData(formData));
  };

  const submitSubscription = async submissionData => {
    const token = await getAccessTokenSilently();
    await subscriptionAPI.POST.createNewSubscription(token, submissionData)
      .then(async response => {
        console.log(response);
        sessionStorage.removeItem('planSelection');
        sessionStorage.removeItem('promoData');
        window.location = '/my-students';
        window.fbq('track', 'CompleteRegistration');
      })
      .catch(error => {
        setPaymentStatus(true);
        console.log(error);
        handleAlert(
          'error',
          'Payment Validation Failed ',
          'There was an error validating the payment. Please re-enter the card information again or, try again later.',
        );
      });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
    ReactGoogleAnalytics.event({
      category: 'Signup subscription',
      action: 'Start signup subscription',
      label: 'Signup subscription',
    });
    window.fbq('track', 'AddPaymentInfo');
  }, []);

  if (!subscription && userType === 'learner') return <Redirect to="/ask-parent" />;
  if (subscription && subscription.user_type === 'parent') return <Redirect to="/my-students" />;
  if (subscription && subscription.user_type === 'learner') return <Redirect to="/ask-parent" />;

  return (
    <div className="payment_section_wrapper">
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      <div className="payment_box">
        <img src="/assets/images/plan_selection_trees.png" className="tree_image" />
        <button role="button" className=" interactive_element back_button" onClick={handleGoBack}>
          <img src="/assets/icons/go_back_arrow_dark.png" width={22.62} height={15.08}></img>
        </button>
        <button role="button" className="interactive_element close_button" onClick={handleLogout}>
          <img src="/assets/icons/close_icon.png" width={15} height={15}></img>
        </button>
        <span className="try_genius_forest">Try Genius Forest for free</span>
        <span className="thirty_day_trial">30 days free trial, cancel anytime</span>
        <PaymentDetails
          handleSubmitPayment={handleSubmitPayment}
          planInformation={
            <PlanInformation
              number_of_children={number_of_children}
              planNum={subscription_plan - 1}
              planType={planType}
              totalPrice={calculatePrice(isPromo, percentOff)}
              handlePromoText={handlePromoText}
              isDisable={isDisable}
              promo={promo}
              handleValidatePromo={handleValidatePromo}
              isPromo={isPromo}
              isError={isError}
            />
          }
          paymentStatus={paymentStatus}
        />
        <span className="accountCharge">
          Your account will be charged ${calculatePrice(isPromo, percentOff).toFixed(2)} on {year}-
          {numMonth}-{numDay}. Cancel anytime.
        </span>
      </div>
    </div>
  );
}
