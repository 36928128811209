import React, { useState, useEffect } from 'react';
import Button from '../../../designSystem/components/buttons/button';
import { useHistory } from 'react-router-dom';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

const Section_one = () => {
  const size = useWindowSize();
  let history = useHistory();

  const buttonHandler = action => {
    if (action === 'enter') {
      document.getElementById('product_section_one_button_cover').classList.add('active');
      document.getElementById('product_section_one_button').classList.add('active');
    } else {
      document.getElementById('product_section_one_button_cover').classList.remove('active');
      document.getElementById('product_section_one_button').classList.remove('active');
    }
  };

  const mouseEnterHandler = () => {
    if (size.width > 1400) {
      console.log('mouse entered');
      document.getElementById('section_one_bg_img_one').classList.add('active');
      document.getElementById('section_one_bg_img_two').classList.add('active');
      document.getElementById('section_one_bg_img_three').classList.add('active');
      document.getElementById('section_one_bg_img_four').classList.add('active');
      document.getElementById('section_one_bg_img_five').classList.add('active');
    }
  };
  const mouseLeaveHandler = () => {
    if (size.width > 1400) {
      document.getElementById('section_one_bg_img_one').classList.remove('active');
      document.getElementById('section_one_bg_img_two').classList.remove('active');
      document.getElementById('section_one_bg_img_three').classList.remove('active');
      document.getElementById('section_one_bg_img_four').classList.remove('active');
      document.getElementById('section_one_bg_img_five').classList.remove('active');
    }
  };

  return (
    <div className="section_one_wrapper page_wrapper">
      <img
        src="/assets/images/section_one_bg_img_one.png"
        alt="section_one_bg_img_one"
        className="section_one_bg_img_one active"
        id="section_one_bg_img_one"
      />
      <img
        src="/assets/images/section_one_bg_img_two.png"
        alt="section_one_bg_img_two"
        className="section_one_bg_img_two active"
        id="section_one_bg_img_two"
      />
      <img
        src="/assets/images/section_one_bg_img_three.png"
        alt="section_one_bg_img_three"
        className="section_one_bg_img_three active"
        id="section_one_bg_img_three"
      />
      <img
        src="/assets/images/section_one_bg_img_four.png"
        alt="section_one_bg_img_four"
        className="section_one_bg_img_four active"
        id="section_one_bg_img_four"
      />
      <img
        src="/assets/images/section_one_bg_img_five.png"
        alt="section_one_bg_img_five"
        className="section_one_bg_img_five active"
        id="section_one_bg_img_five"
      />
      <div className="section_one_content_container">
        <div
          className="section_one_card"
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          <div className="section_one_text">
            <h2>Curriculum-aligned Mathematics</h2>
            <h3>
              Genius Forest measures over 50 math skills which follow curriculum standards for
              grades 6 to 8.
            </h3>
          </div>
          <div className="section_one_buttons_wrapper">
            <div
              className="sign_up_button_wrapper"
              onMouseEnter={() => {
                buttonHandler('enter');
              }}
              onMouseLeave={() => {
                buttonHandler('leave');
              }}
              onClick={() => history.push('/pre-signup')}
              style={{ cursor: 'pointer' }}
              onFocus={() => {
                buttonHandler('enter');
              }}
              onBlur={() => {
                buttonHandler('leave');
              }}
            >
              <div className="sign_up_button_cover" id="product_section_one_button_cover"></div>
              <button
                className="sign_up_button"
                id="product_section_one_button"
                onClick={() => history.push('/pre-signup')}
              >
                Sign up for free
              </button>
            </div>
            <Button
              label="View features"
              width={149}
              height={57}
              buttonStyle="default"
              onClick={() => history.push('/product')}
              fontSize={16}
              addClass="try_demo"
            />
          </div>
        </div>
        <img
          src="/assets/images/curriculum_top_bg.png"
          alt="curriculum background"
          className="curriculum_top_bg"
        />
      </div>
    </div>
  );
};

export default Section_one;
