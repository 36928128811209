import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import HeaderBar from './headerBar';
import QuestionOutlineBox from './questionOutlineBox';
import QuestionSelection from '../../../../questionModels/questionSelection';
import TimeLapsed from '../../../../designSystem/components/interactiveElements/timeElapsed';
import QuestionScore from '../../../../designSystem/components/interactiveElements/questionScore';
import QuestionResultMessage from '../../../../designSystem/components/interactiveElements/questionResultMessage';
import AnimatedNextButton from '../../../../designSystem/components/buttons/animated-next-button';
import Button from '../../../../designSystem/components/buttons/button';
import CloseIcon from '@material-ui/icons/Close';
import QuestionOutlineMobile from './questionOutlineMobile';
import QuestionOutlineBoxMobile from './questionOutlineBoxMobile';
import AnswerHelper from '../../../../designSystem/components/interactiveElements/answerHelper';
import Loading from "../../../../designSystem/components/interactiveElements/loading";

function Practice(props) {
  let {
    match,
    history,
    getNextQuestion,
    updatePracticeController,
    saveRound,
    finishPractice,
    restart_test,
    answer_sheet: { skills, tasks },
    practice_controller: {
      current_skill,
      current_task,
      current_question,
      current_question_number,
      score,
      top_progress_bar,
    },
    loading
  } = props;
  const { topic_id } = match.params;

  const [error_message, setErrorMessage] = React.useState({
    message: '',
    error: null,
  });

  const [go_to_report, setGoToReport] = React.useState(0);
  const [total_time, setResetTotalTime] = React.useState(0);
  const [pause_time, setPauseTimer] = React.useState(false);
  const [reset_timer, setResetTimer] = React.useState(false);
  const [open_mobile_outline, setOpenMobileOutline] = React.useState(false);
  const [display_next_button, setDisplayNext] = React.useState(false);

  const calculateDifficultLevel = skill => {
    const learner_points = skill.skill_learner_points ? skill.skill_learner_points : 0;
    const difficult_level = (learner_points / skill.skill_points) * 100;
    if (difficult_level <= 20) return 1;
    if (difficult_level > 20 && difficult_level <= 40) return 2;
    if (difficult_level > 40 && difficult_level <= 60) return 3;
    if (difficult_level > 60 && difficult_level <= 80) return 4;
    if (difficult_level > 80) return 5;
  };

  const setValidationControlItem = (name, value) => {
    const { validation_control } = current_question;
    validation_control[name] = value;
    updatePracticeController('current_question', current_question);
  };

  const calculatePoints = IS_THE_FIRST_ATTEMPT => {
    const { validation, wrong_attempts, time_spent } = current_question.validation_control;

    let possible_points = current_question.points;
    // TODO: Penalty points should come from the database.
    // Change the penalty points in the future ADMIN dashboard
    let penalty_points = (10 / 100) * possible_points;

    if (IS_THE_FIRST_ATTEMPT && validation)
      return setValidationControlItem('points', possible_points);
    if (IS_THE_FIRST_ATTEMPT && !validation) return setValidationControlItem('points', 0);

    if (time_spent > 50000) {
      possible_points -= penalty_points;
    }

    if (wrong_attempts > 0) {
      const percentage = `${wrong_attempts}0`;
      penalty_points = (parseInt(percentage, 10) / 100) * possible_points;
    }

    possible_points -= penalty_points;
    const result = possible_points < 0 ? 0 : possible_points;

    setValidationControlItem('points', result);

    return result;
  };

  const updateProgressBar = result => {
    top_progress_bar[current_question_number] = result;
    updatePracticeController('top_progress_bar', top_progress_bar);
  };

  const calculateSkillScore = (result, IS_THE_FIRST_ATTEMPT) => {
    const NUMBER_OF_QUESTIONS = 10;
    let penalty = 0;

    if (IS_THE_FIRST_ATTEMPT && !result) {
      penalty = (1 / NUMBER_OF_QUESTIONS) * 100;
    }

    score = Math.round(score - penalty);
    updatePracticeController('score', score);
  };

  const getElapsedTime = time => {
    setResetTotalTime(time);
    setValidationControlItem('total_time', time - total_time);
    setPauseTimer(false);
  };

  const updateQuestionValidation = result => {
    const {
      validation,
      attempts_counter,
      wrong_attempts,
      submitted,
    } = current_question.validation_control;

    const IS_THE_FIRST_ATTEMPT = validation === null;
    const GOT_FIRST_RIGHT = top_progress_bar[current_question_number];

    if (IS_THE_FIRST_ATTEMPT) {
      setValidationControlItem('validation', result);
      setValidationControlItem('attempts_counter', attempts_counter + 1);
      updateProgressBar(result);
      setPauseTimer(true);
    }

    if (!GOT_FIRST_RIGHT && !result && !submitted) {
      setValidationControlItem('wrong_attempts', wrong_attempts + 1);
      setValidationControlItem('attempts_counter', attempts_counter + 1);
      setPauseTimer(true);
    }

    if (!GOT_FIRST_RIGHT && result) {
      if (!submitted) {
        setValidationControlItem('attempts_counter', attempts_counter + 1);
        setPauseTimer(true);
      }
      setValidationControlItem('submitted', true);
    }

    calculatePoints(IS_THE_FIRST_ATTEMPT);
    calculateSkillScore(result, IS_THE_FIRST_ATTEMPT);
  };

  const validateAnswer = selected_answer => {
    const CORRECT_ANSWER = current_question.answer
      .toString()
      .toLowerCase()
      .trim()
      .replace(/ /g, '');
    return (
      selected_answer
        .toString()
        .toLowerCase()
        .trim()
        .replace(/ /g, '') === CORRECT_ANSWER
    );
  };

  const sendResultMessages = result => {
    if (result) {
      setErrorMessage({
        message: 'Nice job!',
        error: false,
      });
    } else {
      setErrorMessage({
        message: 'Nope that wasn’t it! Take another look.',
        error: true,
      });
    }
  };

  const sendEmptyAnswerMessage = () => {
    const { question_type } = current_question;
    if (question_type === 'model_one') {
      setErrorMessage({
        message: 'Please select one answer',
        error: true,
      });
    } else {
      setErrorMessage({
        message: 'Please type your answer',
        error: true,
      });
    }
  };

  const checkAnswer = () => {
    const { selected_answer } = current_question.validation_control;

    if (selected_answer !== null) {
      setDisplayNext(true);
      const RESULT = validateAnswer(selected_answer);
      sendResultMessages(RESULT);
      updateQuestionValidation(RESULT);
    } else {
      sendEmptyAnswerMessage();
    }
  };

  const resetErrorMessage = () => {
    setErrorMessage({
      message: '',
      error: null,
    });
  };

  const handleNextTasks = () => {
    if (current_question_number === 2) {
      console.log('**** Change task ****');
      if (tasks[current_task + 1] !== undefined) {
        updatePracticeController('current_task', current_task + 1);
        return current_task + 1;
      }
    }
    if (current_question_number === 5) {
      console.log('**** Change task ****');
      if (tasks[current_task + 1] !== undefined) {
        updatePracticeController('current_task', current_task + 1);
        return current_task + 1;
      }
    }
    if (current_question_number === 9) {
      console.log('**** Reset task ****');
      updatePracticeController('current_task', 0);
      return 0;
    }
    return current_task;
  };

  const handleNextSkill = () => {
    const LAST_SKILL_QUESTION = current_question_number === 9;
    const LAST_SKILL = current_skill === skills.length - 1;

    if (LAST_SKILL_QUESTION) {
      if (LAST_SKILL) {
        saveRound(current_skill);
        finishPractice();
        setGoToReport(true);
        return false;
      } else {
        saveRound(current_skill);
        return current_skill + 1;
      }
    } else {
      updatePracticeController('current_question_number', current_question_number + 1);
    }
    return current_skill;
  };

  const submitQuestion = async () => {
    setDisplayNext(false);
    resetErrorMessage();
    const next_task = handleNextTasks();
    const next_skill = handleNextSkill();
    console.log('Practice test task: ' + next_task);
    if (next_skill !== false) {
      getNextQuestion(
        next_task,
        skills[next_skill],
        calculateDifficultLevel(skills[next_skill]),
        next_skill,
      );
    }
  };

  React.useEffect(() => {
    setResetTimer(restart_test);
    setResetTotalTime(0);
  }, [restart_test, loading]);

  return (
    <div className="diagnostic_wrapper">
      <HeaderBar
        {...props}
        handleHederButton={() => history.push('/diagnostic/6/NumberSenseandNumeration/1')}
      />
      {/* Practice Body */}
      <Grid container className="page_wrapper m_top_3 m_bottom_3">
        {/* Skill Outline */}
        <Grid item xs={4} md={5} lg={4} className="skill_outline_desktop">
          <h2 className="skill_outline_title">Skill Outline</h2>
          <hr className="custom_hr skill_title_hr" />
          {skills.map((item, index) => (
            <Grid container key={index}>
              {/*  EXPLAIN: There are some missing skills questions, so if there is no tasks means no questions. */}
              {item.tasks.length > 0 && (
                <QuestionOutlineBox question_number={index + 1} {...props} skill={item} />
              )}
            </Grid>
          ))}
        </Grid>

        {open_mobile_outline && (
          <Grid item xs={12} className="question_outline_box-mobile question_outline_mobile ">
            <button className="close_outline" onClick={() => setOpenMobileOutline(false)}>
              <CloseIcon fontSize="sm" />
            </button>
            <h5 className="title">Outline</h5>
            {skills.map((item, index) => (
              <>
                <QuestionOutlineBoxMobile
                  key={index}
                  question_number={index + 1}
                  {...props}
                  skill={item}
                />
                {index < skills.length - 1 && <hr className="question_outline_hr" />}
              </>
            ))}
          </Grid>
        )}

        <Grid item xs>
          <Grid
            container
            className="question_top_section"
            direction="row"
            alignItems="center"
            spacing={1}
          >
            {' '}
            {/* Question Outline-Mobile */}
            {!open_mobile_outline && (
              <Grid item className="question_outline_mobile">
                <QuestionOutlineMobile
                  task={skills[current_skill] && skills[current_skill].tasks[current_task]}
                  handleExpansion={() => setOpenMobileOutline(true)}
                />
              </Grid>
            )}
            <Grid item>
              <TimeLapsed
                getElapsedTime={getElapsedTime}
                pause_time={pause_time}
                reset_timer={reset_timer}
              />
            </Grid>
            <Grid item>
              <QuestionScore score={score} />
            </Grid>
          </Grid>

          {/* Question Body */}
          <Grid container style={{ position: 'relative' }}>
            <Grid item xs={12} className="practice_question_body">
              <QuestionSelection
                question={current_question}
                handleAnswer={(event, value) => setValidationControlItem('selected_answer', value)}
              />
            </Grid>

            {/* Check Button */}
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
                className={
                  error_message.error !== null
                    ? 'position_relative m_top_9 check_buttons_container'
                    : 'position_relative check_buttons_container'
                }
              >
                {error_message.error !== null && (
                  <QuestionResultMessage
                    message={error_message.message}
                    error={error_message.error}
                  />
                )}
                <Grid item className="check_buttons_wrapper">
                  <Button
                    label="Check"
                    addId="check_button"
                    onClick={checkAnswer}
                    width={window.innerWidth < 600 ? '90%' : 90}
                    height={44}
                    buttonStyle="secondary"
                    type="button"
                  />
                </Grid>
                <AnswerHelper />
                <Grid item className="check_buttons_wrapper">
                  {go_to_report === true && (
                    <Button
                      label={loading ? 'Saving diagnostic test' : 'Next Step: Tailored Plan'}
                      addId="tailored plan"
                      onClick={() => history.push(`/diagnostic-report/${topic_id}`)}
                      width={window.innerWidth < 600 ? '100%' : 336}
                      height={44}
                      buttonStyle="primary"
                      type="button"
                      disable={loading}
                    />
                  )}
                  {loading && (
                    <Loading />
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* Animated Next Button */}
            {!go_to_report && display_next_button && (
              <AnimatedNextButton
                handleClick={submitQuestion}
                mobile_break_point={695}
                addClass="next_question_button"
                show_animation={true}
                type="white"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

Practice.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      topic_id: PropTypes.string.isRequired,
    }),
  }),
};

export default Practice;
