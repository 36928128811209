import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Divider } from '@mui/material';
import Button from '../../designSystem/components/buttons/button';
import DefaultAlert from '../../designSystem/components/alerts/defaultAlert';
import { useTheme, useMediaQuery, Grid } from '@material-ui/core';
import { useHistory, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './planSelection.scss';

export default function PlanSelection(props) {
  const { subscription, match } = props;
  const theme = useTheme();
  const sScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { user, logout } = useAuth0();
  const [userType] = React.useState(user['https://www.geniusforest.ca/user_type']);

  let history = useHistory();

  const handleAlert = (severity, title, body) => {
    setDefaultAlert(
      {
        open: true,
        severity,
        title,
        body,
      },
      setTimeout(() => {
        setDefaultAlert({
          open: false,
          severity,
          title,
          body,
        });
      }, 5000),
    );
  };

  const [defaultAlert, setDefaultAlert] = React.useState({
    open: false,
    severity: 'success',
    title: '',
    body: '',
  });

  const getNumberOfChildren = () => {
    return match.params.number_of_children ? Number(match.params.number_of_children) : 1;
  };

  const getSubscriptionPlan = () => {
    return match.params.subscription_plan ? Number(match.params.subscription_plan) : 2;
  };

  const [number_of_children, setNumberOfChildren] = React.useState(getNumberOfChildren());
  const [subscription_plan, setSubscriptionPlan] = React.useState(getSubscriptionPlan());

  const planType = [
    {
      plan: 'Monthly',
      price: 9.99,
      duration: 'month',
    },
    {
      plan: 'Yearly',
      price: 89.91,
      duration: 'year',
    },
  ];

  const totalPrice = planType[subscription_plan - 1].price * number_of_children;

  const handleLogout = () => {
    sessionStorage.clear();
    Cookies.remove('parent');
    Cookies.remove('learner');
    Cookies.remove('parent_selected_learner');
    logout({ returnTo: window.location.origin });
  };

  const handleChange = e => {
    const { value } = e.target;
    if (value === 'monthly') {
      setSubscriptionPlan(1);
    } else {
      setSubscriptionPlan(2);
    }
  };

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var date = new Date();
  var month = date.getMonth() + 1;
  var numMonth = String(date.getMonth() + 2).padStart(2, '0');
  var numDay = String(date.getDate()).padStart(2, '0');
  var day = date.getDate();
  var year = date.getFullYear();

  const studentNumberHandler = action => {
    if (action === 'minus') {
      if (number_of_children > 1) {
        setNumberOfChildren(number_of_children - 1);
      }
    } else {
      if (number_of_children < 50) {
        setNumberOfChildren(number_of_children + 1);
      } else {
        handleAlert('error', 'Error', 'You can only add maximum 50 students.');
      }
    }
  };

  const handleNextButton = () => {
    history.push(`/subscription-payment/${subscription_plan}/${number_of_children}`);
  };

  if (!subscription && userType === 'learner') return <Redirect to="/ask-parent" />;
  if (subscription && subscription.user_type === 'parent') return <Redirect to="/my-students" />;
  if (subscription && subscription.user_type === 'learner')
    return <Redirect to="/ask-parent" />;

  return (
    <div className="plan_section_wrapper">
      <DefaultAlert
        open={defaultAlert.open}
        severity={defaultAlert.severity}
        title={defaultAlert.title}
        body={defaultAlert.body}
      />
      <div className="payment_box">
        <img src="/assets/images/plan_selection_trees.png" className="tree_image" />
        <button role="button" className="interactive_element close_button" onClick={handleLogout}>
          <img src="/assets/icons/close_icon.png" width={15} height={15}></img>
        </button>
        <span className="try_genius_forest">Try Genius Forest for free</span>
        <span className="thirty_day_trial">30 days free trial, cancel anytime</span>
        <span className="select_students">Select number of students</span>
        <div className="student_number_container">
          <button
            role="button"
            className="button"
            onClick={() => {
              studentNumberHandler('minus');
            }}
          >
            <img
              src="/assets/icons/minus_icon.png"
              alt="minus_icon"
              className="billing_card_icons"
              style={{ cursor: 'pointer' }}
            />
          </button>
          <div className="student_number_container">
            <p>{number_of_children}</p>
          </div>
          <button
            role="button"
            className="button"
            onClick={() => {
              studentNumberHandler('plus');
            }}
          >
            <img
              src="/assets/icons/plus_icon.png"
              alt="plus_icon"
              className="billing_card_icons"
              style={{ cursor: 'pointer' }}
            />
          </button>
        </div>
        <div className="grid">
          <div className="save_percent">SAVE 25%</div>
          <label className="card">
            <input
              name="plan"
              className="radio"
              type="radio"
              value="yearly"
              checked={subscription_plan === 2}
              onClick={handleChange}
            />
            <span className="plan_details">
              <span className="plan_type">
                Yearly Plan<span className="striked">$119.88</span>
              </span>
              <span className="plan_price">${planType[1].price}</span>
              <span className="plan_length">/{planType[1].duration}, per student</span>
              <span className="bill_freq">Billed every 12 months</span>
            </span>
          </label>
          <label className="card">
            <input
              name="plan"
              className="radio"
              type="radio"
              checked={subscription_plan === 1}
              value="monthly"
              onClick={handleChange}
            />
            <span className="plan_details">
              <span className="plan_type">Monthly Plan</span>
              <span className="plan_price">${planType[0].price}</span>
              <span className="plan_length">/{planType[0].duration}, per student</span>
              <span className="bill_freq">Billed every month</span>
            </span>
          </label>
        </div>
        <div className="order_div">
          <span className="order_summary">Order Summary</span>
          <span className="summary_plan">{planType[subscription_plan - 1].plan} plan</span>
          <span className="price_calc">
            ${planType[subscription_plan - 1].price}{' '}
            <span className="frequency">/{planType[subscription_plan - 1].duration}</span>
            <span className="studentCount">&nbsp;x {number_of_children} students</span>
          </span>
        </div>
        <Divider className="divider" />
        <Grid
          container
          className="pricing_grid"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <span className="due_current">
              Due {monthNames[month]} {day} {year}
            </span>
          </Grid>
          <Grid>
            <span className="total_price">${totalPrice.toFixed(2)}</span>
          </Grid>
        </Grid>
        <Grid
          container
          className="pricing_grid"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <span className="due_today">Due Today</span>
          </Grid>
          <Grid>
            <span className="today_price">$0.00</span>
          </Grid>
        </Grid>

        <div className="next_button">
          <Button
            label="Next"
            width={sScreen ? 345 : 550}
            height={sScreen ? 42 : 44}
            fontSize={16}
            buttonStyle="primary"
            className="next_button"
            onClick={handleNextButton}
          />
        </div>
        <span className="accountCharge">
          Your account will be charged ${totalPrice.toFixed(2)} on {year}-{numMonth}-{numDay}.
          Cancel anytime.
        </span>
      </div>
    </div>
  );
}
