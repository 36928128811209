import React from 'react';
import PropTypes from 'prop-types';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import CarouselContent from './carouselContent';
import './carousel.scss';

Default_carousel.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
    })
  ).isRequired,
};

function Default_carousel({ list }) {
  React.useEffect(() => {
    window.setTimeout(function () {
      if (sliderValue < list.length) {
        setSliderValue(sliderValue + 1);
      } else {
        setSliderValue(0);
      }
    }, 20000);
  });

  const [sliderValue, setSliderValue] = React.useState(0);

  const sliderChange = (value) => {
    setSliderValue(value);
  };

  return (
    <>
      <Carousel
        value={sliderValue}
        slidesPerPage={2}
        infinite
        addArrowClickHandler
        draggable={false}
        breakpoints={{
          1300: {
            slidesPerPage: 1,
          },
        }}
      >
        {list.map((item, index) => {
          return <CarouselContent key={index} title={item.title} body={item.body} imageUrl={item.imageUrl} learn_more={item.learn_more} />;
        })}
      </Carousel>
      <Dots value={sliderValue} onChange={sliderChange} number={list.length} />
    </>
  );
}

export default Default_carousel;
