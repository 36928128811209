import React from 'react';
import TextField from '../../../../designSystem/components/textFields/textField';
import Button from '../../../../designSystem/components/buttons/button';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from 'react-hook-form';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { learnersAPI } from '../../../../API/learners';

export default function AddExistingEmail() {
  const { getAccessTokenSilently } = useAuth0();
  const useFormMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormMethods;

  const history = useHistory();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('501'));

  const handleNextButton = formData => {
    sessionStorage.setItem('studentInfo', formData.email);
    history.push('/my-students/add-existing/name-grade');
  };
  const checkValue = async value => {
    const data = {
      student_email: value,
    };
    const token = await getAccessTokenSilently();

    const response = await learnersAPI.POST.validateLearnerEmail(data, token);
    console.log(response);
    return response;
  };
  const handleFormSubmit = formData => {
    handleNextButton(formData);
  };
  return (
    <div>
      <span className="enter_email">Enter your student's email address</span>
      <div className="studentCodeField">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <TextField
            id="studentEmail"
            placeholder="Enter your email"
            type="text"
            name="email"
            width={smScreen ? 250 : 390}
            requiredField
            helperText={errors.email ? errors.email.message : ''}
            error={!!errors.email}
            register={register({
              required: 'Email is required',
              maxLength: { value: 100, message: 'Max length 100' },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email format',
              },
              validate: {
                value: async value => {
                  const res = await checkValue(value);
                  if (res.status !== 200) {
                    return res.data.message;
                  } else {
                    return true;
                  }
                },
              },
            })}
          />
          <Button
            buttonStyle="primary"
            label="Next"
            height={50}
            width={smScreen ? 250 : 390}
            type="submit"
            onClick={() => {}}
          />
        </form>
      </div>
    </div>
  );
}
