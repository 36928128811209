import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../designSystem/components/buttons/button';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={150} ref={ref} {...props} />;
});

export default function AddStudent(props) {
  const history = useHistory();
  const [selectedAddStudent, setSelectedAddStudent] = React.useState(addStudent);

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('501'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = React.useState(true);
  let { addStudent } = useParams();

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      history.push('/my-students/');
    }, 150);
  };

  const getActiveLearners = (learners) => {
    let result = [];
    for (let i = 0; i < learners.length; i++) {
      console.log(learners[i])
      if (learners[i].status) {
        result.push(learners[i])
      }
    }
    console.log(result)
    return result;
  }

  const getStudentsAvailable = () => {
    const activeLearners = getActiveLearners(props.subscription.learners)
    const totalStudents = props.subscription.empty_learners.length + activeLearners.length;
    const onboardStudents = activeLearners.length;
    return totalStudents - onboardStudents;
  }


  React.useEffect(() => {
    if (selectedAddStudent !== addStudent) {
      return setSelectedAddStudent(addStudent);
    }
  }, [selectedAddStudent, addStudent]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      disableScrollLock
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
          position: 'unset',
          margin: 'unset',
        },
      }}
    >
      <DialogContent className='addStudentDialog'>
        <div className="addStudent">
          <img src="/assets/images/add_existing_trees.png" className="tree_image" />
          <button
            role="button"
            className="interactive_element close_button"
            onClick={() => history.push('/my-students/')}
          >
            <img
              src="/assets/icons/close_icon.png"
              width={smScreen ? 11 : 15}
              height={smScreen ? 11 : 15}
            ></img>
          </button>
          <span className="addStudentText">Add a student</span>
          <span className="membership_text">
            You have (
            {props.subscription &&
              getStudentsAvailable()}
            ) memberships available
          </span>
          <div className="button1">
            <Button
              width={xsScreen ? 280 : 326}
              fontSize={xsScreen ? 14 : 16}
              height={44}
              label="Connect an existing student account"
              buttonStyle="default"
              onClick={() => history.push('/my-students/add-existing')}
              addClass="button"
            />
          </div>
          <div className="button2">
            <Button
              label="Create a new student account"
              width={xsScreen ? 280 : 326}
              height={44}
              fontSize={xsScreen ? 14 : 16}
              addClass="button"
              onClick={() => history.push('/my-students/add-new')}
              buttonStyle="default"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
