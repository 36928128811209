import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Grid, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../designSystem/components/buttons/button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
// import TextField from '../designSystem/components/textFields/textField';
import CollapsedMenu from './components/collapsedMenu';
import SideNavBar from './components/sideNavBar';
import { alertMessage } from '../utils/accessibilityHelper';
import { assets } from '../utils/config';
import './layoutTopNav.scss';

function Header({ history, subscription, change_layout }) {
  const { loginWithRedirect, isAuthenticated, logout, user, getIdTokenClaims } = useAuth0();
  const activeItem = window.location.pathname;

  const [menuState, setMenuState] = React.useState(false);
  const [userRoles, setUserRoles] = React.useState(false);

  const checkUserPrivileges = React.useCallback(async () => {
    if (user) {
      await getIdTokenClaims().then(response => {
        const userRoles = response[process.env.REACT_APP_AUTH_ROLES_NAMESPACE];
        if (userRoles.includes('teacher')) {
          setUserRoles(userRoles);
        }
      });
    }
  }, [user]);

  const handleActiveItems = pathName => {
    return activeItem.replace('-', ' ').includes(pathName)
      ? 'header_items active_item'
      : 'header_items';
  };

  let collapsedMenuItems = [
    {
      name: 'my-students',
      label: 'My Students',
      url: 'my-students',
    },
    {
      name: 'account-settings',
      label: 'Account Settings',
      url: 'account/settings',
    },
    {
      name: 'membership-&-billing',
      label: 'Membership & Billing',
      url: 'account/membership-and-billing',
    },
    {
      name: 'log-out',
      label: 'Log out',
      url: '',
    },
  ];

  let topNavLinks = [
    {
      id: 'product',
      mobile_id: 'side_menu_product',
      name: 'Product',
      url: 'product',
      private: false,
    },
    {
      id: 'pricing',
      mobile_id: 'side_menu_pricing',
      name: 'Pricing',
      url: 'pricing',
      private: false,
    },
    {
      id: 'curriculum',
      mobile_id: 'side_menu_curriculum',
      name: 'Curriculum',
      url: 'curriculum',
      private: false,
    },
    {
      id: 'faq',
      mobile_id: 'side_menu_faq',
      name: 'FAQ',
      url: 'faq',
      private: false,
    },
    {
      id: 'contact_us',
      mobile_id: 'side_menu_contact_us',
      name: 'Contact Us',
      url: 'contact-us',
      private: false,
    },
  ];

  let userName = subscription && subscription.user_name;

  if (subscription && subscription.user_id === user.sub) {
    topNavLinks = [];
  }

  if (userRoles) {
    topNavLinks = [];
  }

  const getUserName = () => {
    if (user.name.includes('@')) return user.nickname;
    return user.name;
  };

  let userType = '';

  if (isAuthenticated) {
    userName = getUserName();
    userType = user['https://www.geniusforest.ca/user_type'];

    if (userType === 'learner') {
      topNavLinks = [];
      collapsedMenuItems = [
        {
          name: 'account-settings',
          label: 'Account Settings',
          url: `account/settings/${user.sub}`,
        },
        {
          name: 'log-out',
          label: 'Log out',
          url: '',
        },
      ];
    }
    if (userType === 'learner' && !subscription) {
      collapsedMenuItems = [
        {
          name: 'student-signup',
          label: 'Onboarding',
          url: 'ask-parent',
        },
        {
          name: 'log-out',
          label: 'Log out',
          url: '',
        },
      ];
    }
    if (subscription && userType === 'learner') {
      topNavLinks = [
        {
          id: 'my_practice',
          mobile_id: 'side_menu_my_practice',
          name: 'Practice',
          url: 'my-practice',
          private: true,
        },
        {
          id: 'my_progress',
          mobile_id: 'side_menu_my_progress',
          name: 'My Progress',
          url: 'my-progress',
          private: true,
        },
      ];
    }
  }

  const [openCollapsedMenu, setOpenCollapsedMenu] = React.useState(false);

  const changeBodyColor = open => {
    if (open) {
      document.getElementById('app_wrapper').classList.add('body_with_menu_open');
      document.documentElement.classList.add('disable_scroll');
    } else {
      document.getElementById('app_wrapper').classList.remove('body_with_menu_open');
      document.documentElement.classList.remove('disable_scroll');
    }
  };

  const handleCollapsedHeader = event => {
    if (event.keyCode === 9) changeBodyColor(false);
    if (event.type === 'click') {
      setOpenCollapsedMenu(!openCollapsedMenu);
      alertMessage(
        openCollapsedMenu ? 'Collapsed header, collapsed,' : 'Collapsed header, expanded,',
      );
      changeBodyColor(!openCollapsedMenu);
    } else {
      var navItems = document.querySelectorAll('.collapsed_menu_items');
      switch (event.keyCode) {
        case 13:
        case 32:
        case 40:
          if (!menuState) {
            event.preventDefault();
            setOpenCollapsedMenu(!openCollapsedMenu);
            alertMessage(
              openCollapsedMenu ? 'Collapsed header, collapsed,' : 'Collapsed header, expanded,',
            );
            navItems[0].focus();
          }
          changeBodyColor(!openCollapsedMenu);
          break;
        case 38:
          if (!menuState) {
            event.preventDefault();
            setOpenCollapsedMenu(!openCollapsedMenu);
            alertMessage(
              openCollapsedMenu ? 'Collapsed header, collapsed,' : 'Collapsed header, expanded,',
            );
            navItems[navItems.length - 1].focus();
          }
          changeBodyColor(!openCollapsedMenu);
          break;
        default:
          return false;
      }
    }
  };

  const handleHeaderLinks = item => {
    setOpenCollapsedMenu(false);
    history.push(`/${item.url}`);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    Cookies.remove('parent');
    Cookies.remove('learner');
    Cookies.remove('parent_selected_learner');
    logout({ returnTo: window.location.origin });
  };

  const handleCloseCollapsedMenu = () => {
    setOpenCollapsedMenu(false);
    changeBodyColor(false);
  };

  React.useEffect(() => {
    checkUserPrivileges();
  }, [checkUserPrivileges]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={`header_wrapper ${menuState && 'header_opacity'} ${change_layout.header &&
          change_layout.header.css}`}
      >
        <Box>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Box className="logo_wrapper">
              <img
                src={`${assets.url}/logo.svg`}
                role="button"
                type="button"
                onClick={() => history.push('/')}
                alt="Genius Forest Logo"
                className="logo"
                tabIndex="0"
              />
            </Box>
            {/* 
            TODO: needs to apply the functionality for search
            <Box id="header_text_field">
              <TextField
                id="search"
                label="Search"
                type="search"
                value={''}
                helperText=""
                error={false}
                handleChanges={() => {}}
                borderColor="#ECECEC"
                width={200}
                icon
              />
            </Box> */}
            {topNavLinks.map(item => (
              <Box key={item.name} className="buttons_wrapper" id={item.id}>
                <button
                  className={handleActiveItems(item.name.toLowerCase())}
                  onClick={() => handleHeaderLinks(item)}
                >
                  {item.name}
                </button>
              </Box>
            ))}
          </Grid>
        </Box>

        {/* < ------ Items Header  -------> */}
        {change_layout.header && !change_layout.header.hide_buttons && (
          <Box>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <Box>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  {isAuthenticated ? (
                    <>
                      {(subscription && userType === 'parent') || userType === 'learner' ? (
                        <Box key="profile_button" className="buttons_wrapper" id="profile_button">
                          <button
                            className={`${openCollapsedMenu &&
                              'profile_active'} header_profile header_items`}
                            onClick={handleCollapsedHeader}
                            onKeyDown={handleCollapsedHeader}
                          >
                            <div className="user_image_wrapper">
                              {user.picture ? (
                                <img
                                  src={user.picture}
                                  alt="Parent icon"
                                  className="profile_picture"
                                  aria-hidden={true}
                                />
                              ) : (
                                <PersonOutlineIcon className="profile_icon_default" />
                              )}
                            </div>
                            {userName}
                            {openCollapsedMenu ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                          </button>
                        </Box>
                      ) : (
                        <>
                          {userRoles && (
                            <Box className="buttons_wrapper">
                              <button
                                className="admin_name"
                                onClick={() => history.push('/admin-portal/questions')}
                              >
                                Hi, {user.nickname}
                              </button>
                            </Box>
                          )}
                          {!subscription && userType !== 'learner' && (
                            <Box className="buttons_wrapper buttons_spacing " id="subscription">
                              <Button
                                label="Subscription"
                                onClick={() => history.push('/plan-selection')}
                                width={120}
                                height={42}
                                buttonStyle="secondary"
                                fontSize={16}
                              />
                            </Box>
                          )}
                          <Box className="buttons_wrapper buttons_spacing " id="signIn">
                            <Button
                              label="Logout"
                              onClick={handleLogout}
                              width={91}
                              height={42}
                              buttonStyle="default"
                              fontSize={16}
                            />
                          </Box>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Box className="buttons_wrapper" id="login">
                        <button
                          className="header_items"
                          onClick={() => loginWithRedirect({ screen_hint: 'login' })}
                        >
                          Login
                        </button>
                      </Box>
                      <Box className="buttons_wrapper buttons_spacing " id="signIn">
                        <Button
                          label="Sign up"
                          onClick={() => history.push('/pre-signup')}
                          width={91}
                          height={42}
                          buttonStyle="primary"
                          fontSize={16}
                        />
                      </Box>
                    </>
                  )}

                  {/* < ------ SideNavbar (Tablet/Mobile)  -------> */}
                  <SideNavBar
                    topNavLinks={topNavLinks}
                    menuState={menuState}
                    setMenuState={setMenuState}
                    subscription={subscription}
                    changeBodyColor={changeBodyColor}
                    collapsedMenuItems={collapsedMenuItems}
                    userName={userName}
                    userType={userType}
                  />
                </Grid>
              </Box>
            </Grid>
          </Box>
        )}

        <CollapsedMenu
          subscription={subscription}
          handleCloseMenu={handleCloseCollapsedMenu}
          openCollapsedMenu={openCollapsedMenu}
          collapsedMenuItems={collapsedMenuItems}
        />
      </Grid>

      {/* <!------- ------- Screen Reader messages ----------------------> */}
      <div id="alert_wrapper" className="sr_only" role="alert">
        <span id="text_alert"></span>
      </div>
    </>
  );
}

export default withRouter(Header);

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  subscription: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.any)]),
};
