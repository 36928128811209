import React from 'react';

const Section_two = () => {
  return (
    <div className="section_two_wrapper">
      <div className="section_two_content_container">
        <div className="section_two_card">
          <h2 className="mobile_title">Tailored questions to fit individual goals</h2>
          <div className="image_container">
            <img
              src="/assets/images/product_section_two_card_one.svg"
              alt="product_section_two_card_one"
              className="product_section_two_card_image"
            />
            <img
              src="/assets/images/product_section_two_bg_one.png"
              alt="product_section_two_bg_one"
              className="product_section_two_bg_one"
            />
          </div>
          <p className="mobile_text">
            We believe that no two learners are the same. Our diagnostic system provides unique
            learning assessments based on a student's current skill level.
          </p>
          <div className="card_content_container">
            <img
              src="/assets/icons/text-spark.svg"
              alt="spark"
              aria-hidden={true}
              className="text_spark_icon"
            />
            <h2>Tailored questions to fit individual goals</h2>
            <p>
              We believe that no two learners are the same. Our diagnostic system provides unique
              learning assessments based on a student's current skill level.
            </p>
          </div>
        </div>
        <div className="section_two_card">
          <h2 className="mobile_title">Identify strong points and areas of improvements</h2>
          <div className="image_container">
            <img
              src="/assets/images/product_section_two_card_two.svg"
              alt="product_section_two_card_two"
              className="product_section_two_card_image"
            />
            <img
              src="/assets/images/product_section_two_bg_two.png"
              alt="product_section_two_bg_two"
              className="product_section_two_bg_two"
            />
          </div>
          <p className="mobile_text">
            Genius Forest provides recommended skills to help you get started based on students'
            level of understanding.
          </p>
          <div className="card_content_container">
            <img
              src="/assets/icons/text-spark.svg"
              alt="spark"
              aria-hidden={true}
              className="text_spark_icon"
            />
            <h2>Identify strong points and areas of improvements</h2>
            <p>
              Genius Forest provides recommended skills to help you get started based on students'
              level of understanding.
            </p>
          </div>
        </div>
        <div className="section_two_card">
          <h2 className="mobile_title">Monitor learning history and performance overtime</h2>
          <div className="image_container">
            <img
              src="/assets/images/product_section_two_card_three.svg"
              alt="product_section_two_card_three"
              className="product_section_two_card_image"
            />
            <img
              src="/assets/images/product_section_two_bg_three.png"
              alt="product_section_two_bg_three"
              className="product_section_two_bg_three"
            />
          </div>
          <p className="mobile_text">
            Parents and students receive live reporting of students' learning development to ensure
            you are reaching your goals.
          </p>
          <div className="card_content_container">
            <img
              src="/assets/icons/text-spark.svg"
              alt="spark"
              aria-hidden={true}
              className="text_spark_icon"
            />
            <h2>Monitor learning history and performance overtime</h2>
            <p>
              Parents and students receive live reporting of students' learning development to
              ensure you are reaching your goals.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_two;
