import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Controller } from "react-hook-form";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem } from '@material-ui/core';

const FormDropDown = ({
  name,
  control,
  defaultValue,
  children,
  list, selectedItem, handleChange, width, height, bgColor, id, error, helperText, label,
  ...props
}) => {
  const useStyles = makeStyles((theme) => ({
    label: {
      top: '-9px',
      left: '15px'
    },
    errorText: {
      color: theme.palette.error.main
    },
    customSelect: {
      zIndex: 1,
      width,
      height,
      marginBottom: error ? '0px' : '22px',
      fontFamily: theme.fontFamily.default,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '27px',
      color: theme.palette.primary.textField,
      '& .MuiSelect-select': {
        width: '100%',
      },
      '& .MuiSelect-select:focus': {
        background: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        zIndex: -1,
        background: bgColor,
        borderRadius: '7px',
        color: theme.palette.primary.textField,
        fontFamily: theme.fontFamily,
        border: `1px solid ${theme.palette.primary.textField}`,
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${theme.palette.primary.hover} !important`,
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          boxShadow: bgColor && `0 0 0 4px white,0 0 0 5px ${bgColor}`,
          border: bgColor ? 'none' : `2px solid ${theme.palette.primary.focus}`,
        },
      },
      '& .MuiSelect-icon': {
        color: theme.palette.default.text,
      },
    },
  }));
  
  const classes = useStyles();

  const [icon, setIcon] = React.useState(false);
  return (
    <FormControl {...props}>
      <InputLabel className={classes.label} id={id}>{label}</InputLabel>
      <Controller
        as={
          <Select 
            label={label}
            variant="outlined"
            labelId={id}
            id={id}
            defaultValue={selectedItem}
            // value={selectedItem}
            onChange={handleChange}
            onClick={() => setIcon(icon ? false : true)}
            error={error}
            className={classes.customSelect}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            inputProps={{MenuProps: {disableScrollLock: true}}}
          >
            {list.map((item, index) => {
                return (
                  <MenuItem value={item.value} key={index} disabled={item.disabled}>
                    {item.label}
                  </MenuItem>
                );
            })}
          </Select>
        }
        name={name}
        control={control}
      />
    </FormControl>
  );
};
export default FormDropDown;