import React, { useEffect } from 'react';
import TextField from '../textFields/textField';
import Button from '../buttons/button';
import { useForm } from 'react-hook-form';

const NewsLetterForm = ({ handleAddNewsletterEmail, errorTitle, errorState }) => {
  const onError = (errors, e) => {};

  const { register, handleSubmit, errors, setError } = useForm({
    defaultValues: {},
  });

  const handleForm = (formData, e) => {
    handleAddNewsletterEmail(formData);
  };

  useEffect(() => {
    if (errorState && errorTitle) {
      setError('email', {
        type: 'manual',
        message: errorTitle,
      });
    }
  }, [errorState, errorTitle]);

  return (
    <form id="news_letter_form" onSubmit={handleSubmit(handleForm, onError)}>
      <p id="subscribe_text">Subscribe to Our Newsletter</p>
      <div style={{ display: 'flex', gap: '7px' }}>
        <TextField
          type="text"
          name="email"
          label=""
          helperText={errors.email ? errors.email.message : ''}
          error={errors.email && true}
          width="245px"
          height="50px"
          backgroundColor="white"
          placeholder="Enter your email"
          register={register({
            required: 'Email is required',
            minLength: { value: 8, message: 'Min length 8' },
            maxLength: { value: 100, message: 'Max length 100' },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid Email Format',
            },
          })}
        />
        <Button
          label="Subscribe"
          addId="save_button"
          width={104}
          height={52}
          buttonStyle="primary"
          type="submit"
          onClick={() => {}}
        />
      </div>
    </form>
  );
};

export default NewsLetterForm;
